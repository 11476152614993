import { useInfiniteQuery } from '@tanstack/react-query';
import { ApiResponse, useMemoizedArrayData } from 'src/hooks/api/api';
import {
  TimelineItemSearchResponse,
  TimelineItemSearchBodyParams,
  CrmTimelineItemsService,
  SearcherTimelineItemDocument as TimelineItemDocument,
} from 'src/api/generated';
import { SEC_IN_MS } from 'src/utils/date';

interface UseTimelineItemsParams extends TimelineItemSearchBodyParams {}

interface UseTimelineItemsResponse extends ApiResponse {
  timelineItems: TimelineItemDocument[];
  fetchNextPage: () => void;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
}

export const useSearchTimelineItems = (
  params: UseTimelineItemsParams,
  enabled: boolean = true
): UseTimelineItemsResponse => {
  const fetchTimelineItems = async ({
    pageParam,
  }: {
    pageParam: string | undefined;
  }): Promise<TimelineItemSearchResponse> => {
    return CrmTimelineItemsService.searchTimelineItems({
      ...params,
      pageToken: pageParam,
    });
  };

  const getNextPageParam = (lastPage: TimelineItemSearchResponse) =>
    lastPage.nextPageToken;

  const {
    data,
    error,
    isLoading: loading,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['crm/timeline-items/search', params],
    queryFn: fetchTimelineItems,
    getNextPageParam,
    initialPageParam: undefined,
    enabled,
    refetchInterval: SEC_IN_MS * 5,
  });

  const timelineItems = useMemoizedArrayData<TimelineItemDocument>(
    data?.pages.flatMap((page) => page.items) || []
  );

  return {
    loading,
    error,
    timelineItems,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  };
};
