import { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { SearcherDocumentSearchFilter } from 'src/api/generated';
import { Filter, FilterFieldOptions } from '../../types/filters.types';
import { toApiFilter } from '../../utils/filters/mapper.util';

/**
 * Get effective filters by applying override filters to the current filters
 * @param currentFilters - Current filters
 * @param overrideFilters - Override filters
 * @returns Effective filters
 */
const getEffectiveFilters = (
  currentFilters: Filter[],
  overrides: Filter[]
): Filter[] => {
  if (!overrides.length) return currentFilters;

  const overrideFieldIds = new Set(
    overrides.map((filter) => filter.fieldOptionId)
  );

  const otherFilters = currentFilters.filter(
    (filter) => !overrideFieldIds.has(filter.fieldOptionId)
  );

  return [...otherFilters, ...overrides];
};
/**
 * Parameters for the useInitializeFilters hook
 */
interface UseInitializeFiltersParams {
  /** Current filters */
  filters: Filter[];
  /** Initial filters to apply */
  initialFilters: Filter[];
  /** Function to update filters */
  setFilters: (filters: Filter[]) => void;
}

/**
 * Result of the useInitializeFilters hook
 */
interface UseInitializeFiltersResult {
  /** Clear the initialization state */
  clearInitialization: () => void;
}

/**
 * Hook to handle the initialization of filters
 * @param params.filters - Current filters
 * @param params.initialFilters - Initial filters to apply
 * @param params.setFilters - Function to update filters
 * @returns Object with function to clear initialization state
 */
const useInitializeFilters = ({
  filters,
  initialFilters,
  setFilters,
}: UseInitializeFiltersParams): UseInitializeFiltersResult => {
  const initializedFieldIds = useRef<Set<string>>(new Set());

  useEffect(() => {
    if (!initialFilters.length) return;

    const newFilters = [...filters];
    let hasChanges = false;

    initialFilters.forEach((initFilter) => {
      const fieldAlreadyInitialized = initializedFieldIds.current.has(
        initFilter.fieldOptionId
      );
      if (fieldAlreadyInitialized) return;

      const fieldAlreadyFiltered = filters.some(
        (filter) => filter.fieldOptionId === initFilter.fieldOptionId
      );
      if (fieldAlreadyFiltered) return;

      newFilters.push(initFilter);
      initializedFieldIds.current.add(initFilter.fieldOptionId);
      hasChanges = true;
    });

    if (hasChanges) {
      setFilters(newFilters);
    }
  }, [initialFilters, filters, setFilters]);

  const clearInitialization = useCallback(() => {
    initializedFieldIds.current.clear();
  }, []);

  return { clearInitialization };
};

/**
 * Options for the useFilters hook
 */
export interface UseFiltersParams {
  /** Initial filters to apply */
  initialFilters?: Filter[];
  /** Available fields for filtering */
  fieldsOptions: FilterFieldOptions[];
  /** Filters that always override any existing filters with the same fieldOptionId */
  overrideFilters?: Filter[];
}

/**
 * Result of the useFilters hook
 */
export interface UseFiltersResult {
  /** Current filters in component format */
  filters: Filter[];
  /** Current filters in API format (readonly) */
  apiFilters: SearcherDocumentSearchFilter[];
  /** Available fields for filtering */
  fieldsOptions: FilterFieldOptions[];
  /** Number of active filters */
  activeFilterCount: number;
  /** Update filters */
  setFilters: (filters: Filter[]) => void;
  /** Clear all filters */
  clearFilters: () => void;
}

/**
 * A generic hook for managing document filters
 * @param params.fieldsOptions - Available fields for filtering
 * @param params.initialFilters - Initial filters
 * @param params.overrideFilters - Filters that always override any existing filters with the same fieldOptionId
 */
export const useFilters = ({
  fieldsOptions,
  initialFilters = [],
  overrideFilters = [],
}: UseFiltersParams): UseFiltersResult => {
  const [filters, setFilters] = useState<Filter[]>(initialFilters);

  const { clearInitialization } = useInitializeFilters({
    filters,
    initialFilters,
    setFilters,
  });

  const effectiveFilters = useMemo(() => {
    return getEffectiveFilters(filters, overrideFilters);
  }, [filters, overrideFilters]);

  const apiFilters = useMemo(() => {
    return effectiveFilters.map(toApiFilter);
  }, [effectiveFilters]);

  const activeFilterCount = useMemo(() => {
    return effectiveFilters.length;
  }, [effectiveFilters]);

  const clearFilters = useCallback(() => {
    setFilters([]);
    clearInitialization();
  }, [clearInitialization]);

  return {
    filters: effectiveFilters,
    apiFilters,
    setFilters,
    fieldsOptions,
    activeFilterCount,
    clearFilters,
  };
};
