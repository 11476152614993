import TextInput from '../../../../atoms/inputs/text-input';
import {
  InlineEditableInputWrapper,
  InlineEditableInputWrapperProps,
} from '../inline-editable-input-wrapper';

interface InlineTextInputProps
  extends Omit<InlineEditableInputWrapperProps, 'children'> {
  placeholder?: string;
}

export const InlineTextInput: React.FC<InlineTextInputProps> = ({
  placeholder,
  ...props
}) => {
  return (
    <InlineEditableInputWrapper {...props}>
      {({ value, onChange, disabled }) => (
        <TextInput
          className="w-full h-11 !px-3 !pr-[5rem] !py-0"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder || props.label}
          size={props.size}
          autoFocus
          neumorphic="inset-sm"
          disabled={disabled}
        />
      )}
    </InlineEditableInputWrapper>
  );
};
