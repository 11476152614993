import { useQuery } from '@tanstack/react-query';
import {
  CrmCustomFieldsDefinitionsService,
  CustomFieldDefinition,
  CustomFieldDefinitionGetResponse,
  CustomFieldDefinitionListQueryParams,
  CustomFieldDefinitionListResponse,
  CustomFieldEntityType,
} from 'src/api/generated';
import { ApiResponse, useMemoizedArrayData } from 'src/hooks/api/api';
import { createQuery } from 'src/modules/shared/utils/api/query-builder.util';
import { MINUTE_IN_MS } from 'src/utils/date';

interface UseCustomFieldDefinitionsParams
  extends CustomFieldDefinitionListQueryParams {
  entityType?: CustomFieldEntityType;
  enabled?: boolean;
}

interface UseCustomFieldDefinitionsResponse extends ApiResponse {
  definitions: CustomFieldDefinition[];
}

/**
 * Hook for fetching custom field definitions
 * Optionally filter by entity type
 */
export const useCustomFieldDefinitions = (
  params: UseCustomFieldDefinitionsParams = {}
): UseCustomFieldDefinitionsResponse => {
  const { entityType, enabled = true, ...queryParams } = params;

  let filterBy = queryParams.filterBy || '';
  if (entityType) {
    const entityTypeFilter = createQuery()
      .equals('entityType', entityType)
      .build();
    filterBy = filterBy
      ? `${filterBy};${entityTypeFilter.filterBy}`
      : `${entityTypeFilter.filterBy}`;
  }

  const fetchDefinitions =
    async (): Promise<CustomFieldDefinitionListResponse> => {
      return await CrmCustomFieldsDefinitionsService.getCustomFieldDefinitions(
        filterBy,
        queryParams.searchBy,
        queryParams.sortBy,
        queryParams.page,
        queryParams.pageSize,
        queryParams.pageToken
      );
    };

  const {
    data,
    error,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['crm/custom-fields/definitions', { ...params }],
    queryFn: fetchDefinitions,
    enabled,
    staleTime: MINUTE_IN_MS * 10,
  });

  const definitions = useMemoizedArrayData<CustomFieldDefinition>(
    data?.items || []
  );

  return {
    loading,
    error,
    definitions,
    refetch,
  };
};

interface UseCustomFieldDefinitionResponse extends ApiResponse {
  definition: CustomFieldDefinition | null;
}

/**
 * Hook for fetching a single custom field definition by ID
 */
export const useCustomFieldDefinition = (
  id: number,
  enabled: boolean = true
): UseCustomFieldDefinitionResponse => {
  const fetchDefinition =
    async (): Promise<CustomFieldDefinitionGetResponse> => {
      return await CrmCustomFieldsDefinitionsService.getCustomFieldDefinition(
        id
      );
    };

  const {
    data,
    error,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['crm/custom-fields/definitions', id],
    queryFn: fetchDefinition,
    enabled,
  });

  return {
    loading,
    error,
    definition: data || null,
    refetch,
  };
};
