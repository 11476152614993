import { useState, useEffect } from 'react';
import {
  PiXLight,
  PiCheckBold,
  PiTrashLight,
  PiCaretDownLight,
  PiCopyLight,
  PiCheckLight,
  PiPhone,
  PiEnvelope,
} from 'react-icons/pi';
import Button from '../../../../../shared/components/atoms/buttons/button';
import { motion, AnimatePresence } from 'framer-motion';
import { ChannelIcon } from '../../../../../shared/components/atoms/icons/channel-icon';
import { ChannelType } from 'src/api/generated';
import Loading from '../../../../../shared/components/atoms/loading';
import TextInput from '../../../../../shared/components/atoms/inputs/text-input';
import Dropdown from '../../../../../shared/components/molecules/dropdowns/dropdown';
import { isValidPhoneNumber } from 'src/utils/validation/phone';
import Tooltip from 'src/modules/shared/components/atoms/tooltips/tooltip';
import { Typography } from '@material-tailwind/react';
import { ComponentSize } from 'src/modules/shared/types/component.type';
import { isValidEmail } from 'src/modules/shared/utils/validation/email.util';

interface EditableAddressFieldProps {
  address: string;
  channelType: ChannelType;
  onSave: (data: {
    address: string;
    channelType?: ChannelType;
  }) => Promise<void>;
  onDelete?: () => Promise<void>;
  onAction?: (address: string) => void;
  loading?: boolean;
  placeholder?: string;
  onCancel?: () => void;
  startEditing?: boolean;
  allowChannelTypeChange?: boolean;
  actionDisabled?: boolean;
  actionLoading?: boolean;
  actionTooltip?: string;
  size?: ComponentSize;
}

// TODO: check when implementing i18n
const CHANNEL_TYPE_OPTIONS: { value: ChannelType; label: string }[] = [
  { value: ChannelType.EMAIL, label: 'Correo' },
  { value: ChannelType.PHONE, label: 'Teléfono' },
];

const inputSizeMap: Record<ComponentSize, string> = {
  '2xs': 'h-7',
  xs: 'h-8',
  sm: 'h-9',
  md: 'h-11',
  lg: 'h-14',
};

// TODO: make this component reusable for other purposes
// input wrapper with action buttons: copy, edit, delete (like @EditableTextField)
export const EditableAddressField = ({
  address,
  channelType,
  onSave,
  onDelete,
  onAction = () => {},
  loading = false,
  placeholder,
  onCancel,
  startEditing = false,
  allowChannelTypeChange = false,
  actionDisabled = false,
  actionLoading = false,
  actionTooltip,
  size = 'md',
}: EditableAddressFieldProps) => {
  const [isEditing, setIsEditing] = useState(startEditing);
  const [editedAddress, setEditedAddress] = useState(address);
  const [editedChannelType, setEditedChannelType] = useState(channelType);
  const [isCopied, setIsCopied] = useState(false);

  const isPhoneAddress =
    channelType === ChannelType.PHONE || channelType === ChannelType.WHATSAPP;

  const isEmailAddress = channelType === ChannelType.EMAIL;

  const isAddressValid =
    (isPhoneAddress && isValidPhoneNumber(address)) ||
    (isEmailAddress && isValidEmail(address));

  useEffect(() => {
    setIsEditing(startEditing);
  }, [startEditing]);

  const handleSave = async () => {
    try {
      await onSave({
        address: editedAddress,
        ...(allowChannelTypeChange && { channelType: editedChannelType }),
      });
      setIsEditing(false);
    } catch (error) {
      console.error('Failed to save address:', error);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedAddress(address);
    setEditedChannelType(channelType);
    onCancel?.();
  };

  const handleDelete = async () => {
    if (onDelete) {
      const confirmed = window.confirm(
        // TODO: check when implementing i18n
        '¿Quieres eliminar esta dirección?'
      );
      if (!confirmed) return;

      try {
        await onDelete();
      } catch (error) {
        console.error('Failed to delete address:', error);
      }
    }
  };

  const handleCopy = async () => {
    if (!address) return;

    try {
      await navigator.clipboard.writeText(address);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (error) {
      console.error('Error while copying address:', error);
    }
  };

  const handleAction = () => {
    if (!onAction || !isAddressValid || actionDisabled) return;

    if (isEmailAddress) {
      window.location.href = `mailto:${address}`;
      return;
    }

    onAction(address);
  };

  const getInputType = () => {
    switch (editedChannelType) {
      case ChannelType.EMAIL:
        return 'email';
      case ChannelType.PHONE:
        return 'tel';
      default:
        return 'text';
    }
  };

  const getInputPattern = () => {
    switch (editedChannelType) {
      case ChannelType.PHONE:
        return '[0-9]*';
      default:
        return undefined;
    }
  };

  const getActionIcon = () => {
    if (isPhoneAddress) {
      return <PiPhone className="text-xl" />;
    }
    if (isEmailAddress) {
      return <PiEnvelope className="text-xl" />;
    }
    return null;
  };

  const getActionClassName = () => {
    if (isPhoneAddress) return '!text-call-500 hover:!text-call-400';
    if (isEmailAddress) return '!text-email-500 hover:!text-email-400';
    return '';
  };

  const iconSize = 'text-lg';
  const buttonSpacing = 'gap-1.5';
  const inputSize = inputSizeMap[size ?? 'md'];

  return (
    <div className="group relative">
      <div className="flex items-center">
        <AnimatePresence>
          {isEditing ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="flex-1 w-full"
            >
              <div
                className={`relative bg-gray-50 rounded-lg ${inputSize} w-full`}
              >
                {allowChannelTypeChange && (
                  <div className="absolute left-2 top-1/2 -translate-y-1/2 z-10">
                    <Dropdown
                      handler={
                        <Button
                          variant="secondary"
                          appearance="ghost"
                          disabled={loading}
                          size={size}
                          className="flex items-center gap-1.5"
                        >
                          <ChannelIcon channelType={editedChannelType} />
                          <PiCaretDownLight className={iconSize} />
                        </Button>
                      }
                      items={CHANNEL_TYPE_OPTIONS.map((option) => ({
                        icon: <ChannelIcon channelType={option.value} />,
                        content: option.label,
                        onClick: () => setEditedChannelType(option.value),
                        disabled: loading,
                        className: 'w-4',
                      }))}
                      size={size}
                      placement="bottom-start"
                    />
                  </div>
                )}
                <TextInput
                  className={`w-full ${inputSize} !py-0 ${
                    allowChannelTypeChange
                      ? '!pl-[5rem] !pr-[5rem]'
                      : '!px-3 !pr-[5rem]'
                  }`}
                  value={editedAddress}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setEditedAddress(e.target.value)
                  }
                  // TODO: check when implementing i18n
                  placeholder={placeholder || 'Ingrese dirección...'}
                  autoFocus
                  neumorphic="inset-sm"
                  disabled={loading}
                  type={getInputType()}
                  pattern={getInputPattern()}
                  size={size}
                />
                <div
                  className={`absolute right-2 top-1/2 -translate-y-1/2 flex items-center ${buttonSpacing}`}
                >
                  <Button
                    variant="neutral"
                    appearance="ghost"
                    onClick={handleCancel}
                    disabled={loading}
                    isIconOnly
                    size={size}
                  >
                    <PiXLight className={iconSize} />
                  </Button>
                  <Button
                    variant="primary"
                    onClick={handleSave}
                    disabled={loading}
                    isIconOnly
                    size={size}
                  >
                    {loading ? (
                      <Loading
                        size="2xs"
                        variant="primary"
                        appearance="outline"
                      />
                    ) : (
                      <PiCheckLight className={iconSize} />
                    )}
                  </Button>
                </div>
              </div>
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className={`flex-1 flex items-center justify-between py-0.5`}
            >
              <div className="flex items-center gap-0.5">
                <Tooltip size="sm" content={actionTooltip}>
                  <Button
                    variant="primary"
                    appearance="ghost"
                    size={size}
                    onClick={handleAction}
                    disabled={actionDisabled || !isAddressValid || !onAction}
                    loading={actionLoading}
                    isIconOnly
                    className={`${getActionClassName()} group-hover:opacity-80 hover:opacity-100`}
                  >
                    {getActionIcon()}
                  </Button>
                </Tooltip>

                {/* TODO: check when implementing i18n */}
                <Tooltip size="sm" content="Presiona para editar">
                  <Button
                    variant="primary"
                    appearance="ghost"
                    size={size}
                    onClick={() => setIsEditing(true)}
                    className={`${getActionClassName()} group-hover:bg-gray-100`}
                  >
                    {address ? (
                      <Typography className="truncate text-xs font-medium">
                        {address}
                      </Typography>
                    ) : (
                      <Typography className="truncate text-xs font-medium text-secondary-500">
                        —
                      </Typography>
                    )}
                  </Button>
                </Tooltip>

                {address && (
                  <Tooltip
                    size="sm"
                    // TODO: check when implementing i18n
                    content={isCopied ? '¡Copiado!' : 'Copiar'}
                  >
                    <Button
                      variant="neutral"
                      appearance="ghost"
                      size={size}
                      onClick={handleCopy}
                      isIconOnly
                      className="opacity-0 group-hover:opacity-100 transition-opacity"
                    >
                      {isCopied ? (
                        <PiCheckBold className={`${iconSize} text-green-500`} />
                      ) : (
                        <PiCopyLight className={iconSize} />
                      )}
                    </Button>
                  </Tooltip>
                )}

                {/* Delete button */}
                {onDelete && (
                  <Button
                    variant="danger"
                    appearance="ghost"
                    size={size}
                    onClick={handleDelete}
                    isIconOnly
                    className="text-danger-500 opacity-0 group-hover:opacity-100 transition-opacity"
                    disabled={loading}
                  >
                    {loading ? (
                      <Loading
                        size="2xs"
                        variant="primary"
                        appearance="outline"
                      />
                    ) : (
                      <PiTrashLight className={iconSize} />
                    )}
                  </Button>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};
