import {
  UseSortResult,
  useSorts,
} from 'src/modules/shared/hooks/filters/sorts';
import { useConversationFields } from './fields';
import { useMemo } from 'react';
import { Sort } from 'src/modules/shared/types/filters.types';
import { SearcherDocumentSearchSortDirection } from 'src/api/generated';

export type UseConversationSortsResult = UseSortResult;

export function useConversationSorts(): UseConversationSortsResult {
  const { sortFieldsOptions } = useConversationFields();

  const initialSorts = useMemo<Sort[]>(
    () => [
      {
        id: `sort-${Date.now()}-lastInteractionAt`,
        fieldOptionId: 'lastInteractionAt',
        direction: SearcherDocumentSearchSortDirection.DESC,
      },
    ],
    []
  );

  const { sorts, apiSorts, setSorts, activeSortCount, clearSorts } = useSorts({
    fieldsOptions: sortFieldsOptions,
    initialSorts,
  });

  return {
    sorts,
    apiSorts,
    setSorts,
    activeSortCount,
    fieldsOptions: sortFieldsOptions,
    clearSorts,
  };
}
