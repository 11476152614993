import { ReactNode } from 'react';
import { PiCopyLight, PiCheckBold } from 'react-icons/pi';
import Button from '../../../atoms/buttons/button';
import Label from '../../../atoms/inputs/label';
import {
  ComponentVariant,
  ComponentSize,
} from 'src/modules/shared/types/component.type';
import { useCopyToClipboard } from 'src/modules/shared/hooks/copy-to-clipboard';

interface InlineInputProps<T> {
  value: T;
  onChange: (value: T) => void;
  disabled: boolean;
  loading?: boolean;
}

export interface InlineInputWrapperProps<T = string> {
  label: string;
  value: T;
  onSave: (value: T) => Promise<void>;
  loading?: boolean;
  size?: ComponentSize;
  variant?: ComponentVariant;
  disabled?: boolean;
  disableCopy?: boolean;
  onError?: (error: Error) => void;
  onSuccess?: () => void;
  children: (props: InlineInputProps<T>) => ReactNode;
}

/**
 * A wrapper component for inline-editable fields that change immediately
 * without an explicit edit/save mode (like checkboxes, selects, etc.)
 */
export const InlineInputWrapper = <T,>({
  label,
  value,
  onSave,
  loading = false,
  size = 'sm',
  variant = 'primary',
  disabled = false,
  disableCopy = false,
  onError,
  onSuccess,
  children,
}: InlineInputWrapperProps<T>) => {
  const { copyToClipboard, isCopied } = useCopyToClipboard();

  const handleChange = async (newValue: T) => {
    try {
      await onSave(newValue);
      onSuccess?.();
    } catch (error) {
      onError?.(error as Error);
    }
  };

  const iconSize = size === 'sm' ? 'text-lg' : 'text-xl';

  return (
    <div className="group relative">
      <div className="flex items-center justify-between w-full p-2">
        <Label
          size={size}
          variant={variant}
          className="flex-shrink-0 font-light max-w-[40%] break-words"
        >
          {label}:
        </Label>
        <div className="flex-1 flex items-center justify-end min-w-[40%]">
          <div className="flex items-center gap-2">
            {value && !disableCopy && (
              <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                <Button
                  variant="neutral"
                  appearance="ghost"
                  size={size}
                  onClick={() => copyToClipboard(String(value))}
                  // TODO: check when implementing i18n
                  title={isCopied ? 'Copiado!' : 'Copiar'}
                  isIconOnly
                  disabled={disabled}
                >
                  {isCopied ? (
                    <PiCheckBold className={`${iconSize} text-success-500`} />
                  ) : (
                    <PiCopyLight className={iconSize} />
                  )}
                </Button>
              </div>
            )}
            <div className="flex-grow">
              {children({
                value: value,
                onChange: handleChange,
                disabled: loading || disabled,
                loading: loading,
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
