import { useCallback, useMemo, useState } from 'react';
import { useFunnelConversationDocuments } from '../../../../hooks/api';
import {
  FunnelConversationDocument,
  SearcherDocumentSearchFilter,
  SearcherDocumentSearchFilterOperator,
  SearcherDocumentSearchSort,
  CustomFieldEntityType,
} from 'src/api/generated';
import {
  Table,
  TableColumnProps,
} from 'src/modules/shared/components/molecules/table/table';
import { PiChats } from 'react-icons/pi';
import { useUsers } from 'src/modules/shared/admin/hooks/api/users';
import { useFunnelStatuses } from '../../../../hooks/api';
import { createQuery } from 'src/modules/shared/utils/api/query-builder.util';
import { useUpdateFunnelConversation } from '../../../../hooks/api/funnels/funnel-conversations-mutations';
import { useBulkUpdateFunnelConversation } from '../../../../hooks/api/funnels/funnel-conversations-bulk-mutations';
import { TokenPagination } from 'src/modules/shared/components/molecules/paginations/token-pagination';
import { BulkActions } from '../../../molecules/bulk-actions/bulk-actions';
import { Panel } from 'src/modules/shared/components/molecules/panel';
import { Dialog } from 'src/modules/shared/components/molecules/dialog';
import { ErrorState } from 'src/modules/shared/components/atoms/states/error-state';
import { useCustomFieldDefinitions } from '../../../../hooks/api/custom-fields';
import { createBasicColumns } from './column-renderers/basic-columns';
import {
  createConversationCustomFieldColumns,
  createContactCustomFieldColumns,
} from './column-renderers/custom-field-column';
import { useGlobalCallProviderContext } from 'src/contexts/global-call-provider-context';
import { usePhoneContext } from 'src/contexts/phone-context';
import { useGlobalPhoneContext } from 'src/contexts/global-phone-context';
import STATES from 'states/index';
import { useAlert } from 'src/contexts/alert-context';
import { useUpdateContactAddressMutation } from 'src/modules/communications/hooks/api/contact-addresses.mutation';

interface ConversationTableViewProps {
  selectedFunnelId?: number;
  searchTerm?: string;
  onConversationSelect?: (id: number) => void;
  selectedConversationId?: number;
  filters: SearcherDocumentSearchFilter[];
  sorts?: SearcherDocumentSearchSort[];
}

export const ConversationTableView = ({
  selectedFunnelId,
  searchTerm,
  onConversationSelect,
  selectedConversationId,
  filters,
  sorts = [],
}: ConversationTableViewProps) => {
  const [selectedConversationIds, setSelectedConversationIds] = useState<
    string[]
  >([]);
  const [pageSize, setPageSize] = useState(20);
  const [showBulkConfirmation, setShowBulkConfirmation] = useState(false);
  const [pendingBulkAction, setPendingBulkAction] = useState<{
    userId: number | null;
  } | null>(null);
  const [updatingAddressId, setUpdatingAddressId] = useState<number | null>(
    null
  );
  const { showAlert } = useAlert();

  const { users } = useUsers();
  const {
    callActions,
    loading: callProviderLoading,
    error: callProviderError,
  } = useGlobalCallProviderContext();
  const { phoneState } = usePhoneContext();
  const { setGlobalPhoneVisible } = useGlobalPhoneContext();

  const isPhoneReady = phoneState === STATES.PHONE.READY;
  const isPhoneDisabled =
    phoneState === STATES.PHONE.ON_CALL ||
    phoneState === STATES.PHONE.INCOMING ||
    phoneState === STATES.PHONE.OFFLINE ||
    phoneState === STATES.PHONE.ERROR;

  const { definitions: conversationCustomFields } = useCustomFieldDefinitions({
    entityType: CustomFieldEntityType.FUNNEL_CONVERSATION,
  });

  const { definitions: contactCustomFields } = useCustomFieldDefinitions({
    entityType: CustomFieldEntityType.CONTACT,
  });

  const { updateAddress } = useUpdateContactAddressMutation(
    () => {
      setUpdatingAddressId(null);
    },
    (error) => {
      console.error('Failed to update phone address:', error);
      showAlert(
        'Hubo un error al actualizar el teléfono. Por favor, inténtalo de nuevo.',
        'error'
      );
      setUpdatingAddressId(null);
    }
  );

  const statusQuery = useMemo(() => {
    if (!selectedFunnelId) return {};
    return createQuery()
      .equals('funnelId', selectedFunnelId)
      .orderByAsc('position')
      .build();
  }, [selectedFunnelId]);

  const { statuses } = useFunnelStatuses(statusQuery, !!selectedFunnelId);
  const { updateConversation, loading: updatingConversation } =
    useUpdateFunnelConversation();

  const onBulkUpdateSuccess = useCallback(() => {
    setSelectedConversationIds([]);
    setShowBulkConfirmation(false);
    setPendingBulkAction(null);
  }, []);
  const { bulkUpdateConversations, loading: updatingBulkConversations } =
    useBulkUpdateFunnelConversation(onBulkUpdateSuccess);

  const handleStatusChange = useCallback(
    async (conversationId: number, statusId: number) => {
      await updateConversation({
        id: conversationId,
        data: { funnelStatusId: statusId },
      });
    },
    [updateConversation]
  );

  const handleUserAssign = useCallback(
    async (conversationId: number, userId: number | null) => {
      await updateConversation({
        id: conversationId,
        data: { userId },
      });
    },
    [updateConversation]
  );

  const handleCall = useCallback(
    (phoneNumber: string) => {
      if (callProviderLoading || !isPhoneReady) return;

      setGlobalPhoneVisible(true);
      callActions.startCall({ phoneNumber });
    },
    [callActions, callProviderLoading, isPhoneReady, setGlobalPhoneVisible]
  );

  const handleUpdateAddress = useCallback(
    async (addressId: number, data: { address: string }) => {
      setUpdatingAddressId(addressId);
      await updateAddress({ id: addressId, data });
    },
    [updateAddress]
  );

  const handleBulkUserAssign = useCallback(async (userId: number | null) => {
    setPendingBulkAction({ userId });
    setShowBulkConfirmation(true);
  }, []);

  const handleConfirmBulkAction = useCallback(async () => {
    if (!pendingBulkAction) return;

    await bulkUpdateConversations({
      conversationIds: selectedConversationIds.map(Number),
      data: { userId: pendingBulkAction.userId },
    });
  }, [pendingBulkAction, selectedConversationIds, bulkUpdateConversations]);

  const searchParams = useMemo(() => {
    if (!selectedFunnelId) return {};

    const baseFilters: SearcherDocumentSearchFilter[] = [
      {
        field: 'funnelId',
        operator: SearcherDocumentSearchFilterOperator.EQ,
        value: selectedFunnelId.toString(),
      },
    ];

    return {
      search: searchTerm,
      filters: [...baseFilters, ...filters],
      ...(sorts.length > 0 && { sort: sorts }),
      includeTotalCount: true,
      size: pageSize,
    };
  }, [selectedFunnelId, searchTerm, filters, sorts, pageSize]);

  const {
    conversations,
    loading,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    totalCount,
  } = useFunnelConversationDocuments(searchParams, !!selectedFunnelId);

  const columns = useMemo<
    TableColumnProps<FunnelConversationDocument>[]
  >(() => {
    const consolidatedPhoneState = {
      isLoading: callProviderLoading,
      hasError: !!callProviderError,
      isReady: isPhoneReady,
      isDisabled: isPhoneDisabled,
    };

    const baseColumns = createBasicColumns({
      users:
        users?.map((user) => ({
          id: user.id,
          firstName: user.firstName || '',
          lastName: user.lastName || '',
        })) || [],
      statuses,
      selectedConversationId,
      onConversationSelect,
      handleStatusChange,
      handleUserAssign,
      handleCall,
      phoneState: consolidatedPhoneState,
      updatingConversation,
      variant: 'primary',
      handleUpdateAddress,
      updatingAddressId: updatingAddressId || undefined,
    });

    const conversationCustomFieldColumns = createConversationCustomFieldColumns(
      {
        customFields: conversationCustomFields.map((field) => ({
          fieldKey: field.fieldKey,
          fieldLabel: field.fieldLabel || undefined,
          fieldType: field.fieldType,
        })),
        variant: 'primary',
      }
    );

    const contactCustomFieldColumns = createContactCustomFieldColumns({
      customFields: contactCustomFields.map((field) => ({
        fieldKey: field.fieldKey,
        fieldLabel: field.fieldLabel || undefined,
        fieldType: field.fieldType,
      })),
      variant: 'primary',
    });

    return [
      ...baseColumns.slice(0, baseColumns.length - 1),

      ...conversationCustomFieldColumns,
      ...contactCustomFieldColumns,

      baseColumns[baseColumns.length - 1],
    ];
  }, [
    callProviderLoading,
    callProviderError,
    isPhoneReady,
    isPhoneDisabled,
    users,
    statuses,
    selectedConversationId,
    onConversationSelect,
    handleStatusChange,
    handleUserAssign,
    handleCall,
    updatingConversation,
    conversationCustomFields,
    contactCustomFields,
    handleUpdateAddress,
    updatingAddressId,
  ]);

  const handleSelectionChange = (selectedIds: string[]) => {
    setSelectedConversationIds(selectedIds);
  };

  const handlePageSizeChange = (newSize: number) => {
    setPageSize(newSize);
  };

  if (error) {
    return (
      // TODO: check when implementing i18n
      <ErrorState
        title="Error al cargar conversaciones"
        description={error.message}
        onAction={() => window.location.reload()}
      />
    );
  }

  return (
    <div className="flex flex-col h-full p-4 gap-1">
      <TokenPagination
        pageSize={pageSize}
        totalCount={totalCount}
        currentCount={conversations.length}
        onPageSizeChange={handlePageSizeChange}
        onNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isLoading={isFetchingNextPage}
        centerContent={
          <BulkActions
            selectedCount={selectedConversationIds.length}
            onUserAssign={handleBulkUserAssign}
            isLoading={updatingBulkConversations}
          />
        }
      />

      <Panel className="h-full min-h-0 pt-0">
        <Table<FunnelConversationDocument>
          columns={columns}
          data={conversations}
          keyExtractor={(item: FunnelConversationDocument) => item.id}
          selectedIds={selectedConversationIds}
          onSelectionChange={handleSelectionChange}
          variant="primary"
          isLoading={loading}
          emptyStateIcon={<PiChats className="w-4 h-4 text-secondary-500" />}
          emptyStateMessage={'No hay conversaciones'}
          headerClassName="min-w-[200px]"
          fixedFirstColumn
        />
      </Panel>

      <Dialog
        isOpen={showBulkConfirmation}
        onClose={() => {
          setShowBulkConfirmation(false);
          setPendingBulkAction(null);
        }}
        // TODO: check when implementing i18n
        title="Confirmar actualización"
        description="Esta acción actualizará múltiples conversaciones en segundo plano. Los cambios pueden tardar unos momentos en reflejarse en el sistema. ¿Quieres proceder?"
        confirmLabel="Proceder"
        cancelLabel="Cancelar"
        onConfirm={handleConfirmBulkAction}
        isLoading={updatingBulkConversations}
      />
    </div>
  );
};
