import { CustomFieldType } from 'src/api/generated';
import { CUSTOM_FIELD_TYPE_PREFIX } from '../constants/custom-fields.const';
import { CustomField } from '../types/custom-fields.type';

/**
 * Generates a prefixed custom field key based on field type
 */
export function getCustomFieldKey(
  fieldType: CustomFieldType,
  fieldKey: string
): string {
  const prefix = CUSTOM_FIELD_TYPE_PREFIX[fieldType];
  if (!prefix) {
    throw new Error(`Unsupported field type: ${fieldType}`);
  }

  return `${prefix}${fieldKey}`;
}

/**
 * Parses a custom field key to extract its type and original key
 */
export function parseCustomFieldKey(key: string): {
  type: CustomFieldType;
  originalKey: string;
} {
  const matchingEntry = Object.entries(CUSTOM_FIELD_TYPE_PREFIX).find(
    ([_, prefix]) => key.startsWith(prefix)
  );

  if (!matchingEntry) {
    return {
      type: CustomFieldType.STRING,
      originalKey: key,
    };
  }

  const [typeKey, prefix] = matchingEntry;
  return {
    type: typeKey as unknown as CustomFieldType,
    originalKey: key.substring(prefix.length),
  };
}

/**
 * Converts a value to its correct type based on the field type.
 * Used both for parsing incoming data and for transforming values before saving.
 */
export function parseCustomFieldValue(
  value: unknown,
  fieldType: CustomFieldType
): unknown {
  if (value === null || value === undefined) {
    return null;
  }

  const stringValue = String(value);
  if (stringValue === '') return null;

  switch (fieldType) {
    case CustomFieldType.NUMBER:
      return isNaN(Number(stringValue)) ? null : Number(stringValue);
    case CustomFieldType.BOOLEAN:
      return stringValue.toLowerCase() === 'true';
    case CustomFieldType.DATE:
      return stringValue;
    case CustomFieldType.LIST:
      return stringValue;
    case CustomFieldType.STRING:
    default:
      return stringValue;
  }
}

/**
 * Formats a custom field value to string for display
 */
export function formatCustomFieldValue(
  value: unknown,
  fieldType: CustomFieldType
): string {
  if (value === null || value === undefined) {
    return '';
  }

  const stringValue = String(value);

  switch (fieldType) {
    case CustomFieldType.BOOLEAN:
      return Boolean(value).toString();
    case CustomFieldType.LIST:
      if (!stringValue || stringValue === 'null') return '';
      return stringValue;
    case CustomFieldType.DATE:
    case CustomFieldType.NUMBER:
    case CustomFieldType.STRING:
    default:
      return stringValue;
  }
}

/**
 * Creates a formatted field label by removing prefixes and formatting
 */
export function formatCustomFieldLabel(name: string): string {
  const prefixes = Object.values(CUSTOM_FIELD_TYPE_PREFIX);

  let result = name;
  for (const prefix of prefixes) {
    if (name.startsWith(prefix)) {
      result = name.substring(prefix.length);
      break;
    }
  }

  return result
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

/**
 * Creates a CustomField object from definition data
 */
export function createCustomFieldFromDefinition(
  definitionId: number,
  fieldKey: string,
  fieldType: CustomFieldType,
  label: string | null | undefined,
  value: unknown,
  options?: Array<{ label: string; value: string }>
): CustomField {
  const name = label || formatCustomFieldLabel(fieldKey);

  const parsedValue = parseCustomFieldValue(value, fieldType);

  return {
    id: fieldKey,
    name,
    type: fieldType,
    value: parsedValue,
    required: false,
    definitionId,
    options,
  };
}
