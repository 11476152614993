import { ComponentSize, ComponentVariant } from '../../../types/component.type';

export const tagSizeClasses: Record<NonNullable<ComponentSize>, string> = {
  '2xs': 'px-1 pt-[0.0625rem] pb-[0.125rem] text-[0.65rem]',
  xs: 'px-1.5 pt-[0.0625rem] pb-[0.125rem] text-[0.65rem]',
  sm: 'px-2 pt-[0.125rem] pb-[0.1875rem] text-xs',
  md: 'px-2 pt-[0.1875rem] pb-[0.3125rem] text-sm',
  lg: 'px-2.5 pt-[0.025rem] pb-[0.4375rem] text-md',
};

export const tagTextSizeClasses: Record<NonNullable<ComponentSize>, string> = {
  '2xs': 'text-2xs',
  xs: 'text-2xs',
  sm: 'text-xs',
  md: 'text-sm',
  lg: 'text-base',
};

export const tagTextColorClasses: Record<
  NonNullable<ComponentVariant>,
  string
> = {
  primary: 'text-primary-700',
  secondary: 'text-secondary-700',
  danger: 'text-danger-700',
  warning: 'text-warning-700',
  neutral: 'text-neutral-700',
  success: 'text-success-700',
  info: 'text-blue-700',
};

export const tagFilledClasses: Record<NonNullable<ComponentVariant>, string> = {
  primary: 'bg-primary-50 ring-primary-700/60 hover:bg-primary-100',
  secondary: 'bg-secondary-50 ring-secondary-700/60 hover:bg-secondary-100',
  danger: 'bg-danger-50 ring-danger-700/60 hover:bg-danger-100',
  warning: 'bg-warning-50 ring-warning-700/60 hover:bg-warning-100',
  neutral: 'bg-neutral-50 ring-neutral-700/60 hover:bg-neutral-100',
  success: 'bg-success-50 ring-success-700/60 hover:bg-success-100',
  info: 'bg-blue-50 ring-blue-700/60 hover:bg-blue-100',
};

export const tagOutlineClasses: Record<
  NonNullable<ComponentVariant>,
  string
> = {
  primary: 'bg-white ring-1 ring-primary-500 hover:bg-primary-50',
  secondary: 'bg-white ring-1 ring-secondary-500 hover:bg-secondary-50',
  danger: 'bg-white ring-1 ring-danger-500 hover:bg-danger-50',
  warning: 'bg-white ring-1 ring-warning-500 hover:bg-warning-50',
  neutral: 'bg-white ring-1 ring-neutral-500 hover:bg-neutral-50',
  success: 'bg-white ring-1 ring-success-500 hover:bg-success-50',
  info: 'bg-white ring-1 ring-blue-500 hover:bg-blue-50',
};
