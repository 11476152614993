import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiResponse, useMemoizedArrayData } from 'hooks/api/api';
import {
  MessagingTemplatesService,
  Template,
  TemplateListResponse,
  TemplateCreateBodyParams,
  TemplateUpdateBodyParams,
  TemplateListQueryParams,
} from 'src/api/generated';
import { updateCache } from 'src/modules/shared/utils/cache/update.util';
import { CacheKeyValue } from 'src/types/cache';

interface TemplatesResponse extends ApiResponse {
  templates: Template[];
  totalPages: number;
}

export const useTemplates = (
  queryParams: TemplateListQueryParams = {},
  enabled: boolean = true
): TemplatesResponse => {
  const fetchTemplates = async (): Promise<TemplateListResponse> => {
    const { filterBy, searchBy, sortBy, page, pageSize } = queryParams;

    return await MessagingTemplatesService.getTemplates(
      filterBy,
      searchBy,
      sortBy,
      page,
      pageSize
    );
  };

  const {
    data,
    error,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['messaging/templates', queryParams],
    queryFn: fetchTemplates,
    enabled,
  });

  const templates = useMemoizedArrayData<Template>(data?.data);
  const totalPages = data?.totalPages || 1;

  return {
    loading,
    error,
    templates,
    totalPages,
    refetch,
  };
};

interface UseCreateTemplateParams {
  onSuccess?: (data: Template) => void;
  onError?: (error: Error) => void;
}

interface UseCreateTemplateResponse {
  loading: boolean;
  error: Error | null;
  template?: Template;
  createTemplate: (params: TemplateCreateBodyParams) => void;
}

export const useCreateTemplate = ({
  onSuccess = () => {},
  onError = () => {},
}: UseCreateTemplateParams = {}): UseCreateTemplateResponse => {
  const queryClient = useQueryClient();

  const createTemplate = async (
    params: TemplateCreateBodyParams
  ): Promise<Template> => {
    return await MessagingTemplatesService.createTemplate(params);
  };

  const mutation = useMutation({
    mutationFn: createTemplate,
    onSuccess: (data: Template) => {
      const queryKey: CacheKeyValue[] = ['messaging/templates'];

      updateCache({
        queryClient,
        queryKey,
        updatedData: data,
      });

      onSuccess(data);
    },
    onError: (error: Error) => onError(error),
  });

  return {
    loading: mutation.isPending,
    error: mutation.error,
    template: mutation.data,
    createTemplate: mutation.mutate,
  };
};

interface UseUpdateTemplateParams {
  onSuccess?: (data: Template) => void;
  onError?: (error: Error) => void;
}

interface UseTemplateUpdateParams extends TemplateUpdateBodyParams {
  id: number;
}

interface UseUpdateTemplateResponse {
  loading: boolean;
  error: Error | null;
  updatedTemplate?: Template;
  updateTemplate: (params: UseTemplateUpdateParams) => void;
}

export const useUpdateTemplate = ({
  onSuccess = () => {},
  onError = () => {},
}: UseUpdateTemplateParams = {}): UseUpdateTemplateResponse => {
  const queryClient = useQueryClient();

  const updateTemplate = async ({
    id,
    ...params
  }: UseTemplateUpdateParams): Promise<Template> => {
    const { tenantId: _, ...templateParams } = params;
    return MessagingTemplatesService.updateTemplate(id, templateParams);
  };

  const mutation = useMutation({
    mutationFn: updateTemplate,
    onSuccess: (data: Template) => {
      const queryKey: CacheKeyValue[] = ['messaging/templates'];

      updateCache({
        queryClient,
        queryKey,
        updatedData: data,
      });

      onSuccess(data);
    },
    onError: (error: Error) => onError(error),
  });

  return {
    loading: mutation.isPending,
    error: mutation.error,
    updatedTemplate: mutation.data,
    updateTemplate: mutation.mutate,
  };
};

interface UseDeleteTemplateParams {
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}

interface UseDeleteTemplateResponse {
  loading: boolean;
  error: Error | null;
  deleteTemplate: (id: number) => void;
}

export const useDeleteTemplate = ({
  onSuccess = () => {},
  onError = () => {},
}: UseDeleteTemplateParams = {}): UseDeleteTemplateResponse => {
  const queryClient = useQueryClient();

  const deleteTemplate = async (id: number): Promise<void> => {
    await MessagingTemplatesService.deleteTemplate(id);
  };

  const mutation = useMutation({
    mutationFn: deleteTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['messaging/templates'] });
      onSuccess();
    },
    onError: (error: Error) => onError(error),
  });

  return {
    loading: mutation.isPending,
    error: mutation.error,
    deleteTemplate: mutation.mutate,
  };
};
