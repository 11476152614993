import { QueryClient, QueryFilters, QueryKey } from '@tanstack/react-query';
import { IModel, UpdateListCacheParams } from 'src/types/cache';

/**
 * Models that implement this interface can have an optional "updatedAt" property,
 * which is used to compare freshness in the cache.
 */
interface ITimestampedModel extends IModel {
  updatedAt?: string;
}

/**
 * Represents the data structure for infinite queries managed by React Query.
 */
export interface InfiniteQueryData<TModel> {
  pages: { items: TModel[] }[];
  pageParams: unknown[];
}

/**
 * Represents the data structure for regular (paginated) queries in React Query.
 */
export interface RegularQueryData<TModel> {
  count: number;
  data: TModel[];
  totalPages: number;
}

/**
 * Extended UpdateListCacheParams that allows conditional removal via "shouldRemoveItem",
 * and optional conditional addition via "shouldAddItem".
 */
export interface ExtendedUpdateListCacheParams<TModel extends IModel>
  extends UpdateListCacheParams<TModel> {
  shouldRemoveItem?: (matchedKey: QueryKey, oldItem: TModel) => boolean;
  shouldAddItem?: (matchedKey: QueryKey, newItem: TModel) => boolean;
}

export interface SnapshotType {
  queryKey: QueryKey;
  queries: [QueryKey, unknown][];
}

/**
 * Gets a model from the cache by searching through all matching queries.
 * If multiple matches are found, returns the most recently updated one.
 *
 * @param queryClient - The query client instance used to manage the cache.
 * @param queryKey - The key used to identify the queries in the cache.
 * @param modelId - The ID of the model to find.
 * @returns The found model or undefined if not found.
 */
export const getModelFromCache = <TModel extends ITimestampedModel>(
  queryClient: QueryClient,
  queryKey: unknown[],
  modelId: string | number
): TModel | undefined => {
  const queriesData = queryClient.getQueriesData<
    InfiniteQueryData<TModel> | RegularQueryData<TModel> | TModel
  >({
    queryKey,
    exact: false,
  });

  return queriesData.reduce<TModel | undefined>(
    (previouslyFoundModel, [, data]) => {
      if (!data) return previouslyFoundModel;

      let modelInCurrentQuery: TModel | undefined;

      if (isInfiniteQueryData<TModel>(data)) {
        for (const page of data.pages) {
          const found = page.items.find(
            (item) => item.id.toString() === modelId.toString()
          );
          if (found) {
            modelInCurrentQuery = found;
            break;
          }
        }
      }

      if (isRegularQueryData<TModel>(data)) {
        modelInCurrentQuery = data.data.find(
          (item) => item.id.toString() === modelId.toString()
        );
      }

      if (isSingleModelQueryData<TModel>(data)) {
        if (data.id.toString() === modelId.toString()) {
          modelInCurrentQuery = data;
        }
      }

      if (!modelInCurrentQuery) return previouslyFoundModel;
      if (!previouslyFoundModel) return modelInCurrentQuery;

      const currentUpdatedAt = modelInCurrentQuery.updatedAt;
      const previousUpdatedAt = previouslyFoundModel.updatedAt;
      if (currentUpdatedAt && previousUpdatedAt) {
        return new Date(currentUpdatedAt) > new Date(previousUpdatedAt)
          ? modelInCurrentQuery
          : previouslyFoundModel;
      }

      return modelInCurrentQuery;
    },
    undefined
  );
};

/**
 * Updates or removes (or optionally adds) an item to every matching query in the cache.
 * If "exact" is true, only the exact query key is updated. For list queries,
 * you may also provide "shouldAddItem" to control whether the updated item should be added
 * to a query that does not yet have it.
 *
 * @param queryClient - The QueryClient instance
 * @param queryKey - The key identifying queries to be updated
 * @param updatedData - The new or updated item
 * @param shouldRemoveItem - If returns true, removes the item from the query
 * @param shouldAddItem - If returns true, adds the item if it doesn't exist
 * @param exact - Whether to match queryKey exactly or not
 */
export const updateCache = <TModel extends IModel>({
  queryClient,
  queryKey,
  updatedData,
  shouldRemoveItem = () => false,
  shouldAddItem,
  exact = false,
}: ExtendedUpdateListCacheParams<TModel> & { exact?: boolean }) => {
  const filters: QueryFilters = {
    queryKey,
    exact,
  };

  const matchedQueries = queryClient.getQueriesData<
    InfiniteQueryData<TModel> | RegularQueryData<TModel> | TModel
  >(filters);

  matchedQueries.forEach(([matchedKey, oldData]) => {
    const newData = updateEntry(
      matchedKey,
      oldData,
      updatedData,
      shouldRemoveItem,
      shouldAddItem
    );

    queryClient.setQueryData(matchedKey, newData);
  });
};

/**
 * Type guard checking if the provided data is InfiniteQueryData.
 */
export const isInfiniteQueryData = <TModel>(
  data: unknown
): data is InfiniteQueryData<TModel> => {
  return (
    typeof data === 'object' &&
    data !== null &&
    'pages' in data &&
    Array.isArray((data as InfiniteQueryData<TModel>).pages)
  );
};

/**
 * Type guard checking if the provided data is RegularQueryData.
 */
export const isRegularQueryData = <TModel>(
  data: unknown
): data is RegularQueryData<TModel> => {
  return (
    typeof data === 'object' &&
    data !== null &&
    'data' in data &&
    Array.isArray((data as RegularQueryData<TModel>).data)
  );
};

/**
 * Type guard checking if the provided data is a single Model.
 */
const isSingleModelQueryData = <TModel>(data: unknown): data is TModel => {
  return typeof data === 'object' && data !== null && 'id' in data;
};

/**
 * Updates page-based (infinite) query data by removing items that match shouldRemoveItem,
 * updating existing items, and optionally adding new items if they don't exist and shouldAddItem is true.
 * Note: Items that were removed will not be re-added in the same operation.
 *
 * @param matchedKey - The query key that matched this data
 * @param oldData - The existing InfiniteQueryData in the cache
 * @param updatedData - The item to update or add
 * @param shouldRemoveItem - If returns true for an item, it will be removed
 * @param shouldAddItem - If returns true, the item will be added if not found and wasn't just removed
 */
const updateInfiniteQueryCache = <TModel extends IModel>(
  matchedKey: QueryKey,
  oldData: InfiniteQueryData<TModel>,
  updatedData: TModel,
  shouldRemoveItem: (matchedKey: QueryKey, oldItem: TModel) => boolean = () =>
    false,
  shouldAddItem?: (matchedKey: QueryKey, newItem: TModel) => boolean
): InfiniteQueryData<TModel> => {
  const removedIds = new Set<string>();

  const newPages = oldData.pages.map((page) => {
    const updatedItems = page.items.map((item) => {
      // First check if this item should be removed
      if (shouldRemoveItem(matchedKey, item)) {
        removedIds.add(item.id.toString());
        return null;
      }

      // Then check if this is the item we're updating
      const isSameItem = item.id.toString() === updatedData.id.toString();
      if (isSameItem) {
        return { ...item, ...updatedData };
      }

      return item;
    });

    return {
      ...page,
      items: updatedItems.filter((item): item is TModel => item !== null),
    };
  });

  const itemExists = newPages.some((page) =>
    page.items.some((item) => item.id.toString() === updatedData.id.toString())
  );

  // Only add if:
  // 1. Item doesn't exist in any page
  // 2. We have pages to add to
  // 3. The item wasn't just removed
  // 4. shouldAddItem returns true
  const wasJustRemoved = removedIds.has(updatedData.id.toString());
  const shouldAdd =
    !itemExists &&
    newPages.length > 0 &&
    !wasJustRemoved &&
    shouldAddItem?.(matchedKey, updatedData);

  if (shouldAdd) {
    newPages[0].items = [updatedData, ...newPages[0].items];
  }

  return {
    ...oldData,
    pages: newPages,
  };
};

/**
 * Updates a paginated (regular) query by removing items that match shouldRemoveItem,
 * updating existing items, and optionally adding new items if they don't exist and shouldAddItem is true.
 * Note: Items that were removed will not be re-added in the same operation.
 *
 * @param matchedKey - The query key that matched this data
 * @param oldData - The existing RegularQueryData in the cache
 * @param updatedData - The item to update or add
 * @param shouldRemoveItem - If returns true for an item, it will be removed
 * @param shouldAddItem - If returns true, the item will be added if not found and wasn't just removed
 */
const updateRegularQueryCache = <TModel extends IModel>(
  matchedKey: QueryKey,
  oldData: RegularQueryData<TModel>,
  updatedData: TModel,
  shouldRemoveItem: (matchedKey: QueryKey, oldItem: TModel) => boolean = () =>
    false,
  shouldAddItem?: (matchedKey: QueryKey, newItem: TModel) => boolean
): RegularQueryData<TModel> => {
  const removedIds = new Set<string>();
  const newDataArray = [...oldData.data];
  let newCount = oldData.count;

  // First remove any items that should be removed
  const initialLength = newDataArray.length;
  const filteredArray = newDataArray.filter((item) => {
    const shouldRemove = shouldRemoveItem(matchedKey, item);
    if (shouldRemove) {
      removedIds.add(item.id.toString());
    }
    return !shouldRemove;
  });
  newCount -= initialLength - filteredArray.length;

  // Then update or add the new item
  const index = filteredArray.findIndex(
    (item) => item.id.toString() === updatedData.id.toString()
  );
  const itemExists = index !== -1;
  const wasJustRemoved = removedIds.has(updatedData.id.toString());

  if (itemExists) {
    filteredArray[index] = { ...filteredArray[index], ...updatedData };
  } else if (!wasJustRemoved && shouldAddItem?.(matchedKey, updatedData)) {
    filteredArray.unshift(updatedData);
    newCount += 1;
  }

  return {
    ...oldData,
    data: filteredArray,
    count: newCount,
  };
};

/**
 * Updates a single model query by checking if it should be removed or updated.
 *
 * @param matchedKey - The query key that matched this data
 * @param oldData - The existing model in the cache
 * @param updatedData - The item to update
 * @param shouldRemoveItem - If returns true for the item, it will be removed
 */
const updateSingleModelQueryCache = <TModel extends IModel>(
  matchedKey: QueryKey,
  oldData: TModel,
  updatedData: TModel,
  shouldRemoveItem: (matchedKey: QueryKey, oldItem: TModel) => boolean
): TModel | undefined => {
  if (shouldRemoveItem(matchedKey, oldData)) {
    return undefined;
  }

  // TODO: check if we should create the item in the cache if it doesn't exist
  // CURRENTLY not a problem

  const isSameItem = oldData.id.toString() === updatedData.id.toString();
  if (!isSameItem) return oldData;

  return { ...oldData, ...updatedData };
};

/**
 * Updates a single model in the cache for the exact queryKey.
 *
 * @param queryClient - The QueryClient instance
 * @param queryKey - The exact query key
 * @param updatedData - The new data to set
 */
export const updateModelCache = <TModel extends IModel>(
  queryClient: QueryClient,
  queryKey: unknown[],
  updatedData: TModel
): void => {
  queryClient.setQueryData<TModel>(queryKey, (oldData) => {
    if (!oldData) return updatedData;

    return { ...oldData, ...updatedData };
  });
};

/**
 * Retrieves a single model from the cache for the exact queryKey, without searching all queries.
 *
 * @param queryClient - The query client instance managing the cache.
 * @param queryKey - The exact query key for the record.
 * @returns The model if found in the specific query, undefined otherwise.
 */
export const getModelFromQuery = <TModel extends IModel>(
  queryClient: QueryClient,
  queryKey: unknown[]
): TModel | undefined => {
  return queryClient.getQueryData<TModel>(queryKey);
};

/**
 * Helper that merges or removes/adds the updated item depending on data type.
 */
const updateEntry = <TModel extends IModel>(
  matchedKey: QueryKey,
  oldData:
    | InfiniteQueryData<TModel>
    | RegularQueryData<TModel>
    | TModel
    | undefined,
  updatedData: TModel,
  shouldRemoveItem: (matchedKey: QueryKey, oldItem: TModel) => boolean,
  shouldAddItem?: (matchedKey: QueryKey, newItem: TModel) => boolean
):
  | InfiniteQueryData<TModel>
  | RegularQueryData<TModel>
  | TModel
  | undefined => {
  if (!oldData) return undefined;

  if (isInfiniteQueryData<TModel>(oldData)) {
    return updateInfiniteQueryCache(
      matchedKey,
      oldData,
      updatedData,
      shouldRemoveItem,
      shouldAddItem
    );
  }
  if (isRegularQueryData<TModel>(oldData)) {
    return updateRegularQueryCache(
      matchedKey,
      oldData,
      updatedData,
      shouldRemoveItem,
      shouldAddItem
    );
  }
  if (isSingleModelQueryData<TModel>(oldData)) {
    return updateSingleModelQueryCache(
      matchedKey,
      oldData,
      updatedData,
      shouldRemoveItem
    );
  }
  return oldData;
};

/**
 * Gets all queries data for a list of query keys.
 * Useful for taking snapshots before making optimistic updates.
 */
export const getQueriesSnapshots = async (
  queryClient: QueryClient,
  queryKeys: QueryKey[]
): Promise<SnapshotType[]> => {
  return Promise.all(
    queryKeys.map(async (queryKey) => {
      const queryData = queryClient.getQueriesData({ queryKey });
      return { queryKey, queries: queryData };
    })
  );
};
