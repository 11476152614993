import { useMemo } from 'react';
import { useFunnelStatuses } from '../../api/funnels/funnel-statuses';
import { createQuery } from 'src/modules/shared/utils/api/query-builder.util';
import { StatusOption } from 'src/modules/shared/types/filters.types';

interface UseStatusSelectProps {
  selectedFunnelId?: number;
}

interface UseStatusSelectResult {
  statusOptions: StatusOption[];
  loading: boolean;
}

export function useStatusSelect({
  selectedFunnelId,
}: UseStatusSelectProps): UseStatusSelectResult {
  const statusQuery = useMemo(() => {
    if (!selectedFunnelId) return {};

    return createQuery()
      .equals('funnelId', selectedFunnelId)
      .orderByAsc('position')
      .build();
  }, [selectedFunnelId]);

  const { statuses, loading } = useFunnelStatuses(
    statusQuery,
    !!selectedFunnelId
  );

  const statusOptions = useMemo(() => {
    return statuses.map((status) => ({
      id: status.id,
      name: status.name,
      position: status.position,
    }));
  }, [statuses]);

  return {
    statusOptions,
    loading,
  };
}
