import { useState, ReactNode } from 'react';
import {
  PiXLight,
  PiCheckLight,
  PiCopyLight,
  PiCheckBold,
} from 'react-icons/pi';
import Button from '../../../atoms/buttons/button';
import Label from '../../../atoms/inputs/label';
import { motion, AnimatePresence } from 'framer-motion';
import {
  ComponentVariant,
  ComponentSize,
} from 'src/modules/shared/types/component.type';
import Loading from '../../../atoms/loading';
import { useCopyToClipboard } from 'src/modules/shared/hooks/copy-to-clipboard';

interface InlineEditableInputProps {
  value: string;
  onChange: (value: string) => void;
  disabled: boolean;
}

export interface InlineEditableInputWrapperProps {
  label: string;
  value: string;
  onSave: (value: string) => Promise<void>;
  loading?: boolean;
  size?: ComponentSize;
  variant?: ComponentVariant;
  disabled?: boolean;
  onCancel?: () => void;
  onError?: (error: Error) => void;
  onSuccess?: () => void;
  children: (props: InlineEditableInputProps) => ReactNode;
}

/**
 * A wrapper component for inline-editable fields that use an explicit edit/save mode
 * (like text fields, number fields, etc.)
 */
export const InlineEditableInputWrapper: React.FC<
  InlineEditableInputWrapperProps
> = ({
  label,
  value,
  onSave,
  loading = false,
  size = 'sm',
  variant = 'primary',
  disabled = false,
  onCancel,
  onError,
  onSuccess,
  children,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(value);
  const { copyToClipboard, isCopied } = useCopyToClipboard();

  const handleSave = async () => {
    try {
      await onSave(editedValue);
      onSuccess?.();
      setIsEditing(false);
    } catch (error) {
      onError?.(error as Error);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedValue(value);
    onCancel?.();
  };

  const iconSize = size === 'sm' ? 'text-lg' : 'text-xl';

  return (
    <div className="group relative">
      <div className="flex items-center">
        <AnimatePresence>
          {isEditing ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="flex-1"
            >
              <div className="relative bg-gray-50 rounded-lg h-11">
                {children({
                  value: editedValue,
                  onChange: setEditedValue,
                  disabled: loading || disabled,
                })}
                <div className="absolute right-1 top-1/2 -translate-y-1/2 flex items-center gap-1">
                  <Button
                    variant="neutral"
                    appearance="ghost"
                    size={size}
                    onClick={handleCancel}
                    isIconOnly
                    disabled={loading}
                  >
                    <PiXLight className={iconSize} />
                  </Button>
                  <Button
                    variant={variant}
                    size={size}
                    onClick={handleSave}
                    disabled={loading}
                    isIconOnly
                  >
                    {loading ? (
                      <Loading
                        size="2xs"
                        variant="primary"
                        appearance="outline"
                      />
                    ) : (
                      <PiCheckLight className={iconSize} />
                    )}
                  </Button>
                </div>
              </div>
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="flex-1 flex items-center justify-between w-full p-2"
            >
              <Label
                size={size}
                variant="primary"
                className="flex-shrink-0 font-light max-w-[40%] break-words"
              >
                {label}
              </Label>
              <div className="flex-1 flex items-center justify-end min-w-[40%]">
                {value && (
                  <div className="flex items-center gap-1 opacity-0 group-hover:opacity-100 transition-opacity duration-200 mr-1">
                    <Button
                      variant="neutral"
                      appearance="ghost"
                      size={size}
                      onClick={() => copyToClipboard(value)}
                      // TODO: check when implementing i18n
                      title={isCopied ? 'Copiado!' : 'Copiar'}
                      isIconOnly
                      disabled={disabled}
                    >
                      {isCopied ? (
                        <PiCheckBold
                          className={`${iconSize} text-success-500`}
                        />
                      ) : (
                        <PiCopyLight className={iconSize} />
                      )}
                    </Button>
                  </div>
                )}
                <Button
                  variant={variant}
                  appearance="ghost"
                  size={size}
                  onClick={disabled ? undefined : () => setIsEditing(true)}
                  disabled={disabled}
                  // TODO: check when implementing i18n
                  title={disabled ? undefined : 'Presiona para editar'}
                  className={`font-light text-right group-hover:bg-gray-100 px-2 py-1 rounded transition-all relative flex items-center gap-2 min-w-[100px] max-w-3/4 !justify-end ${disabled ? 'opacity-70' : 'hover:text-primary-600'}`}
                >
                  {value ? (
                    <span className="truncate">{value}</span>
                  ) : (
                    <span className="truncate text-secondary-500">—</span>
                  )}
                </Button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};
