import { TimelineInteractionItem } from '../atoms/timeline-interaction-item';
import { useMemo, useRef } from 'react';
import { DateGroupItems } from '../../../shared/components/molecules/date-group/date-group-items';
import {
  useInfiniteScroll,
  useScrollToBottom,
} from 'src/modules/shared/hooks/scroll/movement';
import Loading from 'src/modules/shared/components/atoms/loading';
import { useSearchTimelineItems } from '../../hooks/api/timeline-items';
import {
  SearcherDocumentSearchFilterOperator,
  SearcherDocumentSearchSortDirection,
  Contact,
} from 'src/api/generated';
import { CardSkeleton } from 'src/modules/shared/components/atoms/cards/card-skeleton';
import { EmptyState } from 'src/modules/shared/components/atoms/states/empty-state';
import { useScrollPosition } from 'src/modules/shared/hooks/scroll/movement';
import Button from 'src/modules/shared/components/atoms/buttons/button';
import { useDateGroupItems } from 'src/modules/shared/hooks/date-group-items';
import { groupSequentialItems } from '../../utils/timeline-grouping.util';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

interface TimelineItemListProps {
  loadingContact?: boolean;
  contact?: Contact;
}

export function TimelineItemList({
  loadingContact = false,
  contact,
}: TimelineItemListProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const timelineItemsQuery = useMemo(() => {
    if (!contact?.id) return {};

    return {
      filters: [
        {
          field: 'contactId',
          operator: SearcherDocumentSearchFilterOperator.EQ,
          value: contact.id.toString(),
        },
      ],
      sort: [
        {
          field: 'createdAt',
          direction: SearcherDocumentSearchSortDirection.DESC,
        },
      ],
      size: 20,
    };
  }, [contact?.id]);
  const {
    timelineItems,
    loading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useSearchTimelineItems(timelineItemsQuery, !!contact?.id);

  const { isAtBottom, handleScroll } = useScrollPosition(timelineItems);
  const { scrollToBottom } = useScrollToBottom({
    bottomAnchorRef: messagesEndRef,
    isAtBottom,
    dependencies: timelineItems,
  });

  useInfiniteScroll({
    containerRef,
    isFetching: isFetchingNextPage,
    hasMore: hasNextPage,
    fetchMore: fetchNextPage,
  });

  const { groupedItems: dateGroupedItems, sortedDates } =
    useDateGroupItems(timelineItems);

  const groupedByDateAndSequence = sortedDates.reduce(
    (acc, date) => {
      acc[date] = groupSequentialItems(dateGroupedItems[date]);
      return acc;
    },
    {} as Record<string, ReturnType<typeof groupSequentialItems>>
  );

  if (loading || loadingContact) {
    return (
      <div className="p-6 space-y-4">
        {[...Array(3)].map((_, index) => (
          <CardSkeleton
            key={index}
            header={{
              avatar: true,
              title: true,
              subtitle: true,
            }}
            content={{
              lines: 2,
              widths: ['75%', '50%'],
            }}
          />
        ))}
      </div>
    );
  }

  if (timelineItems.length === 0) {
    return (
      // TODO: check when implementing i18n
      <EmptyState
        title="No hay interacciones"
        description="Inicia la conversación enviando un mensaje o realizando una llamada"
        icon={<span className="text-2xl">💬</span>}
        className="p-4"
      />
    );
  }

  return (
    <div
      className="flex-1 overflow-y-auto p-6 space-y-6"
      ref={containerRef}
      onScroll={handleScroll}
    >
      {isFetchingNextPage && (
        <div className="flex justify-center py-2">
          <Loading className="h-full items-center" />
        </div>
      )}

      {sortedDates.map((date) => (
        <DateGroupItems key={date} date={new Date(date)}>
          {groupedByDateAndSequence[date].map((groupedItem) => (
            <TimelineInteractionItem
              key={groupedItem.mainItem.id}
              item={groupedItem.mainItem}
              groupedItems={groupedItem.groupedItems}
              contact={contact}
            />
          ))}
        </DateGroupItems>
      ))}

      <div ref={messagesEndRef} />

      {!isAtBottom && (
        <div className="sticky bottom-4 flex justify-end">
          <Button
            onClick={scrollToBottom}
            size="sm"
            variant="primary"
            appearance="outline"
            className="shadow-nm-floating-lg rounded-full"
          >
            <ChevronDownIcon className="w-4 h-4 m-1" />
          </Button>
        </div>
      )}
    </div>
  );
}
