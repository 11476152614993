/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MessageGetResponse } from '../models/MessageGetResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class V1MessagingMessagesService {

    /**
     * Get a message by ID.
     *
     * This endpoint allows you to retrieve a specific message using its unique identifier.
     * The response includes all message details including content, sender/recipient info,
     * signed attachments, and metadata.
     * @param messageId
     * @returns MessageGetResponse Ok
     * @throws ApiError
     */
    public static getMessage(
        messageId: string,
    ): CancelablePromise<MessageGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/messaging/messages/{messageId}',
            path: {
                'messageId': messageId,
            },
            errors: {
                400: `Validation Error`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }

}
