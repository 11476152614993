import { ComponentVariant } from '../types/component.type';

export const callButtonVariantOverrides: Record<ComponentVariant, string> = {
  primary: [
    '!bg-call-600',
    '!text-white',
    'hover:!bg-call-700',
    'active:!bg-call-800',
    'focus:!ring-call-500',
  ].join(' '),

  secondary: [
    '!bg-white',
    '!text-call-700',
    '!border-call-500',
    'hover:!bg-call-50',
    'active:!bg-call-100',
    'focus:!ring-call-500',
  ].join(' '),

  danger: [
    '!bg-call-600',
    '!text-white',
    'hover:!bg-call-700',
    'active:!bg-call-800',
    'focus:!ring-call-500',
  ].join(' '),

  warning: [
    '!bg-call-500',
    '!text-white',
    'hover:!bg-call-600',
    'active:!bg-call-700',
    'focus:!ring-call-500',
  ].join(' '),

  neutral: [
    '!text-call-600',
    'hover:!bg-call-50',
    'active:!bg-call-100',
    'focus:!ring-call-500',
  ].join(' '),

  success: [
    '!bg-call-500',
    '!text-white',
    'hover:!bg-call-600',
    'active:!bg-call-700',
    'focus:!ring-call-500',
  ].join(' '),

  info: [
    '!bg-call-500',
    '!text-white',
    'hover:!bg-call-600',
    'active:!bg-call-700',
    'focus:!ring-call-500',
  ].join(' '),
};
