import { FunnelConversation } from 'src/api/generated/models/FunnelConversation';
import { FunnelConversationDocument } from 'src/api/generated/models/FunnelConversationDocument';

export interface UpdateFunnelConversationParams {
  id: number;
  data: Partial<FunnelConversation>;
}

export interface UpdateFunnelConversationContext {
  params: UpdateFunnelConversationParams;
  previousConversation?: FunnelConversation;
  previousConversationDocument?: FunnelConversationDocument;
}

/**
 * Creates an optimistic conversation document for cache updates.
 */
export const createOptimisticConversationDocument = (
  params: UpdateFunnelConversationParams,
  previousDocument?: FunnelConversationDocument
): FunnelConversationDocument => {
  const baseDocument: Omit<FunnelConversationDocument, 'id'> = {
    ...previousDocument,
    createdAt: previousDocument?.createdAt ?? new Date().toISOString(),
    modelType: FunnelConversationDocument.modelType.CONVERSATION,
    funnelId: previousDocument?.funnelId,
    funnelStatusId: previousDocument?.funnelStatusId,
    contactId: previousDocument?.contactId,
    archivedAt: previousDocument?.archivedAt,
    tenantId: previousDocument?.tenantId ?? params.data.tenantId ?? '',
    updatedAt: new Date().toISOString(),
  };

  const { funnelStatusId, archivedAt, contactId, funnelId, ...updatedData } =
    params.data;

  const optimisticDocument: FunnelConversationDocument = {
    ...baseDocument,
    ...updatedData,
    id: params.id.toString(),
  };

  if (funnelStatusId !== undefined) {
    optimisticDocument.funnelStatusId = funnelStatusId
      ? funnelStatusId.toString()
      : null;
  }
  if (archivedAt !== undefined) {
    optimisticDocument.archivedAt = archivedAt || undefined;
  }
  if (contactId !== undefined) {
    optimisticDocument.contactId = contactId.toString();
  }
  if (funnelId !== undefined) {
    optimisticDocument.funnelId = funnelId.toString();
  }

  return optimisticDocument;
};

/**
 * Creates an optimistic conversation for regular cache updates.
 */
export const createOptimisticConversation = (
  params: UpdateFunnelConversationParams,
  previousConversation?: FunnelConversation
): FunnelConversation => {
  const baseConversation: FunnelConversation = {
    id: params.id,
    tenantId: previousConversation?.tenantId ?? '',
    contactId: previousConversation?.contactId ?? 0,
    funnelId: previousConversation?.funnelId ?? 0,
    createdAt: previousConversation?.createdAt ?? new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    ...previousConversation,
  };

  const {
    funnelStatusId,
    archivedAt,
    contactId,
    funnelId,
    userId,
    ...updatedData
  } = params.data;

  const optimisticConversation: FunnelConversation = {
    ...baseConversation,
    ...updatedData,
  };

  if (funnelStatusId !== undefined) {
    optimisticConversation.funnelStatusId = funnelStatusId;
  }
  if (archivedAt !== undefined) {
    optimisticConversation.archivedAt = archivedAt;
  }
  if (contactId !== undefined) {
    optimisticConversation.contactId = contactId;
  }
  if (funnelId !== undefined) {
    optimisticConversation.funnelId = funnelId;
  }
  if (userId !== undefined) {
    optimisticConversation.userId = userId;
  }

  return optimisticConversation;
};
