import { FC, useState } from 'react';
import { PiArrowsDownUpLight } from 'react-icons/pi';
import Button from '../../../atoms/buttons/button';
import { Sort, SortFieldOptions } from 'src/modules/shared/types/filters.types';
import { Typography } from '@material-tailwind/react';
import { Separator } from '../../../atoms/separator';
import Tag from '../../../atoms/tag';
import { Popover } from '../../popovers/popover';
import { SortBuilder } from './sort-builder';

export interface AdvancedSortsProps {
  fieldsOptions: SortFieldOptions[];
  onChange: (sorts: Sort[]) => void;
  activeSortCount: number;
  sorts: Sort[];
}

export const AdvancedSorts: FC<AdvancedSortsProps> = ({
  fieldsOptions,
  onChange,
  activeSortCount = 0,
  sorts: initialSorts,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [localSorts, setLocalSorts] = useState<Sort[]>(initialSorts);

  const handleOpen = (open: boolean) => {
    if (open) {
      setLocalSorts(initialSorts);
    }
    setIsOpen(open);
  };

  const handleClose = () => {
    setIsOpen(false);
    setLocalSorts(initialSorts);
  };

  const handleApply = () => {
    onChange(localSorts);
    setIsOpen(false);
  };

  const sortButtonClasses = `flex items-center gap-1.5 rounded-md ${activeSortCount > 0 ? 'bg-primary-50' : ''}`;
  const sortButton = (
    <Button appearance="ghost" size="md" className={sortButtonClasses}>
      <PiArrowsDownUpLight className="w-4 h-4" />
      {/* TODO: check when implementing i18n */}
      <Typography className="font-normal text-md">Ordenar</Typography>
      {activeSortCount > 0 && (
        <Tag
          content={activeSortCount}
          size="sm"
          variant="primary"
          appearance="filled"
          className="ml-1"
          textClassName="font-normal text-md ml-[-1px] text-primary-500"
        />
      )}
    </Button>
  );

  return (
    <Popover
      handler={sortButton}
      open={isOpen}
      onOpenChange={handleOpen}
      placement="bottom-end"
      contentClassName="w-[80%] md:w-96"
    >
      <div className="w-full">
        <div className="p-4 max-h-[200px] overflow-y-auto">
          <SortBuilder
            fieldsOptions={fieldsOptions}
            sorts={localSorts}
            onChange={setLocalSorts}
          />
        </div>

        <Separator />

        <div className="flex justify-end gap-2 p-3">
          <Button appearance="ghost" size="sm" onClick={handleClose}>
            <Typography className="font-normal text-xs">Cancelar</Typography>
          </Button>
          <Button size="sm" onClick={handleApply}>
            <Typography className="font-normal text-xs">Aplicar</Typography>
          </Button>
        </div>
      </div>
    </Popover>
  );
};
