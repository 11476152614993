import React from 'react';
import { TESelect } from 'tw-elements-react';
import type {
  SelectData,
  selectTheme,
} from 'tw-elements-react/dist/types/forms/Select/types';
import {
  ComponentSize,
  ComponentVariant,
} from 'src/modules/shared/types/component.type';
import { inputSizeClasses } from 'src/modules/shared/styles/component.styles';
import {
  multiSelectVariantClasses,
  multiSelectInputClasses,
  multiSelectOptionClasses,
  multiSelectOptionSelectedClasses,
  multiSelectArrowClasses,
  multiSelectOptionsWrapperClasses,
  multiSelectDropdownClasses,
  multiSelectContainerClasses,
} from './styles';

interface SelectOption {
  content: React.ReactNode | string;
  value: string;
}

interface MultiSelectProps {
  options: SelectOption[];
  value: string[];
  onChange: (value: string[]) => void;
  size?: ComponentSize;
  variant?: ComponentVariant;
  disabled?: boolean;
  loading?: boolean;
  containerClassName?: string;
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  options,
  value,
  onChange,
  size = 'md',
  variant = 'primary',
  disabled = false,
  loading = false,
  containerClassName = '',
}) => {
  const data: SelectData[] = options.map((option) => ({
    text: option.content?.toString() || '',
    value: option.value,
    disabled: false,
  }));

  const handleValueChange = (selectedValues: SelectData | SelectData[]) => {
    if (Array.isArray(selectedValues)) {
      const validValues = selectedValues
        .filter(
          (v): v is Required<SelectData> =>
            v !== null && v !== undefined && typeof v.value !== 'undefined'
        )
        .map((v) => v.value.toString());
      onChange(validValues);
    }
  };

  const teSize = size === 'sm' ? 'sm' : size === 'lg' ? 'lg' : undefined;

  const theme: selectTheme = {
    selectInput: [
      ...multiSelectInputClasses,
      multiSelectVariantClasses[variant],
      disabled || loading ? 'opacity-50 cursor-not-allowed' : '',
      inputSizeClasses[size],
    ].join(' '),
    selectOption: multiSelectOptionClasses.join(' '),
    selectOptionSelected: multiSelectOptionSelectedClasses.join(' '),
    selectArrow: multiSelectArrowClasses.join(' '),
    optionsWrapper: multiSelectOptionsWrapperClasses.join(' '),
    dropdown: multiSelectDropdownClasses.join(' '),
  };

  const containerClasses = [
    ...multiSelectContainerClasses,
    containerClassName,
  ].join(' ');

  return (
    <div className={containerClasses}>
      <TESelect
        data={data}
        multiple
        size={teSize}
        disabled={disabled || loading}
        onValueChange={handleValueChange}
        value={value}
        theme={theme}
        // TODO: check when implementing i18n
        selectAllLabel="Seleccionar todos"
      />
    </div>
  );
};

export default MultiSelect;
