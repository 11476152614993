import React, { ComponentProps } from 'react';
import {
  BaseProps,
  ComponentSize,
  ComponentVariant,
  ComponentAppearance,
} from 'src/modules/shared/types/component.type';
import Loading from 'src/modules/shared/components/atoms/loading';
import { getDisabledClasses } from 'src/modules/shared/styles/component.styles';
import {
  baseButtonClasses,
  buttonFilledClasses,
  buttonGhostClasses,
  buttonOutlineClasses,
  buttonSoftClasses,
} from './button.styles';

interface ButtonProps
  extends BaseProps<Omit<ComponentProps<'button'>, 'size' | 'variant'>> {
  isIconOnly?: boolean;
}

const sizeClasses: Record<NonNullable<ComponentSize>, string> = {
  '2xs': 'h-4 text-2xs',
  xs: 'h-6 text-xs',
  sm: 'h-7 text-xs',
  md: 'h-8 text-sm',
  lg: 'h-10 text-base',
};

const paddingClasses: Record<NonNullable<ComponentSize>, string> = {
  '2xs': 'px-1',
  xs: 'px-1.5',
  sm: 'px-2',
  md: 'px-3',
  lg: 'px-4',
};

const loadingSizeClasses: Record<NonNullable<ComponentSize>, string> = {
  '2xs': 'w-1.5 h-1.5',
  xs: 'w-2.5 h-2.5',
  sm: 'w-3 h-3',
  md: 'w-3.5 h-3.5',
  lg: 'w-4 h-4',
};

const appearanceClasses: Record<
  NonNullable<ComponentAppearance>,
  Record<NonNullable<ComponentVariant>, string>
> = {
  filled: buttonFilledClasses,
  outline: buttonOutlineClasses,
  soft: buttonSoftClasses,
  ghost: buttonGhostClasses,
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      className = '',
      variant = 'primary',
      appearance = 'filled',
      size = 'md',
      disabled = false,
      loading = false,
      isIconOnly = false,
      ...rest
    },
    ref
  ) => {
    const classes = [
      ...baseButtonClasses,
      appearanceClasses[appearance][variant],
      sizeClasses[size],
      !isIconOnly && paddingClasses[size],
      isIconOnly && 'aspect-square',
      getDisabledClasses(disabled || loading),
      className,
    ]
      .filter(Boolean)
      .join(' ');

    return (
      <button
        ref={ref}
        className={classes}
        disabled={disabled || loading}
        {...rest}
      >
        {loading ? (
          <>
            <Loading
              className={`w-fit ${loadingSizeClasses[size]}`}
              iconClassName={loadingSizeClasses[size]}
              variant={variant}
              appearance={appearance}
            />
            {children}
          </>
        ) : (
          children
        )}
      </button>
    );
  }
);

Button.displayName = 'Button';

export default Button;
