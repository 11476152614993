export interface ExpirationInfo {
  isExpired: boolean;
  timeRemaining: number | null;
}

export const getExpirationDate = (
  expirationTime?: string | Date
): Date | undefined => {
  if (!expirationTime) return undefined;
  return new Date(expirationTime);
};

export const getExpirationInfo = (
  expirationTime?: string | Date
): ExpirationInfo => {
  const expirationDate = getExpirationDate(expirationTime);
  if (!expirationDate) {
    return { isExpired: false, timeRemaining: null };
  }

  const now = new Date();
  const timeRemaining = expirationDate.getTime() - now.getTime();

  return {
    isExpired: timeRemaining <= 0,
    timeRemaining: timeRemaining > 0 ? timeRemaining : 0,
  };
};

export const isExpired = (expirationTime?: string | Date): boolean => {
  return getExpirationInfo(expirationTime).isExpired;
};

export const getTimeUntilExpiration = (
  expirationTime?: string | Date
): number | null => {
  return getExpirationInfo(expirationTime).timeRemaining;
};
