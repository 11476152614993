import { useAuth0 } from '@auth0/auth0-react';
import Button from 'src/modules/shared/components/atoms/buttons/button';
import React from 'react';

interface LoginButtonProps {
  openInPopup?: boolean;
  className?: string;
  size?: 'sm' | 'md' | 'lg';
}

const LoginButton: React.FC<LoginButtonProps> = ({
  openInPopup = false,
  className = '',
  size = 'md',
}) => {
  const { loginWithRedirect, loginWithPopup } = useAuth0();

  const loginFunction = openInPopup
    ? () => loginWithPopup()
    : () => loginWithRedirect();

  // TODO: check when implementing i18n
  return (
    <Button onClick={loginFunction} className={className} size={size}>
      Entrar
    </Button>
  );
};

export default LoginButton;
