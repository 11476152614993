import {
  InlineInputWrapper,
  InlineInputWrapperProps,
} from '../inline-input-wrapper';
import Switch from 'src/modules/shared/components/atoms/switch';

interface InlineBooleanInputProps
  extends Omit<InlineInputWrapperProps, 'children'> {}

export const InlineBooleanInput: React.FC<InlineBooleanInputProps> = ({
  variant = 'primary',
  size = 'sm',
  ...props
}) => {
  return (
    <InlineInputWrapper {...props} disableCopy={true}>
      {({ value, onChange, disabled, loading }) => {
        const isChecked = value === 'true' || value === '1' || value === 'yes';

        return (
          <div className="flex items-center justify-end min-w-[100px]">
            <Switch
              checked={isChecked}
              onChange={(checked) => onChange(checked.toString())}
              disabled={disabled}
              variant={variant}
              size={size}
              loading={loading}
            />
          </div>
        );
      }}
    </InlineInputWrapper>
  );
};
