import React, { useState } from 'react';
import { PiPlay } from 'react-icons/pi';
import { MediaModal } from '../../atoms/media/media-modal';
import { MediaSkeleton } from '../../atoms/media/media-skeleton';
import Button from 'src/modules/shared/components/atoms/buttons/button';
import { MediaErrorSkeleton } from '../../atoms/media/media-error-skeleton';

interface VideoMediaProps {
  url: string;
  isLoading?: boolean;
  className?: string;
}

export const VideoMedia: React.FC<VideoMediaProps> = ({
  url,
  isLoading = false,
  className = '',
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);

  function handleLoadedMetadata() {
    setIsLoaded(true);
    setHasError(false);
  }

  function handleError() {
    setIsLoaded(false);
    setHasError(true);
  }

  const containerClasses = `relative w-full h-auto max-w-[27rem] max-h-[27rem] rounded-lg p-0.5 ${className}`;
  const playOverlayClasses = `
    absolute w-full h-full inset-0 flex items-center justify-center 
    cursor-pointer group
  `;

  return (
    <>
      <div className={containerClasses}>
        {isLoading && !isLoaded && <MediaSkeleton type="video" />}
        {hasError && <MediaErrorSkeleton />}
        <div className="relative w-full h-full">
          <video
            key={url}
            onClick={() => setIsModalOpen(true)}
            onLoadedMetadata={handleLoadedMetadata}
            onError={handleError}
            muted
            className={`w-full h-full rounded-lg cursor-pointer object-cover ${
              isLoaded ? 'block' : 'hidden'
            }`}
          >
            <source src={url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {isLoaded && !hasError && (
            <div
              className={playOverlayClasses}
              onClick={() => setIsModalOpen(true)}
            >
              <Button
                variant="secondary"
                appearance="soft"
                size="md"
                className=" group-hover:bg-secondary-100"
              >
                <PiPlay className="w-4 h-4 text-primary-600" />
              </Button>
            </div>
          )}
        </div>
      </div>
      <MediaModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        {isLoading && !isLoaded && <MediaSkeleton type="video" />}
        {hasError && <MediaErrorSkeleton />}
        <video
          key={url}
          controls
          autoPlay
          onLoadedMetadata={handleLoadedMetadata}
          onError={handleError}
          className={`max-w-full max-h-full rounded-lg ${isLoaded ? 'block' : 'hidden'}`}
        >
          <source src={url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </MediaModal>
    </>
  );
};
