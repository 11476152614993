import {
  ComponentVariant,
  NeumorphicStyle,
} from 'src/modules/shared/types/component.type';

export const panelVariantClasses: Record<
  ComponentVariant,
  {
    base: string;
    hover: string;
    active: string;
  }
> = {
  primary: {
    base: 'bg-primary-50',
    hover: 'hover:bg-primary-100',
    active: 'bg-primary-100',
  },
  secondary: {
    base: 'bg-secondary-50',
    hover: 'hover:bg-secondary-100',
    active: 'bg-secondary-100',
  },
  neutral: {
    base: 'bg-neutral-50',
    hover: 'hover:bg-neutral-100',
    active: 'bg-neutral-100',
  },
  danger: {
    base: 'bg-danger-50',
    hover: 'hover:bg-danger-100',
    active: 'bg-danger-100',
  },
  warning: {
    base: 'bg-warning-50',
    hover: 'hover:bg-warning-100',
    active: 'bg-warning-100',
  },
  success: {
    base: 'bg-success-50',
    hover: 'hover:bg-success-100',
    active: 'bg-success-100',
  },
  info: {
    base: 'bg-info-50',
    hover: 'hover:bg-info-100',
    active: 'bg-info-100',
  },
};

export const panelBaseClasses = 'rounded-lg transition-all duration-200';

export const panelNeumorphicClasses: Record<NeumorphicStyle, string> = {
  inset: 'shadow-nm-inset',
  'inset-sm': 'shadow-nm-inset-2xs',
  flat: 'shadow-nm-flat bg-white',
  none: '',
};
