import React from 'react';
import Modal from 'src/modules/shared/components/molecules/modals/modal';

interface MediaModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export const MediaModal: React.FC<MediaModalProps> = ({
  isOpen,
  onClose,
  children,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      body={children}
      dialogClassName="!max-w-[90vw] !max-h-[90vh] !w-auto !h-auto"
      bodyClassName="!p-0 !overflow-visible"
    />
  );
};
