import React from 'react';
import {
  ComponentSize,
  ComponentVariant,
} from 'src/modules/shared/types/component.type';
import { switchSizeClasses } from './switch.styles';
import { switchColorClasses } from './switch.styles';
import Loading from 'src/modules/shared/components/atoms/loading';

export interface SwitchProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  size?: ComponentSize;
  variant?: ComponentVariant;
  label?: string | React.ReactNode;
  labelPosition?: 'left' | 'right';
  className?: string;
  loading?: boolean;
}

const loadingSizeClasses: Record<NonNullable<ComponentSize>, string> = {
  '2xs': 'w-1.5 h-1.5',
  xs: 'w-2.5 h-2.5',
  sm: 'w-3 h-3',
  md: 'w-3.5 h-3.5',
  lg: 'w-4 h-4',
};

const Switch: React.FC<SwitchProps> = ({
  checked,
  onChange,
  disabled = false,
  size = 'sm',
  variant = 'primary',
  label,
  labelPosition = 'right',
  className = '',
  loading = false,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };

  const sizeClasses = switchSizeClasses[size];
  const colorClasses = switchColorClasses[variant];
  const isDisabled = disabled || loading;

  return (
    <div className={`flex items-center ${className}`}>
      {label && labelPosition === 'left' && (
        <label
          className={`mr-2 text-sm text-gray-700 ${isDisabled ? 'opacity-50' : ''}`}
        >
          {label}
        </label>
      )}
      <div className="flex items-center">
        {loading && (
          <div className="mr-2 flex items-center transition-opacity duration-300 ease-in-out">
            <Loading
              className={`w-fit ${loadingSizeClasses[size]}`}
              iconClassName={loadingSizeClasses[size]}
              variant={variant}
              appearance="filled"
            />
          </div>
        )}
        <div className={`relative inline-block ${sizeClasses.container}`}>
          <input
            type="checkbox"
            checked={checked}
            onChange={handleChange}
            disabled={isDisabled}
            className={`
              peer appearance-none rounded-full cursor-pointer transition-colors duration-300 ease-in-out
              ${sizeClasses.input}
              ${colorClasses.input}
              ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}
            `}
          />
          <span
            className={`
              absolute bg-white rounded-full border shadow-sm transition-all duration-300 ease-in-out pointer-events-none
              ${sizeClasses.handle}
              ${colorClasses.handle}
              ${isDisabled ? 'opacity-50' : ''}
            `}
          ></span>
        </div>
      </div>
      {label && labelPosition === 'right' && (
        <label
          className={`ml-2 text-sm text-gray-700 transition-opacity duration-200 ${isDisabled ? 'opacity-50' : ''}`}
        >
          {label}
        </label>
      )}
    </div>
  );
};

export default Switch;
