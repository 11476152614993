import { MessageStatus } from 'src/api/generated';
import Tooltip from '../../tooltips/tooltip';
import {
  ComponentVariant,
  type BaseProps,
} from 'src/modules/shared/types/component.type';
import { Typography } from '@material-tailwind/react';
import {
  PiCheckFatLight,
  PiClockLight,
  PiWarningCircleLight,
} from 'react-icons/pi';

interface MessageStatusIndicatorProps extends Omit<BaseProps, 'variant'> {
  status: MessageStatus;
  variant?: ComponentVariant | 'whatsapp';
  errorMessage?: string;
}

interface StatusConfig {
  icon: JSX.Element;
  text: string;
  className?: string;
}

const VARIANT_COLORS: Record<ComponentVariant | 'whatsapp', string> = {
  primary: 'text-primary-600',
  secondary: 'text-secondary-600',
  danger: 'text-rose-500',
  neutral: 'text-gray-400',
  warning: 'text-warning-600',
  success: 'text-success-600',
  info: 'text-blue-600',
  whatsapp: 'text-whatsapp-600',
};

// TODO: check when implementing i18n
const STATUS_CONFIGS: Record<MessageStatus, StatusConfig | null> = {
  [MessageStatus.PENDING]: {
    icon: <PiClockLight className="h-3 w-3" />,
    text: 'Enviando',
    className: VARIANT_COLORS.neutral,
  },
  [MessageStatus.FAILED]: {
    icon: <PiWarningCircleLight className="h-4 w-4 mt-0.5 stroke-[3px]" />,
    text: 'Fallido',
    className: VARIANT_COLORS.danger,
  },
  [MessageStatus.SENT]: {
    icon: <PiCheckFatLight className="h-3 w-3 stroke-[2px]" />,
    text: 'Enviado',
  },
  [MessageStatus.DELIVERED]: {
    icon: (
      <span className="flex -space-x-1">
        <PiCheckFatLight className="h-3 w-3 stroke-[2px]" />
        <PiCheckFatLight className="h-3 w-3 stroke-[2px]" />
      </span>
    ),
    text: 'Entregado',
  },
  [MessageStatus.READ]: {
    icon: (
      <span className="flex -space-x-1">
        <PiCheckFatLight className="h-3 w-3 stroke-[3px]" />
        <PiCheckFatLight className="h-3 w-3 stroke-[3px]" />
      </span>
    ),
    text: 'Leído',
  },
  [MessageStatus.DELETED]: null,
  [MessageStatus.WARNING]: null,
};

const baseContainerClasses = 'inline-flex items-center gap-1 text-xs';

export const MessageStatusIndicator: React.FC<MessageStatusIndicatorProps> = ({
  status,
  errorMessage,
  variant = 'primary',
  className = '',
}) => {
  const config = STATUS_CONFIGS[status];
  if (!config) return null;

  const iconClassName = config.className || VARIANT_COLORS[variant];

  if (status === MessageStatus.FAILED) {
    return (
      <Tooltip content={errorMessage || config.text} size="sm" placement="top">
        <span className={`${baseContainerClasses} ${className}`}>
          <span className={iconClassName}>{config.icon}</span>
          <Typography className="text-secondary-800 text-xs">
            {config.text}
          </Typography>
        </span>
      </Tooltip>
    );
  }

  return (
    <span className={`${baseContainerClasses} ${className}`}>
      <span className={iconClassName}>{config.icon}</span>
      <Typography className="text-secondary-800 text-xs">
        {config.text}
      </Typography>
    </span>
  );
};
