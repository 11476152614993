import {
  createNamespacedContainerIdFactory,
  createNamespacedItemIdFactory,
  extractContainerId,
  extractItemId,
} from 'src/modules/shared/utils/dnd.utils';

/** Namespace used for pipeline-related drag and drop operations */
const PIPELINE_NAMESPACE = 'communications-pipeline';

/**
 * Creates a container ID for pipeline elements
 * @param id The ID to namespace
 * @returns A namespaced container ID string
 */
export const createPipelineContainerId =
  createNamespacedContainerIdFactory(PIPELINE_NAMESPACE);

/**
 * Creates an item ID for pipeline elements
 * @param id The ID to namespace
 * @returns A namespaced item ID string
 */
export const createPipelineItemId =
  createNamespacedItemIdFactory(PIPELINE_NAMESPACE);

/**
 * Extracts the original ID from a pipeline container ID
 * @param containerId The namespaced container ID
 * @returns The original ID without the namespace, or undefined if invalid
 */
export const extractPipelineContainerId = (
  containerId: string
): string | undefined => {
  return extractContainerId(PIPELINE_NAMESPACE, containerId);
};

/**
 * Extracts the original ID from a pipeline item ID
 * @param itemId The namespaced item ID
 * @returns The original ID without the namespace, or undefined if invalid
 */
export const extractPipelineItemId = (itemId: string): string | undefined => {
  return extractItemId(PIPELINE_NAMESPACE, itemId);
};

/**
 * Extracts and converts a status ID from a container ID to a number
 * @param containerId The container ID containing the status ID
 * @returns The status ID as a number, or undefined if invalid
 */
export const extractStatusIdFromContainerId = (
  containerId: string
): number | undefined => {
  const id = extractPipelineContainerId(containerId);
  return id ? parseInt(id) : undefined;
};
