import { QueryClient, QueryKey } from '@tanstack/react-query';
import {
  SearcherDocumentSearchFilter,
  SearcherDocumentSearchFilterOperator,
} from 'src/api/generated';
import { QueryParams } from '../../types/api/query-builder.type';

/**
 * Parses a filter string in the format "field=value" into an object with field and value properties.
 *
 * @param filterBy - The filter string to parse in the format "field=value"
 * @returns An object containing the field and value, or null if the filter is invalid
 * @example
 * ```ts
 * parseFilterBy('userId=123') // returns { field: 'userId', value: '123' }
 * parseFilterBy(undefined) // returns null
 * ```
 */
export const parseFilterBy = (filterBy?: QueryParams['filterBy']) => {
  if (!filterBy) return null;
  const [field, value] = filterBy.split('=');
  return { field, value };
};

/**
 * Gets an array of field names that have been updated in a partial object.
 *
 * @param data - The partial object containing updated fields
 * @returns An array of field names that were updated
 * @example
 * ```ts
 * getUpdatedFields({ name: 'John', age: 30 }) // returns ['name', 'age']
 * ```
 */
export const getUpdatedFields = <T extends Record<string, unknown>>(
  data: Partial<T>
): string[] => {
  return Object.keys(data);
};

/**
 * Creates a type-safe optimistic field checker function for a given set of fields.
 *
 * @param fields - A readonly array of field names that can be optimistically updated
 * @returns A type guard function that checks if a field name is in the optimistic fields list
 * @example
 * ```ts
 * const FIELDS = ['userId', 'status'] as const;
 * const isOptimisticField = createOptimisticFieldChecker(FIELDS);
 * isOptimisticField('userId') // returns true
 * isOptimisticField('other') // returns false
 * ```
 */
export function createOptimisticFieldChecker<T extends readonly string[]>(
  fields: T
) {
  return function isOptimisticField(field: string): field is T[number] {
    return fields.includes(field as T[number]);
  };
}

/**
 * Checks if a set of filters contains any non-optimistic fields or unsupported operators.
 *
 * @param filters - The filters to check, can be an array of SearcherDocumentSearchFilter or a simple field-value object
 * @param isOptimisticField - A function that checks if a field can be optimistically updated
 * @returns True if there are any non-optimistic filters, false otherwise
 */
export function hasNonOptimisticFilters(
  filters:
    | SearcherDocumentSearchFilter[]
    | { field: string; value: string }
    | null,
  isOptimisticField: (field: string) => boolean
): boolean {
  if (!filters) return false;

  if (Array.isArray(filters)) {
    return filters.some(
      (filter) =>
        !isOptimisticField(filter.field) ||
        ![
          SearcherDocumentSearchFilterOperator.EQ,
          SearcherDocumentSearchFilterOperator.IN,
          SearcherDocumentSearchFilterOperator.MISSING,
          SearcherDocumentSearchFilterOperator.EXISTS,
        ].includes(filter.operator)
    );
  }

  return !isOptimisticField(filters.field);
}

/**
 * Creates a function that determines whether a query should be invalidated based on its filters.
 *
 * @param isOptimisticField - A function that checks if a field can be optimistically updated
 * @returns A function that takes a query key and returns whether the query should be invalidated
 * @example
 * ```ts
 * const shouldInvalidateQuery = createQueryInvalidator(isOptimisticField);
 * shouldInvalidateQuery(['entity', { filters: [{ field: 'status', operator: 'eq' }] }]);
 * ```
 */
export function createQueryInvalidator(
  isOptimisticField: (field: string) => boolean
) {
  return function shouldInvalidateQuery(matchedKey: QueryKey): boolean {
    if (!Array.isArray(matchedKey) || matchedKey.length <= 1) return false;

    const params = matchedKey[1];
    if (!params || typeof params !== 'object') return false;

    if ('filters' in params && Array.isArray(params.filters)) {
      return hasNonOptimisticFilters(params.filters, isOptimisticField);
    }

    if ('filterBy' in params && typeof params.filterBy === 'string') {
      const filter = parseFilterBy(params.filterBy as QueryParams['filterBy']);
      return hasNonOptimisticFilters(filter, isOptimisticField);
    }

    return false;
  };
}

/**
 * Checks if an item matches a given filter condition.
 *
 * @param filter - The filter condition to check against
 * @param item - The item to check
 * @returns True if the item matches the filter, false otherwise
 * @example
 * ```ts
 * matchesFilter(
 *   { field: 'status', operator: 'eq', value: 'active' },
 *   { status: 'active', id: 1 }
 * ) // returns true
 * ```
 */
export function matchesFilter<T extends Record<string, unknown>>(
  filter: SearcherDocumentSearchFilter,
  item: T
): boolean {
  const { field, operator, value } = filter;

  if (operator === SearcherDocumentSearchFilterOperator.EXISTS) {
    return field in item && item[field as keyof T] != null;
  }

  if (operator === SearcherDocumentSearchFilterOperator.MISSING) {
    return !(field in item) || item[field as keyof T] == null;
  }

  const itemValue = item[field as keyof T]?.toString();
  if (!itemValue || !value) return false;

  switch (operator) {
    case SearcherDocumentSearchFilterOperator.IN:
      return Array.isArray(value)
        ? value.includes(itemValue)
        : value === itemValue;
    case SearcherDocumentSearchFilterOperator.EQ:
      return value === itemValue;
    default:
      return false;
  }
}

/**
 * Invalidates queries that contain non-optimistic filters.
 *
 * @param queryClient - The TanStack Query client instance
 * @param queryKey - The base query key to match against
 * @param shouldInvalidateQueryFn - A function that determines if a specific query should be invalidated
 * @example
 * ```ts
 * invalidateNonOptimisticQueries(
 *   queryClient,
 *   ['entities'],
 *   shouldInvalidateQuery
 * );
 * ```
 */
export const invalidateNonOptimisticQueries = (
  queryClient: QueryClient,
  queryKey: string[],
  shouldInvalidateQueryFn: (key: QueryKey) => boolean
) => {
  const matchedQueries = queryClient.getQueriesData({
    queryKey,
  });

  matchedQueries.forEach(([queryKey]) => {
    if (shouldInvalidateQueryFn(queryKey)) {
      queryClient.invalidateQueries({ queryKey });
    }
  });
};
