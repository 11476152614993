import { FC, useState } from 'react';
import { PiFunnelSimpleLight } from 'react-icons/pi';
import Button from '../../../atoms/buttons/button';
import { FilterBuilder } from './filter-builder';
import {
  FilterFieldOptions,
  Filter,
  StatusOption,
} from 'src/modules/shared/types/filters.types';
import { Typography } from '@material-tailwind/react';
import { Separator } from '../../../atoms/separator';
import Tag from '../../../atoms/tag';
import { Popover } from '../../popovers/popover';

export interface AdvancedFiltersProps {
  fieldsOptions: FilterFieldOptions[];
  onChange: (filters: Filter[]) => void;
  activeFilterCount: number;
  filters: Filter[];
  userOptions?: { id: number | string; name: string }[];
  statusOptions?: StatusOption[];
}

export const AdvancedFilters: FC<AdvancedFiltersProps> = ({
  fieldsOptions,
  onChange,
  activeFilterCount = 0,
  filters: initialFilters,
  userOptions,
  statusOptions = [],
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [localFilters, setLocalFilters] = useState<Filter[]>(initialFilters);

  const handleOpen = (open: boolean) => {
    if (open) {
      setLocalFilters(initialFilters);
    }
    setIsOpen(open);
  };

  const handleClose = () => {
    setIsOpen(false);
    setLocalFilters(initialFilters);
  };

  const handleApply = () => {
    onChange(localFilters);
    setIsOpen(false);
  };

  const filterButtonClasses = `flex items-center gap-1.5 rounded-md ${activeFilterCount > 0 ? 'bg-primary-50' : ''}`;
  const filterButton = (
    <Button appearance="ghost" size="md" className={filterButtonClasses}>
      <PiFunnelSimpleLight className="w-4 h-4" />
      {/* TODO: check when implementing i18n */}
      <Typography className="font-normal text-md">Filtrar</Typography>
      {activeFilterCount > 0 && (
        <Tag
          content={activeFilterCount}
          size="sm"
          variant="primary"
          appearance="filled"
          className="ml-1"
          textClassName="font-normal text-md ml-[-1px] text-primary-500"
        />
      )}
    </Button>
  );

  return (
    <Popover
      handler={filterButton}
      open={isOpen}
      onOpenChange={handleOpen}
      placement="bottom-end"
      contentClassName="w-[80%] md:w-[40rem]"
    >
      <div className="w-full">
        <div className="p-4 max-h-[400px] overflow-y-auto">
          <FilterBuilder
            fieldsOptions={fieldsOptions}
            filters={localFilters}
            onChange={setLocalFilters}
            userOptions={userOptions}
            statusOptions={statusOptions}
          />
        </div>

        <Separator />

        <div className="flex justify-end gap-2 p-3">
          <Button appearance="ghost" size="sm" onClick={handleClose}>
            {/* TODO: check when implementing i18n */}
            <Typography className="font-normal text-xs">Cancelar</Typography>
          </Button>
          <Button size="sm" onClick={handleApply}>
            {/* TODO: check when implementing i18n */}
            <Typography className="font-normal text-xs">Aplicar</Typography>
          </Button>
        </div>
      </div>
    </Popover>
  );
};
