import {
  baseInputClasses,
  inputSizeClasses,
  inputVariantClasses,
} from 'src/modules/shared/styles/component.styles';

export const dateInputContainerClasses = ['relative', 'flex-1'];

export const dateInputBaseClasses = [
  'appearance-none',
  ...baseInputClasses,
  '[&::-webkit-calendar-picker-indicator]:opacity-50',
  '[&::-webkit-calendar-picker-indicator]:hover:opacity-100',
  '[&::-webkit-calendar-picker-indicator]:cursor-pointer',
  '[&::-webkit-calendar-picker-indicator]:transition-opacity',
  '[&::-webkit-calendar-picker-indicator]:duration-200',
];

export const dateInputSizeClasses = inputSizeClasses;
export const dateInputVariantClasses = inputVariantClasses;
