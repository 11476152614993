import { FC, useMemo } from 'react';
import TextInput from 'src/modules/shared/components/atoms/inputs/text-input';
import Select from 'src/modules/shared/components/atoms/inputs/select';
import { DateInput } from 'src/modules/shared/components/atoms/inputs/date-input';
import MultiSelect from 'src/modules/shared/components/atoms/inputs/multi-select';
import { TagInput } from 'src/modules/shared/components/atoms/inputs/tag-input';
import { SearcherDocumentSearchFilterOperator } from 'src/api/generated';
import {
  FilterFieldOptions,
  FilterValue,
  FilterOption,
} from 'src/modules/shared/types/filters.types';
import {
  isArrayOperator,
  isNoValueOperator,
} from 'src/modules/shared/utils/filters/operators.util';

export interface FilterValueInputProps {
  field?: FilterFieldOptions;
  operator?: SearcherDocumentSearchFilterOperator;
  value?: FilterValue;
  onChange: (value: FilterValue) => void;
  options?: FilterOption[];
}

export const FilterValueInput: FC<FilterValueInputProps> = ({
  field,
  operator,
  value,
  onChange,
  options = [],
}) => {
  const selectOptions = useMemo(() => {
    return options.map((option) => ({
      content: 'name' in option ? option.name : option.label || option.value,
      value: 'id' in option ? String(option.id) : option.value,
    }));
  }, [options]);

  if (!field || !operator || isNoValueOperator(operator)) {
    return null;
  }

  const isArrayOp = isArrayOperator(operator);

  if (field.type === 'boolean') {
    // TODO: check when implementing i18n
    const valueString =
      value !== true && value !== false ? 'Verdadero' : String(value);

    return (
      <Select
        // TODO: check when implementing i18n
        options={[
          { content: 'Verdadero', value: 'true' },
          { content: 'Falso', value: 'false' },
        ]}
        size="sm"
        variant="primary"
        value={valueString}
        onChange={(e) => onChange(e.target.value === 'true')}
        containerClassName="flex-1 w-44"
      />
    );
  }

  if (
    field.type === 'user' ||
    field.type === 'list' ||
    field.type === 'status'
  ) {
    if (isArrayOp) {
      return (
        <div className="flex-1 w-44">
          <MultiSelect
            options={selectOptions}
            size="sm"
            value={Array.isArray(value) ? value.map(String) : [String(value)]}
            onChange={(values) => onChange(values)}
            containerClassName="flex-1"
          />
        </div>
      );
    }

    return (
      <Select
        options={selectOptions}
        size="sm"
        variant="primary"
        value={String(value)}
        onChange={(e) => onChange(e.target.value)}
        containerClassName="flex-1 w-44"
      />
    );
  }

  if (field.type === 'date') {
    return (
      <div className="flex-1 w-44">
        <DateInput
          type={
            operator === SearcherDocumentSearchFilterOperator.EQ
              ? 'date'
              : 'datetime-local'
          }
          size="sm"
          variant="primary"
          value={value as string}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
    );
  }

  if (isArrayOp) {
    return (
      <div className="flex-1 w-44">
        <TagInput
          size="sm"
          variant="primary"
          value={Array.isArray(value) ? value.map(String) : []}
          onChange={(values) => {
            if (field.type === 'number') {
              const numberValues = values.map(Number).filter((n) => !isNaN(n));
              onChange(numberValues as number[]);
            } else {
              onChange(values as string[]);
            }
          }}
          validateValue={(value) => {
            if (field.type === 'number') {
              const num = Number(value);
              return !isNaN(num);
            }
            return true;
          }}
          formatValue={(value) => {
            if (field.type === 'number') {
              return String(Number(value));
            }
            return value;
          }}
        />
      </div>
    );
  }

  if (field.type === 'number') {
    return (
      <div className="w-44">
        <TextInput
          type="number"
          size="sm"
          variant="primary"
          value={value as number}
          onChange={(e) => onChange(Number(e.target.value))}
          // TODO: check when implementing i18n
          placeholder="Ingresa un valor"
        />
      </div>
    );
  }

  return (
    <div className="flex-1 w-44">
      <TextInput
        size="sm"
        variant="primary"
        value={String(value)}
        onChange={(e) => onChange(e.target.value)}
        // TODO: check when implementing i18n
        placeholder="Ingresa un texto"
      />
    </div>
  );
};
