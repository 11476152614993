import React, { useEffect, useState } from 'react';
import { useWhatsAppTemplates } from 'src/modules/messaging/whatsapp/hooks/api/templates';
import { useCreateWhatsAppTemplateMessage } from 'src/modules/messaging/whatsapp/hooks/api/messages-mutations';
import Loading from 'components/atoms/loading/loading';
import InfoError from 'components/atoms/info-error';
import TemplateSelect from '../../../molecules/templates/template-select';
import TemplateInfo from '../../../molecules/templates/template-info';
import MessagePreview from '../../../molecules/templates/message-preview';
import WhatsAppTemplatesModalFooter from '../../../molecules/templates/modal-footer';
import { useTemplateMessage } from 'src/modules/messaging/whatsapp/hooks/templates';
import IntegrationPhoneSelect from '../../../molecules/phones/integration-phone-select';
import {
  usePhoneNumberInput,
  useSelectedPhone,
} from 'src/modules/messaging/whatsapp/hooks/phones';
import LabelTextInput from 'src/modules/shared/components/atoms/inputs/label-text-input';
import { useAlert } from 'src/contexts/alert-context';
import Modal from 'src/modules/shared/components/molecules/modals/modal';
import { Typography } from '@material-tailwind/react';

interface WhatsAppTemplateModalProps {
  isOpen: boolean;
  onClose: () => void;
  fromPhoneNumberId?: string;
  toNumber?: string;
}

export const WhatsAppTemplateModal: React.FC<WhatsAppTemplateModalProps> = ({
  isOpen,
  onClose,
  fromPhoneNumberId,
  toNumber,
}) => {
  const { showAlert } = useAlert();
  const [showTemplateInfo, setShowTemplateInfo] = useState(false);

  const {
    phoneNumber: selectedToNumber,
    isValid: isValidNumber,
    handleChange: handleNumberChange,
    setPhoneNumber,
  } = usePhoneNumberInput({ initialNumber: toNumber || '' });

  useEffect(() => {
    if (!isOpen) return;

    setPhoneNumber(toNumber || '');
  }, [isOpen, toNumber, setPhoneNumber]);

  const {
    phones,
    loading: phonesLoading,
    error: phonesError,
    selectedPhone,
    handlePhoneChange,
  } = useSelectedPhone({ initialPhoneId: fromPhoneNumberId });

  const {
    templates,
    loading: templatesLoading,
    error: templatesError,
  } = useWhatsAppTemplates({
    integrationProviderId: selectedPhone?.integrationProviderId,
  });

  const {
    selectedTemplate,
    messageComponents,
    setMessageComponents,
    selectTemplate,
  } = useTemplateMessage(templates);

  const { createWhatsAppTemplateMessage, loading: createMessageLoading } =
    useCreateWhatsAppTemplateMessage(
      () => {
        showAlert('Mensaje enviado correctamente.', 'success');
        onClose();
      },
      (error: Error) => {
        console.error('Failed to send message', error);
        showAlert(
          `Ocurrió un error enviando el mensaje. Por favor revisa tu conexión e intenta nuevamente.`,
          'error'
        );
      }
    );

  const handleSendTemplateMessage = () => {
    const recipientNumber = toNumber || selectedToNumber;
    if (
      !selectedPhone ||
      !recipientNumber ||
      !selectedTemplate ||
      !isValidNumber
    ) {
      return;
    }

    createWhatsAppTemplateMessage({
      fromPhoneNumber: selectedPhone.address,
      toPhoneNumber: recipientNumber,
      templateName: selectedTemplate.name,
      components: messageComponents,
      languageCode: selectedTemplate.language,
    });
  };

  const hasComponentWithEmptyParams = messageComponents.some((comp) =>
    comp.parameters?.some((param) => !('text' in param) || param.text === '')
  );

  return (
    // TODO: check when implementing i18n
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <Typography className="text-xl font-medium">Nuevo mensaje</Typography>
      }
      body={
        <div className="flex flex-col flex-1 space-y-6 pt-2">
          {phonesLoading && <Loading />}
          {phonesError && <InfoError error={phonesError} />}
          {!phonesLoading && !phonesError && (
            <div className="pr-10">
              <IntegrationPhoneSelect
                phones={phones}
                selectedPhoneId={selectedPhone?.providerId}
                onPhoneChange={handlePhoneChange}
              />
            </div>
          )}
          <div className="pr-10">
            <LabelTextInput
              // TODO: check when implementing i18n
              label="Destinatario"
              size="md"
              inputProps={{
                className: isValidNumber
                  ? ''
                  : 'border-red-500 focus:border-red-500 focus:ring-red-500',
                value: selectedToNumber,
                onChange: handleNumberChange,
                placeholder: '+1234567890',
              }}
            />
          </div>
          {templatesLoading && <Loading />}
          {templatesError && <InfoError error={templatesError} />}
          {!templatesLoading && !templatesError && (
            <>
              <TemplateSelect
                templates={templates}
                selectedTemplateId={selectedTemplate?.id}
                onTemplateChange={selectTemplate}
                onShowInfo={() => setShowTemplateInfo(!showTemplateInfo)}
                isInfoOpen={showTemplateInfo}
              />

              {selectedTemplate && showTemplateInfo && (
                <TemplateInfo template={selectedTemplate} />
              )}

              {selectedTemplate && (
                <MessagePreview
                  template={selectedTemplate}
                  messageComponents={messageComponents}
                  onComponentChange={setMessageComponents}
                />
              )}
            </>
          )}
        </div>
      }
      footer={
        <WhatsAppTemplatesModalFooter
          onCancel={onClose}
          onSend={handleSendTemplateMessage}
          sendDisabled={
            !selectedTemplate ||
            hasComponentWithEmptyParams ||
            createMessageLoading ||
            !isValidNumber
          }
          loading={createMessageLoading}
        />
      }
    />
  );
};
