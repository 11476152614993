/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents the entity types that can have custom fields.
 */
export enum CustomFieldEntityType {
    CONTACT = 'CONTACT',
    COMPANY = 'COMPANY',
    FUNNEL_CONVERSATION = 'FUNNEL_CONVERSATION',
    FUNNEL_CHANNEL_SESSION = 'FUNNEL_CHANNEL_SESSION',
}
