import { useSearch } from '@tanstack/react-router';
import ViewSwitcher from '../../molecules/view-switcher';
import LabelTextInput from 'src/modules/shared/components/atoms/inputs/label-text-input';
import { useState, ReactNode } from 'react';
import FunnelSelector from '../../molecules/funnel-selector';
import { Funnel, SettingName } from 'src/api/generated';
import { useDebounce } from '@uidotdev/usehooks';
import { Route as CommunicationsRoute } from 'src/routes/communications';
import ConversationListView from './lists/conversation-list-view';
import { ConversationPipelineView } from './pipelines/conversation-pipeline-view';
import { PanelHeader } from 'src/modules/shared/components/molecules/panel/panel-header';
import { Panel } from 'src/modules/shared/components/molecules/panel';
import { InteractionsActionsDropdown } from '../../molecules/interactions/actions-dropdown';
import LabelSelect from 'src/modules/shared/components/atoms/inputs/label-select';
import { UNASSIGNED_USER_ID } from 'src/modules/communications/constants/filters.const';
import { useBooleanSetting } from 'hooks/settings/settings';
import { useHasRoles } from 'src/modules/shared/hooks/auth';
import { ErrorState } from 'src/modules/shared/components/atoms/states/error-state';
import { AdvancedFilters } from 'src/modules/shared/components/molecules/advanced-filters/filters/advanced-filters';
import { useConversationFilters } from 'src/modules/communications/hooks/advanced-filters/conversations/filters';
import { AdvancedSorts } from 'src/modules/shared/components/molecules/advanced-filters/sorts/advanced-sorts';
import { useConversationSorts } from 'src/modules/communications/hooks/advanced-filters/conversations/sorts';
import { ConversationTableView } from './tables/conversation-table-view';

interface LayoutProps {
  conversationListWidth: string;
  conversationDetailWidth: string;
  contactProfileWidth: string;
}

interface ConversationPanelProps {
  funnels: Funnel[];
  selectedFunnelId?: number;
  onFunnelSelect: (id: number) => void;
  onConversationSelect?: (id: number) => void;
  selectedConversationId?: number;
  layout?: LayoutProps;
  children?: ReactNode;
}

const ConversationPanel: React.FC<ConversationPanelProps> = ({
  funnels,
  selectedFunnelId,
  onFunnelSelect,
  onConversationSelect,
  selectedConversationId,
  layout,
  children,
}) => {
  const { view } = useSearch({ from: CommunicationsRoute.id });
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSearch = useDebounce(searchTerm, 800);

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  const { setting: showAllConversations } = useBooleanSetting(
    SettingName.FEATURE_FLAG_AGENT_SHOW_ALL_CONVERSATIONS
  );
  const { hasRole: isAdmin } = useHasRoles('Admin');
  const showAgentSelector = showAllConversations || isAdmin;

  const {
    fieldsOptions: filterFieldsOptions,
    activeFilterCount,
    filters,
    setFilters,
    userOptions,
    loading,
    apiFilters,
    statusOptions,
    selectedUserIds,
    setSelectedUserIds,
  } = useConversationFilters({ selectedFunnelId });

  const {
    sorts,
    setSorts,
    activeSortCount,
    apiSorts,
    fieldsOptions: sortFieldsOptions,
  } = useConversationSorts();

  const handleUserSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;

    if (value === String(UNASSIGNED_USER_ID)) {
      setSelectedUserIds([UNASSIGNED_USER_ID]);
    } else {
      setSelectedUserIds(value ? [Number(value)] : []);
    }
  };

  const assignedSelectOptions = [
    // TODO: check when implementing i18n
    { content: 'Todos', value: '' },
    { content: 'Sin asignar', value: String(UNASSIGNED_USER_ID) },
    ...userOptions.map((opt) => ({
      content: opt.label,
      value: String(opt.value),
    })),
  ];

  if (!selectedFunnelId) {
    return (
      <ErrorState
        // TODO: check when implementing i18n
        title="No hay embudo seleccionado"
        description="Por favor selecciona o crea un embudo para ver las conversaciones"
      />
    );
  }

  return (
    <div className="flex flex-col h-full">
      <div className="w-full relative z-10">
        <PanelHeader fixed size="lg" border={false}>
          <div className="flex items-center gap-3 min-w-0 w-full px-1">
            <InteractionsActionsDropdown />
            <ViewSwitcher showLabels={false} />
            <div className="w-40 flex-shrink-0">
              <FunnelSelector
                funnels={funnels}
                selectedFunnelId={selectedFunnelId}
                onFunnelSelect={onFunnelSelect}
                size="md"
              />
            </div>
            {showAgentSelector && (
              <div className="w-40 flex-shrink-0">
                <LabelSelect
                  label="Asignado"
                  options={assignedSelectOptions}
                  labelPosition="top"
                  selectProps={{
                    value: String(selectedUserIds[0] || ''),
                    onChange: handleUserSelect,
                    loading,
                  }}
                  size="md"
                />
              </div>
            )}
            <div className="flex-1 min-w-[200px] max-w-[300px]">
              <LabelTextInput
                label="Buscar"
                labelPosition="top"
                size="md"
                inputProps={{
                  className: 'w-full',
                  placeholder: 'Busca conversaciones...',
                  value: searchTerm,
                  onChange: (e) => handleSearch(e.target.value),
                }}
              />
            </div>
            <div className="ml-auto flex items-center gap-2">
              <AdvancedSorts
                fieldsOptions={sortFieldsOptions}
                onChange={setSorts}
                activeSortCount={activeSortCount}
                sorts={sorts}
              />
              <AdvancedFilters
                fieldsOptions={filterFieldsOptions}
                onChange={setFilters}
                activeFilterCount={activeFilterCount}
                filters={filters}
                userOptions={userOptions.map((user) => ({
                  id: user.value,
                  name: user.label,
                }))}
                statusOptions={statusOptions}
              />
            </div>
          </div>
        </PanelHeader>
      </div>

      <div className="flex flex-1 overflow-hidden">
        <div
          className={`${layout?.conversationListWidth || 'w-full'} transition-all duration-200`}
        >
          {view === 'cards' && (
            <Panel
              neumorphic="inset-sm"
              className="flex-1 h-full overflow-y-auto bg-white rounded-lg mx-3.5 p-2.5"
            >
              <ConversationListView
                selectedFunnelId={selectedFunnelId}
                searchTerm={debouncedSearch}
                onConversationSelect={onConversationSelect}
                selectedConversationId={selectedConversationId}
                filters={apiFilters}
                sorts={apiSorts}
              />
            </Panel>
          )}
          {view === 'pipeline' && (
            <Panel
              neumorphic="flat"
              className="flex-1 h-full overflow-y-auto bg-white rounded-none px-2"
            >
              <ConversationPipelineView
                selectedFunnelId={selectedFunnelId}
                searchTerm={debouncedSearch}
                onConversationSelect={onConversationSelect}
                selectedConversationId={selectedConversationId}
                filters={apiFilters}
                sorts={apiSorts}
              />
            </Panel>
          )}
          {view === 'table' && (
            <Panel
              neumorphic="flat"
              className="flex-1 h-full overflow-y-auto bg-white rounded-none"
            >
              <ConversationTableView
                selectedFunnelId={selectedFunnelId}
                searchTerm={debouncedSearch}
                onConversationSelect={onConversationSelect}
                selectedConversationId={selectedConversationId}
                filters={apiFilters}
                sorts={apiSorts}
              />
            </Panel>
          )}
        </div>

        {children}
      </div>
    </div>
  );
};

export default ConversationPanel;
