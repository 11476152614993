import { z } from 'zod';

// Example to add search params
// https://tanstack.com/router/latest/docs/framework/react/guide/search-params
export const globalSearchSchema = z.object({
  phoneNumber: z.string().optional(),
  phoneVersion: z.enum(['default', 'v1']).optional(),
});

export type GlobalSearchSchema = z.infer<typeof globalSearchSchema>;
