import { Typography } from '@material-tailwind/react';
import { Contact } from 'src/api/generated';
import { Avatar } from 'src/modules/shared/components/atoms/avatar';
import { useState } from 'react';
import Button from 'src/modules/shared/components/atoms/buttons/button';
import LabelTextInput from 'src/modules/shared/components/atoms/inputs/label-text-input';
import { motion, AnimatePresence } from 'framer-motion';
import { useAlert } from 'src/contexts/alert-context';
import Modal from 'src/modules/shared/components/molecules/modals/modal';
import ModalFooter from 'src/modules/shared/components/molecules/modals/modal-footer';
import { SlideDownAnimation } from 'src/modules/shared/components/atoms/animations/slide-down';
import { PiPlusLight, PiPencilLight, PiTrashLight } from 'react-icons/pi';
import { ExpandableSection } from 'src/modules/shared/components/molecules/expandable-section';
import { FormSkeleton } from 'src/modules/shared/components/molecules/form-skeleton';
import { EmptyState } from 'src/modules/shared/components/atoms/states/empty-state';
import { ErrorState } from 'src/modules/shared/components/atoms/states/error-state';

interface CompanyInfoSectionProps {
  contact: Contact | null;
  loading?: boolean;
  error?: Error | null;
}

export const CompanyInfoSection: React.FC<CompanyInfoSectionProps> = ({
  contact,
  loading = false,
  error = null,
}) => {
  const { showAlert } = useAlert();
  const [isEditing, setIsEditing] = useState(false);
  const [isDeletingCompany, setIsDeletingCompany] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  const handleSaveCompany = async () => {
    try {
      // Mock API call
      await new Promise((resolve) => setTimeout(resolve, 1000));
      showAlert('Company updated successfully', 'success');
      setIsEditing(false);
    } catch (error) {
      console.error('Failed to update company:', error);
      showAlert('Failed to update company', 'error');
    }
  };

  const handleDeleteCompany = async () => {
    try {
      setIsDeleting(true);
      // Mock API call
      await new Promise((resolve) => setTimeout(resolve, 1000));
      showAlert('Company removed successfully', 'success');
      setCompanyName('');
      setIsDeletingCompany(false);
    } catch (error) {
      console.error('Failed to remove company:', error);
      showAlert('Failed to remove company', 'error');
    } finally {
      setIsDeleting(false);
    }
  };

  const renderAddButton = () => {
    if (contact?.contactCompanyId) return null;

    return (
      <Button
        variant="neutral"
        appearance="ghost"
        size="sm"
        onClick={() => setIsEditing(true)}
        className="flex items-center gap-1"
        disabled={true}
      >
        <PiPlusLight className="w-4 h-4" />
      </Button>
    );
  };

  const renderContent = () => {
    if (loading) {
      return (
        <FormSkeleton
          fieldCount={2}
          variant="primary"
          spacing="space-y-3"
          defaultInputClassName="w-5/6"
        />
      );
    }

    if (error) {
      return (
        <ErrorState
          title="Error al cargar información"
          description="No se pudo cargar la información de la empresa. Por favor, inténtalo de nuevo."
          variant="primary"
        />
      );
    }

    const hasCompany = contact?.contactCompanyId || companyName;

    return (
      <div className="space-y-3">
        <AnimatePresence>
          <SlideDownAnimation
            isVisible={isEditing}
            className="border border-gray-200 rounded-lg p-4 mb-4"
          >
            <div className="space-y-4">
              <LabelTextInput
                // TODO: check when implemnting i18n
                label="Nombre"
                labelPosition="top"
                size="sm"
                inputProps={{
                  className: 'w-full',
                  value: companyName,
                  onChange: (e) => setCompanyName(e.target.value),
                  // TODO: check when implemnting i18n
                  placeholder: 'Ingrese nombre de la empresa...',
                }}
              />
              <div className="flex justify-end gap-2">
                <Button
                  variant="neutral"
                  appearance="ghost"
                  size="sm"
                  onClick={() => {
                    setIsEditing(false);
                    setCompanyName('');
                  }}
                >
                  {/* TODO: check when implemnting i18n */}
                  Cancelar
                </Button>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={handleSaveCompany}
                  disabled={!companyName}
                >
                  {/* TODO: check when implemnting i18n */}
                  Guardar
                </Button>
              </div>
            </div>
          </SlideDownAnimation>
          {hasCompany ? (
            <motion.div
              key="company-info"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              className="group relative flex items-center justify-between text-sm px-2 hover:bg-gray-50 rounded-lg"
            >
              <div className="flex items-center gap-3">
                <Avatar text={companyName} size="sm" />
                <Typography color="blue-gray" className="text-sm font-normal">
                  {companyName || 'Sin empresa'}
                </Typography>
              </div>
              <div className="opacity-0 group-hover:opacity-100 transition-opacity flex items-center gap-2">
                <button
                  onClick={() => setIsEditing(true)}
                  className="p-1 text-gray-500 hover:text-gray-700"
                >
                  <PiPencilLight className="w-4 h-4" />
                </button>
                <button
                  onClick={() => setIsDeletingCompany(true)}
                  className="p-1 text-gray-500 hover:text-red-600"
                >
                  <PiTrashLight className="w-4 h-4" />
                </button>
              </div>
            </motion.div>
          ) : (
            <EmptyState
              key="empty-state"
              // TODO: check when implementing i18n
              description="Agrega una empresa a este contacto"
            />
          )}
        </AnimatePresence>
      </div>
    );
  };

  return (
    <>
      {/* TODO: check when implementing i18n */}
      <ExpandableSection
        title="Empresa"
        actions={!loading && !error ? renderAddButton() : undefined}
      >
        {renderContent()}
      </ExpandableSection>

      <Modal
        isOpen={isDeletingCompany}
        onClose={() => setIsDeletingCompany(false)}
        header={
          <Typography variant="h5" color="blue-gray">
            {/* TODO: check when implemnting i18n */}
            Eliminar Empresa
          </Typography>
        }
        body={
          <Typography variant="paragraph" color="blue-gray">
            {/* TODO: check when implemnting i18n */}
            ¿Está seguro que desea eliminar esta empresa? Esta acción no se
            puede deshacer.
          </Typography>
        }
        footer={
          <ModalFooter
            onCancel={() => setIsDeletingCompany(false)}
            onConfirm={handleDeleteCompany}
            // TODO: check when implemnting i18n
            cancelText="Cancelar"
            confirmText="Eliminar"
            confirmDisabled={isDeleting}
            loading={isDeleting}
            variant="danger"
          />
        }
      />
    </>
  );
};
