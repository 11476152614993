import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { Typography } from '@material-tailwind/react';
import Button from '../../buttons/button';
import { type BaseProps } from 'src/modules/shared/types/component.type';

interface ErrorStateProps extends BaseProps {
  title?: string;
  description?: string;
  onAction?: () => void;
  actionText?: string;
}

// TODO: check when implementing i18n
export const ErrorState: React.FC<ErrorStateProps> = ({
  className = '',
  title = 'Algo salió mal',
  description = 'Ocurrió un error inesperado',
  onAction,
  actionText = 'Intentar de nuevo',
  variant = 'primary',
}) => {
  return (
    <div
      className={`flex flex-col items-center justify-center h-full space-y-4 p-4 ${className}`}
    >
      <div className="w-16 h-16 rounded-full bg-danger-50 flex items-center justify-center">
        <ExclamationCircleIcon
          className="w-8 h-8 text-danger-500"
          strokeWidth={2}
        />
      </div>
      <div className="text-center space-y-1">
        <Typography variant="paragraph" className="text-gray-900 font-medium">
          {title}
        </Typography>
        <Typography variant="small" className="text-gray-600">
          {description}
        </Typography>
      </div>
      {onAction && (
        <Button variant={variant} onClick={onAction} className="mt-2">
          {actionText}
        </Button>
      )}
    </div>
  );
};
