import { useInfiniteQuery } from '@tanstack/react-query';
import { ApiResponse, useMemoizedArrayData } from 'src/hooks/api/api';
import {
  ContactAddress,
  CrmContactsAddressesService,
  ContactAddressListResponse,
  ContactAddressListQueryParams,
} from 'src/api/generated';

interface UseContactAddressesParams extends ContactAddressListQueryParams {}

interface UseContactAddressesResponse extends ApiResponse {
  addresses: ContactAddress[];
  fetchNextPage: () => void;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
}

export const useContactAddresses = (
  params: UseContactAddressesParams,
  enabled: boolean = true
): UseContactAddressesResponse => {
  const fetchAddresses = async ({
    pageParam,
  }: {
    pageParam: string | undefined;
  }): Promise<ContactAddressListResponse> => {
    const { filterBy, searchBy, sortBy, page, pageSize = 20 } = params;

    return await CrmContactsAddressesService.getContactAddresses(
      filterBy,
      searchBy,
      sortBy,
      page,
      pageSize,
      pageParam
    );
  };

  const getNextPageParam = (lastPage: ContactAddressListResponse) =>
    lastPage.nextPageToken;

  const {
    data,
    error,
    isLoading: loading,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['crm/contacts/addresses', params],
    queryFn: fetchAddresses,
    getNextPageParam,
    initialPageParam: undefined,
    enabled,
  });

  const addresses = useMemoizedArrayData<ContactAddress>(
    data?.pages.flatMap((page) => page.items) || []
  );

  return {
    loading,
    error,
    addresses,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  };
};
