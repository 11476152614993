import { ContactDocument, Contact, ContactAddress } from 'src/api/generated';

export interface UpdateContactParams {
  id: number;
  data: Partial<Contact>;
}

/**
 * Merges existing addresses, updating any that match by ID
 * and appending any new addresses
 */
const mergeContactAddresses = (
  existingAddresses: ContactAddress[] = [],
  newAddresses: ContactAddress[] = []
): ContactAddress[] => {
  const mergedAddresses = existingAddresses.map((existingAddress) => {
    const updatedAddress = newAddresses.find(
      (addr) => addr.id === existingAddress.id
    );
    return updatedAddress ?? existingAddress;
  });

  const addressesToAdd = newAddresses.filter(
    (newAddr) =>
      !existingAddresses.some((existingAddr) => existingAddr.id === newAddr.id)
  );

  return [...mergedAddresses, ...addressesToAdd];
};

/**
 * Merges contact data from an existing contact with new data,
 * preserving rich data while applying updates
 */
export const mergeContact = (
  existingContact: Contact | undefined,
  newData: Partial<Contact>
): Contact => {
  return {
    id: existingContact?.id || newData.id || 0,
    tenantId: existingContact?.tenantId || newData.tenantId || '',
    createdAt:
      existingContact?.createdAt ||
      newData.createdAt ||
      new Date().toISOString(),
    updatedAt: newData.updatedAt || new Date().toISOString(),
    providerId:
      newData.providerId !== undefined
        ? newData.providerId
        : (existingContact?.providerId ?? null),
    providerName:
      newData.providerName !== undefined
        ? newData.providerName
        : (existingContact?.providerName ?? null),
    nationalId: newData.nationalId || existingContact?.nationalId || null,
    firstName: newData.firstName || existingContact?.firstName || null,
    lastName: newData.lastName || existingContact?.lastName || null,
    email: newData.email || existingContact?.email || null,
    companyPosition:
      newData.companyPosition || existingContact?.companyPosition || null,
    addresses: mergeContactAddresses(
      existingContact?.addresses,
      newData.addresses
    ),
    phone: newData.phone || existingContact?.phone || null,
    userId:
      newData.userId !== undefined
        ? newData.userId
        : (existingContact?.userId ?? null),
    contactCompanyId:
      newData.contactCompanyId !== undefined
        ? newData.contactCompanyId
        : (existingContact?.contactCompanyId ?? null),
    metadata: {
      ...(existingContact?.metadata || {}),
      ...(newData.metadata || {}),
    },
  };
};

/**
 * Creates an optimistic contact document for cache updates.
 */
export const createOptimisticContactDocument = (
  params: UpdateContactParams,
  previousDocument?: ContactDocument
): ContactDocument => {
  const previousContact: Contact | undefined = previousDocument
    ? {
        ...previousDocument,
        id: Number(previousDocument.id),
        userId: previousDocument.userId
          ? Number(previousDocument.userId)
          : null,
        contactCompanyId: previousDocument.contactCompanyId
          ? Number(previousDocument.contactCompanyId)
          : null,
      }
    : undefined;

  const mergedContact = mergeContact(previousContact, params.data);

  const optimisticDocument: ContactDocument = {
    ...mergedContact,
    modelType: ContactDocument.modelType.CONTACT,
    id: String(mergedContact.id),
    userId: mergedContact.userId ? String(mergedContact.userId) : null,
    contactCompanyId: mergedContact.contactCompanyId
      ? String(mergedContact.contactCompanyId)
      : null,
    eventTimestamp: previousDocument?.eventTimestamp,
    eventSequence: previousDocument?.eventSequence,
  };

  return optimisticDocument;
};

/**
 * Creates an optimistic contact for regular cache updates.
 */
export const createOptimisticContact = (
  params: UpdateContactParams,
  previousContact?: Contact
): Contact => {
  return mergeContact(previousContact, { ...params.data, id: params.id });
};
