// src/contexts/query-context.js

import React, { useState } from 'react';
import {
  QueryClient,
  QueryClientProvider,
  QueryCache,
  MutationCache,
  QueryClientConfig,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { MINUTE_IN_MS } from 'src/utils/date';
import {
  exponentialBackoffDelay,
  onRetryLimitReached,
  shouldRetry,
  useSetApiToken,
} from 'hooks/api/api';
import { useRefreshAuthToken } from 'src/modules/shared/hooks/auth';
import { useAuth0 } from '@auth0/auth0-react';
import { useAlert } from './alert-context';

interface QueryProviderProps {
  children: React.ReactNode;
}

const QueryProvider: React.FC<QueryProviderProps> = ({ children }) => {
  const { logout } = useAuth0();
  const { refresh } = useRefreshAuthToken();
  const { showAlert } = useAlert();

  const shouldRetryRequest = (failureCount: number, error: Error) => {
    return shouldRetry(failureCount, error, refresh);
  };

  const onRetryLimit = (error: Error) => {
    onRetryLimitReached(error, logout, showAlert);
  };

  const queryClientConfig: QueryClientConfig = {
    defaultOptions: {
      queries: {
        staleTime: MINUTE_IN_MS,
        retryDelay: exponentialBackoffDelay,
        retry: shouldRetryRequest,
      },
    },
    queryCache: new QueryCache({
      onError: onRetryLimit,
    }),
    mutationCache: new MutationCache({
      onError: onRetryLimit,
    }),
  };
  const [queryClient] = useState(() => new QueryClient(queryClientConfig));

  useSetApiToken();

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default QueryProvider;
