import { Typography } from '@material-tailwind/react';
import React from 'react';
import {
  ComponentSize,
  ComponentVariant,
  ComponentAppearance,
} from '../../../types/component.type';
import {
  tagSizeClasses,
  tagTextSizeClasses,
  tagFilledClasses,
  tagOutlineClasses,
  tagTextColorClasses,
} from './tag.styles';

export interface TagProps {
  content: string | React.ReactNode;
  size?: ComponentSize;
  variant?: ComponentVariant;
  appearance?: ComponentAppearance;
  className?: string;
  textClassName?: string;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  onClick?: () => void;
}

const appearanceClasses: Record<
  NonNullable<ComponentAppearance>,
  Record<NonNullable<ComponentVariant>, string>
> = {
  filled: tagFilledClasses,
  outline: tagOutlineClasses,
  soft: tagFilledClasses,
  ghost: tagFilledClasses,
};

const Tag: React.FC<TagProps> = ({
  content,
  size = 'md',
  variant = 'primary',
  appearance = 'filled',
  className,
  textClassName,
  icon,
  iconPosition = 'left',
  onClick,
}) => {
  const containerClasses = [
    'flex w-fit items-center justify-center gap-1 rounded-full font-medium',
    'ring-1 ring-inset transition-colors duration-200',
    tagSizeClasses[size],
    appearanceClasses[appearance][variant],
    onClick ? 'cursor-pointer' : '',
    className || '',
  ]
    .join(' ')
    .trim();

  const contentClasses = [
    'flex h-fit items-center m-0 whitespace-nowrap',
    tagTextSizeClasses[size],
    tagTextColorClasses[variant],
    textClassName || '',
  ]
    .join(' ')
    .trim();

  return (
    <div className={containerClasses} onClick={onClick}>
      {iconPosition === 'left' && icon}
      {typeof content === 'string' ? (
        <Typography className={contentClasses}>{content}</Typography>
      ) : (
        <div className={contentClasses}>{content}</div>
      )}
      {iconPosition === 'right' && icon}
    </div>
  );
};

export default Tag;
