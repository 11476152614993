import { ClockIcon } from '@heroicons/react/24/outline';
import { FunnelChannelSession } from 'src/api/generated';
import { Alert } from 'src/modules/shared/components/atoms/alert';
import { ComponentVariant } from 'src/modules/shared/types/component.type';
import {
  getExpirationInfo,
  isExpired,
} from 'src/modules/shared/utils/expiration.util';
import { formatDistanceToNowStrict } from 'date-fns';
import { Typography } from '@material-tailwind/react';
import { HOUR_IN_MS } from 'src/utils/date';
import { es } from 'date-fns/locale';
import { alertTextSizeClasses } from 'src/modules/shared/components/atoms/alert/alert.styles';

interface WhatsAppExpirationAlertProps {
  channelSession?: FunnelChannelSession;
}

const EXPIRATION_WARNING_THRESHOLD = 3 * HOUR_IN_MS;

// TODO: check when implementing i18n
export const WhatsAppExpirationAlert: React.FC<
  WhatsAppExpirationAlertProps
> = ({ channelSession }) => {
  const endTime = channelSession?.endTime ?? undefined;
  if (!endTime) return null;

  const isSessionExpired = isExpired(endTime);
  const { timeRemaining } = getExpirationInfo(endTime);
  const isCloseToExpiration =
    timeRemaining !== null &&
    timeRemaining <= EXPIRATION_WARNING_THRESHOLD &&
    !isSessionExpired;

  const getExpirationMessage = (): string | React.ReactNode => {
    if (isSessionExpired) {
      return (
        <div className="space-y-0.5">
          <Typography className={alertTextSizeClasses['sm']}>
            Esta conversación ha expirado.
          </Typography>
          <Typography className={alertTextSizeClasses['sm']}>
            Se reactivará cuando el cliente te envíe un mensaje. Intenta enviar
            una plantilla.
          </Typography>
        </div>
      );
    }

    const timeLeft = formatDistanceToNowStrict(new Date(endTime), {
      // TODO: check when implementing i18n
      locale: es,
      addSuffix: false,
    });
    if (isCloseToExpiration) {
      return (
        <div className="space-y-0.5">
          <Typography className={alertTextSizeClasses['sm']}>
            Esta conversación expirará en {timeLeft}.
          </Typography>
          <Typography className={alertTextSizeClasses['sm']}>
            Extiéndela haciendo que el cliente te envíe un mensaje.
          </Typography>
        </div>
      );
    }

    return `Esta conversación expirará en ${timeLeft}.`;
  };

  const getExpirationVariant = (): ComponentVariant => {
    if (isCloseToExpiration) return 'warning';
    return 'secondary';
  };

  return (
    <Alert
      variant={getExpirationVariant()}
      icon={<ClockIcon className="h-4 w-4" />}
      message={getExpirationMessage()}
    />
  );
};
