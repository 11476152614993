import {
  InlineInputWrapper,
  InlineInputWrapperProps,
} from '../inline-input-wrapper';
import {
  TagDropdown,
  TagDropdownOption,
} from 'src/modules/shared/components/molecules/dropdowns/tag-dropdown';
import { useMemo } from 'react';

type OptionValue = string;

interface Option {
  label: string;
  value: OptionValue;
}

interface InlineSelectInputProps
  extends Omit<InlineInputWrapperProps<OptionValue>, 'children'> {
  options: Option[];
  placeholder?: string;
  loading?: boolean;
}

export const InlineSelectInput: React.FC<InlineSelectInputProps> = ({
  options,
  placeholder,
  loading = false,
  ...props
}) => {
  const dropdownOptions: TagDropdownOption[] = useMemo(() => {
    return options.map((option) => ({
      id: String(option.value),
      content: option.label,
    }));
  }, [options]);

  const getSelectedOptionLabel = (value: OptionValue) => {
    const selectedOption = options.find((option) => option.value === value);
    if (!selectedOption) {
      // TODO: check when implementing i18n
      return placeholder || 'Selecciona';
    }

    return selectedOption.label;
  };

  const handleOptionSelect = (
    option: TagDropdownOption,
    onChange: (value: OptionValue) => void
  ) => {
    const selectedOptionId = String(option.id);

    const selectedOption = options.find(
      (option) => String(option.value) === selectedOptionId
    );
    if (!selectedOption) return;

    onChange(selectedOption?.value);
  };

  return (
    <InlineInputWrapper<OptionValue> {...props}>
      {({ value, onChange, disabled }) => (
        <TagDropdown
          content={getSelectedOptionLabel(value)}
          variant="primary"
          size="sm"
          appearance="outline"
          isLoading={loading}
          disabled={disabled}
          options={dropdownOptions}
          onOptionSelect={(option: TagDropdownOption) => {
            handleOptionSelect(option, onChange);
          }}
          className="max-w-[180px] transition-all"
        />
      )}
    </InlineInputWrapper>
  );
};
