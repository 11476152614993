import { FC } from 'react';
import { PiPlusLight, PiXLight } from 'react-icons/pi';
import Button from '../../../atoms/buttons/button';
import { Sort, SortFieldOptions } from 'src/modules/shared/types/filters.types';
import { Typography } from '@material-tailwind/react';
import { EmptyState } from '../../../atoms/states/empty-state';
import Select from '../../../atoms/inputs/select';
import { SearcherDocumentSearchSortDirection } from 'src/api/generated';

export interface SortBuilderProps {
  fieldsOptions: SortFieldOptions[];
  sorts: Sort[];
  onChange: (sorts: Sort[]) => void;
}

export const SortBuilder: FC<SortBuilderProps> = ({
  fieldsOptions,
  sorts,
  onChange,
}) => {
  const handleAddSort = () => {
    if (fieldsOptions.length === 0) return;

    const firstField = fieldsOptions[0];
    const newSort: Sort = {
      id: `sort-${Date.now()}-${firstField.id}`,
      fieldOptionId: firstField.id,
      direction: SearcherDocumentSearchSortDirection.ASC,
    };

    onChange([...sorts, newSort]);
  };

  const handleRemoveSort = (sortId: string) => {
    const remainingSorts = sorts.filter((sort) => sort.id !== sortId);
    onChange(remainingSorts);
  };

  const handleFieldChange = (sortId: string, fieldOptionId: string) => {
    onChange(
      sorts.map((sort) => {
        if (sort.id !== sortId) return sort;

        return { ...sort, fieldOptionId };
      })
    );
  };

  const handleDirectionChange = (
    sortId: string,
    direction: SearcherDocumentSearchSortDirection
  ) => {
    onChange(
      sorts.map((sort) => {
        if (sort.id !== sortId) return sort;

        return { ...sort, direction };
      })
    );
  };

  return (
    <div className="flex flex-col space-y-4">
      {sorts.length === 0 ? (
        <EmptyState
          description="Agrega un orden para empezar a ordenar"
          className="py-3"
        />
      ) : (
        <div className="space-y-2">
          {sorts.map((sort) => {
            const fieldOption = fieldsOptions.find(
              (fieldOption) => fieldOption.id === sort.fieldOptionId
            );

            return (
              <div
                key={`${sort.id}-${sort.fieldOptionId}`}
                className="group flex items-center gap-2"
              >
                <div className="flex-1 flex items-center gap-2 p-2">
                  <Select
                    options={fieldsOptions.map((field) => ({
                      content: field.label,
                      value: field.id,
                    }))}
                    size="sm"
                    value={sort.fieldOptionId}
                    onChange={(e) => handleFieldChange(sort.id, e.target.value)}
                    className="w-44"
                  />
                  <Select
                    options={[
                      // TODO: check when implementing i18n
                      {
                        content: 'Ascendente',
                        value: SearcherDocumentSearchSortDirection.ASC,
                      },
                      {
                        content: 'Descendente',
                        value: SearcherDocumentSearchSortDirection.DESC,
                      },
                    ]}
                    size="sm"
                    value={sort.direction}
                    onChange={(e) =>
                      handleDirectionChange(
                        sort.id,
                        e.target.value as SearcherDocumentSearchSortDirection
                      )
                    }
                    disabled={!fieldOption}
                    className="w-full"
                    containerClassName="w-28"
                  />
                </div>

                <Button
                  variant="secondary"
                  appearance="ghost"
                  size="sm"
                  onClick={() => handleRemoveSort(sort.id)}
                  aria-label="Remove sort"
                >
                  <PiXLight className="w-4 h-4" />
                </Button>
              </div>
            );
          })}
        </div>
      )}

      <div className="flex justify-center">
        <Button appearance="ghost" size="sm" onClick={handleAddSort}>
          <PiPlusLight className="w-4 h-4" />
          {/* TODO: check when implementing i18n */}
          <Typography className="text-xs font-normal">Agregar orden</Typography>
        </Button>
      </div>
    </div>
  );
};
