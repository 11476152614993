import { SearcherDocumentSearchFilterOperator } from 'src/api/generated';

/**
 * Map of operator values to their labels
 */
export const OPERATOR_LABELS: Record<
  SearcherDocumentSearchFilterOperator,
  string
> = {
  // TODO: check when implementing i18n
  [SearcherDocumentSearchFilterOperator.EQ]: 'es',
  [SearcherDocumentSearchFilterOperator.NEQ]: 'no es',
  [SearcherDocumentSearchFilterOperator.GT]: 'es mayor que',
  [SearcherDocumentSearchFilterOperator.GTE]: 'es mayor o igual que',
  [SearcherDocumentSearchFilterOperator.LT]: 'es menor que',
  [SearcherDocumentSearchFilterOperator.LTE]: 'es menor o igual que',
  [SearcherDocumentSearchFilterOperator.CONTAINS]: 'contiene',
  [SearcherDocumentSearchFilterOperator.STARTS_WITH]: 'comienza con',
  [SearcherDocumentSearchFilterOperator.EXISTS]: 'no es vacío',
  [SearcherDocumentSearchFilterOperator.MISSING]: 'es vacío',
  [SearcherDocumentSearchFilterOperator.IN]: 'es cualquiera de',
  [SearcherDocumentSearchFilterOperator.NIN]: 'no es cualquiera de',
};

const DATE_OPERATOR_LABELS: Record<
  SearcherDocumentSearchFilterOperator,
  string
> = {
  ...OPERATOR_LABELS,
  // TODO: check when implementing i18n
  [SearcherDocumentSearchFilterOperator.GT]: 'es posterior a',
  [SearcherDocumentSearchFilterOperator.LT]: 'es anterior a',
};

/**
 * Map of field types to their allowed operators with context-specific labels
 */
export const FIELD_TYPE_OPERATORS: Record<
  string,
  Array<{ value: SearcherDocumentSearchFilterOperator; label: string }>
> = {
  string: [
    {
      value: SearcherDocumentSearchFilterOperator.EQ,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.EQ],
    },
    {
      value: SearcherDocumentSearchFilterOperator.NEQ,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.NEQ],
    },
    {
      value: SearcherDocumentSearchFilterOperator.CONTAINS,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.CONTAINS],
    },
    {
      value: SearcherDocumentSearchFilterOperator.STARTS_WITH,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.STARTS_WITH],
    },
    {
      value: SearcherDocumentSearchFilterOperator.MISSING,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.MISSING],
    },
    {
      value: SearcherDocumentSearchFilterOperator.EXISTS,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.EXISTS],
    },
    {
      value: SearcherDocumentSearchFilterOperator.IN,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.IN],
    },
  ],
  number: [
    {
      value: SearcherDocumentSearchFilterOperator.EQ,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.EQ],
    },
    {
      value: SearcherDocumentSearchFilterOperator.NEQ,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.NEQ],
    },
    {
      value: SearcherDocumentSearchFilterOperator.GT,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.GT],
    },
    {
      value: SearcherDocumentSearchFilterOperator.GTE,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.GTE],
    },
    {
      value: SearcherDocumentSearchFilterOperator.LT,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.LT],
    },
    {
      value: SearcherDocumentSearchFilterOperator.LTE,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.LTE],
    },
    {
      value: SearcherDocumentSearchFilterOperator.MISSING,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.MISSING],
    },
    {
      value: SearcherDocumentSearchFilterOperator.EXISTS,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.EXISTS],
    },
  ],
  boolean: [
    {
      value: SearcherDocumentSearchFilterOperator.EQ,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.EQ],
    },
    {
      value: SearcherDocumentSearchFilterOperator.NEQ,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.NEQ],
    },
    {
      value: SearcherDocumentSearchFilterOperator.MISSING,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.MISSING],
    },
    {
      value: SearcherDocumentSearchFilterOperator.EXISTS,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.EXISTS],
    },
  ],
  date: [
    {
      value: SearcherDocumentSearchFilterOperator.EQ,
      label: DATE_OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.EQ],
    },
    {
      value: SearcherDocumentSearchFilterOperator.GT,
      label: DATE_OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.GT],
    },
    {
      value: SearcherDocumentSearchFilterOperator.LT,
      label: DATE_OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.LT],
    },
    {
      value: SearcherDocumentSearchFilterOperator.MISSING,
      label: DATE_OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.MISSING],
    },
    {
      value: SearcherDocumentSearchFilterOperator.EXISTS,
      label: DATE_OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.EXISTS],
    },
  ],
  user: [
    {
      value: SearcherDocumentSearchFilterOperator.EQ,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.EQ],
    },
    {
      value: SearcherDocumentSearchFilterOperator.NEQ,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.NEQ],
    },
    {
      value: SearcherDocumentSearchFilterOperator.MISSING,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.MISSING],
    },
    {
      value: SearcherDocumentSearchFilterOperator.EXISTS,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.EXISTS],
    },
  ],
  status: [
    {
      value: SearcherDocumentSearchFilterOperator.EQ,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.EQ],
    },
    {
      value: SearcherDocumentSearchFilterOperator.NEQ,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.NEQ],
    },
    {
      value: SearcherDocumentSearchFilterOperator.IN,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.IN],
    },
    {
      value: SearcherDocumentSearchFilterOperator.NIN,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.NIN],
    },
    {
      value: SearcherDocumentSearchFilterOperator.MISSING,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.MISSING],
    },
    {
      value: SearcherDocumentSearchFilterOperator.EXISTS,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.EXISTS],
    },
  ],
  list: [
    {
      value: SearcherDocumentSearchFilterOperator.EQ,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.EQ],
    },
    {
      value: SearcherDocumentSearchFilterOperator.NEQ,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.NEQ],
    },
    {
      value: SearcherDocumentSearchFilterOperator.IN,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.IN],
    },
    {
      value: SearcherDocumentSearchFilterOperator.NIN,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.NIN],
    },
    {
      value: SearcherDocumentSearchFilterOperator.MISSING,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.MISSING],
    },
    {
      value: SearcherDocumentSearchFilterOperator.EXISTS,
      label: OPERATOR_LABELS[SearcherDocumentSearchFilterOperator.EXISTS],
    },
  ],
};

/**
 * Get the label for an operator in the context of a specific field type
 */
export function getOperatorLabel(
  operator: SearcherDocumentSearchFilterOperator,
  fieldType: string = 'string'
): string {
  const typeOperators =
    FIELD_TYPE_OPERATORS[fieldType] || FIELD_TYPE_OPERATORS.string;
  const operatorConfig = typeOperators.find((op) => op.value === operator);

  return operatorConfig?.label || OPERATOR_LABELS[operator] || operator;
}

export const isNoValueOperator = (
  operator: SearcherDocumentSearchFilterOperator
): boolean => {
  return (
    operator === SearcherDocumentSearchFilterOperator.EXISTS ||
    operator === SearcherDocumentSearchFilterOperator.MISSING
  );
};

export const isArrayOperator = (
  operator: SearcherDocumentSearchFilterOperator
): boolean => {
  return (
    operator === SearcherDocumentSearchFilterOperator.IN ||
    operator === SearcherDocumentSearchFilterOperator.NIN
  );
};
