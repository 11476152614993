import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  BulkConversationPassthroughUpdateParams,
  BulkOperationResponse,
  V1ConversationsService,
  BulkSingleConversationUpdateParams,
  FunnelConversation,
  BulkOperationItemValidation,
  FunnelConversationDocument,
} from 'src/api/generated';
import { updateFunnelConversationCache } from '../../../utils/cache/funnel-conversations/update.util';
import { getModelFromCache } from 'src/modules/shared/utils/cache/update.util';
import { createOptimisticConversation } from 'src/modules/communications/utils/cache/funnel-conversations/optimistic.util';

interface BulkUpdateFunnelConversationParams {
  conversationIds: number[];
  data: Omit<BulkSingleConversationUpdateParams, 'id'>;
}

interface BulkUpdateFunnelConversationResponse {
  loading: boolean;
  error: Error | null;
  response?: BulkOperationResponse;
  bulkUpdateConversations: (
    params: BulkUpdateFunnelConversationParams
  ) => Promise<BulkOperationResponse>;
}

export const useBulkUpdateFunnelConversation = (
  onSuccess: (response: BulkOperationResponse) => void = () => {},
  onError: (error: Error) => void = () => {}
): BulkUpdateFunnelConversationResponse => {
  const queryClient = useQueryClient();

  const bulkUpdateFunnelConversations = async ({
    conversationIds,
    data,
  }: BulkUpdateFunnelConversationParams): Promise<BulkOperationResponse> => {
    const bulkParams: BulkConversationPassthroughUpdateParams = {
      conversations: conversationIds.map((id) => ({
        id,
        ...data,
      })),
    };

    return await V1ConversationsService.bulkUpdate(bulkParams);
  };

  const mutation = useMutation({
    mutationFn: bulkUpdateFunnelConversations,
    onMutate: async ({ conversationIds, data }) => {
      await queryClient.cancelQueries({
        queryKey: ['crm/documents/funnel-conversations'],
      });
      await queryClient.cancelQueries({
        queryKey: ['crm/funnel-conversations'],
      });

      const previousData = queryClient.getQueriesData({
        queryKey: ['crm/documents/funnel-conversations'],
      });
      const previousConversationsData = queryClient.getQueriesData({
        queryKey: ['crm/funnel-conversations'],
      });

      const indexToIdMap = new Map<number, number>();

      conversationIds.forEach((id, index) => {
        indexToIdMap.set(index, id);
        const previousConversation = getModelFromCache<FunnelConversation>(
          queryClient,
          ['crm/funnel-conversations'],
          id
        );
        const previousConversationDocument =
          getModelFromCache<FunnelConversationDocument>(
            queryClient,
            ['crm/documents/funnel-conversations'],
            id
          );

        const optimisticConversation = createOptimisticConversation(
          { id, data },
          previousConversation
        );

        updateFunnelConversationCache(queryClient, optimisticConversation, {
          params: { id, data },
          previousConversation,
          previousConversationDocument,
        });
      });

      return {
        previousData,
        previousConversationsData,
        indexToIdMap,
      };
    },
    onError: (error, _variables, context) => {
      if (context?.previousData) {
        context.previousData.forEach(([queryKey, value]) => {
          queryClient.setQueryData(queryKey, value);
        });
      }
      if (context?.previousConversationsData) {
        context.previousConversationsData.forEach(([queryKey, value]) => {
          queryClient.setQueryData(queryKey, value);
        });
      }
      onError(error);
    },
    onSuccess: (response, variables, context) => {
      if (response.report.batchErrors.length > 0) {
        if (context?.previousData) {
          context.previousData.forEach(([queryKey, value]) => {
            queryClient.setQueryData(queryKey, value);
          });
        }
        if (context?.previousConversationsData) {
          context.previousConversationsData.forEach(([queryKey, value]) => {
            queryClient.setQueryData(queryKey, value);
          });
        }
        onError(new Error(response.report.batchErrors[0].message));
        return;
      }

      const failedIds = new Set<number>();
      response.report.itemsIssues.forEach(
        (issue: BulkOperationItemValidation) => {
          if (!issue.errors.length || !context?.indexToIdMap) return;

          const conversationId = context.indexToIdMap.get(issue.index);
          if (!conversationId) return;

          failedIds.add(conversationId);
        }
      );

      if (failedIds.size > 0) {
        variables.conversationIds.forEach((id) => {
          if (!failedIds.has(id)) return;

          const previousConversation = getModelFromCache<FunnelConversation>(
            queryClient,
            ['crm/funnel-conversations'],
            id
          );
          if (!previousConversation) return;

          updateFunnelConversationCache(queryClient, previousConversation, {
            params: { id, data: {} },
            previousConversation,
          });
        });
      }

      onSuccess(response);
    },
  });

  return {
    loading: mutation.isPending,
    error: mutation.error,
    response: mutation.data,
    bulkUpdateConversations: mutation.mutateAsync,
  };
};
