/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Alias_ConversationUpdateBodyParams } from '../models/Alias_ConversationUpdateBodyParams';
import type { ConversationGetResponse } from '../models/ConversationGetResponse';
import type { ConversationsListResponse } from '../models/ConversationsListResponse';
import type { ConversationStatus } from '../models/ConversationStatus';
import type { ConversationUpdateResponse } from '../models/ConversationUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MessagingConversationsService {

    /**
     * @param limit
     * @param pageToken
     * @param status
     * @param tenantId
     * @returns ConversationsListResponse Ok
     * @throws ApiError
     */
    public static getConversations(
        limit?: number,
        pageToken?: string,
        status?: ConversationStatus,
        tenantId?: string,
    ): CancelablePromise<ConversationsListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/messaging/conversations',
            query: {
                'limit': limit,
                'pageToken': pageToken,
                'status': status,
                'tenantId': tenantId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden.`,
            },
        });
    }

    /**
     * @param conversationId
     * @returns ConversationGetResponse Ok
     * @throws ApiError
     */
    public static getConversation(
        conversationId: string,
    ): CancelablePromise<ConversationGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/messaging/conversations/{conversationId}',
            path: {
                'conversationId': conversationId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden.`,
            },
        });
    }

    /**
     * @param conversationId
     * @param requestBody
     * @returns ConversationUpdateResponse Ok
     * @throws ApiError
     */
    public static updateConversation(
        conversationId: string,
        requestBody: Alias_ConversationUpdateBodyParams,
    ): CancelablePromise<ConversationUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/messaging/conversations/{conversationId}',
            path: {
                'conversationId': conversationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden.`,
            },
        });
    }

}
