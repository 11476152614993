import { useInfiniteQuery } from '@tanstack/react-query';
import { ApiResponse, useMemoizedArrayData } from 'src/hooks/api/api';
import {
  FunnelChannelSession,
  FunnelChannelSessionListResponse,
  CrmFunnelsChannelSessionsService,
  FunnelChannelSessionListQueryParams,
} from 'src/api/generated';

interface UseFunnelChannelSessionsParams
  extends FunnelChannelSessionListQueryParams {}

interface UseFunnelChannelSessionsResponse extends ApiResponse {
  channelSessions: FunnelChannelSession[];
  fetchNextPage: () => void;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
}

export const useFunnelChannelSessions = (
  params: UseFunnelChannelSessionsParams
): UseFunnelChannelSessionsResponse => {
  const fetchChannelSessions = async ({
    pageParam,
  }: {
    pageParam: string | undefined;
  }): Promise<FunnelChannelSessionListResponse> => {
    const { filterBy, searchBy, sortBy, page, pageSize } = params;

    return await CrmFunnelsChannelSessionsService.getFunnelChannelSessions(
      filterBy,
      searchBy,
      sortBy,
      page,
      pageSize,
      pageParam
    );
  };

  const getNextPageParam = (lastPage: FunnelChannelSessionListResponse) =>
    lastPage.nextPageToken;

  const {
    data,
    error,
    isLoading: loading,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['crm/funnel-channel-sessions', params],
    queryFn: fetchChannelSessions,
    getNextPageParam,
    initialPageParam: undefined,
  });

  const channelSessions = useMemoizedArrayData<FunnelChannelSession>(
    data?.pages.flatMap((page) => page.items) || []
  );

  return {
    loading,
    error,
    channelSessions,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  };
};
