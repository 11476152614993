import React, { useState } from 'react';
import { Conversation, ConversationStatus } from 'src/api/generated';
import { MessageList } from '../message-list';
import { useParticipants } from 'src/modules/messaging/hooks/api/participants';
import ConversationDetailsModal from '../../../molecules/conversations/conversation-details-modal';
import { useUpdateMessagingConversation } from 'src/modules/shared/messaging/hooks/api/conversations';
import { Typography } from '@material-tailwind/react';
import { getConversationStatusOptions } from 'src/modules/messaging/utils/conversations/status.util';
import LabelSelect from 'src/modules/shared/components/atoms/inputs/label-select';

interface ConversationDetailProps {
  conversation: Conversation;
  onUpdateConversation: (conversation: Conversation) => void;
}

export const ConversationDetail: React.FC<ConversationDetailProps> = ({
  conversation,
  onUpdateConversation,
}) => {
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const { participants, loading, error } = useParticipants({
    conversationId: conversation.id,
  });

  const { updateConversation, loading: updateLoading } =
    useUpdateMessagingConversation({
      onUpdateSuccess: (updatedConversation) => {
        onUpdateConversation(updatedConversation);
      },
      onUpdateError: (error) => {
        console.error('Error updating conversation:', error);
      },
    });

  const handleStatusChange = (newStatus: string) => {
    updateConversation({
      conversationId: conversation.id,
      status: newStatus as ConversationStatus,
    });
  };

  return (
    <>
      <div className="flex-none h-16 p-0 border-b items-center flex justify-between">
        <Typography
          variant="h5"
          className="flex-1 h-full px-4 cursor-pointer hover:bg-gray-50 flex items-center"
          onClick={() => setIsDetailsModalOpen(true)}
        >
          {conversation.title}
        </Typography>
        <LabelSelect
          // TODO: check when implementing i18n
          label="Estado"
          labelPosition="top"
          options={getConversationStatusOptions()}
          size="sm"
          className="pr-4 max-w-fit"
          selectProps={{
            value: conversation.status,
            onChange: (e) => handleStatusChange(e.target.value),
            loading: updateLoading,
          }}
        />
      </div>

      <MessageList conversation={conversation} participants={participants} />

      <ConversationDetailsModal
        isOpen={isDetailsModalOpen}
        onClose={() => setIsDetailsModalOpen(false)}
        conversation={conversation}
        participants={participants}
        loading={loading}
        error={error}
      />
    </>
  );
};
