import {
  ComponentVariant,
  ComponentSize,
  type BaseProps,
} from 'src/modules/shared/types/component.type';

import { PiArrowElbowLeftDownBold } from 'react-icons/pi';

const indicatorSizeClasses: Record<NonNullable<ComponentSize>, string> = {
  '2xs': 'w-3 h-3',
  xs: 'w-3.5 h-3.5',
  sm: 'w-4 h-4',
  md: 'w-4.5 h-4.5',
  lg: 'w-5 h-5',
};

interface DirectionIndicatorProps extends BaseProps {
  isOutbound: boolean;
  size?: ComponentSize;
}

export const DirectionIndicator: React.FC<DirectionIndicatorProps> = ({
  isOutbound,
  variant = 'primary',
  size = '2xs',
  className = '',
}) => {
  const variantColors: Record<ComponentVariant, string> = {
    primary: 'text-primary-500',
    secondary: 'text-secondary-500',
    danger: 'text-rose-500',
    warning: 'text-warning-500',
    neutral: 'text-gray-500',
    success: 'text-success-500',
    info: 'text-blue-500',
  };

  const colorClass = variantColors[variant];
  const sizeClass = indicatorSizeClasses[size];

  return isOutbound ? null : (
    // <div className={`${sizeClass} ${className}`} />
    <PiArrowElbowLeftDownBold
      className={`${sizeClass} ${colorClass} ${className}`}
    />
  );
};
