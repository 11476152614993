import { type FC } from 'react';
import { formatDistanceToNowStrict } from 'date-fns';
import { es } from 'date-fns/locale';
import {
  FunnelConversationDocument,
  FunnelInteractionDirection,
  FunnelInteractionType,
} from 'src/api/generated';
import { StyledIcon } from 'src/modules/shared/components/atoms/icons/styled-icon';
import { Typography } from '@material-tailwind/react';
import { DirectionIndicator } from 'src/modules/shared/components/atoms/indicators/direction-indicator';
import { CallStatusIndicator } from 'src/modules/shared/components/atoms/indicators/call-status-indicator';
import { PiChatCircle } from 'react-icons/pi';
import { usePeriodicRerender } from 'src/modules/shared/hooks/render';

interface ConversationCardInteractionProps {
  interaction?: FunnelConversationDocument['lastInteraction'];
  lastInteractionAt?: string;
  className?: string;
}

const getDirectionIcon = (
  interaction: NonNullable<FunnelConversationDocument['lastInteraction']>
) => {
  const { modelType, direction } = interaction;

  const isCall = modelType === FunnelInteractionType.CALL;
  switch (direction) {
    case FunnelInteractionDirection.INCOMING:
      return (
        <DirectionIndicator
          isOutbound={false}
          className={isCall ? '!text-call-500' : '!text-whatsapp-500'}
        />
      );
    case FunnelInteractionDirection.OUTGOING:
      return (
        <DirectionIndicator
          isOutbound={true}
          className={isCall ? '!text-call-500' : '!text-whatsapp-500'}
        />
      );
    default:
      return null;
  }
};

const getStatusIcon = (
  interaction: NonNullable<FunnelConversationDocument['lastInteraction']>
) => {
  const { modelType, status } = interaction;

  if (modelType === FunnelInteractionType.CALL) {
    return <CallStatusIndicator status={status} size="sm" />;
  }
  if (modelType === FunnelInteractionType.MESSAGE) {
    return (
      <StyledIcon
        icon={<PiChatCircle className="w-4 h-4" />}
        variant="secondary"
        size="sm"
        className="!text-whatsapp-500"
      />
    );
  }

  return null;
};

export const ConversationCardInteraction: FC<
  ConversationCardInteractionProps
> = ({ interaction, lastInteractionAt, className = '' }) => {
  usePeriodicRerender(5000);

  if (!interaction) {
    return <div className={`h-8 ${className}`} />;
  }

  const hasLongText = interaction.snippet && interaction.snippet.length > 40;

  const containerClasses = `relative transition-all duration-200 ${
    hasLongText ? 'group-hover:h-auto min-h-[2rem]' : 'h-8'
  } ${className}`;

  const snippetClasses = `
    text-xs text-secondary-600 leading-[1.2] overflow-hidden
    [display:-webkit-box] [display:box]
    [-webkit-box-orient:vertical] [box-orient:vertical]
    [-webkit-line-clamp:2]
    ${hasLongText ? 'group-hover:[-webkit-line-clamp:none]' : ''}
  `;

  const timestampClasses =
    'text-xs text-secondary-600 font-normal min-w-[1.5rem]';

  return (
    <div className={containerClasses}>
      <div className="flex h-full w-full items-center gap-2 justify-between">
        <div className="flex items-center gap-1">
          {getDirectionIcon(interaction)}
          {getStatusIcon(interaction)}
        </div>

        {interaction.snippet && (
          <div className="flex-1">
            <div className={snippetClasses}>{interaction.snippet}</div>
          </div>
        )}

        {lastInteractionAt && (
          <Typography className={timestampClasses}>
            {formatDistanceToNowStrict(new Date(lastInteractionAt), {
              addSuffix: false,
              // TODO: check when implementing i18n
              locale: es,
            })}
          </Typography>
        )}
      </div>
    </div>
  );
};
