import React, { ReactNode } from 'react';
import { Typography } from '@material-tailwind/react';
import Select from '../../atoms/inputs/select';
import Button from '../../atoms/buttons/button';
import { PiCaretRightBold } from 'react-icons/pi';
import { PaginationBar } from './pagination-bar';

export interface TokenPaginationProps {
  pageSize: number;
  totalCount?: number;
  currentCount: number;
  onPageSizeChange: (newSize: number) => void;
  onNextPage: () => void;
  hasNextPage?: boolean;
  isLoading?: boolean;
  className?: string;
  centerContent?: ReactNode;
}

const PAGE_SIZE_OPTIONS = [10, 20, 50, 100].map((size) => ({
  content: size.toString(),
  value: size.toString(),
}));

export const TokenPagination: React.FC<TokenPaginationProps> = ({
  pageSize,
  totalCount,
  currentCount,
  onPageSizeChange,
  onNextPage,
  hasNextPage,
  isLoading,
  className = '',
  centerContent,
}) => {
  const textClasses = 'font-normal text-sm text-secondary-600';

  const paginationControls = (
    <div className="flex items-center gap-4">
      <div className="flex items-center gap-2">
        <Typography className={textClasses}>
          {/* TODO: check when implementing i18n */}
          Traer
        </Typography>
        <Select
          value={pageSize.toString()}
          onChange={(e) => onPageSizeChange(Number(e.target.value))}
          options={PAGE_SIZE_OPTIONS}
          size="sm"
          className="!min-w-[60px]"
        />
        <Typography className={textClasses}>
          {/* TODO: check when implementing i18n */}
          registros
        </Typography>
      </div>

      <Button
        variant="primary"
        appearance="ghost"
        size="md"
        onClick={onNextPage}
        disabled={!hasNextPage || isLoading}
        loading={isLoading}
      >
        {/* TODO: check when implementing i18n */}
        <Typography className="font-medium text-sm">Cargar más</Typography>
        <PiCaretRightBold className="w-4 h-4 mt-[3px]" />
      </Button>
    </div>
  );

  const countDisplay = (
    <div className="flex items-center gap-1.5">
      <Typography className={textClasses}>{currentCount}</Typography>
      {totalCount !== undefined && (
        <>
          <Typography className={textClasses}>
            {/* TODO: check when implementing i18n */}
            de {totalCount} registros
          </Typography>
        </>
      )}
    </div>
  );

  return (
    <PaginationBar
      start={countDisplay}
      center={centerContent}
      end={paginationControls}
      className={className}
    />
  );
};
