import { type FC } from 'react';
import { type FunnelConversationDocument } from 'src/api/generated';
import { Card } from 'src/modules/shared/components/atoms/cards';
import { ConversationCardHeader } from './card-header';
import { ConversationCardInteraction } from './card-interaction';
import { motion } from 'framer-motion';

interface ConversationCardProps {
  conversation: FunnelConversationDocument;
  isSelected?: boolean;
  onClick?: () => void;
  className?: string;
}

export const ConversationCard: FC<ConversationCardProps> = ({
  conversation,
  isSelected,
  onClick,
  className = '',
}) => {
  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      className={`w-full max-w-full ${className}`}
    >
      <Card
        onClick={onClick}
        size="sm"
        isHighlighted={isSelected}
        className="p-4 group transition-all duration-200"
      >
        <ConversationCardHeader conversation={conversation} className="mb-2" />
        <ConversationCardInteraction
          interaction={conversation.lastInteraction}
          lastInteractionAt={conversation.lastInteractionAt}
        />
      </Card>
    </motion.div>
  );
};
