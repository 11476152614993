import TextInput from '../../../../atoms/inputs/text-input';
import {
  InlineEditableInputWrapper,
  InlineEditableInputWrapperProps,
} from '../inline-editable-input-wrapper';

interface InlineNumberInputProps
  extends Omit<InlineEditableInputWrapperProps, 'children'> {
  placeholder?: string;
  min?: number;
  max?: number;
  step?: number;
}

export const InlineNumberInput: React.FC<InlineNumberInputProps> = ({
  placeholder,
  min,
  max,
  step = 1,
  ...props
}) => {
  return (
    <InlineEditableInputWrapper {...props}>
      {({ value, onChange, disabled }) => (
        <TextInput
          className="w-full h-11 !px-3 !pr-[5rem] !py-0"
          value={value}
          onChange={(e) => {
            const newValue = e.target.value;
            if (newValue === '') {
              onChange('');
            } else {
              const numValue = parseFloat(newValue);
              if (!isNaN(numValue)) {
                onChange(newValue);
              }
            }
          }}
          placeholder={placeholder || props.label}
          size={props.size}
          autoFocus
          neumorphic="inset-sm"
          disabled={disabled}
          type="number"
          min={min}
          max={max}
          step={step}
        />
      )}
    </InlineEditableInputWrapper>
  );
};
