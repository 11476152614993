/**
 * Creates a container ID with a namespace to avoid collisions
 */
export const createContainerId = (
  namespace: string,
  id: string | number
): string => `${namespace}-container-${id}`;

/**
 * Creates an item ID with a namespace to avoid collisions
 */
export const createItemId = (namespace: string, id: string | number): string =>
  `${namespace}-item-${id}`;

/**
 * Extracts the original ID from a container ID
 */
export const extractContainerId = (
  namespace: string,
  containerId: string
): string | undefined => {
  const prefix = `${namespace}-container-`;
  return containerId.startsWith(prefix)
    ? containerId.slice(prefix.length)
    : undefined;
};

/**
 * Extracts the original ID from an item ID
 */
export const extractItemId = (
  namespace: string,
  itemId: string
): string | undefined => {
  const prefix = `${namespace}-item-`;
  return itemId.startsWith(prefix) ? itemId.slice(prefix.length) : undefined;
};

/**
 * Type-safe container ID creator for a specific namespace
 */
export const createNamespacedContainerIdFactory =
  (namespace: string) => (id: string | number) =>
    createContainerId(namespace, id);

/**
 * Type-safe item ID creator for a specific namespace
 */
export const createNamespacedItemIdFactory =
  (namespace: string) => (id: string | number) =>
    createItemId(namespace, id);
