import { type FC, type ReactNode } from 'react';
import {
  type BaseProps,
  ComponentVariant,
} from 'src/modules/shared/types/component.type';
import {
  panelBaseClasses,
  panelNeumorphicClasses,
  panelVariantClasses,
} from './styles';

interface PanelProps extends BaseProps {
  children: ReactNode;
  isActive?: boolean;
  variant?: ComponentVariant;
  isHover?: boolean;
}

export const Panel: FC<PanelProps> = ({
  children,
  neumorphic = 'none',
  variant = 'primary',
  isActive = false,
  isHover = false,
  className = '',
}) => {
  const variantClasses = panelVariantClasses[variant];
  const neumorphicClass = panelNeumorphicClasses[neumorphic];

  const backgroundClass = isActive
    ? variantClasses.active
    : neumorphic === 'flat'
      ? 'bg-white'
      : variantClasses.base;

  const hoverClass = isHover ? variantClasses.hover : '';

  const combinedClasses = [
    panelBaseClasses,
    neumorphicClass,
    backgroundClass,
    hoverClass,
    className,
  ].join(' ');

  return <div className={combinedClasses}>{children}</div>;
};
