import {
  CustomFieldEntityType,
  CustomFieldType,
  FunnelConversation,
} from 'src/api/generated';
import { CustomFieldEditor } from '../../custom-fields/custom-field-editor';
import { useUpdateFunnelConversation } from 'src/modules/communications/hooks/api/funnels/funnel-conversations-mutations';
import { FormSkeleton } from 'src/modules/shared/components/molecules/form-skeleton';
import { ExpandableSection } from 'src/modules/shared/components/molecules/expandable-section';
import { useCustomFieldDefinitions } from '../../../../hooks/api/custom-fields';
import { EmptyState } from 'src/modules/shared/components/atoms/states/empty-state';
import { ErrorState } from 'src/modules/shared/components/atoms/states/error-state';
import {
  createCustomFieldFromDefinition,
  getCustomFieldKey,
} from '../../../../utils/custom-fields.util';
import { useCustomFieldListOptions } from '../../../../hooks/api/custom-fields/list-options';
import { createQuery } from 'src/modules/shared/utils/api/query-builder.util';
import { useMemo } from 'react';

interface ConversationFieldsSectionProps {
  conversation?: FunnelConversation;
  loading?: boolean;
  readOnly?: boolean;
  error?: Error | null;
}

export const ConversationFieldsSection: React.FC<
  ConversationFieldsSectionProps
> = ({ conversation, loading = false, readOnly = false, error = null }) => {
  const { updateConversation, loading: isUpdatingConversation } =
    useUpdateFunnelConversation();

  const {
    definitions,
    loading: loadingDefinitions,
    error: definitionsError,
  } = useCustomFieldDefinitions({
    entityType: CustomFieldEntityType.FUNNEL_CONVERSATION,
    pageSize: 100,
    enabled: !!conversation,
  });

  const listDefinitionIds = definitions
    .filter((def) => def.fieldType === CustomFieldType.LIST)
    .map((def) => def.id);

  const optionsQuery = useMemo(() => {
    if (listDefinitionIds.length === 0) return null;

    return createQuery()
      .equals('definitionId', listDefinitionIds)
      .take(100)
      .build();
  }, [listDefinitionIds]);

  const { options: listOptions } = useCustomFieldListOptions({
    ...(optionsQuery || {}),
    enabled: listDefinitionIds.length > 0,
  });

  const optionsMap = useMemo(() => {
    const map: Record<number, Array<{ label: string; value: string }>> = {};

    listOptions.forEach((option) => {
      if (!map[option.definitionId]) {
        map[option.definitionId] = [];
      }

      map[option.definitionId].push({
        label: option.label || option.value,
        value: option.value,
      });
    });

    return map;
  }, [listOptions]);

  const handleUpdateConversationField = async (
    fieldId: string | number,
    value: unknown
  ) => {
    if (!conversation || readOnly) return;

    const customFields = {
      ...(conversation.customFields || {}),
      [fieldId.toString()]: value,
    };

    await updateConversation({
      id: conversation.id,
      data: {
        customFields,
      },
    });
  };

  const renderContent = () => {
    if (loading || loadingDefinitions) {
      return (
        <FormSkeleton
          fieldCount={3}
          variant="primary"
          spacing="space-y-2"
          defaultInputClassName="w-5/6"
        />
      );
    }

    if (error || definitionsError) {
      return (
        <ErrorState
          title="Error cargando los datos"
          description="No se pudo cargar la conversación. Por favor, inténtalo de nuevo."
          variant="primary"
        />
      );
    }

    if (!conversation || definitions.length === 0) {
      return (
        <EmptyState description="Selecciona una conversación para ver sus detalles" />
      );
    }

    return (
      <div className="space-y-2">
        {definitions.map((definition) => {
          const prefixedKey = getCustomFieldKey(
            definition.fieldType,
            definition.fieldKey
          );
          const value = conversation.customFields?.[prefixedKey] ?? null;

          const customField = createCustomFieldFromDefinition(
            definition.id,
            definition.fieldKey,
            definition.fieldType,
            definition.fieldLabel,
            value,
            definition.fieldType === CustomFieldType.LIST
              ? optionsMap[definition.id]
              : undefined
          );

          return (
            <CustomFieldEditor
              key={definition.fieldKey}
              field={customField}
              onSave={(value) =>
                handleUpdateConversationField(prefixedKey, value)
              }
              disabled={isUpdatingConversation || readOnly}
              loading={isUpdatingConversation}
            />
          );
        })}
      </div>
    );
  };

  return (
    // TODO: check when implementing i18n
    <ExpandableSection title="Conversación">
      {renderContent()}
    </ExpandableSection>
  );
};
