import {
  ComponentSize,
  ComponentVariant,
} from 'src/modules/shared/types/component.type';

export const checkboxSizeClasses: Record<
  ComponentSize,
  { container: string; input: string; icon: string }
> = {
  '2xs': {
    container: 'w-3 h-3',
    input: 'w-3 h-3',
    icon: 'w-3 h-3',
  },
  xs: {
    container: 'w-4 h-4',
    input: 'w-4 h-4',
    icon: 'w-4 h-4',
  },
  sm: {
    container: 'w-5 h-5',
    input: 'w-5 h-5',
    icon: 'w-5 h-5',
  },
  md: {
    container: 'w-6 h-6',
    input: 'w-6 h-6',
    icon: 'w-6 h-6',
  },
  lg: {
    container: 'w-7 h-7',
    input: 'w-7 h-7',
    icon: 'w-7 h-7',
  },
};

export const checkboxColorClasses: Record<
  ComponentVariant,
  { input: string; icon: string }
> = {
  primary: {
    input:
      'border-2 border-secondary-500 checked:border-primary-500 checked:bg-primary-500 hover:border-primary-400 [&:indeterminate]:bg-primary-500 [&:indeterminate]:border-primary-500',
    icon: 'text-white',
  },
  secondary: {
    input:
      'border-2 border-secondary-500 checked:border-secondary-500 checked:bg-secondary-500 hover:border-secondary-400 [&:indeterminate]:bg-secondary-500 [&:indeterminate]:border-secondary-500',
    icon: 'text-white',
  },
  neutral: {
    input:
      'border-2 border-secondary-500 checked:border-neutral-500 checked:bg-neutral-500 hover:border-neutral-400 [&:indeterminate]:bg-neutral-500 [&:indeterminate]:border-neutral-500',
    icon: 'text-white',
  },
  danger: {
    input:
      'border-2 border-secondary-500 checked:border-danger-500 checked:bg-danger-500 hover:border-danger-400 [&:indeterminate]:bg-danger-500 [&:indeterminate]:border-danger-500',
    icon: 'text-white',
  },
  warning: {
    input:
      'border-2 border-secondary-500 checked:border-warning-500 checked:bg-warning-500 hover:border-warning-400 [&:indeterminate]:bg-warning-500 [&:indeterminate]:border-warning-500',
    icon: 'text-white',
  },
  success: {
    input:
      'border-2 border-secondary-500 checked:border-success-500 checked:bg-success-500 hover:border-success-400 [&:indeterminate]:bg-success-500 [&:indeterminate]:border-success-500',
    icon: 'text-white',
  },
  info: {
    input:
      'border-2 border-secondary-500 checked:border-info-500 checked:bg-info-500 hover:border-info-400 [&:indeterminate]:bg-info-500 [&:indeterminate]:border-info-500',
    icon: 'text-white',
  },
};
