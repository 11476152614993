import { motion } from 'framer-motion';
import { PropsWithChildren } from 'react';

interface SlideDownAnimationProps extends PropsWithChildren {
  isVisible: boolean;
  className?: string;
}

export const SlideDownAnimation: React.FC<SlideDownAnimationProps> = ({
  isVisible,
  children,
  className = '',
}) => {
  return (
    <>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0, height: 0, y: -20 }}
          animate={{
            opacity: 1,
            height: 'auto',
            y: 0,
          }}
          exit={{
            opacity: 0,
            height: 0,
            y: -20,
          }}
          transition={{
            duration: 0.2,
            ease: 'easeInOut',
          }}
          className={`overflow-hidden ${className}`}
        >
          {children}
        </motion.div>
      )}
    </>
  );
};
