import {
  ComponentSize,
  ComponentVariant,
} from 'src/modules/shared/types/component.type';

export const tableCellText = 'font-normal text-xs';
export const tableHeaderText = 'font-medium text-sm';

export const tableCellPadding: Record<ComponentSize, string> = {
  '2xs': '!py-1 !px-2',
  xs: '!py-1.5 !px-3',
  sm: '!py-2 !px-4',
  md: '!py-3 !px-6',
  lg: '!py-4 !px-6',
};

export const tableHeaderPadding: Record<ComponentSize, string> = {
  '2xs': '!py-2.5 !px-2',
  xs: '!py-3 !px-3',
  sm: '!py-3.5 !px-4',
  md: '!py-4.5 !px-6',
  lg: '!py-6 !px-6',
};

// Variant-specific styles
export const tableVariantClasses: Record<
  ComponentVariant,
  {
    row: string;
    rowHover: string;
    rowSelected: string;
    text: string;
    hoverText: string;
  }
> = {
  primary: {
    row: 'bg-primary-50',
    rowHover: 'hover:bg-primary-100',
    rowSelected: 'bg-primary-200',
    text: 'text-secondary-700',
    hoverText: 'hover:text-primary-600',
  },
  secondary: {
    row: 'bg-secondary-50',
    rowHover: 'hover:bg-secondary-100',
    rowSelected: 'bg-secondary-200',
    text: 'text-secondary-700',
    hoverText: 'hover:text-secondary-600',
  },
  neutral: {
    row: 'bg-neutral-50',
    rowHover: 'hover:bg-neutral-100',
    rowSelected: 'bg-neutral-200',
    text: 'text-neutral-700',
    hoverText: 'hover:text-neutral-600',
  },
  danger: {
    row: 'bg-danger-50',
    rowHover: 'hover:bg-danger-100',
    rowSelected: 'bg-danger-200',
    text: 'text-danger-700',
    hoverText: 'hover:text-danger-600',
  },
  warning: {
    row: 'bg-warning-50',
    rowHover: 'hover:bg-warning-100',
    rowSelected: 'bg-warning-200',
    text: 'text-warning-700',
    hoverText: 'hover:text-warning-600',
  },
  success: {
    row: 'bg-success-50',
    rowHover: 'hover:bg-success-100',
    rowSelected: 'bg-success-200',
    text: 'text-success-700',
    hoverText: 'hover:text-success-600',
  },
  info: {
    row: 'bg-info-50',
    rowHover: 'hover:bg-info-100',
    rowSelected: 'bg-info-200',
    text: 'text-info-700',
    hoverText: 'hover:text-info-600',
  },
};

export const tableContainerClasses =
  'flex-1 overflow-y-auto rounded-lg mx-3.5 p-2.5 w-full !p-0 !m-0 h-full overflow-auto scroll-shadows';
export const tableHeaderRowBase = '!border-b-2 !border-secondary-400';
export const tableRowTransition = 'transition-colors duration-50';

export const stickyHeaderBase = 'sticky top-0 !bg-inherit';
export const stickyHeaderWithZIndex = 'sticky top-0 !bg-inherit !z-[40]';
export const stickyColumnBase = 'sticky z-[30]';
