import React from 'react';
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Typography } from '@material-tailwind/react';
import {
  ComponentSize,
  ComponentVariant,
} from 'src/modules/shared/types/component.type';
import Checkbox from 'src/modules/shared/components/atoms/checkbox';
import {
  tableHeaderPadding,
  tableCellPadding,
  tableCellText,
  tableHeaderText,
  tableVariantClasses,
  tableContainerClasses,
  tableHeaderRowBase,
  tableRowTransition,
  stickyHeaderBase,
  stickyHeaderWithZIndex,
  stickyColumnBase,
} from './styles';
import { EmptyState } from '../../atoms/states/empty-state';
import { TableRowSkeleton } from './table-row-skeleton';

export interface TableColumnProps<T> {
  header: string | JSX.Element;
  accessor: keyof T | string;
  className?: string;
  render?: (value: unknown, row: T) => React.ReactNode;
}

export interface TableProps<T> {
  columns: TableColumnProps<T>[];
  data: T[];
  keyExtractor: (item: T) => string;
  selectedIds?: string[];
  onSelectionChange?: (selectedIds: string[]) => void;
  className?: string;
  headerClassName?: string;
  emptyStateMessage?: string;
  emptyStateIcon?: React.ReactNode;
  size?: ComponentSize;
  variant?: ComponentVariant;
  rowClassName?: (row: T) => string;
  isLoading?: boolean;
  fixedFirstColumn?: boolean;
}

export const Table = <T extends object>({
  columns,
  data,
  keyExtractor,
  selectedIds = [],
  onSelectionChange,
  className = '',
  headerClassName = '',
  // TODO: check when implementing i18n
  emptyStateMessage = 'No se encontraron datos',
  emptyStateIcon,
  size = 'xs',
  variant = 'primary',
  rowClassName,
  isLoading = false,
  fixedFirstColumn = false,
}: TableProps<T>) => {
  const cellClassName = tableCellPadding[size];
  const headerCellClassName = tableHeaderPadding[size];
  const variantClasses = tableVariantClasses[variant];

  const containerClasses = `${tableContainerClasses} ${className}`;
  const headerClasses = `${variantClasses.row} ${tableHeaderRowBase} ${headerClassName}`;
  const rowBaseClasses = `${tableRowTransition} ${variantClasses.row} ${variantClasses.rowHover}`;

  const stickyHeaderClasses = fixedFirstColumn
    ? stickyHeaderWithZIndex
    : stickyHeaderBase;

  const getStickyColumnClasses = (isSelection: boolean = false) => {
    if (!fixedFirstColumn) return '';

    const stickyColumnWidth =
      onSelectionChange && !isSelection ? 'left-[40px]' : 'left-0';
    const stickyShadow = !isSelection ? 'shadow-scroll-right' : '';
    return `${stickyColumnBase} !bg-inherit ${stickyColumnWidth} ${stickyShadow}`;
  };

  const getHeaderClasses = (
    index: number,
    className?: string,
    isSelectionHeader = false
  ) => {
    const base = `!bg-inherit ${headerClasses} ${headerCellClassName} ${className || ''}`;

    if (isSelectionHeader) {
      return `${base} !min-w-0 ${stickyHeaderClasses} ${getStickyColumnClasses(true)}`;
    }

    if (index === 0 && fixedFirstColumn) {
      return `${base} ${stickyHeaderClasses} ${getStickyColumnClasses(false)}`;
    }

    return base;
  };

  const handleSelectAll = (checked: boolean) => {
    if (!onSelectionChange) return;

    if (checked) {
      const newSelectedIds = data.map(keyExtractor);
      onSelectionChange(newSelectedIds);
      return;
    }
    onSelectionChange([]);
  };

  const handleSelectOne = (id: string, checked: boolean) => {
    if (!onSelectionChange) return;

    if (checked) {
      onSelectionChange([...selectedIds, id]);
    } else {
      onSelectionChange(selectedIds.filter((selectedId) => selectedId !== id));
    }
  };

  const renderCell = (
    item: T,
    column: TableColumnProps<T>
  ): React.ReactNode => {
    if (column.render) {
      const value =
        column.accessor in item ? item[column.accessor as keyof T] : undefined;
      return column.render(value, item);
    }

    const value =
      column.accessor in item ? item[column.accessor as keyof T] : '';
    return (
      <Typography className={`${tableCellText} ${variantClasses.text}`}>
        {value?.toString() || ''}
      </Typography>
    );
  };

  return (
    <TableContainer className={containerClasses}>
      <MuiTable stickyHeader className="w-full">
        <TableHead>
          <TableRow className={headerClasses}>
            {onSelectionChange && (
              <TableCell
                padding="checkbox"
                className={getHeaderClasses(0, '', true)}
              >
                <Checkbox
                  checked={
                    data.length > 0 && selectedIds.length === data.length
                  }
                  indeterminate={
                    selectedIds.length > 0 && selectedIds.length < data.length
                  }
                  onChange={handleSelectAll}
                  size={size}
                  variant={variant}
                />
              </TableCell>
            )}
            {columns.map((column, index) => (
              <TableCell
                key={column.accessor.toString()}
                className={getHeaderClasses(index, column.className)}
              >
                {typeof column.header === 'string' ? (
                  <Typography
                    className={`${tableHeaderText} ${variantClasses.text}`}
                  >
                    {column.header}
                  </Typography>
                ) : (
                  column.header
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            Array.from({ length: 5 }).map((_, index) => (
              <TableRowSkeleton
                key={index}
                cellCount={columns.length}
                variant={variant}
                size={size}
                showSelection={!!onSelectionChange}
                className={variantClasses.row}
              />
            ))
          ) : data.length === 0 ? (
            <TableRow className={rowBaseClasses}>
              <TableCell
                colSpan={columns.length + (onSelectionChange ? 1 : 0)}
                className={`text-center p-4 pb-6 ${cellClassName}`}
              >
                <EmptyState
                  title={emptyStateMessage}
                  icon={emptyStateIcon}
                  className="w-full py-8"
                />
              </TableCell>
            </TableRow>
          ) : (
            data.map((item) => {
              const id = keyExtractor(item);
              const isSelected = selectedIds.indexOf(id) !== -1;
              const customRowClass = rowClassName?.(item) || '';

              return (
                <TableRow
                  key={id}
                  className={`${customRowClass} ${rowBaseClasses} ${
                    isSelected ? variantClasses.rowSelected : ''
                  }`}
                >
                  {onSelectionChange && (
                    <TableCell
                      padding="checkbox"
                      className={`${cellClassName} ${getStickyColumnClasses(true)}`}
                    >
                      <Checkbox
                        checked={isSelected}
                        onChange={(checked) => handleSelectOne(id, checked)}
                        size={size}
                        variant={variant}
                      />
                    </TableCell>
                  )}

                  {columns.map((column, index) => (
                    <TableCell
                      key={`${id}-${column.accessor.toString()}`}
                      className={`${cellClassName} ${column.className || ''} 
                        ${index === 0 && fixedFirstColumn ? getStickyColumnClasses(false) : ''}`}
                    >
                      {renderCell(item, column)}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })
          )}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
};
