/** Base interface for container drag data */
export interface BaseContainerDragData<T = unknown> {
  type: 'container';
  data: T;
  containerId: string;
}

/** Base interface for item drag data */
export interface BaseItemDragData<T = unknown> {
  type: 'item';
  data: T;
}

/** Union type for base drag data */
export type BaseDragData<TContainer = unknown, TItem = unknown> =
  | BaseContainerDragData<TContainer>
  | BaseItemDragData<TItem>;

/**
 * Type guard to check if data is container drag data
 * @param data - Data to check
 * @returns True if data is container drag data
 */
export const isContainerDragData = <T = unknown>(
  data: unknown
): data is BaseContainerDragData<T> => {
  return (
    typeof data === 'object' &&
    data !== null &&
    'type' in data &&
    data.type === 'container'
  );
};

/**
 * Type guard to check if data is item drag data
 * @param data - Data to check
 * @returns True if data is item drag data
 */
export const isItemDragData = <T = unknown>(
  data: unknown
): data is BaseItemDragData<T> => {
  return (
    typeof data === 'object' &&
    data !== null &&
    'type' in data &&
    data.type === 'item'
  );
};
