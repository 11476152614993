import { useState } from 'react';
import {
  List,
  ListItem,
  ListItemPrefix,
  Typography,
  Popover,
  PopoverHandler,
  PopoverContent,
} from '@material-tailwind/react';
import { Avatar } from 'src/modules/shared/components/atoms/avatar';
import { formatPhoneNumber } from 'src/modules/shared/utils/formatting/phone.util';
import { useContacts } from 'src/modules/communications/hooks/api/contacts';
import { createQuery } from 'src/modules/shared/utils/api/query-builder.util';
import { Contact, ContactAddress, ChannelType } from 'src/api/generated';
import { formatFullName } from 'src/modules/shared/utils/formatting/name.util';
import { useDebounce } from '@uidotdev/usehooks';
import { PiPhoneLight, PiUserLight } from 'react-icons/pi';
import TextInput from '../../../atoms/inputs/text-input';
import { EmptyState } from '../../../atoms/states/empty-state';
import Button from '../../../atoms/buttons/button';
import { ListItemSkeleton } from '../../../atoms/lists/list-item-skeleton';
interface ContactSelectProps {
  onSelect: (contactId: string, phoneNumber?: string) => void;
  selectedContactId: string | null;
}

export const ContactSelect: React.FC<ContactSelectProps> = ({
  onSelect,
  selectedContactId,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearch = useDebounce(searchTerm, 300);

  const query = createQuery()
    .take(5)
    .search(debouncedSearch)
    .orderByDesc('updatedAt')
    .build();

  const { contacts, loading } = useContacts({
    ...query,
    enabled: true,
  });

  const getContactPhones = (contact: Contact): ContactAddress[] => {
    const phones = contact.addresses?.filter(
      (addr: ContactAddress) => addr.channelType === ChannelType.PHONE
    );
    return phones || [];
  };

  return (
    <div className="space-y-2">
      <div className="relative">
        <TextInput
          type="text"
          // TODO: check when implementing i18n
          placeholder="Busca un contacto"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <List className="h-48 overflow-auto">
        {loading && <ListItemSkeleton />}

        {!loading && contacts.length === 0 && (
          // TODO: check when implementing i18n
          <EmptyState
            icon={<PiUserLight className="h-4 w-4 text-secondary-500" />}
            title="No hay resultados"
            description="Intenta con otra búsqueda"
          />
        )}

        {!loading &&
          contacts.map((contact, index) => {
            const phones = getContactPhones(contact);
            const name = formatFullName(contact.firstName, contact.lastName);
            return (
              <Popover
                key={`${contact.id}-${index}`}
                placement="right"
                offset={10}
                animate={{
                  mount: { scale: 1, y: 0 },
                  unmount: { scale: 0, y: 25 },
                }}
              >
                <PopoverHandler>
                  <div className="w-full">
                    <ListItem
                      selected={contact.id.toString() === selectedContactId}
                      className="hover:bg-blue-gray-50 cursor-pointer p-3"
                      onClick={() =>
                        onSelect(contact.id.toString(), phones[0]?.address)
                      }
                    >
                      <ListItemPrefix>
                        <Avatar text={name} size="sm" />
                      </ListItemPrefix>
                      <div>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-medium"
                        >
                          {name}
                        </Typography>
                        <Typography
                          variant="small"
                          color="gray"
                          className="font-normal"
                        >
                          {phones.length === 1 ? (
                            formatPhoneNumber(phones[0].address)
                          ) : (
                            <>
                              {phones.length}{' '}
                              {/* TODO: check when implementing i18n */}
                              {phones.length === 1 ? 'teléfono' : 'teléfonos'}
                            </>
                          )}
                        </Typography>
                      </div>
                    </ListItem>
                  </div>
                </PopoverHandler>
                {phones.length > 1 && (
                  <PopoverContent className="p-2 z-[999]">
                    <div className="flex flex-col space-y-2">
                      {phones.map((phone, phoneIndex) => (
                        <Button
                          key={`${phone.id}-${phoneIndex}`}
                          variant="neutral"
                          appearance="ghost"
                          size="sm"
                          className="justify-start gap-2"
                          onClick={() =>
                            onSelect(contact.id.toString(), phone.address)
                          }
                        >
                          <PiPhoneLight className="h-4 w-4" />
                          {formatPhoneNumber(phone.address)}
                        </Button>
                      ))}
                    </div>
                  </PopoverContent>
                )}
              </Popover>
            );
          })}
      </List>
    </div>
  );
};
