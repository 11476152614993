import React from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import {
  ComponentSize,
  ComponentVariant,
  ComponentAppearance,
} from 'src/modules/shared/types/component.type';
import Button from '../../../atoms/buttons/button';
import Dropdown, { DropdownItem, DropdownPlacement } from '../dropdown';
import Loading from '../../../atoms/loading';

export type { DropdownItem };

interface MenuDropdownProps {
  items: DropdownItem[];
  className?: string;
  size?: ComponentSize;
  variant?: ComponentVariant;
  appearance?: ComponentAppearance;
  icon?: React.ReactNode;
  placement?: DropdownPlacement;
  label?: string;
  handlerComponent?: React.ReactNode;
  isLoading?: boolean;
  disabled?: boolean;
}

const MenuDropdown: React.FC<MenuDropdownProps> = ({
  label,
  items,
  className = '',
  size = 'md',
  variant = 'primary',
  appearance = 'filled',
  icon,
  placement = 'bottom-end',
  handlerComponent,
  isLoading = false,
  disabled = false,
}) => {
  const defaultHandler = (
    <Button
      variant={variant}
      size={size}
      appearance={appearance}
      className={className}
      disabled={disabled}
    >
      <div className="flex items-center justify-center gap-1">
        {icon}
        <span>{label}</span>
      </div>
      {isLoading ? (
        <Loading size="2xs" variant={variant} />
      ) : (
        <ChevronDownIcon className="h-4 w-4 ml-1" />
      )}
    </Button>
  );

  return (
    <Dropdown
      items={items}
      handler={handlerComponent ?? defaultHandler}
      size={size}
      placement={placement}
      isLoading={isLoading}
      disabled={disabled}
    />
  );
};

export default MenuDropdown;
