import {
  type FunnelStatus,
  type FunnelConversationDocument,
} from 'src/api/generated';
import {
  type BaseContainerDragData,
  type BaseItemDragData,
  type BaseDragData,
  isContainerDragData,
  isItemDragData,
} from 'src/modules/shared/types/dnd.types';

/** Interface representing status data for drag and drop operations */
export interface StatusData {
  /** The funnel status object representing the column */
  status: FunnelStatus;
  /** Numeric ID of the status */
  statusId: number;
}

/** Interface representing conversation data for drag and drop operations */
export interface ConversationData {
  /** The conversation document being dragged */
  conversation: FunnelConversationDocument;
  /** The associated funnel status */
  status: FunnelStatus;
}

/** Type for status container drag data extending base container drag data */
export type StatusContainerDragData = BaseContainerDragData<StatusData>;

/** Type for conversation drag data extending base item drag data */
export type ConversationDragData = BaseItemDragData<ConversationData>;

/** Union type for all pipeline drag data types */
export type PipelineDragData = BaseDragData<StatusData, ConversationData>;

/**
 * Type guard to check if data is status container drag data
 * @param data - Data to check
 * @returns True if data is status container drag data
 */
export const isStatusContainerDragData = (
  data: unknown
): data is StatusContainerDragData => {
  return isContainerDragData<StatusData>(data);
};

/**
 * Type guard to check if data is conversation drag data
 * @param data - Data to check
 * @returns True if data is conversation drag data
 */
export const isConversationDragData = (
  data: unknown
): data is ConversationDragData => {
  return isItemDragData<ConversationData>(data);
};
