import React from 'react';
import { SiWhatsapp } from 'react-icons/si';
import { MdEmail } from 'react-icons/md';
import { Typography } from '@material-tailwind/react';

export type MessageType = 'whatsapp' | 'email';

interface MessageTypeSelectorProps {
  selectedType: MessageType;
  onTypeChange: (type: MessageType) => void;
  availableTypes?: MessageType[];
  className?: string;
}

interface MessageTypeConfig {
  icon: React.ElementType;
  label: string;
  color: string;
  selectedBg: string;
  hoverBg: string;
}

const typeConfig: Record<MessageType, MessageTypeConfig> = {
  whatsapp: {
    icon: SiWhatsapp,
    label: 'WhatsApp',
    color: 'text-whatsapp-500',
    selectedBg: 'bg-whatsapp-500',
    hoverBg: 'hover:bg-whatsapp-50',
  },
  email: {
    icon: MdEmail,
    label: 'Email',
    color: 'text-email-500',
    selectedBg: 'bg-email-500',
    hoverBg: 'hover:bg-email-50',
  },
};

// TODO: extract to a button group component
export const MessageTypeSelector: React.FC<MessageTypeSelectorProps> = ({
  selectedType,
  onTypeChange,
  availableTypes = ['whatsapp', 'email'],
  className,
}) => {
  if (availableTypes.length < 2) return null;

  return (
    <div
      className={`inline-flex h-8 w-fit rounded-lg border border-gray-200 bg-white shadow-nm-flat-xs ${
        className || ''
      }`}
    >
      {availableTypes.map((type, index) => {
        const config = typeConfig[type];
        const Icon = config.icon;
        const isSelected = type === selectedType;
        const isFirst = index === 0;
        const isLast = index === availableTypes.length - 1;

        return (
          <button
            key={type}
            type="button"
            className={`
              whitespace-nowrap inline-flex h-full items-center gap-1.5 px-2.5 text-sm font-medium transition-all
              ${!isFirst ? 'border-l border-gray-200' : ''}
              ${isFirst ? 'rounded-l-lg' : ''}
              ${isLast ? 'rounded-r-lg' : ''}
              ${
                isSelected
                  ? `${config.selectedBg} text-white`
                  : `${config.color} ${config.hoverBg}`
              }
            `}
            onClick={() => onTypeChange(type)}
          >
            <Icon className="h-3.5 w-3.5" />
            <Typography className="text-sm font-medium">
              {config.label}
            </Typography>
          </button>
        );
      })}
    </div>
  );
};
