import React, { useEffect } from 'react';
import LabelSelect from 'src/modules/shared/components/atoms/inputs/label-select';
import { WhatsAppTemplate } from 'src/api/generated';
import { PiInfoLight, PiXLight } from 'react-icons/pi';
import Button from 'src/modules/shared/components/atoms/buttons/button';

interface TemplateSelectProps {
  templates: WhatsAppTemplate[];
  selectedTemplateId?: string;
  onTemplateChange: (templateId?: string) => void;
  onShowInfo?: () => void;
  isInfoOpen?: boolean;
}

const TemplateSelect: React.FC<TemplateSelectProps> = ({
  templates,
  selectedTemplateId,
  onTemplateChange,
  onShowInfo,
  isInfoOpen,
}) => {
  useEffect(() => {
    if (templates.length > 0 && !selectedTemplateId) {
      onTemplateChange(templates[0].id);
    }
  }, [templates, onTemplateChange, selectedTemplateId]);

  return (
    <div className="relative">
      <div className="pr-10">
        <LabelSelect
          // TODO: check when implementing i18n
          label="Plantilla"
          size="md"
          options={templates.map((template) => ({
            content: template.name,
            value: template.id,
          }))}
          selectProps={{
            value: selectedTemplateId,
            onChange: (event: React.ChangeEvent<HTMLSelectElement>) => {
              onTemplateChange(event.target.value);
            },
          }}
        />
      </div>
      {selectedTemplateId && (
        <div className="absolute right-0 bottom-0">
          <Button
            variant="neutral"
            appearance="ghost"
            size="md"
            isIconOnly
            onClick={onShowInfo}
          >
            {isInfoOpen ? (
              <PiXLight className="h-5 w-5" />
            ) : (
              <PiInfoLight className="h-5 w-5" />
            )}
          </Button>
        </div>
      )}
    </div>
  );
};

export default TemplateSelect;
