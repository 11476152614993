import { useMemo } from 'react';
import { Typography } from '@material-tailwind/react';
import {
  ComponentVariant,
  type BaseProps,
} from 'src/modules/shared/types/component.type';
import { parseWhatsAppMessage } from 'src/modules/shared/utils/messages/whatsapp/format.util';

interface WhatsAppTextProps extends BaseProps {
  text: string;
}

export const WhatsAppText: React.FC<WhatsAppTextProps> = ({
  text,
  className = '',
  variant = 'primary',
}) => {
  const segments = useMemo(() => parseWhatsAppMessage(text), [text]);

  const variantClasses: Record<ComponentVariant, string> = {
    primary: 'text-primary-900',
    secondary: 'text-secondary-900',
    danger: 'text-danger-900',
    neutral: 'text-gray-900',
    warning: 'text-warning-900',
    success: 'text-success-900',
    info: 'text-blue-900',
  };

  return (
    <Typography
      className={`text-sm whitespace-pre-wrap ${variantClasses[variant]} ${className}`}
    >
      {segments.map((segment, index) => {
        const { content, styles } = segment;
        let styledElement: React.ReactNode = content;

        if (styles.includes('bold')) {
          styledElement = <span className="font-bold">{styledElement}</span>;
        }
        if (styles.includes('italic')) {
          styledElement = <span className="italic">{styledElement}</span>;
        }
        if (styles.includes('strikethrough')) {
          styledElement = <span className="line-through">{styledElement}</span>;
        }
        if (styles.includes('monospace')) {
          styledElement = (
            <code className="font-mono bg-gray-100 text-gray-800 px-1 rounded">
              {styledElement}
            </code>
          );
        }
        if (styles.includes('newline')) {
          styledElement = <br />;
        }

        return <span key={index}>{styledElement}</span>;
      })}
    </Typography>
  );
};
