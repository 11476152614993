import React, { useState } from 'react';
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Typography,
} from '@material-tailwind/react';
import { ComponentSize } from 'src/modules/shared/types/component.type';

export interface DropdownItem {
  onClick: () => void;
  separator?: boolean;
  disabled?: boolean;
  content: React.ReactNode | string;
  icon?: React.ReactNode;
  selected?: boolean;
}

export type DropdownPlacement =
  | 'top-start'
  | 'top'
  | 'top-end'
  | 'right-start'
  | 'right'
  | 'right-end'
  | 'bottom-start'
  | 'bottom'
  | 'bottom-end'
  | 'left-start'
  | 'left'
  | 'left-end';

export interface DropdownProps {
  items: DropdownItem[];
  handler: React.ReactNode;
  className?: string;
  size?: ComponentSize;
  placement?: DropdownPlacement;
  isLoading?: boolean;
  disabled?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  items,
  handler,
  className = '',
  size = 'md',
  placement = 'bottom-end',
  isLoading = false,
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const menuItemSizeClasses: Record<ComponentSize, string> = {
    lg: 'py-3 px-4 text-md',
    md: 'py-2 px-4 text-sm',
    sm: 'py-1.5 px-3 text-sm',
    xs: 'py-1 px-2 text-xs',
    '2xs': 'py-0.5 px-1.5 text-xs',
  };

  const menuItemBaseClasses = [
    'flex',
    'items-center',
    'gap-2',
    menuItemSizeClasses[size],
  ].join(' ');

  const handlerClasses = `inline-block ${disabled ? 'opacity-50 cursor-not-allowed' : ''} ${className}`;
  const menuListClasses = 'flex flex-col gap-1 p-1';

  const getMenuItemClasses = (isSelected: boolean | undefined) => {
    return `${menuItemBaseClasses} ${isSelected ? '!bg-blue-50' : ''} ${
      isLoading ? 'opacity-50' : ''
    }`;
  };

  const getStringContentClasses = (isSelected: boolean | undefined) => {
    return `text-secondary-800 ${
      isSelected ? 'font-semibold text-primary-800' : ''
    }`;
  };

  const separatorClasses = 'my-1 border-blue-gray-50';

  const handleOpen = (open: boolean) => {
    if (isLoading || disabled) return;

    setIsOpen(open);
  };

  return (
    <Menu open={isOpen} handler={handleOpen} placement={placement}>
      <MenuHandler>
        <div className={handlerClasses}>{handler}</div>
      </MenuHandler>
      <MenuList className={menuListClasses}>
        {items.map((item, index) => (
          <React.Fragment key={index}>
            <MenuItem
              onClick={() => {
                if (isLoading || disabled) return;

                setIsOpen(false);
                if (item.selected) return;

                item.onClick();
              }}
              className={getMenuItemClasses(item.selected)}
              disabled={item.disabled || isLoading || disabled}
            >
              {item.icon}
              {typeof item.content === 'string' ? (
                <Typography className={getStringContentClasses(item.selected)}>
                  {item.content}
                </Typography>
              ) : (
                item.content
              )}
            </MenuItem>
            {item.separator && index < items.length - 1 && (
              <hr className={separatorClasses} />
            )}
          </React.Fragment>
        ))}
      </MenuList>
    </Menu>
  );
};

export default Dropdown;
