import { isExpired } from '../expiration.util';

interface AttachmentWithUrl {
  url: string;
}

/**
 * Extracts expiration date from an AWS signed URL
 * @param url - The AWS signed URL to parse
 * @returns The expiration date or undefined if it cannot be determined
 */
const getExpirationFromUrl = (url: string): Date | undefined => {
  try {
    const urlObj = new URL(url);

    // AWS signed URLs include X-Amz-Date in format YYYYMMDDTHHMMSSZ
    const dateParam = urlObj.searchParams.get('X-Amz-Date');
    if (!dateParam) return undefined;

    // AWS signed URLs include X-Amz-Expires in seconds
    const expiresInSeconds = urlObj.searchParams.get('X-Amz-Expires');
    if (!expiresInSeconds) return undefined;

    const year = dateParam.slice(0, 4);
    const month = dateParam.slice(4, 6);
    const day = dateParam.slice(6, 8);
    const hour = dateParam.slice(9, 11);
    const minute = dateParam.slice(11, 13);
    const second = dateParam.slice(13, 15);

    const date = new Date(
      Date.UTC(
        parseInt(year),
        parseInt(month) - 1, // JavaScript months are 0-based
        parseInt(day),
        parseInt(hour),
        parseInt(minute),
        parseInt(second)
      )
    );

    date.setSeconds(date.getSeconds() + parseInt(expiresInSeconds));
    return date;
  } catch {
    return undefined;
  }
};

/**
 * Checks if any attachment in the array has an expired signed URL
 * @param attachments - Array of attachments with URLs to check
 * @returns true if any attachment URL is expired or expiration cannot be determined
 */
export const isAnyAttachmentExpired = (
  attachments?: AttachmentWithUrl[]
): boolean => {
  if (!attachments?.length) return false;

  return attachments.some((attachment) => {
    const expirationDate = getExpirationFromUrl(attachment.url);
    return expirationDate ? isExpired(expirationDate) : true;
  });
};
