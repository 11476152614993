import { type FC } from 'react';
import {
  type FunnelStatus,
  SearcherDocumentSearchFilter,
  SearcherDocumentSearchSort,
} from 'src/api/generated';
import { EmptyState } from 'src/modules/shared/components/atoms/states/empty-state';
import { LoadMoreButton } from 'src/modules/shared/components/atoms/buttons/load-more-button';
import { DraggableItem } from 'src/modules/shared/components/molecules/draggable-item';
import { DraggableBoardColumn } from 'src/modules/shared/components/molecules/board/column';
import { PipelineStatusColumnHeader } from './status-header';
import { ConversationCard } from '../../conversations/card';
import { ConversationCardSkeleton } from '../../conversations/card/card-skeleton';
import { useStatusColumnConversations } from '../../../../hooks/pipeline/status-column-conversations';
import { createPipelineItemId } from '../../../../utils/pipeline/dnd.utils';

interface PipelineStatusColumnProps {
  status: FunnelStatus;
  selectedFunnelId?: number;
  searchTerm?: string;
  selectedConversationId?: string;
  onConversationSelect?: (id: string) => void;
  isHighlighted?: boolean;
  className?: string;
  filters?: SearcherDocumentSearchFilter[];
  sorts?: SearcherDocumentSearchSort[];
}

export const PipelineStatusColumn: FC<PipelineStatusColumnProps> = ({
  status,
  selectedFunnelId,
  searchTerm,
  selectedConversationId,
  onConversationSelect,
  isHighlighted,
  className = '',
  filters = [],
  sorts = [],
}) => {
  const {
    setRefs,
    conversations,
    loading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    totalCount,
  } = useStatusColumnConversations({
    status,
    selectedFunnelId,
    searchTerm,
    filters,
    sorts,
  });

  return (
    <DraggableBoardColumn
      setRefs={setRefs}
      isHighlighted={isHighlighted}
      className={className}
      header={
        <PipelineStatusColumnHeader
          status={status}
          count={totalCount ?? conversations.length}
        />
      }
      items={conversations.map((c) => createPipelineItemId(c.id))}
    >
      <div className="flex flex-col h-full space-y-3 py-0.5">
        {loading ? (
          Array.from({ length: 3 }).map((_, index) => (
            <ConversationCardSkeleton key={index} />
          ))
        ) : conversations.length === 0 ? (
          <EmptyState
            // TODO: check when implementing i18n
            title="No hay conversaciones"
            description="Arrastra y suelta conversaciones aquí"
            icon={<span className="text-2xl">💬</span>}
          />
        ) : (
          conversations.map((conversation) => (
            <DraggableItem
              key={conversation.id}
              id={createPipelineItemId(conversation.id)}
              data={{
                type: 'conversation',
                conversation,
                status,
              }}
            >
              <ConversationCard
                conversation={conversation}
                isSelected={selectedConversationId === conversation.id}
                onClick={() => onConversationSelect?.(conversation.id)}
              />
            </DraggableItem>
          ))
        )}

        {hasNextPage && !loading && (
          <LoadMoreButton
            onClick={() => fetchNextPage()}
            loading={isFetchingNextPage}
          />
        )}
      </div>
    </DraggableBoardColumn>
  );
};
