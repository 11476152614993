import { useMemo } from 'react';
import {
  SearcherDocumentSearchFilterOperator,
  SettingName,
} from 'src/api/generated';
import { useUsers } from 'src/modules/shared/admin/hooks/api/users';
import {
  useFilters,
  UseFiltersResult,
} from 'src/modules/shared/hooks/filters/filters';
import { useUsersSelect } from 'src/modules/shared/hooks/users/select';
import { UNASSIGNED_USER_ID } from '../../../constants/filters.const';
import { Filter, StatusOption } from 'src/modules/shared/types/filters.types';
import { useBooleanSetting } from 'hooks/settings/settings';
import { useConversationFields } from './fields';
import { useStatusSelect } from './status-select';

export interface UseConversationFiltersProps {
  selectedFunnelId?: number;
}

export interface UseConversationFiltersResult extends UseFiltersResult {
  selectedUserIds: number[];
  setSelectedUserIds: (userIds: number[]) => void;
  userOptions: { value: number; label: string }[];
  statusOptions: StatusOption[];
  loading: boolean;
}

export function useConversationFilters({
  selectedFunnelId,
}: UseConversationFiltersProps): UseConversationFiltersResult {
  const { users, loading: isLoadingUsers } = useUsers();
  const { statusOptions, loading: isLoadingStatuses } = useStatusSelect({
    selectedFunnelId,
  });

  const {
    setting: showAllConversations,
    loading: loadingShowAllConversations,
  } = useBooleanSetting(SettingName.FEATURE_FLAG_AGENT_SHOW_ALL_CONVERSATIONS);

  const {
    selectedUserIds: userSelectIds,
    setSelectedUserIds: setUserSelectIds,
    userOptions,
    loading: isLoadingUserSelect,
  } = useUsersSelect({ users: users || [] });

  const loading =
    isLoadingUserSelect ||
    isLoadingUsers ||
    loadingShowAllConversations ||
    isLoadingStatuses;

  const { filterFieldsOptions } = useConversationFields();

  const initialFilters = useMemo(() => {
    if (userSelectIds.length === 0 || loading) return [];

    return [
      {
        id: `filter-${Date.now()}-init-${userSelectIds[0]}`,
        fieldOptionId: 'userId',
        operator: SearcherDocumentSearchFilterOperator.EQ,
        value: userSelectIds[0],
      },
    ];
  }, [userSelectIds, loading]);

  const overrideFilters = useMemo(() => {
    if (userSelectIds.length === 0 || loading || showAllConversations) {
      return [];
    }

    return userSelectIds.map((userId) => ({
      id: `filter-${Date.now()}-${userId}`,
      fieldOptionId: 'userId',
      operator: SearcherDocumentSearchFilterOperator.EQ,
      value: userId,
    }));
  }, [userSelectIds, showAllConversations, loading]);

  const { filters, apiFilters, setFilters, activeFilterCount, clearFilters } =
    useFilters({
      fieldsOptions: filterFieldsOptions,
      overrideFilters,
      initialFilters,
    });

  const selectedUserIds = useMemo(() => {
    const userFilters = filters.filter(
      (filter) => filter.fieldOptionId === 'userId'
    );
    if (!userFilters.length) return [];

    return userFilters.flatMap((filter) => {
      if (filter.operator === SearcherDocumentSearchFilterOperator.MISSING) {
        return [UNASSIGNED_USER_ID];
      }

      if (filter.value) {
        return Array.isArray(filter.value)
          ? filter.value.map(Number)
          : [Number(filter.value)];
      }

      return [];
    });
  }, [filters]);

  const setSelectedUserIds = (userIds: number[]) => {
    if (showAllConversations) {
      setUserSelectIds(userIds);
    }

    const otherFilters = filters.filter(
      (filter) => filter.fieldOptionId !== 'userId'
    );

    if (userIds.length === 0) {
      setFilters(otherFilters);
      return;
    }

    const userFilter: Filter = {
      id: `filter-${Date.now()}-${userIds[0]}`,
      fieldOptionId: 'userId',
      operator:
        userIds[0] === UNASSIGNED_USER_ID
          ? SearcherDocumentSearchFilterOperator.MISSING
          : SearcherDocumentSearchFilterOperator.IN,
      value: userIds[0] === UNASSIGNED_USER_ID ? undefined : userIds[0],
    };

    setFilters([...otherFilters, userFilter]);
  };

  return {
    filters,
    apiFilters,
    setFilters,
    fieldsOptions: filterFieldsOptions,
    activeFilterCount,
    clearFilters,
    selectedUserIds,
    setSelectedUserIds,
    userOptions,
    loading,
    statusOptions,
  };
}
