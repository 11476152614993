import {
  InlineInputWrapper,
  InlineInputWrapperProps,
} from '../inline-input-wrapper';

interface InlineDateInputProps
  extends Omit<InlineInputWrapperProps, 'children'> {
  placeholder?: string;
  min?: string;
  max?: string;
}

export const InlineDateInput: React.FC<InlineDateInputProps> = ({
  placeholder,
  min,
  max,
  ...props
}) => {
  // Format a date object or string to YYYY-MM-DD for the input value
  const formatDateForInput = (dateValue: string): string => {
    if (!dateValue) return '';

    try {
      const date = new Date(dateValue);
      // Check if valid date
      if (isNaN(date.getTime())) return '';

      return date.toISOString().split('T')[0];
    } catch (e) {
      return '';
    }
  };

  return (
    <InlineInputWrapper {...props}>
      {({ value, onChange, disabled }) => (
        <input
          type="date"
          className="w-full px-2 py-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          value={formatDateForInput(value)}
          onChange={(e) => {
            const newDate = e.target.value;
            if (newDate) {
              // Convert to ISO string for consistent storage
              const date = new Date(newDate);
              onChange(date.toISOString());
            } else {
              onChange('');
            }
          }}
          placeholder={placeholder || props.label}
          disabled={disabled}
          min={min}
          max={max}
        />
      )}
    </InlineInputWrapper>
  );
};
