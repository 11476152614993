import React from 'react';
import { Template } from 'src/api/generated';
import LabelTextInput from 'src/modules/shared/components/atoms/inputs/label-text-input';
import LabelTextArea from 'src/modules/shared/components/atoms/inputs/label-text-area';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import ModalFooter from 'src/modules/shared/components/molecules/modals/modal-footer';

const templateSchema = z.object({
  // TODO: check when implementing i18n
  title: z.string().min(1, 'Debes ingresar un título'),
  content: z.string().min(1, 'Debes ingresar contenido'),
  category: z
    .string()
    .max(20, 'La categoría no puede tener más de 20 caracteres')
    .optional(),
});

export type TemplateFormData = z.infer<typeof templateSchema>;

interface TemplateFormProps {
  template?: Template;
  onSubmit: (data: TemplateFormData) => void;
  onCancel: () => void;
  isSubmitting?: boolean;
}

const TemplateForm: React.FC<TemplateFormProps> = ({
  template,
  onSubmit,
  onCancel,
  isSubmitting = false,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<TemplateFormData>({
    resolver: zodResolver(templateSchema),
    defaultValues: {
      title: template?.title || '',
      content: template?.content || '',
      category: template?.category || '',
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 w-full">
      <div className="space-y-4">
        {/* TODO: check when implementing i18n */}
        <LabelTextInput
          label="Título"
          inputProps={{
            error: errors.title?.message,
            ...register('title'),
          }}
        />

        {/* TODO: check when implementing i18n */}
        <LabelTextArea
          label="Contenido"
          inputProps={{
            size: 'md',
            error: errors.content?.message,
            placeholder: 'Escribe el contenido de tu plantilla aquí...',
            ...register('content'),
          }}
        />

        {/* TODO: check when implementing i18n */}
        <LabelTextInput
          label="Categoría (Opcional)"
          inputProps={{
            error: errors.category?.message,
            placeholder: 'Agrupa tus plantillas...',
            ...register('category'),
          }}
        />
      </div>

      <ModalFooter
        onCancel={onCancel}
        onConfirm={handleSubmit(onSubmit)}
        // TODO: check when implementing i18n
        confirmText={template ? 'Actualizar' : 'Crear'}
        confirmDisabled={isSubmitting}
        loading={isSubmitting}
      />
    </form>
  );
};

export default TemplateForm;
