import { ComponentVariant } from 'src/modules/shared/types/component.type';

export const multiSelectVariantClasses: Record<ComponentVariant, string> = {
  primary:
    'border-neutral-300 focus:border-primary-500 focus:ring-2 focus:ring-primary-500 focus:ring-offset-2',
  secondary:
    'border-neutral-300 focus:border-secondary-600 focus:ring-2 focus:ring-secondary-500 focus:ring-offset-2',
  danger:
    'border-danger-300 focus:border-danger-500 focus:ring-2 focus:ring-danger-500 focus:ring-offset-2',
  warning:
    'border-warning-300 focus:border-warning-500 focus:ring-2 focus:ring-warning-500 focus:ring-offset-2',
  neutral:
    'border-transparent bg-neutral-100 focus:bg-white focus:border-neutral-300 focus:ring-2 focus:ring-neutral-500 focus:ring-offset-2',
  success:
    'border-success-300 focus:border-success-500 focus:ring-2 focus:ring-success-500 focus:ring-offset-2',
  info: 'border-blue-300 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-offset-2',
};

export const multiSelectContainerClasses = ['relative', 'flex-1'];

export const multiSelectInputClasses = [
  'w-full',
  'rounded-md',
  'transition-all',
  'duration-200',
  'cursor-auto',
  'text-secondary-600',
  'border',
  'outline-none',
  '[&~*>*]:!border-none',
  '[&~*>*]:!shadow-none',
  '[&~*>*]:!outline-none',
  'focus:outline-none',
];

export const multiSelectOptionClasses = [
  'py-1.5',
  'px-3',
  'text-xs',
  'hover:bg-neutral-100',
  'cursor-pointer',
  'transition-colors',
  'duration-200',
  'whitespace-nowrap',
  '[&>*]:flex',
  '[&>*]:gap-1',
  '[&>*]:items-center',
];

export const multiSelectOptionSelectedClasses = [
  'bg-primary-50',
  'text-primary-600',
];

export const multiSelectArrowClasses = [
  'w-3',
  'h-3',
  'text-secondary-600',
  'pointer-events-none',
  'absolute',
  'top-1/2',
  '-translate-y-1/2',
  'right-2',
];

export const multiSelectOptionsWrapperClasses = [
  'mt-1',
  'bg-white',
  'rounded-md',
  'shadow-md',
  'border',
  'border-neutral-200',
  'w-fit',
  'max-h-60',
  'overflow-auto',
];

export const multiSelectDropdownClasses = ['rounded-md'];
