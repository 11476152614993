// src/modules/messaging/whatsapp/hooks/api/messages.ts

import {
  useMutation,
  QueryClient,
  useQueryClient,
} from '@tanstack/react-query';
import {
  Message,
  MessagingWhatsappMessagesService,
  Alias_WhatsAppTemplateMessageCreateBodyParams as WhatsAppTemplateMessageCreateParams,
  Alias_WhatsAppMessageCreateBodyParams as WhatsAppMessageCreateBodyParams,
  MessageDirection,
  MessageStatus,
  ConversationChannel,
  ParticipantRole,
} from 'src/api/generated';

interface UseWhatsAppTemplateMessageCreateResponse {
  loading: boolean;
  error: Error | null;
  message?: Message;
  createWhatsAppTemplateMessage: (
    params: WhatsAppTemplateMessageCreateParams
  ) => void;
}

export const useCreateWhatsAppTemplateMessage = (
  onSuccess: (data: Message) => void = () => {},
  onError: (error: Error) => void = () => {}
): UseWhatsAppTemplateMessageCreateResponse => {
  const createWhatsAppTemplateMessage = async (
    params: WhatsAppTemplateMessageCreateParams
  ): Promise<Message> => {
    const { tenantId: _, ...messageParams } = params;

    return await MessagingWhatsappMessagesService.createTemplateMessage(
      messageParams
    );
  };

  const mutation = useMutation({
    mutationFn: createWhatsAppTemplateMessage,
    onSuccess: (data: Message) => onSuccess(data),
    onError: (error: Error) => onError(error),
  });

  return {
    loading: mutation.isPending,
    error: mutation.error,
    message: mutation.data,
    createWhatsAppTemplateMessage: mutation.mutate,
  };
};

export interface UseWhatsAppMessageCreateParams
  extends WhatsAppMessageCreateBodyParams {
  conversationId: string;
}

// TODO: use a generic type for the context
interface WhatsAppMessageContext {
  queryClient: QueryClient;
  params: UseWhatsAppMessageCreateParams;
  optimisticMessage?: Message;
}

interface UseWhatsAppMessageCreateResponse {
  loading: boolean;
  error: Error | null;
  message?: Message;
  createWhatsAppMessage: (params: UseWhatsAppMessageCreateParams) => void;
}

const createOptimisticMessage = (
  params: UseWhatsAppMessageCreateParams
): Message => {
  return {
    id: `temp-${Date.now()}`,
    tenantId: params.tenantId ?? '',
    providerId: '',
    direction: MessageDirection.OUTBOUND,
    status: MessageStatus.PENDING,
    metadata: {},
    conversationId: params.conversationId,
    content: params.content,
    channel: ConversationChannel.WHATSAPP,
    sender: {
      providerId: params.fromPhoneNumberId,
      address: params.fromPhoneNumberId,
      role: ParticipantRole.USER,
      displayName: params.fromPhoneNumberId,
    },
    recipient: {
      providerId: params.toPhoneNumber,
      address: params.toPhoneNumber,
      role: ParticipantRole.CONTACT,
      displayName: params.toPhoneNumber,
    },
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  };
};

export const useCreateWhatsAppMessage = (
  onSuccess: (
    message: Message,
    context: WhatsAppMessageContext
  ) => void = () => {},
  onError: (error: Error, context: WhatsAppMessageContext) => void = () => {},
  onUpdate?: (message: Message, context: WhatsAppMessageContext) => void
): UseWhatsAppMessageCreateResponse => {
  const createWhatsAppMessage = async ({
    conversationId,
    ...params
  }: UseWhatsAppMessageCreateParams): Promise<Message> => {
    const { tenantId: _, ...messageParams } = params;

    return MessagingWhatsappMessagesService.createMessage(
      conversationId,
      messageParams
    );
  };

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: createWhatsAppMessage,
    onMutate: async (params) => {
      await queryClient.cancelQueries({
        queryKey: ['crm/timeline-items/search'],
      });

      const optimisticMessage = createOptimisticMessage(params);

      if (onUpdate) {
        onUpdate(optimisticMessage, { queryClient, params, optimisticMessage });
      }

      return { optimisticMessage };
    },
    onSuccess: (message, params, context) => {
      onSuccess(message, {
        queryClient,
        params,
        optimisticMessage: context?.optimisticMessage,
      });
    },
    onError: (error, params, context) => {
      onError(error, {
        queryClient,
        params,
        optimisticMessage: context?.optimisticMessage,
      });
    },
  });

  return {
    loading: mutation.isPending,
    error: mutation.error,
    message: mutation.data,
    createWhatsAppMessage: mutation.mutate,
  };
};
