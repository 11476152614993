import {
  Filter,
  FilterFieldOptions,
  FilterValue,
  FilterOption,
} from '../../types/filters.types';
import { SearcherDocumentSearchFilterOperator } from 'src/api/generated';
import { getDefaultValueForFieldType } from './values.util';
import { isArrayOperator, isNoValueOperator } from './operators.util';

/**
 * Validates and normalizes a filter value based on the operator type
 * @returns The normalized value if valid, null if invalid
 */
const validateAndNormalizeValue = (
  value: FilterValue,
  operator: SearcherDocumentSearchFilterOperator,
  fieldOption?: FilterFieldOptions,
  getOptionsForField?: (fieldOption?: FilterFieldOptions) => FilterOption[]
): FilterValue | null => {
  if (isNoValueOperator(operator)) {
    return undefined;
  }

  const isEmptyValue =
    value === undefined ||
    value === '' ||
    (Array.isArray(value) && value.length === 0);
  if (isEmptyValue) {
    if (!fieldOption || !getOptionsForField) {
      return null;
    }

    return getDefaultValueForFieldType(
      fieldOption.type,
      getOptionsForField(fieldOption)
    );
  }

  const isArrayOp = isArrayOperator(operator);
  const isValueArray = Array.isArray(value);

  if (isArrayOp && !isValueArray) {
    return [String(value)];
  }
  if (!isArrayOp && isValueArray) {
    return value.length > 0 ? value[0] : null;
  }

  return value;
};

/**
 * Updates a filter's operator and normalizes its value accordingly
 */
export const updateFilterOperator = (
  filter: Filter,
  operator: SearcherDocumentSearchFilterOperator,
  getOptionsForField: (fieldOption?: FilterFieldOptions) => FilterOption[],
  fieldOption?: FilterFieldOptions
): Filter => {
  if (!fieldOption) return filter;

  if (isNoValueOperator(operator)) {
    return {
      ...filter,
      operator,
      value: undefined,
    };
  }

  const normalizedValue = validateAndNormalizeValue(
    filter.value,
    operator,
    fieldOption,
    getOptionsForField
  );

  const defaultValue = getDefaultValueForFieldType(
    fieldOption.type,
    getOptionsForField(fieldOption)
  );

  return {
    ...filter,
    operator,
    value: normalizedValue ?? defaultValue,
  };
};

/**
 * Updates a filter's value and normalizes it according to the operator
 */
export const updateFilterValue = (
  filter: Filter,
  value: FilterValue
): Filter | null => {
  const normalizedValue = validateAndNormalizeValue(value, filter.operator);
  if (normalizedValue === null) {
    return filter;
  }

  return {
    ...filter,
    value: normalizedValue,
  };
};
