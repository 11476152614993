import { PiCaretDownLight } from 'react-icons/pi';
import MenuDropdown, { DropdownItem } from '../menu-dropdown';
import Tag from '../../../atoms/tag';
import {
  ComponentSize,
  ComponentVariant,
  ComponentAppearance,
} from 'src/modules/shared/types/component.type';
import { useCallback, useMemo } from 'react';
import Loading from '../../../atoms/loading';

export type TagDropdownOption = Omit<DropdownItem, 'onClick'> & {
  id: string | number;
};

interface TagDropdownProps {
  content: string | React.ReactNode;
  variant?: ComponentVariant;
  size?: ComponentSize;
  appearance?: ComponentAppearance;
  isLoading?: boolean;
  disabled?: boolean;
  options?: TagDropdownOption[];
  onOptionSelect?: (option: TagDropdownOption) => void;
  className?: string;
}

export const TagDropdown: React.FC<TagDropdownProps> = ({
  content,
  variant = 'primary',
  size = 'md',
  appearance = 'filled',
  isLoading,
  disabled = false,
  options = [],
  onOptionSelect,
  className,
}) => {
  const handleSelect = useCallback(
    (option: TagDropdownOption) => {
      onOptionSelect?.(option);
    },
    [onOptionSelect]
  );

  const dropdownOptions = useMemo(() => {
    return options.map((option) => ({
      ...option,
      onClick: () => handleSelect(option),
    }));
  }, [options, handleSelect]);

  const renderHandler = () => (
    <Tag
      content={
        <div className="flex items-center gap-1">
          {content}
          {isLoading ? (
            <Loading size="2xs" variant={variant} />
          ) : (
            <PiCaretDownLight className="h-4 w-4 pt-0.5" />
          )}
        </div>
      }
      variant={variant}
      size={size}
      appearance={appearance}
      className={`${className} ${isLoading ? 'opacity-50' : ''} ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
    />
  );

  return (
    <MenuDropdown
      isLoading={isLoading}
      disabled={disabled}
      placement="bottom-start"
      handlerComponent={renderHandler()}
      size={size}
      items={dropdownOptions}
    />
  );
};
