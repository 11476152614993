import {
  CallDirection,
  CallDocument,
  CallStatus,
  Contact,
} from 'src/api/generated';
import { motion } from 'framer-motion';
import { format } from 'date-fns';
import { CircleDisplay } from 'src/modules/shared/components/atoms/circle-display';
import { Typography } from '@material-tailwind/react';
import ButtonModal from 'src/modules/shared/components/molecules/modals/button-modal';
import FeedbackModalContent from 'components/molecules/analysis/feedback-modal-content';
import SummaryModalContent from 'components/molecules/analysis/summary-modal-content';
import TranscriptionModalContent from 'components/molecules/analysis/transcription-modal-content';
import { Expandable } from 'src/modules/shared/components/molecules/expandable';
import { callButtonVariantOverrides } from 'src/modules/shared/styles/call.styles';
import {
  PiChartLineLight,
  PiFileTextLight,
  PiGraduationCapLight,
} from 'react-icons/pi';
import { formatFullName } from 'src/modules/shared/utils/formatting/name.util';
import { formatPhoneNumber } from 'src/modules/shared/utils/formatting/phone.util';
import { IoCall } from 'react-icons/io5';
import { CallStatusIndicator } from 'src/modules/shared/components/atoms/indicators/call-status-indicator';
import {
  useUsers,
  useCurrentUser,
} from 'src/modules/shared/admin/hooks/api/users';
import { TextSkeleton } from 'src/modules/shared/components/atoms/text/text-skeleton';

interface TimelineCallItemProps {
  item: CallDocument;
  groupedItems?: CallDocument[];
  isNew?: boolean;
  contact?: Contact;
}

export const TimelineCallItem: React.FC<TimelineCallItemProps> = ({
  item,
  groupedItems = [],
  isNew = false,
  contact,
}) => {
  const { users, loading: usersLoading } = useUsers();
  const { user: currentUser, loading: currentUserLoading } = useCurrentUser();

  const isOutbound = item.direction === CallDirection.OUTGOING;
  const isCompleted = item.status === CallStatus.COMPLETED;

  const getCallItemName = (call: CallDocument): string => {
    if (!isOutbound) {
      return formatFullName(
        contact?.firstName,
        contact?.lastName,
        formatPhoneNumber(call.to)
      );
    }

    const currentUserId = currentUser?.id ? currentUser.id : null;
    if (currentUserId && call.userId === String(currentUserId)) {
      // TODO: check when implementing i18n
      return 'Tú';
    }

    const callUser = users?.find((user) => String(user.id) === call.userId);
    return callUser
      ? formatFullName(callUser.firstName, callUser.lastName)
      : // TODO: check when implementing i18n
        'Usuario';
  };

  const renderCallItem = (call: CallDocument, isMainItem: boolean = false) => (
    <div className="flex items-start gap-4 p-4">
      <CircleDisplay
        icon={<IoCall />}
        variant="secondary"
        size="sm"
        className={`flex-shrink-0 !text-call-500 ${
          isOutbound ? '!bg-white' : '!bg-call-50'
        }`}
      />

      <div className="flex-1 flex flex-col gap-1 min-w-0">
        <div className="flex flex-wrap items-center justify-between gap-4 mb-2">
          <h4 className="text-sm font-semibold text-primary-800 break-all">
            {usersLoading || currentUserLoading ? (
              <TextSkeleton className="w-36 h-5" />
            ) : (
              getCallItemName(call)
            )}
          </h4>
          <Typography className="text-xs font-normal text-secondary-600">
            {format(new Date(call.createdAt), 'HH:mm')}
          </Typography>
        </div>

        {isCompleted && (
          <div className="flex flex-wrap gap-2 gap-y-1.5 mb-2">
            <ButtonModal
              buttonContent={
                <>
                  <PiFileTextLight className="w-3 h-3" />
                  {/* TODO: check when implementing i18n */}
                  <span>Transcripción</span>
                </>
              }
              // TODO: check when implementing i18n
              title="Transcripción"
              size="2xs"
              variant="secondary"
              className={callButtonVariantOverrides.secondary}
            >
              {(enabled: boolean) => (
                <TranscriptionModalContent
                  callId={parseInt(call.id)}
                  enabled={enabled}
                />
              )}
            </ButtonModal>
            <ButtonModal
              buttonContent={
                <>
                  <PiChartLineLight className="w-3 h-3" />
                  <span>Resumen</span>
                </>
              }
              title="Resumen"
              size="2xs"
              variant="secondary"
              className={callButtonVariantOverrides.secondary}
              bodyClassName={'!p-0'}
            >
              {(enabled: boolean) => (
                <SummaryModalContent
                  callId={parseInt(call.id)}
                  enabled={enabled}
                />
              )}
            </ButtonModal>
            <ButtonModal
              buttonContent={
                <>
                  <PiGraduationCapLight className="w-3 h-3" />
                  <span>Feedback</span>
                </>
              }
              title="Feedback"
              bodyClassName={'!p-0'}
              size="2xs"
              variant="secondary"
              className={callButtonVariantOverrides.secondary}
            >
              {(enabled: boolean) => (
                <FeedbackModalContent
                  callId={parseInt(call.id)}
                  enabled={enabled}
                />
              )}
            </ButtonModal>
          </div>
        )}

        <div className="flex flex-wrap items-center justify-end gap-4 gap-y-2">
          <div className="flex flex-wrap items-center gap-1.5 text-xs text-secondary-600">
            <CallStatusIndicator
              status={call.status}
              duration={call.duration || undefined}
              size="sm"
            />
          </div>
          {isMainItem && groupedItems && groupedItems.length > 1 && (
            <Typography className="text-xs font-normal text-secondary-600">
              ({groupedItems.length})
            </Typography>
          )}
        </div>
      </div>
    </div>
  );

  const expandedContent = groupedItems.slice(1).map((call) => (
    <div key={call.id} className="border-t border-gray-100">
      {renderCallItem(call)}
    </div>
  ));

  return (
    <motion.div
      initial={isNew ? { opacity: 0, y: 20 } : false}
      animate={{ opacity: 1, y: 0 }}
      className={`flex ${isOutbound ? 'justify-end' : 'justify-start'}`}
    >
      <div
        className={`w-fit min-w-[200px] max-w-[85%] overflow-hidden ${
          isOutbound ? 'bg-blue-50' : 'bg-white'
        } rounded-lg shadow-nm-flat-2xs`}
      >
        {groupedItems.length > 1 ? (
          <Expandable expandedContent={expandedContent}>
            {renderCallItem(item, true)}
          </Expandable>
        ) : (
          renderCallItem(item, true)
        )}
      </div>
    </motion.div>
  );
};
