import { useMemo } from 'react';
import { useFunnelStatuses } from '../../../../hooks/api';
import { createQuery } from 'src/modules/shared/utils/api/query-builder.util';
import { PipelineBoard } from './board';
import { PipelineBoardSkeleton } from './board/pipeline-board-skeleton';
import {
  SearcherDocumentSearchFilter,
  SearcherDocumentSearchSort,
} from 'src/api/generated';

interface ConversationPipelineViewProps {
  selectedFunnelId?: number;
  searchTerm?: string;
  onConversationSelect?: (id: number) => void;
  selectedConversationId?: number;
  filters: SearcherDocumentSearchFilter[];
  sorts: SearcherDocumentSearchSort[];
}

export const ConversationPipelineView: React.FC<
  ConversationPipelineViewProps
> = ({
  selectedFunnelId,
  searchTerm,
  onConversationSelect,
  selectedConversationId,
  filters,
  sorts,
}) => {
  const statusQuery = useMemo(() => {
    if (!selectedFunnelId) return {};

    return createQuery()
      .equals('funnelId', selectedFunnelId)
      .orderByAsc('position')
      .build();
  }, [selectedFunnelId]);

  const { statuses, loading: loadingStatuses } = useFunnelStatuses(
    statusQuery,
    !!selectedFunnelId
  );

  if (!statuses || loadingStatuses) return <PipelineBoardSkeleton />;

  return (
    <PipelineBoard
      statuses={statuses}
      selectedFunnelId={selectedFunnelId}
      searchTerm={searchTerm}
      onConversationSelect={(id) => onConversationSelect?.(Number(id))}
      selectedConversationId={selectedConversationId?.toString()}
      filters={filters}
      sorts={sorts}
    />
  );
};
