import { MdCallEnd } from 'react-icons/md';
import Button from '../../../buttons/button';
import { PhoneControlButtonProps } from '../types';

export const EndCallButton: React.FC<PhoneControlButtonProps> = ({
  onClick,
  disabled,
  size = 'sm',
}) => {
  return (
    <Button
      variant="neutral"
      appearance="ghost"
      size={size}
      onClick={onClick}
      disabled={disabled}
      className="rounded-full w-8 h-8 p-0 flex items-center justify-center bg-danger-50 hover:bg-danger-100 text-danger-500"
    >
      <MdCallEnd className="w-4 h-4" />
    </Button>
  );
};
