import { useState } from 'react';
import SidebarButton from 'components/atoms/buttons/sidebar-button';
import {
  PiChartBarLight,
  PiPhoneLight,
  PiChatCircleLight,
  PiBookOpenTextLight,
  PiUsersLight,
  PiStarLight,
  PiGearSixLight,
  PiChatsCircleLight,
  PiPushPinLight,
  PiPushPinSlashLight,
  PiSignOutLight,
  PiHeadphonesLight,
} from 'react-icons/pi';
import { SettingName } from 'src/api/generated';
import { Route as ReportsIndexRoute } from 'src/routes/reports.index';
import { Route as CallsIndexRoute } from 'src/routes/calls.index';
import { Route as ConversationsIndexRoute } from 'src/routes/conversations.index';
import { Route as ContactsIndexRoute } from 'src/routes/contacts.index';
import { Route as FeaturedCallsIndexRoute } from 'src/routes/featured-calls.index';
import { Route as CommunicationsIndexRoute } from 'src/routes/communications/index';
import { Route as UsersIndexRoute } from 'src/routes/users.index';
import { Route as SettingsIndexRoute } from 'src/routes/settings.index';
import { useAuth0 } from '@auth0/auth0-react';
import { useFeatureFlagActive } from 'hooks/settings/feature-flags';
import { useBooleanSetting } from 'hooks/settings/settings';
import { useCurrentUser } from 'src/modules/shared/admin/hooks/api/users';
import Loading from 'components/atoms/loading/loading';
import Button from 'src/modules/shared/components/atoms/buttons/button';
import { formatFullName } from 'src/modules/shared/utils/formatting/name.util';

const Sidebar = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isHoverExpanded, setIsHoverExpanded] = useState(false);

  const { logout } = useAuth0();
  const { user, loading: userLoading } = useCurrentUser();

  const crmActive = useFeatureFlagActive(SettingName.FEATURE_FLAG_CRM);
  const conversationsActive = useFeatureFlagActive(
    SettingName.FEATURE_FLAG_CONVERSATIONS
  );
  const { setting: showReports, loading } = useBooleanSetting(
    SettingName.SHOW_REPORTS
  );

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const handleSupport = () => {
    const userDetected = user && !userLoading;

    // TODO: check when implementing i18n
    const message = userDetected
      ? `Hola, soy ${formatFullName(user.firstName, user.lastName)}`.trim() +
        ` (ID: ${user.id}). Necesito ayuda con la plataforma`
      : 'Hola, necesito ayuda con la plataforma';
    const encodedMessage = encodeURIComponent(message);
    window.open(`https://wa.me/56948897378?text=${encodedMessage}`, '_blank');
  };

  const handleToggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const containerClasses = `
    flex flex-col bg-primary-600 h-full min-h-screen py-4 px-1
    text-white flex flex-col justify-between
    transition-[width] duration-300 ease-in-out overflow-hidden
    ${!isExpanded && !isHoverExpanded ? 'w-[64px]' : 'w-[220px]'}
  `;

  const buttonClasses = `
    border-white border rounded-full !p-1.5
    hover:!bg-primary-500 active:!bg-primary-500
    focus:!ring-0 focus:!ring-offset-0 active:!ring-0 active:!ring-offset-0
    transition-transform duration-300 ease-in-out
    ${isExpanded ? 'rotate-0 bg-primary-500' : '-rotate-90'}
  `;

  return (
    <div
      className={containerClasses}
      onMouseEnter={() => !isExpanded && setIsHoverExpanded(true)}
      onMouseLeave={() => !isExpanded && setIsHoverExpanded(false)}
    >
      <div>
        <div className="flex items-center justify-end mx-3 my-[17px]">
          <Button
            onClick={handleToggleExpanded}
            // TODO: check when implementing i18n
            title={isExpanded ? 'Fijar barra' : 'Desfijar barra'}
            className={buttonClasses}
            appearance="ghost"
            variant="neutral"
            size="sm"
            isIconOnly
          >
            {isExpanded ? (
              <PiPushPinSlashLight className="h-4 w-4 text-white" />
            ) : (
              <PiPushPinLight className="h-4 w-4 text-white" />
            )}
          </Button>
        </div>
        {loading ? (
          <Loading className="py-6 w-[153px]" />
        ) : (
          <nav className="space-y-4">
            {/* TODO: check when implementing i18n */}
            {showReports && (
              <SidebarButton
                to={ReportsIndexRoute.to}
                icon={<PiChartBarLight className="h-6 w-6" />}
                label="Reportes"
                isCollapsed={!isExpanded && !isHoverExpanded}
              />
            )}
            <SidebarButton
              to={CommunicationsIndexRoute.to}
              icon={<PiChatsCircleLight className="h-6 w-6" />}
              label="Comunicaciones"
              hidden={!crmActive}
              isCollapsed={!isExpanded && !isHoverExpanded}
            />
            <SidebarButton
              to={CallsIndexRoute.to}
              icon={<PiPhoneLight className="h-6 w-6" />}
              label="Llamadas"
              isCollapsed={!isExpanded && !isHoverExpanded}
            />
            <SidebarButton
              to={ConversationsIndexRoute.to}
              icon={<PiChatCircleLight className="h-6 w-6" />}
              label="Conversaciones"
              hidden={!conversationsActive}
              isCollapsed={!isExpanded && !isHoverExpanded}
            />
            <SidebarButton
              to={ContactsIndexRoute.to}
              icon={<PiBookOpenTextLight className="h-6 w-6" />}
              label="Contactos"
              isCollapsed={!isExpanded && !isHoverExpanded}
            />
            <SidebarButton
              to={UsersIndexRoute.to}
              icon={<PiUsersLight className="h-6 w-6" />}
              label="Usuarios"
              isCollapsed={!isExpanded && !isHoverExpanded}
            />
            <SidebarButton
              to={FeaturedCallsIndexRoute.to}
              icon={<PiStarLight className="h-6 w-6" />}
              label="Destacadas"
              isCollapsed={!isExpanded && !isHoverExpanded}
            />
            <SidebarButton
              to={SettingsIndexRoute.to}
              icon={<PiGearSixLight className="h-6 w-6" />}
              label="Ajustes"
              hidden={!conversationsActive}
              isCollapsed={!isExpanded && !isHoverExpanded}
            />
          </nav>
        )}
      </div>
      <div className="my-4 space-y-4">
        <SidebarButton
          icon={<PiHeadphonesLight className="h-6 w-6" />}
          label="Ayuda"
          onClick={handleSupport}
          isCollapsed={!isExpanded && !isHoverExpanded}
        />
        <SidebarButton
          icon={<PiSignOutLight className="h-6 w-6" />}
          label="Salir"
          onClick={handleLogout}
          isCollapsed={!isExpanded && !isHoverExpanded}
        />
      </div>
    </div>
  );
};

export default Sidebar;
