/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Contact } from './Contact';
import type { Dictionary_unknown_ } from './Dictionary_unknown_';
import type { FunnelChannelSession } from './FunnelChannelSession';
import type { FunnelInteractionDirection } from './FunnelInteractionDirection';
import type { FunnelInteractionStatus } from './FunnelInteractionStatus';
import type { FunnelInteractionType } from './FunnelInteractionType';

/**
 * Interface representing a funnel conversation document
 */
export type FunnelConversationDocument = {
    tenantId: string;
    createdAt: string;
    updatedAt: string;
    /**
     * Reference to the user involved in the conversation
     */
    userId?: number | null;
    /**
     * Optional array of channel sessions associated with this conversation
     */
    channelSessions?: Array<FunnelChannelSession>;
    /**
     * Optional array of contacts involved in this conversation
     */
    contacts?: Array<Contact>;
    /**
     * Optional custom fields for the conversation
     */
    customFields?: Dictionary_unknown_;
    id: string;
    modelType: FunnelConversationDocument.modelType;
    archivedAt?: string | null;
    eventSequence?: number;
    eventTimestamp?: string;
    contactId?: string | null;
    funnelId?: string | null;
    funnelStatusId?: string | null;
    lastInteractionAt?: string;
    lastInteraction?: {
        direction?: FunnelInteractionDirection;
        status?: FunnelInteractionStatus;
        snippet?: string;
        modelType: FunnelInteractionType;
        id: string;
    };
};

export namespace FunnelConversationDocument {

    export enum modelType {
        CONVERSATION = 'Conversation',
    }


}

