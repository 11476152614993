import { useState } from 'react';
import { ContactAddress, ChannelType } from 'src/api/generated';
import Button from 'src/modules/shared/components/atoms/buttons/button';
import { PiPlusLight } from 'react-icons/pi';
import { useAlert } from 'src/contexts/alert-context';
import {
  useCreateContactAddressMutation,
  useUpdateContactAddressMutation,
  useDeleteContactAddressMutation,
} from 'src/modules/communications/hooks/api/contact-addresses.mutation';
import { EditableAddressField } from 'src/modules/communications/components/molecules/contacts/editable-address-field';
import { SlideDownAnimation } from 'src/modules/shared/components/atoms/animations/slide-down';
import { ExpandableSection } from 'src/modules/shared/components/molecules/expandable-section';
import { FormSkeleton } from 'src/modules/shared/components/molecules/form-skeleton';
import { EmptyState } from 'src/modules/shared/components/atoms/states/empty-state';
import { ErrorState } from 'src/modules/shared/components/atoms/states/error-state';
import { useGlobalCallProviderContext } from 'src/contexts/global-call-provider-context';
import { usePhoneContext } from 'src/contexts/phone-context';
import { useGlobalPhoneContext } from 'src/contexts/global-phone-context';
import { isValidPhoneNumber } from 'src/utils/validation/phone';
import {
  getActionTooltip,
  getQuickPhoneState,
  isActionDisabled,
} from 'src/modules/communications/utils/quick-phone.util';

interface ContactAddressesSectionProps {
  addresses: ContactAddress[];
  loading?: boolean;
  contactId: number;
  error?: Error | null;
}

export const ContactAddressesSection: React.FC<
  ContactAddressesSectionProps
> = ({ addresses, loading = false, contactId, error = null }) => {
  const { showAlert } = useAlert();
  const [isAddingAddress, setIsAddingAddress] = useState(false);
  const [newAddress, setNewAddress] = useState({
    channelType: ChannelType.PHONE,
    address: '',
  });
  const [processingAddressId, setProcessingAddressId] = useState<number | null>(
    null
  );

  const callProvider = useGlobalCallProviderContext();
  const { phoneState } = usePhoneContext();
  const { setGlobalPhoneVisible } = useGlobalPhoneContext();

  const quickPhoneState = getQuickPhoneState(callProvider, phoneState);

  const handleCall = (phoneNumber: string) => {
    if (callProvider.loading || !quickPhoneState.isReady) return;

    setGlobalPhoneVisible(true);
    callProvider.callActions.startCall({ phoneNumber });
  };

  const { createAddress, loading: creating } = useCreateContactAddressMutation(
    () => {
      setIsAddingAddress(false);
      setNewAddress({ channelType: ChannelType.PHONE, address: '' });
    },
    (error) => {
      console.error('Failed to add address:', error);
      showAlert(
        'Hubo un error al agregar la dirección. Por favor, inténtalo de nuevo.',
        'error'
      );
    }
  );

  const { updateAddress, loading: updating } = useUpdateContactAddressMutation(
    () => {
      setProcessingAddressId(null);
    },
    (error) => {
      console.error('Failed to update address:', error);
      showAlert(
        'Hubo un error al actualizar la dirección. Por favor, inténtalo de nuevo.',
        'error'
      );
      setProcessingAddressId(null);
    }
  );

  const { deleteAddress, loading: deleting } = useDeleteContactAddressMutation(
    () => {
      setProcessingAddressId(null);
    },
    (error) => {
      console.error('Failed to delete address:', error);
      showAlert(
        'Hubo un error al eliminar la dirección. Por favor, inténtalo de nuevo.',
        'error'
      );
      setProcessingAddressId(null);
    }
  );

  const handleUpdateAddress = async ({
    id,
    data,
  }: {
    id: number;
    data: Partial<ContactAddress>;
  }) => {
    setProcessingAddressId(id);
    await updateAddress({ id, data });
  };

  const handleDeleteAddress = async (addressId: number) => {
    setProcessingAddressId(addressId);
    await deleteAddress(addressId);
  };

  const renderAddButton = () => (
    <Button
      variant="neutral"
      appearance="ghost"
      size="sm"
      onClick={() => setIsAddingAddress(true)}
      className="flex items-center gap-1"
    >
      <PiPlusLight className="w-4 h-4" />
    </Button>
  );

  const renderContent = () => {
    if (loading) {
      return (
        <FormSkeleton
          fieldCount={3}
          variant="primary"
          spacing="space-y-3"
          defaultInputClassName="w-5/6"
        />
      );
    }

    if (error) {
      return (
        <ErrorState
          title="Error al cargar direcciones"
          description="No se pudieron cargar las direcciones de contacto. Por favor, inténtalo de nuevo."
        />
      );
    }

    const sortedAddresses = addresses.sort((a, b) => {
      const order = [
        ChannelType.PHONE,
        ChannelType.EMAIL,
        ChannelType.WHATSAPP,
      ];
      return order.indexOf(a.channelType) - order.indexOf(b.channelType);
    });

    return (
      <div className="flex flex-col space-y-2 px-1">
        <SlideDownAnimation
          isVisible={isAddingAddress}
          className="border border-gray-200 rounded-lg"
        >
          <EditableAddressField
            address={newAddress.address}
            channelType={newAddress.channelType}
            onSave={async ({ address, channelType }) => {
              await createAddress({
                contactId,
                address,
                channelType: channelType || ChannelType.PHONE,
              });
            }}
            loading={creating}
            onCancel={() => {
              setIsAddingAddress(false);
              setNewAddress({
                channelType: ChannelType.PHONE,
                address: '',
              });
            }}
            startEditing
            allowChannelTypeChange
          />
        </SlideDownAnimation>

        {sortedAddresses.length === 0 ? (
          // TODO: check when implementing i18n
          <EmptyState description="Agrega una dirección a este contacto" />
        ) : (
          sortedAddresses.map((address) => {
            const isValidPhone =
              address.channelType === ChannelType.PHONE &&
              isValidPhoneNumber(address.address);
            const isUpdating =
              processingAddressId === address.id && (updating || deleting);

            return (
              <EditableAddressField
                key={address.id}
                address={address.address}
                channelType={address.channelType}
                onSave={async ({ address: newAddress }) => {
                  await handleUpdateAddress({
                    id: address.id,
                    data: { address: newAddress },
                  });
                }}
                onDelete={() => handleDeleteAddress(address.id)}
                loading={isUpdating}
                onAction={isValidPhone ? handleCall : undefined}
                actionDisabled={isActionDisabled(
                  address.channelType,
                  quickPhoneState
                )}
                actionLoading={quickPhoneState.isLoading}
                actionTooltip={getActionTooltip(
                  address.channelType,
                  quickPhoneState,
                  isValidPhone
                )}
              />
            );
          })
        )}
      </div>
    );
  };

  return (
    // TODO: check when implementing i18n
    <ExpandableSection
      title="Direcciones de contacto"
      actions={!loading && !error ? renderAddButton() : undefined}
    >
      {renderContent()}
    </ExpandableSection>
  );
};
