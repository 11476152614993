import {
  SearcherTimelineItemDocument,
  CallDocument,
  MessageDocument,
  ConversationChannel,
  Contact,
} from 'src/api/generated';
import { TimelineCallItem } from '../molecules/timeline/timeline-call-item';
import { TimelineWhatsAppItem } from '../molecules/timeline/timeline-whatsapp-item';
import { TimelineEmailItem } from '../molecules/timeline/timeline-email-item';

interface TimelineInteractionItemProps {
  item: SearcherTimelineItemDocument;
  groupedItems?: SearcherTimelineItemDocument[];
  isNew?: boolean;
  contact?: Contact;
}

export const TimelineInteractionItem: React.FC<
  TimelineInteractionItemProps
> = ({ item, groupedItems, isNew = false, contact }) => {
  const modelType = (item as { modelType: string }).modelType;

  if (modelType === CallDocument.modelType.CALL) {
    return (
      <TimelineCallItem
        item={item as CallDocument}
        groupedItems={groupedItems as CallDocument[]}
        isNew={isNew}
        contact={contact}
      />
    );
  }

  if (modelType === MessageDocument.modelType.MESSAGE) {
    const messageItem = item as MessageDocument;
    const groupedMessageItems = groupedItems as MessageDocument[];

    switch (messageItem.channel) {
      case ConversationChannel.WHATSAPP:
        return (
          <TimelineWhatsAppItem
            item={messageItem}
            groupedItems={groupedMessageItems}
            isNew={isNew}
            contact={contact}
          />
        );
      case ConversationChannel.EMAIL:
        return (
          <TimelineEmailItem
            item={messageItem}
            isNew={isNew}
            contact={contact}
          />
        );
      default:
        return (
          <TimelineWhatsAppItem
            item={messageItem}
            groupedItems={groupedMessageItems}
            isNew={isNew}
            contact={contact}
          />
        );
    }
  }

  // Fallback for unknown types
  console.warn(`Unknown timeline item type: ${modelType}`);
  return null;
};
