import { FC, useMemo } from 'react';
import { SearcherDocumentSearchFilterOperator } from 'src/api/generated';
import Select from 'src/modules/shared/components/atoms/inputs/select';
import { FilterFieldOptions } from 'src/modules/shared/types/filters.types';
import { FIELD_TYPE_OPERATORS } from 'src/modules/shared/utils/filters/operators.util';

export interface FilterConditionProps {
  fieldsOptions: FilterFieldOptions[];
  selectedFieldId?: string;
  selectedOperator?: SearcherDocumentSearchFilterOperator;
  onFieldChange: (fieldId: string) => void;
  onOperatorChange: (operator: SearcherDocumentSearchFilterOperator) => void;
}

export const FilterCondition: FC<FilterConditionProps> = ({
  fieldsOptions,
  selectedFieldId,
  selectedOperator,
  onFieldChange,
  onOperatorChange,
}) => {
  const fieldOptionsOptions = useMemo(() => {
    return fieldsOptions.map((field) => ({
      content: field.label,
      value: field.id,
    }));
  }, [fieldsOptions]);

  const selectedFieldType = useMemo(() => {
    const field = fieldsOptions.find((field) => field.id === selectedFieldId);
    return field?.type || 'string';
  }, [fieldsOptions, selectedFieldId]);

  const operatorOptions = useMemo(() => {
    const operators = FIELD_TYPE_OPERATORS[selectedFieldType] || [];

    return operators.map((op) => ({
      content: op.label,
      value: op.value,
    }));
  }, [selectedFieldType]);

  return (
    <div className="flex items-center gap-2">
      <Select
        options={fieldOptionsOptions}
        size="sm"
        value={selectedFieldId}
        onChange={(e) => onFieldChange(e.target.value)}
        className="w-44"
      />
      <Select
        options={operatorOptions}
        size="sm"
        value={selectedOperator}
        onChange={(e) =>
          onOperatorChange(
            e.target.value as SearcherDocumentSearchFilterOperator
          )
        }
        disabled={!selectedFieldId}
        className="w-full"
        containerClassName="w-28"
      />
    </div>
  );
};
