import { ChannelType, ContactAddress } from 'src/api/generated';

export interface UpdateContactAddressParams {
  id: number;
  data: Partial<ContactAddress>;
}

/**
 * Creates an optimistic contact for regular cache updates.
 */
export const createOptimisticContactAddress = (
  params: UpdateContactAddressParams,
  previousAddress?: ContactAddress
): ContactAddress => {
  const optimisticAddress: ContactAddress = {
    id: params.data.id ?? params.id,
    tenantId: params.data.tenantId ?? '',
    contactId: params.data.contactId ?? previousAddress?.contactId ?? 0,
    channelType:
      params.data.channelType ??
      previousAddress?.channelType ??
      ChannelType.PHONE,
    address: params.data.address ?? previousAddress?.address ?? '',
    createdAt:
      params.data.createdAt ??
      previousAddress?.createdAt ??
      new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  };

  return optimisticAddress;
};
