import { ChannelType } from 'src/api/generated';
import { IoLogoWhatsapp } from 'react-icons/io';
import { IoCall, IoMail } from 'react-icons/io5';
import { FC } from 'react';
import { StyledIcon } from './styled-icon';
import { BaseComponentProps } from 'src/modules/shared/types/component.type';

interface ChannelIconProps extends BaseComponentProps {
  channelType?: ChannelType;
}

export const ChannelIcon: FC<ChannelIconProps> = ({
  channelType,
  variant = 'secondary',
  size = 'sm',
  className = '',
}) => {
  const getIcon = () => {
    switch (channelType) {
      case ChannelType.WHATSAPP:
        return <IoLogoWhatsapp />;
      case ChannelType.EMAIL:
        return <IoMail />;
      case ChannelType.PHONE:
        return <IoCall />;
      default:
        return null;
    }
  };

  return (
    <StyledIcon
      icon={getIcon()}
      variant={variant}
      size={size}
      className={className}
    />
  );
};
