import { useMemo } from 'react';
import Button from 'src/modules/shared/components/atoms/buttons/button';
import { PanelHeader } from 'src/modules/shared/components/molecules/panel/panel-header';
import { Avatar } from 'src/modules/shared/components/atoms/avatar';
import { formatFullName } from 'src/modules/shared/utils/formatting/name.util';
import { Typography } from '@material-tailwind/react';
import { Contact, ContactAddress } from 'src/api/generated';
import { useFunnelStatuses } from '../../../hooks/api/funnels/funnel-statuses';
import { useUsers } from 'src/hooks/api/users';
import { createQuery } from 'src/modules/shared/utils/api/query-builder.util';
import { HeaderSkeleton } from 'src/modules/shared/components/atoms/headers/header-skeleton';
import { ContactCallButton } from '../../molecules/buttons/contact-call-button';
import { useGlobalCallProviderContext } from 'src/contexts/global-call-provider-context';
import {
  TagDropdown,
  TagDropdownOption,
} from 'src/modules/shared/components/molecules/dropdowns/tag-dropdown';
import { useUpdateFunnelConversation } from '../../../hooks/api/funnels/funnel-conversations-mutations';
import { PiUserLight, PiXLight } from 'react-icons/pi';
import { TagSkeleton } from 'src/modules/shared/components/atoms/tag/tag-skeleton';

interface ConversationHeaderProps {
  isLoading: boolean;
  contact?: Contact;
  addresses?: ContactAddress[];
  funnelId?: number;
  funnelStatusId?: number;
  userId?: number;
  conversationId: number;
  onOpenProfile: (contactId: number) => void;
  onClose: () => void;
}

export const ConversationHeader: React.FC<ConversationHeaderProps> = ({
  isLoading,
  contact,
  addresses,
  funnelId,
  funnelStatusId,
  userId,
  conversationId,
  onOpenProfile,
  onClose,
}) => {
  const callProvider = useGlobalCallProviderContext();

  const statusQuery = useMemo(() => {
    if (!funnelId) return {};

    return createQuery()
      .equals('funnelId', funnelId)
      .orderByAsc('position')
      .build();
  }, [funnelId]);

  const { statuses, loading: loadingStatuses } = useFunnelStatuses(
    statusQuery,
    !!funnelId
  );

  const { users, loading: loadingAgents } = useUsers();

  const { updateConversation, loading: updatingConversation } =
    useUpdateFunnelConversation();

  const conversationName = useMemo(() => {
    // TODO: check when implementing i18n
    if (isLoading) return 'Cargando...';

    const firstAddress = addresses?.[0];

    return formatFullName(
      contact?.firstName,
      contact?.lastName,
      firstAddress?.address
    );
  }, [addresses, contact, isLoading]);

  const handleStatusChange = async (statusId: number | null) => {
    await updateConversation({
      id: conversationId,
      data: { funnelStatusId: statusId },
    });
  };

  const handleUserAssign = async (userId: number | null) => {
    await updateConversation({
      id: conversationId,
      data: { userId },
    });
  };

  const statusOptions = useMemo(() => {
    const options: TagDropdownOption[] =
      statuses?.map((status) => ({
        id: status.id,
        content: status.name,
        selected: status.id === funnelStatusId,
      })) ?? [];

    return options;
  }, [statuses, funnelStatusId]);

  const userOptions = useMemo(() => {
    const options: TagDropdownOption[] =
      users?.map((user) => ({
        id: user.id,
        content: formatFullName(user.firstName, user.lastName),
        selected: user.id === userId,
      })) ?? [];

    // TODO: check when implementing i18n
    return [
      {
        id: 'unassign',
        content: 'Sin asignar',
        icon: (
          <PiXLight className="h-4 w-4 text-secondary-600 absolute right-4" />
        ),
        selected: !userId,
      },
      ...options,
    ];
  }, [users, userId]);

  const currentStatus = useMemo(() => {
    return statuses?.find((status) => status.id === funnelStatusId);
  }, [statuses, funnelStatusId]);

  const currentUser = useMemo(() => {
    return users?.find((user) => user.id === userId);
  }, [users, userId]);

  const handleCall = (phoneNumber: string) => {
    callProvider.callActions.startCall({ phoneNumber });
  };

  return (
    <PanelHeader
      fixed
      size="md"
      actions={
        <>
          <ContactCallButton
            addresses={addresses}
            onCall={handleCall}
            isLoading={isLoading}
          />
          <Button
            variant="primary"
            appearance="outline"
            onClick={() => onOpenProfile(contact?.id ?? 0)}
            size="sm"
            disabled={isLoading}
          >
            <PiUserLight className="h-5 w-5" />
          </Button>
          <Button
            variant="neutral"
            appearance="ghost"
            size="md"
            onClick={onClose}
            isIconOnly
          >
            <PiXLight className="h-5 w-5" />
          </Button>
        </>
      }
    >
      {isLoading ? (
        <HeaderSkeleton actions={false} />
      ) : (
        <div className="flex items-center gap-3 min-w-0">
          <div
            className="cursor-pointer flex-shrink-0"
            onClick={() => onOpenProfile(contact?.id ?? 0)}
          >
            <Avatar
              text={conversationName.startsWith('+') ? '#' : conversationName}
              isOnline={false}
              size="md"
            />
          </div>
          <div className="flex flex-col gap-0.5 min-w-0">
            <Typography
              variant="h6"
              className="truncate cursor-pointer"
              onClick={() => onOpenProfile(contact?.id ?? 0)}
            >
              {conversationName}
            </Typography>
            <div className="flex items-center gap-2">
              {loadingStatuses ? (
                <TagSkeleton size="sm" />
              ) : (
                <TagDropdown
                  // TODO: check when implementing i18n
                  content={currentStatus?.name}
                  variant="primary"
                  appearance="outline"
                  size="sm"
                  options={statusOptions}
                  onOptionSelect={(option) =>
                    handleStatusChange(option.id as number)
                  }
                  isLoading={updatingConversation}
                />
              )}
              {loadingAgents ? (
                <TagSkeleton size="sm" />
              ) : (
                <TagDropdown
                  // TODO: check when implementing i18n
                  content={
                    currentUser
                      ? formatFullName(
                          currentUser.firstName,
                          currentUser.lastName
                        )
                      : 'Sin asignar'
                  }
                  variant="primary"
                  appearance="outline"
                  size="sm"
                  options={userOptions}
                  onOptionSelect={(option) =>
                    handleUserAssign(
                      option.id === 'unassign' ? null : (option.id as number)
                    )
                  }
                  isLoading={updatingConversation}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </PanelHeader>
  );
};
