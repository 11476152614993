import { Typography } from '@material-tailwind/react';
import { StyledIcon } from 'src/modules/shared/components/atoms/icons/styled-icon';
import { PiChatCircle, PiUser } from 'react-icons/pi';
import { CustomFieldType, FunnelConversationDocument } from 'src/api/generated';
import { formatDate } from 'src/modules/shared/utils/formatting/date.util';
import {
  formatCustomFieldValue,
  getCustomFieldKey,
} from '../../../../../utils/custom-fields.util';
import { TableColumnProps } from 'src/modules/shared/components/molecules/table/table';
import {
  tableCellText,
  tableVariantClasses,
} from 'src/modules/shared/components/molecules/table/styles';

interface CustomFieldDefinition {
  fieldKey: string;
  fieldLabel?: string;
  fieldType: CustomFieldType;
}

interface CustomFieldColumnsParams {
  customFields: CustomFieldDefinition[];
  variant?: keyof typeof tableVariantClasses;
}

export const createConversationCustomFieldColumns = ({
  customFields,
  variant = 'primary',
}: CustomFieldColumnsParams): TableColumnProps<FunnelConversationDocument>[] => {
  const { text } = tableVariantClasses[variant];

  return customFields.map((field) => ({
    header: (
      <div className="flex items-center gap-1.5">
        <StyledIcon
          icon={<PiChatCircle className="w-3.5 h-3.5" />}
          variant="secondary"
          size="xs"
        />
        <Typography className={`font-medium text-sm ${text}`}>
          {field.fieldLabel || field.fieldKey}
        </Typography>
      </div>
    ),
    accessor: `customFields.${field.fieldKey}`,
    className: 'min-w-[140px]',
    render: (_value: unknown, row: FunnelConversationDocument) => {
      if (!row.customFields) return null;

      const fieldKey = getCustomFieldKey(field.fieldType, field.fieldKey);
      const fieldValue = row.customFields[fieldKey];

      return renderCustomFieldValue(fieldValue, field.fieldType, variant);
    },
  }));
};

export const createContactCustomFieldColumns = ({
  customFields,
  variant = 'primary',
}: CustomFieldColumnsParams): TableColumnProps<FunnelConversationDocument>[] => {
  const { text } = tableVariantClasses[variant];

  return customFields.map((field) => ({
    header: (
      <div className="flex items-center gap-1.5">
        <StyledIcon
          icon={<PiUser className="w-3.5 h-3.5" />}
          variant="secondary"
          size="xs"
        />
        <Typography className={`font-medium text-sm ${text}`}>
          {field.fieldLabel || field.fieldKey}
        </Typography>
      </div>
    ),
    accessor: `contacts.customFields.${field.fieldKey}`,
    className: 'min-w-[120px]',
    render: (_value: unknown, row: FunnelConversationDocument) => {
      const contacts = row.contacts;
      if (!contacts || contacts.length === 0 || !contacts[0].customFields) {
        return null;
      }

      const fieldKey = getCustomFieldKey(field.fieldType, field.fieldKey);
      const fieldValue = contacts[0].customFields[fieldKey];

      return renderCustomFieldValue(fieldValue, field.fieldType, variant);
    },
  }));
};

export const renderCustomFieldValue = (
  fieldValue: unknown,
  fieldType: CustomFieldType,
  variant: keyof typeof tableVariantClasses = 'primary'
) => {
  if (fieldValue === null || fieldValue === undefined) {
    return null;
  }

  const { text } = tableVariantClasses[variant];

  if (fieldType === CustomFieldType.BOOLEAN) {
    return (
      <div className="flex items-center">
        <span
          className={`flex h-4 w-4 rounded-full ${
            fieldValue ? 'bg-success-500' : 'bg-secondary-300'
          }`}
        />
        <Typography className={`${tableCellText} ${text} ml-2`}>
          {fieldValue ? 'Sí' : 'No'}
        </Typography>
      </div>
    );
  }

  if (fieldType === CustomFieldType.DATE && fieldValue) {
    return (
      <Typography className={`${tableCellText} ${text}`}>
        {formatDate(fieldValue as string)}
      </Typography>
    );
  }

  return (
    <Typography className={`${tableCellText} ${text}`}>
      {formatCustomFieldValue(fieldValue, fieldType)}
    </Typography>
  );
};
