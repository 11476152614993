import { FunnelConversationDocument } from 'src/api/generated';
import { ConversationCard } from '../conversations/card';
import { ConversationCardSkeleton } from '../conversations/card/card-skeleton';
import Button from 'src/modules/shared/components/atoms/buttons/button';
import { ErrorState } from 'src/modules/shared/components/atoms/states/error-state';
import { EmptyState } from 'src/modules/shared/components/atoms/states/empty-state';

interface ConversationListProps {
  isLoading?: boolean;
  error?: Error | null;
  conversations?: FunnelConversationDocument[];
  onLoadMore?: () => void;
  hasNextPage?: boolean;
  onConversationSelect?: (id: number) => void;
  selectedConversationId?: string;
  onRetry?: () => void;
}

const ConversationList: React.FC<ConversationListProps> = ({
  isLoading,
  error,
  conversations = [],
  onLoadMore,
  hasNextPage,
  onConversationSelect,
  selectedConversationId,
  onRetry,
}) => {
  const handleConversationClick = (
    conversation: FunnelConversationDocument
  ) => {
    onConversationSelect?.(Number(conversation.id));
  };

  if (error) {
    return (
      <ErrorState
        title="Error al cargar conversaciones"
        description={error.message || 'Ocurrió un error inesperado'}
        onAction={onRetry}
        variant="primary"
      />
    );
  }

  return (
    <div className="flex flex-col h-full">
      <div className="flex-1 overflow-y-auto">
        {isLoading ? (
          <div className="flex flex-col space-y-3">
            {[...Array(5)].map((_, index) => (
              <ConversationCardSkeleton key={index} />
            ))}
          </div>
        ) : conversations.length === 0 ? (
          // TODO: check when implementing i18n
          <EmptyState
            title="No hay conversaciones"
            description="Inicia una nueva conversación o cambia tus filtros"
            icon={<span className="text-2xl">💬</span>}
          />
        ) : (
          <div className="flex flex-col space-y-3">
            {conversations.map((conversation) => (
              <ConversationCard
                key={conversation.id}
                conversation={conversation}
                onClick={() => handleConversationClick(conversation)}
                isSelected={conversation.id === selectedConversationId}
              />
            ))}
            {hasNextPage && (
              <Button
                variant="neutral"
                appearance="ghost"
                onClick={onLoadMore}
                className="w-full"
              >
                Load more
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ConversationList;
