import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { MediaSkeleton } from '../../atoms/media/media-skeleton';
import { MediaErrorSkeleton } from '../../atoms/media/media-error-skeleton';

interface AudioMediaProps {
  url: string;
  isLoading?: boolean;
  className?: string;
}

export const AudioMedia: React.FC<AudioMediaProps> = ({
  url,
  isLoading = false,
  className = '',
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoaded(false);
    setHasError(false);
  }, [url, isLoading]);

  const containerClasses = `bg-gray-100 rounded-lg ${className}`;
  const audioLoading = !isLoaded || isLoading;
  const audioUrl = audioLoading ? undefined : url;

  return (
    <div className={containerClasses}>
      {audioLoading && <MediaSkeleton type="audio" />}
      {hasError && <MediaErrorSkeleton />}
      <div className={!audioLoading ? 'block' : 'hidden'}>
        <ReactPlayer
          url={audioUrl}
          controls={true}
          onReady={() => setIsLoaded(true)}
          onError={() => {
            setIsLoaded(false);
            setHasError(true);
          }}
          config={{
            file: {
              forceAudio: true,
              attributes: {
                controlsList: 'nodownload',
              },
            },
          }}
          width="300px"
          height="40px"
        />
      </div>
    </div>
  );
};
