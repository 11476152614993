import {
  Contact,
  CustomFieldEntityType,
  CustomFieldType,
} from 'src/api/generated';
import { useUpdateContactMutation } from 'src/modules/communications/hooks/api/contacts-mutations';
import { CustomFieldEditor } from '../../custom-fields/custom-field-editor';
import { useCustomFieldDefinitions } from '../../../../hooks/api/custom-fields';
import { Spinner } from '@material-tailwind/react';
import {
  createCustomFieldFromDefinition,
  getCustomFieldKey,
} from '../../../../utils/custom-fields.util';
import { useCustomFieldListOptions } from '../../../../hooks/api/custom-fields/list-options';
import { createQuery } from 'src/modules/shared/utils/api/query-builder.util';
import { useMemo } from 'react';

interface ContactCustomFieldsSectionProps {
  contact: Contact;
  loading?: boolean;
}

export const ContactCustomFieldsSection: React.FC<
  ContactCustomFieldsSectionProps
> = ({ contact, loading = false }) => {
  const { updateContact, loading: isUpdatingContact } =
    useUpdateContactMutation();

  const {
    definitions,
    loading: loadingDefinitions,
    error: definitionsError,
  } = useCustomFieldDefinitions({
    entityType: CustomFieldEntityType.CONTACT,
  });

  const listDefinitionIds = definitions
    .filter((def) => def.fieldType === CustomFieldType.LIST)
    .map((def) => def.id);

  const optionsQuery = useMemo(() => {
    if (listDefinitionIds.length === 0) return null;

    return createQuery()
      .equals('definitionId', listDefinitionIds)
      .take(100)
      .build();
  }, [listDefinitionIds]);

  const { options: listOptions } = useCustomFieldListOptions({
    ...(optionsQuery || {}),
    enabled: listDefinitionIds.length > 0,
  });

  const optionsMap = useMemo(() => {
    const map: Record<number, Array<{ label: string; value: string }>> = {};

    listOptions.forEach((option) => {
      if (!map[option.definitionId]) {
        map[option.definitionId] = [];
      }

      map[option.definitionId].push({
        label: option.label || option.value,
        value: option.value,
      });
    });

    return map;
  }, [listOptions]);

  const handleUpdateContactField = async (
    fieldId: string | number,
    value: unknown
  ) => {
    if (!contact) return;

    const customFields = {
      ...(contact.customFields || {}),
      [fieldId.toString()]: value,
    };

    await updateContact({
      id: contact.id,
      data: {
        customFields,
      },
    });
  };

  if (loadingDefinitions) {
    return (
      <div className="flex justify-center p-4">
        <Spinner />
      </div>
    );
  }

  if (definitionsError) {
    return <div className="text-red-500">Error loading custom fields</div>;
  }

  if (definitions.length === 0) {
    return (
      <div className="text-gray-500 italic">
        No hay campos personalizados definidos
      </div>
    );
  }

  return (
    <div className="space-y-2">
      {definitions.map((definition) => {
        const prefixedKey = getCustomFieldKey(
          definition.fieldType,
          definition.fieldKey
        );
        const value = contact.customFields?.[prefixedKey] ?? null;

        const customField = createCustomFieldFromDefinition(
          definition.id,
          definition.fieldKey,
          definition.fieldType,
          definition.fieldLabel,
          value,
          definition.fieldType === CustomFieldType.LIST
            ? optionsMap[definition.id]
            : undefined
        );

        return (
          <CustomFieldEditor
            key={definition.fieldKey}
            field={customField}
            onSave={(value) => handleUpdateContactField(prefixedKey, value)}
            disabled={loading || isUpdatingContact}
            loading={loading || isUpdatingContact}
          />
        );
      })}
    </div>
  );
};
