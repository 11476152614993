import { QueryClient, QueryKey } from '@tanstack/react-query';
import {
  Message,
  SearcherTimelineItemDocument,
  MessageDocument,
  MessageStatus,
} from 'src/api/generated';
import { UseWhatsAppMessageCreateParams } from 'src/modules/messaging/whatsapp/hooks/api/messages-mutations';
import { updateCache } from 'src/modules/shared/utils/cache/update.util';

interface WhatsAppMessageContext {
  queryClient: QueryClient;
  params: UseWhatsAppMessageCreateParams;
  optimisticMessage?: Message;
}

const convertMessageToTimelineItem = (
  message: Message
): SearcherTimelineItemDocument => {
  return {
    ...message,
    modelType: MessageDocument.modelType.MESSAGE,
  };
};

export const createWhatsAppMessageCacheUpdate = (
  message: Message,
  context: WhatsAppMessageContext
) => {
  const timelineItem = convertMessageToTimelineItem(message);
  const isTemporary = message.id.startsWith('temp-');

  updateCache({
    queryClient: context.queryClient,
    queryKey: ['crm/timeline-items/search'],
    updatedData: timelineItem,
    shouldAddItem: (
      _matchedKey: QueryKey,
      _newItem: SearcherTimelineItemDocument
    ) => {
      if (isTemporary && message.status === MessageStatus.FAILED) {
        return false;
      }

      const shouldAdd =
        !isTemporary || message.status === MessageStatus.PENDING;
      return shouldAdd;
    },
    shouldRemoveItem: (_, oldItem) => {
      if (oldItem.modelType !== MessageDocument.modelType.MESSAGE) {
        return false;
      }

      const isSameId = oldItem.id === message.id;
      const isPreviousTemporary = oldItem.id.startsWith('temp-');
      const isSameConversation =
        oldItem.conversationId === message.conversationId;
      const shouldRemove =
        isSameId || (!isTemporary && isPreviousTemporary && isSameConversation);

      return shouldRemove;
    },
  });
};
