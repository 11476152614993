import { type FC } from 'react';
import { type BaseProps } from 'src/modules/shared/types/component.type';
import { getDisabledClasses } from 'src/modules/shared/styles/component.styles';
import {
  cardBaseClasses,
  cardVariantClasses,
  cardSizeClasses,
  cardHighlightedClass,
} from './styles';

export type CardProps = BaseProps & {
  onClick?: () => void;
  isHighlighted?: boolean;
};

export const Card: FC<CardProps> = ({
  children,
  className = '',
  variant = 'secondary',
  size = 'md',
  disabled = false,
  onClick,
  isHighlighted = false,
}) => {
  const handleClick = (_e: React.MouseEvent) => {
    if (!disabled && onClick) {
      onClick();
    }
  };

  const variantClasses = cardVariantClasses[variant];
  const sizeClass = cardSizeClasses[size];
  const disabledClass = getDisabledClasses(disabled);
  const cursorClass = !disabled && onClick ? 'cursor-pointer' : '';
  const highlightedClass = isHighlighted ? cardHighlightedClass : '';

  const combinedClasses = [
    cardBaseClasses,
    variantClasses.base,
    !disabled && onClick ? variantClasses.hover : '',
    !disabled && onClick ? variantClasses.active : '',
    sizeClass,
    disabledClass,
    cursorClass,
    highlightedClass,
    className,
  ].join(' ');

  return (
    <div onClick={handleClick} className={combinedClasses}>
      {children}
    </div>
  );
};
