import { Outlet } from '@tanstack/react-router';

function UnauthenticatedApp() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-secondary-50 to-primary-50 flex items-center justify-center p-4">
      <main className="w-full max-w-md">
        <Outlet />
      </main>
    </div>
  );
}

export default UnauthenticatedApp;
