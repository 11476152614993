import { useQuery } from '@tanstack/react-query';
import { User, V2AdminUsersService, ApiQueryParams } from 'src/api/generated';
import { ApiResponse } from 'src/hooks/api/api';

interface UsersResponse extends ApiResponse {
  users: User[];
}

export const useUsers = (queryParams: ApiQueryParams = {}): UsersResponse => {
  const fetchUsers = async (): Promise<User[]> => {
    const { filterBy, searchBy, sortBy, page, pageSize } = queryParams;

    return await V2AdminUsersService.getUsers(
      filterBy,
      searchBy,
      sortBy,
      page,
      pageSize
    );
  };

  const {
    data: users,
    error,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['admin/users', queryParams],
    queryFn: fetchUsers,
  });

  return {
    loading: isLoading,
    error,
    users: users || [],
    refetch,
  };
};

interface UseUserResponse extends ApiResponse {
  user: User | null;
}

export const useUser = (id: number): UseUserResponse => {
  const fetchUser = async (): Promise<User> => {
    return await V2AdminUsersService.getUser(id);
  };

  const {
    data,
    error,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['admin/users', id],
    queryFn: fetchUser,
  });

  return {
    loading,
    error,
    user: data ?? null,
    refetch,
  };
};

export const useCurrentUser = (): UseUserResponse => {
  const fetchCurrentUser = async (): Promise<User> => {
    return await V2AdminUsersService.getCurrentUser();
  };

  const {
    data,
    error,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['admin/users/current'],
    queryFn: fetchCurrentUser,
  });

  return {
    loading,
    error,
    user: data ?? null,
    refetch,
  };
};
