import { ConversationCardSkeleton } from '../../../../molecules/conversations/card/card-skeleton';

export const PipelineBoardSkeleton = () => {
  return (
    <div className="flex h-full overflow-x-auto bg-gray-50">
      {[1, 2, 3, 4].map((columnIndex) => (
        <div
          key={columnIndex}
          className="flex-none w-80 h-full p-4 bg-white border-r border-gray-200"
        >
          {/* Header */}
          <div className="flex items-center justify-between mb-4">
            <div className="space-y-2">
              <div className="h-4 bg-gray-200 rounded w-24 animate-pulse" />
              <div className="h-3 bg-gray-200 rounded w-16 animate-pulse" />
            </div>
          </div>

          {/* Cards */}
          <div className="space-y-3">
            <ConversationCardSkeleton />
            <ConversationCardSkeleton />
            <ConversationCardSkeleton />
          </div>
        </div>
      ))}
    </div>
  );
};
