import { useHasRoles } from 'src/modules/shared/hooks/auth';
import { useCurrentUser } from 'hooks/users';
import { useState, useEffect } from 'react';
import { User } from 'src/api/generated';
import { formatFullName } from '../../utils/formatting/name.util';

interface UseSelectUsersOptions {
  autoSelectCurrentUser?: boolean;
  clearSelectionForAdmin?: boolean;
}

interface UseSelectUsersProps {
  users: User[];
  options?: UseSelectUsersOptions;
}

export interface UseUsersSelectResult {
  currentUser: User | null;
  selectedUserIds: number[];
  setSelectedUserIds: (userIds: number[]) => void;
  userOptions: { value: number; label: string }[];
  isAdmin: boolean;
  loading: boolean;
}

export const useUsersSelect = ({
  users,
  options = {
    autoSelectCurrentUser: true,
    clearSelectionForAdmin: true,
  },
}: UseSelectUsersProps) => {
  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);
  const { currentUser, loading: loadingCurrentUser } = useCurrentUser();
  const { hasRole: isAdmin, loading: loadingIsAdmin } = useHasRoles('Admin');

  useEffect(() => {
    if (!currentUser || !options.autoSelectCurrentUser) return;

    if (isAdmin && options.clearSelectionForAdmin) {
      setSelectedUserIds([]);
      return;
    }

    setSelectedUserIds([currentUser.id]);
  }, [
    currentUser,
    isAdmin,
    options.autoSelectCurrentUser,
    options.clearSelectionForAdmin,
  ]);

  const userOptions = users.map((user) => ({
    value: user.id,
    label: formatFullName(user.firstName, user.lastName),
  }));

  const handleUserIdsChange = (values: (string | number)[]) => {
    setSelectedUserIds(values.map((v) => Number(v)));
  };

  return {
    currentUser,
    selectedUserIds,
    setSelectedUserIds: handleUserIdsChange,
    userOptions,
    isAdmin,
    loading: loadingCurrentUser || loadingIsAdmin,
  };
};
