import { CallProvider } from 'hooks/phones/types';
import { ChannelType } from 'src/api/generated';
import STATES from 'states/index';

export interface QuickPhoneState {
  isLoading: boolean;
  hasError: boolean;
  isReady: boolean;
  isDisabled: boolean;
}

export const getQuickPhoneState = (
  callProvider: Pick<CallProvider, 'loading' | 'error'>,
  phoneState: string
): QuickPhoneState => {
  return {
    isLoading: callProvider.loading,
    hasError: !!callProvider.error,
    isReady: phoneState === STATES.PHONE.READY,
    isDisabled:
      phoneState === STATES.PHONE.ON_CALL ||
      phoneState === STATES.PHONE.INCOMING ||
      phoneState === STATES.PHONE.OFFLINE ||
      phoneState === STATES.PHONE.ERROR,
  };
};

export const isActionDisabled = (
  channelType: ChannelType,
  phoneState: QuickPhoneState
): boolean => {
  if (channelType !== ChannelType.PHONE) return false;

  return (
    phoneState.isLoading ||
    phoneState.hasError ||
    !phoneState.isReady ||
    phoneState.isDisabled
  );
};

export const getActionTooltip = (
  channelType: ChannelType,
  phoneState: QuickPhoneState,
  isValidNumber: boolean
): string | undefined => {
  if (channelType !== ChannelType.PHONE) return undefined;

  // TODO: check when implementing i18n
  if (phoneState.isLoading) return 'Cargando teléfono...';
  if (phoneState.hasError) {
    return 'Ocurrió un error al cargar el teléfono';
  }
  if (phoneState.isDisabled) {
    return 'Teléfono no disponible';
  }
  if (!phoneState.isReady) {
    return 'Teléfono no listo';
  }
  if (isValidNumber) {
    return 'Llamar';
  } else {
    return 'Número no válido. Arréglalo para realizar llamadas.';
  }
};
