import { type ComponentVariant } from 'src/modules/shared/types/component.type';

interface TextSkeletonProps {
  variant?: ComponentVariant;
  className?: string;
}

export const TextSkeleton: React.FC<TextSkeletonProps> = ({
  variant = 'secondary',
  className = '',
}) => {
  const variantClasses: Record<ComponentVariant, string> = {
    primary: 'bg-gray-200',
    secondary: 'bg-secondary-200',
    danger: 'bg-danger-200',
    neutral: 'bg-neutral-200',
    warning: 'bg-warning-200',
    success: 'bg-success-200',
    info: 'bg-info-200',
  };

  return (
    <div
      className={`
        animate-pulse rounded max-w-full w-full h-4
        ${variantClasses[variant]}
        ${className}
      `.trim()}
    />
  );
};
