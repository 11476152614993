import { QueryClient, QueryKey } from '@tanstack/react-query';
import {
  FunnelConversation,
  FunnelConversationDocument,
  SearcherDocumentSearchFilter,
  SearcherDocumentSearchFilterOperator,
  SearcherDocumentSearchFilterValue,
} from 'src/api/generated';
import { QueryParams } from 'src/modules/shared/types/api/query-builder.type';
import { updateCache } from 'src/modules/shared/utils/cache/update.util';
import {
  createOptimisticFieldChecker,
  createQueryInvalidator,
  getUpdatedFields,
  invalidateNonOptimisticQueries,
  matchesFilter,
  parseFilterBy,
} from 'src/modules/shared/utils/cache/optimistic.util';
import { createOptimisticConversationDocument } from './optimistic.util';
import { UpdateFunnelConversationContext } from './optimistic.util';

/**
 * Extracts filter information for the document cache from the query parameters
 */
const getDocumentFilterInfo = (
  matchedKey: QueryKey
): {
  hasStatusFilter: boolean;
  hasUserFilter: boolean;
} => {
  const params =
    Array.isArray(matchedKey) && matchedKey.length > 1
      ? (matchedKey[1] as DocumentQueryParams)
      : undefined;

  if (!params?.filters) {
    return { hasStatusFilter: false, hasUserFilter: false };
  }

  const hasStatusFilter = params.filters.some(
    (filter: SearcherDocumentSearchFilter) => filter.field === 'funnelStatusId'
  );
  const hasUserFilter = params.filters.some(
    (filter: SearcherDocumentSearchFilter) => filter.field === 'userId'
  );

  return { hasStatusFilter, hasUserFilter };
};

/**
 * Extracts filter information for the conversation cache from the query parameters
 */
const getConversationFilterInfo = (
  matchedKey: QueryKey
): {
  hasStatusFilter: boolean;
  hasUserFilter: boolean;
  filter: { field: string; value: string } | null;
} => {
  const params =
    Array.isArray(matchedKey) && matchedKey.length > 1
      ? (matchedKey[1] as QueryParams)
      : undefined;

  const filter = params && parseFilterBy(params.filterBy);

  if (!filter) {
    return { hasStatusFilter: false, hasUserFilter: false, filter: null };
  }

  const hasStatusFilter = filter.field === 'funnelStatusId';
  const hasUserFilter = filter.field === 'userId';

  return { hasStatusFilter, hasUserFilter, filter };
};

/**
 * Validates if matchedKey is valid for document queries
 */
const isValidDocumentQueryKey = (matchedKey: QueryKey): boolean => {
  const isValidMatchedKey =
    Array.isArray(matchedKey) && matchedKey.length === 2;
  if (!isValidMatchedKey) return false;

  const params = matchedKey[1] as DocumentQueryParams;
  return !!params?.filters;
};

/**
 * Validates if matchedKey is valid for conversation queries
 */
const isValidConversationQueryKey = (matchedKey: QueryKey): boolean => {
  return Array.isArray(matchedKey) && matchedKey.length === 2;
};

/**
 * Checks if document filters match the specified item
 */
const shouldAddItemForDocumentFilters = <T extends Record<string, unknown>>(
  filters: SearcherDocumentSearchFilter[],
  item: T
): boolean => {
  return filters.every((filter) => {
    if (!isOptimisticField(filter.field)) return false;

    if (
      ![
        SearcherDocumentSearchFilterOperator.EQ,
        SearcherDocumentSearchFilterOperator.IN,
        SearcherDocumentSearchFilterOperator.MISSING,
        SearcherDocumentSearchFilterOperator.EXISTS,
      ].includes(filter.operator)
    ) {
      return false;
    }

    return matchesFilter(filter, item);
  });
};

/**
 * Checks if the item should be added to unassigned filter query
 */
const shouldAddItemForUnassignedFilter = <
  T extends { userId?: string | number | null | undefined },
>(
  field: string,
  value: string | undefined,
  item: T
): boolean => {
  if (field === 'userId' && !value) {
    return item.userId == null;
  }
  return false;
};

/**
 * Checks if the funnel status has changed and requires removing the item from the cache
 */
const hasFunnelStatusChangedForRemoval = <
  T extends { funnelStatusId?: string | number | null | undefined },
>(
  hasStatusFilter: boolean,
  updatedFields: string[],
  oldItem: T,
  newItem: T
): boolean => {
  return (
    hasStatusFilter &&
    updatedFields.includes('funnelStatusId') &&
    String(oldItem.funnelStatusId) !== String(newItem.funnelStatusId)
  );
};

/**
 * Checks if the user assignment has changed and requires removing the item from the cache
 */
const hasUserChangedForRemoval = <
  T extends { userId?: string | number | null | undefined },
>(
  hasUserFilter: boolean,
  updatedFields: string[],
  oldItem: T,
  newItem: T
): boolean => {
  if (!hasUserFilter || !updatedFields.includes('userId')) {
    return false;
  }

  const wasUserAssigned = oldItem.userId != null;
  const isUserAssigned = newItem.userId != null;

  const hasUserAssignmentStatusChanged = wasUserAssigned !== isUserAssigned;
  const hasUserIdChanged = String(oldItem.userId) !== String(newItem.userId);

  return hasUserAssignmentStatusChanged || hasUserIdChanged;
};

const OPTIMISTIC_FIELDS = ['funnelStatusId', 'userId', 'funnelId'] as const;
const isOptimisticField = createOptimisticFieldChecker(OPTIMISTIC_FIELDS);
const shouldInvalidateQuery = createQueryInvalidator(isOptimisticField);

type DocumentQueryParams = {
  filters?: SearcherDocumentSearchFilter[];
};

export const updateFunnelConversationDocumentCache = (
  queryClient: QueryClient,
  _conversation: FunnelConversation,
  context: UpdateFunnelConversationContext
) => {
  const conversationDocument = createOptimisticConversationDocument(
    context.params,
    context.previousConversationDocument
  );
  const updatedFields = getUpdatedFields(context.params.data);

  invalidateNonOptimisticQueries(
    queryClient,
    ['crm/documents/funnel-conversations'],
    shouldInvalidateQuery
  );

  updateCache({
    queryClient,
    queryKey: ['crm/documents/funnel-conversations'],
    updatedData: conversationDocument,
    shouldRemoveItem: (
      matchedKey: QueryKey,
      oldItem: FunnelConversationDocument
    ) => {
      if (oldItem.id !== conversationDocument.id) return false;

      const { hasStatusFilter, hasUserFilter } =
        getDocumentFilterInfo(matchedKey);

      const shouldRemoveForFunnelStatusChange =
        hasFunnelStatusChangedForRemoval(
          hasStatusFilter,
          updatedFields,
          oldItem,
          conversationDocument
        );

      const shouldRemoveForUserChange = hasUserChangedForRemoval(
        hasUserFilter,
        updatedFields,
        oldItem,
        conversationDocument
      );

      return shouldRemoveForFunnelStatusChange || shouldRemoveForUserChange;
    },
    shouldAddItem: (matchedKey: QueryKey) => {
      if (!isValidDocumentQueryKey(matchedKey)) return false;

      const params = matchedKey[1] as DocumentQueryParams;
      return shouldAddItemForDocumentFilters(
        params.filters!,
        conversationDocument
      );
    },
  });
};

export const updateFunnelConversationCache = (
  queryClient: QueryClient,
  conversation: FunnelConversation,
  context: UpdateFunnelConversationContext
) => {
  updateFunnelConversationDocumentCache(queryClient, conversation, context);

  const updatedFields = getUpdatedFields(context.params.data);

  invalidateNonOptimisticQueries(
    queryClient,
    ['crm/funnel-conversations'],
    shouldInvalidateQuery
  );

  updateCache({
    queryClient,
    queryKey: ['crm/funnel-conversations'],
    updatedData: conversation,
    shouldRemoveItem: (matchedKey: QueryKey, oldItem: FunnelConversation) => {
      if (oldItem.id !== conversation.id) return false;

      const { hasStatusFilter, hasUserFilter, filter } =
        getConversationFilterInfo(matchedKey);

      if (!filter) return false;

      const shouldRemoveForFunnelStatusChange =
        hasFunnelStatusChangedForRemoval(
          hasStatusFilter,
          updatedFields,
          oldItem,
          conversation
        );

      const shouldRemoveForUserChange = hasUserChangedForRemoval(
        hasUserFilter,
        updatedFields,
        oldItem,
        conversation
      );

      return shouldRemoveForFunnelStatusChange || shouldRemoveForUserChange;
    },
    shouldAddItem: (matchedKey: QueryKey) => {
      if (!isValidConversationQueryKey(matchedKey)) return false;

      const params = matchedKey[1] as QueryParams;
      const filter = parseFilterBy(params.filterBy);

      if (!filter) return true;

      if (!isOptimisticField(filter.field)) return false;

      if (
        shouldAddItemForUnassignedFilter(
          filter.field,
          filter.value,
          conversation
        )
      ) {
        return true;
      }

      const simpleFilterBySearchFilter: SearcherDocumentSearchFilter = {
        field: filter.field,
        operator: SearcherDocumentSearchFilterOperator.EQ,
        value: filter.value as SearcherDocumentSearchFilterValue,
      };

      return matchesFilter(simpleFilterBySearchFilter, conversation);
    },
  });

  updateCache({
    queryClient,
    queryKey: ['crm/funnel-conversations', context.params.id],
    updatedData: conversation,
    exact: true,
  });
};
