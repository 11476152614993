import {
  ComponentSize,
  ComponentVariant,
} from 'src/modules/shared/types/component.type';
import {
  baseInputClasses,
  inputVariantClasses,
} from 'src/modules/shared/styles/component.styles';

export const tagInputVariantClasses = inputVariantClasses;

export const tagInputContainerClasses = [
  ...baseInputClasses,
  'relative',
  'flex-1',
  'flex',
  'flex-wrap',
  'items-center',
  'gap-1.5',
  'py-1',
  'overflow-y-auto',
  'overflow-x-hidden',
];

export const tagInputClasses = [
  'appearance-none',
  'flex-1',
  'min-w-[80px]',
  'max-w-full',
  'bg-transparent',
  'outline-none',
  'focus:outline-none',
  'border-none',
  'text-xs',
  'py-0',
];

export const tagClasses = [
  'inline-flex',
  'items-center',
  'gap-1',
  'px-2',
  'py-0.5',
  'rounded-md',
  'text-xs',
  'font-medium',
  'transition-colors',
  'duration-200',
];

export const tagVariantClasses: Record<ComponentVariant, string> = {
  primary: 'bg-primary-50 text-primary-700',
  secondary: 'bg-secondary-50 text-secondary-700',
  danger: 'bg-danger-50 text-danger-700',
  warning: 'bg-warning-50 text-warning-700',
  neutral: 'bg-neutral-50 text-neutral-700',
  success: 'bg-success-50 text-success-700',
  info: 'bg-blue-50 text-blue-700',
};

export const tagInputContainerSizeClasses: Record<ComponentSize, string> = {
  '2xs': 'min-h-[1.5rem] px-1.5 text-xs',
  xs: 'min-h-[1.5rem] px-1.5 text-xs',
  sm: 'min-h-[1.75rem] px-2 text-xs',
  md: 'min-h-[2rem] px-2.5 text-sm',
  lg: 'min-h-[2.5rem] px-3.5 text-sm',
};

export const tagSizeMap: Record<ComponentSize, ComponentSize> = {
  '2xs': '2xs',
  xs: '2xs',
  sm: '2xs',
  md: 'sm',
  lg: 'sm',
};
