import { useRef, useCallback } from 'react';
import { useDroppable } from '@dnd-kit/core';
import {
  SearcherDocumentSearchFilter,
  SearcherDocumentSearchSort,
  type FunnelStatus,
} from 'src/api/generated';
import { useFunnelConversationDocuments } from '../api/funnels/funnel-conversations';
import { useInfiniteScroll } from 'src/modules/shared/hooks/scroll/movement';
import { SearcherDocumentSearchFilterOperator } from 'src/api/generated';
import { type StatusContainerDragData } from '../../types/pipeline/dnd.types';
import { createPipelineContainerId } from '../../utils/pipeline/dnd.utils';

interface UseStatusColumnParams {
  status: FunnelStatus;
  selectedFunnelId?: number;
  searchTerm?: string;
  filters?: SearcherDocumentSearchFilter[];
  sorts?: SearcherDocumentSearchSort[];
}

interface UseStatusColumnReturn {
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
  setRefs: (node: HTMLDivElement | null) => void;
  conversations: ReturnType<
    typeof useFunnelConversationDocuments
  >['conversations'];
  loading: boolean;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: () => void;
  totalCount?: number;
}

export const useStatusColumnConversations = ({
  status,
  selectedFunnelId,
  searchTerm,
  filters = [],
  sorts = [],
}: UseStatusColumnParams): UseStatusColumnReturn => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const containerId = createPipelineContainerId(status.id);

  const { setNodeRef } = useDroppable({
    id: containerId,
    data: {
      type: 'container',
      data: {
        status,
        statusId: status.id,
      },
      containerId,
    } satisfies StatusContainerDragData,
  });

  const setRefs = useCallback(
    (node: HTMLDivElement | null) => {
      setNodeRef(node);
      containerRef.current = node;
    },
    [setNodeRef]
  );

  const baseFilters: SearcherDocumentSearchFilter[] = [
    {
      field: 'funnelId',
      operator: SearcherDocumentSearchFilterOperator.EQ,
      value: selectedFunnelId?.toString(),
    },
    {
      field: 'funnelStatusId',
      operator: SearcherDocumentSearchFilterOperator.EQ,
      value: status.id.toString(),
    },
  ];

  const statusColumnFilters: SearcherDocumentSearchFilter[] = [
    ...baseFilters,
    ...filters,
  ];

  const {
    conversations,
    loading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    totalCount,
  } = useFunnelConversationDocuments(
    {
      search: searchTerm,
      filters: statusColumnFilters,
      ...(sorts.length > 0 && { sort: sorts }),
      size: 20,
      includeTotalCount: true,
    },
    !!selectedFunnelId
  );

  useInfiniteScroll({
    containerRef,
    isFetching: isFetchingNextPage,
    hasMore: hasNextPage,
    fetchMore: () => fetchNextPage(),
  });

  return {
    containerRef,
    setRefs,
    conversations,
    loading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    totalCount,
  };
};
