/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomFieldListOptionListResponse } from '../models/CustomFieldListOptionListResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CrmCustomFieldsOptionsService {

    /**
     * @param filterBy
     * @param searchBy
     * @param sortBy
     * @param page
     * @param pageSize
     * @param pageToken
     * @returns CustomFieldListOptionListResponse Ok
     * @throws ApiError
     */
    public static getCustomFieldListOptions(
        filterBy?: string,
        searchBy?: string,
        sortBy?: string,
        page?: number,
        pageSize?: number,
        pageToken?: string,
    ): CancelablePromise<CustomFieldListOptionListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/crm/custom-fields/options',
            query: {
                'filterBy': filterBy,
                'searchBy': searchBy,
                'sortBy': sortBy,
                'page': page,
                'pageSize': pageSize,
                'pageToken': pageToken,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

}
