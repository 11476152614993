import { useInfiniteQuery } from '@tanstack/react-query';
import { ApiResponse, useMemoizedArrayData } from 'src/hooks/api/api';
import {
  FunnelStatus,
  FunnelStatusListQueryParams,
  FunnelStatusListResponse,
  CrmFunnelsStatusesService,
} from 'src/api/generated';

interface UseFunnelStatusesParams extends FunnelStatusListQueryParams {
  enabled?: boolean;
}

interface UseFunnelStatusesResponse extends ApiResponse {
  statuses: FunnelStatus[];
  fetchNextPage: () => void;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
}

export const useFunnelStatuses = (
  params: UseFunnelStatusesParams,
  enabled?: boolean
): UseFunnelStatusesResponse => {
  const fetchStatuses = async ({
    pageParam,
  }: {
    pageParam: string | undefined;
  }): Promise<FunnelStatusListResponse> => {
    const { filterBy, searchBy, sortBy, page, pageSize = 20 } = params;

    return await CrmFunnelsStatusesService.getFunnelStatuses(
      filterBy,
      searchBy,
      sortBy,
      page,
      pageSize,
      pageParam
    );
  };

  const getNextPageParam = (lastPage: FunnelStatusListResponse) =>
    lastPage.nextPageToken;

  const {
    data,
    error,
    isLoading: loading,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['crm/funnels/statuses', params],
    queryFn: fetchStatuses,
    getNextPageParam,
    initialPageParam: undefined,
    enabled,
  });

  const statuses = useMemoizedArrayData<FunnelStatus>(
    data?.pages.flatMap((page) => page.items) || []
  );

  return {
    loading,
    error,
    statuses,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  };
};
