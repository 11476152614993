import { ChannelType, ContactAddress } from 'src/api/generated';
import { standardizePhoneNumber } from 'src/modules/shared/utils/formatting/phone.util';
import { SplitButtonDropdown } from 'src/modules/shared/components/molecules/dropdowns/split-button-dropdown';
import { callButtonVariantOverrides } from 'src/modules/shared/styles/call.styles';
import { PiPhoneLight, PiGearSixLight } from 'react-icons/pi';
interface ContactCallButtonProps {
  addresses?: ContactAddress[];
  onCall: (phoneNumber: string) => void;
  onOpenConfig?: () => void;
  isLoading?: boolean;
}

export function ContactCallButton({
  addresses = [],
  onCall,
  onOpenConfig,
  isLoading,
}: ContactCallButtonProps) {
  const phoneAddresses = addresses.filter(
    (addr) => addr.channelType === ChannelType.PHONE
  );
  const hasPhoneNumbers = phoneAddresses.length > 0;

  const getPhoneNumberLabel = (phoneNumber: string) => {
    const number = standardizePhoneNumber(phoneNumber) || phoneNumber;
    // TODO: check when implementing i18n
    return `Llamar ${number}`;
  };
  const dropdownItems = [
    ...phoneAddresses.map((address, index) => ({
      content: getPhoneNumberLabel(address.address),
      onClick: () => onCall(address.address),
      icon: <PiPhoneLight className="w-4 h-4" />,
      separator: index === phoneAddresses.length - 1,
    })),
    {
      // TODO: check when implementing i18n
      content: 'Configuración',
      onClick: onOpenConfig ?? (() => {}),
      icon: <PiGearSixLight className="w-4 h-4" />,
      disabled: true,
    },
  ];

  const defaultPhoneNumber = phoneAddresses[0]?.address ?? '';

  return (
    <SplitButtonDropdown
      icon={<PiPhoneLight className="w-5 h-5" />}
      label=""
      onClick={() => onCall(defaultPhoneNumber)}
      items={dropdownItems}
      size="sm"
      className={callButtonVariantOverrides.primary}
      variant="primary"
      disabled={isLoading || !hasPhoneNumbers}
    />
  );
}
