/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum SettingName {
    ADVANCED_REPORTS = 'advanced-reports',
    ALLOW_CALL_REANALYSIS = 'allow-call-reanalysis',
    ASSIGNED_PHONE = 'assigned-phone',
    ASSIGNED_PHONE_CHOOSE = 'assigned-phone-choose',
    BYOC_TRUNK = 'byoc-trunk',
    CALL_QUALITY = 'call-quality',
    CALL_WITHOUT_INTERNET = 'call-without-internet',
    CALL_WITHOUT_INTERNET_CHOOSE = 'call-without-internet-choose',
    CAN_RECEIVE_CALLS = 'can-receive-calls',
    COMPANY_PHONES_FALLBACK = 'company-phones-fallback',
    COUNTRY = 'country',
    CRM_PUBLISH_FEEDBACK = 'crm-publish-feedback',
    DOWNLOADS_CALLS = 'downloads-calls',
    FEATURE_FLAG_ADMIN_DOWNLOAD_RECORDINGS = 'feature-flag-admin-download-recordings',
    FEATURE_FLAG_AGENT_SHOW_ALL_CALLS = 'feature-flag-agent-show-all-calls',
    FEATURE_FLAG_AGENT_SHOW_ALL_CONVERSATIONS = 'feature-flag-agent-show-all-conversations',
    FEATURE_FLAG_CONVERSATIONS = 'feature-flag-conversations',
    FEATURE_FLAG_CRM = 'feature-flag-crm',
    FEATURE_FLAG_SHOW_RECORDINGS = 'feature-flag-show-recordings',
    FEATURE_FLAG_SHOW_TRANSCRIPTIONS = 'feature-flag-show-transcriptions',
    SHOW_CONTACTABILITY = 'show-contactability',
    SHOW_EXACT_TOTAL_MINUTES = 'show-exact-total-minutes',
    SHOW_REPORTS = 'show-reports',
    SIMULTANEOUS_RINGING = 'simultaneous-ringing',
    TIMEOUT_ON_INCOMING = 'timeout-on-incoming',
    TIMEZONE = 'timezone',
    TWILIO_EDGE_LOCATIONS = 'twilio-edge-locations',
}
