import React, { useState } from 'react';
import MediaModal from '../../../atoms/media/media-modal';
import MediaSkeleton from '../../../atoms/media/media-skeleton';
import { PlayIcon } from '@heroicons/react/24/outline';
import Button from 'src/modules/shared/components/atoms/buttons/button';

interface VideoMediaModalProps {
  url: string;
}

const VideoMediaModal: React.FC<VideoMediaModalProps> = ({ url }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);

  function handleLoadedMetadata() {
    setIsLoaded(true);
    setHasError(false);
  }

  function handleError() {
    setIsLoaded(false);
    setHasError(true);
  }

  const playOverlayClasses = `
    absolute w-full h-full inset-0 flex items-center justify-center 
    cursor-pointer opacity-80 hover:opacity-100 transition-opacity duration-300
  `;

  return (
    <>
      <div className="relative w-full h-auto max-w-[27rem] max-h-[27rem] rounded-lg p-0.5">
        <MediaSkeleton isLoading={!isLoaded} hasError={hasError} />
        <div className="relative w-full h-full">
          <video
            onClick={() => setIsModalOpen(true)}
            onLoadedMetadata={handleLoadedMetadata}
            onError={handleError}
            muted
            className={`w-full h-full rounded-lg cursor-pointer ${
              isLoaded ? 'block' : 'hidden'
            }`}
          >
            <source src={url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {isLoaded && !hasError && (
            <div
              className={playOverlayClasses}
              onClick={() => setIsModalOpen(true)}
            >
              <Button
                variant="secondary"
                appearance="outline"
                className="!p-2 rounded-full ml-1 mt-1"
              >
                <PlayIcon className="h-5 w-5 fill-current ml-0.5" />
              </Button>
            </div>
          )}
        </div>
      </div>
      <MediaModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <MediaSkeleton isLoading={!isLoaded} hasError={hasError} />
        <video
          controls
          autoPlay
          onLoadedMetadata={handleLoadedMetadata}
          onError={handleError}
          className={`max-w-full max-h-full rounded-lg ${isLoaded ? 'block' : 'hidden'}`}
        >
          <source src={url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </MediaModal>
    </>
  );
};

export default VideoMediaModal;
