import { motion } from 'framer-motion';
import { useContact } from '../../../hooks/api/contacts';
import { useFunnelConversation } from '../../../hooks/api/funnels/funnel-conversations';
import { Panel } from 'src/modules/shared/components/molecules/panel';
import { createQuery } from 'src/modules/shared/utils/api/query-builder.util';
import { useContactAddresses } from '../../../hooks/api/contact-addresses';
import { ErrorState } from 'src/modules/shared/components/atoms/states/error-state';
import { ConversationHeader } from './conversation-header';
import { usePhoneContext } from 'src/contexts/phone-context';
import { useGlobalPhoneContext } from 'src/contexts/global-phone-context';
import { ContainerPhone } from 'src/modules/shared/components/organisms/phones/container-phone';
import STATES from 'states/index';
import { useMemo, useEffect } from 'react';
import { standardizePhoneNumber } from 'src/modules/shared/utils/formatting/phone.util';
import { useGlobalCallProviderContext } from 'src/contexts/global-call-provider-context';
import { ConversationMessageBox } from 'src/modules/communications/components/molecules/conversations/message-box';
import { TimelineItemList } from '../../molecules/timeline-item-list';

interface ConversationDetailPanelProps {
  conversationId: number;
  onOpenProfile: (contactId: number) => void;
  onClose: () => void;
}

export const ConversationDetailPanel: React.FC<
  ConversationDetailPanelProps
> = ({ conversationId, onOpenProfile, onClose }) => {
  const {
    conversation,
    loading: loadingConversation,
    error: conversationError,
  } = useFunnelConversation({
    id: conversationId,
  });

  const { contact, loading: loadingContact } = useContact(
    { id: conversation?.contactId ?? 0 },
    !!conversation?.contactId
  );

  const contactAddressesQuery = createQuery()
    .equals('contactId', contact?.id ?? 0)
    .orderByDesc('createdAt')
    .build();
  const { addresses: contactAddresses, loading: loadingAddresses } =
    useContactAddresses(contactAddressesQuery, !!contact?.id);

  const callProvider = useGlobalCallProviderContext();
  const { setGlobalPhoneEnabled } = useGlobalPhoneContext();
  const { phoneState } = usePhoneContext();

  const shouldShowContainerPhone = useMemo(() => {
    if (phoneState === STATES.PHONE.READY) return false;
    if (!contactAddresses?.length) return false;

    const currentNumber =
      phoneState === STATES.PHONE.INCOMING
        ? callProvider.callerNumber
        : callProvider.onCallNumber;

    if (!currentNumber) return false;

    const standardizedCurrentNumber = standardizePhoneNumber(currentNumber);
    if (!standardizedCurrentNumber) return false;

    return contactAddresses.some(
      (addr) =>
        standardizePhoneNumber(addr.address) === standardizedCurrentNumber
    );
  }, [
    contactAddresses,
    callProvider.callerNumber,
    callProvider.onCallNumber,
    phoneState,
  ]);

  useEffect(() => {
    setGlobalPhoneEnabled(!shouldShowContainerPhone);

    return () => {
      setGlobalPhoneEnabled(true);
    };
  }, [shouldShowContainerPhone, setGlobalPhoneEnabled]);

  if (conversationError) {
    return (
      // TODO: check when implementing i18n
      <ErrorState
        title="Error al cargar la conversación"
        description={conversationError.message || 'Ocurrió un error inesperado'}
        actionText="Cerrar"
        onAction={onClose}
        variant="primary"
      />
    );
  }

  const isLoading = loadingConversation || loadingContact || loadingAddresses;

  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ duration: 0.2 }}
      className="h-full flex flex-col bg-white"
    >
      <ConversationHeader
        isLoading={isLoading}
        contact={contact || undefined}
        addresses={contactAddresses}
        funnelId={conversation?.funnelId || undefined}
        funnelStatusId={conversation?.funnelStatusId || undefined}
        userId={conversation?.userId || undefined}
        conversationId={conversationId}
        onOpenProfile={onOpenProfile}
        onClose={onClose}
      />

      <div className="flex-1 bg-white min-h-0">
        <Panel
          neumorphic="inset-sm"
          variant="secondary"
          className="relative flex h-full rounded-none"
        >
          {shouldShowContainerPhone && (
            <ContainerPhone callProvider={callProvider} />
          )}
          <TimelineItemList
            loadingContact={loadingConversation || loadingContact}
            contact={contact || undefined}
          />
        </Panel>
      </div>

      <ConversationMessageBox
        conversationId={conversationId}
        contactAddresses={contactAddresses}
      />
    </motion.div>
  );
};
