import { useState } from 'react';
import { SiWhatsapp } from 'react-icons/si';
import { useAlert } from 'src/contexts/alert-context';
import { FunnelChannelSession, WhatsAppMessageType } from 'src/api/generated';
import Button from 'src/modules/shared/components/atoms/buttons/button';
import { useCreateWhatsAppMessage } from 'src/modules/messaging/whatsapp/hooks/api/messages-mutations';
import { createWhatsAppMessageCacheUpdate } from 'src/modules/communications/utils/cache/whatsapp-update.util';
import { MessageInput } from '../../../../../../shared/messaging/components/molecules/message-input';
import { WhatsAppTemplateModal } from 'src/modules/messaging/whatsapp/components/organisms/templates/template-modal';
import { useMessagingConversation } from '../../../../../../shared/messaging/hooks/api/conversations';
import { MessageStatus } from 'src/api/generated';
import { isExpired } from 'src/modules/shared/utils/expiration.util';
import { Typography } from '@material-tailwind/react';
import { WhatsAppExpirationAlert } from './expiration-alert';
import { PiPaperPlaneRightLight } from 'react-icons/pi';
interface WhatsAppMessageBoxProps {
  toAddress?: string;
  channelSession?: FunnelChannelSession;
}

export const WhatsAppMessageBox: React.FC<WhatsAppMessageBoxProps> = ({
  toAddress,
  channelSession,
}) => {
  const { showAlert } = useAlert();
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);

  const { conversation: messagingConversation } = useMessagingConversation(
    channelSession?.messagingConversationId ?? '',
    !!channelSession?.messagingConversationId
  );

  const { createWhatsAppMessage, loading: isSendingMessage } =
    useCreateWhatsAppMessage(
      (message, context) => {
        createWhatsAppMessageCacheUpdate(message, context);
      },
      (error, context) => {
        console.error('Error sending message:', error);
        if (context.optimisticMessage) {
          createWhatsAppMessageCacheUpdate(
            {
              ...context.optimisticMessage,
              status: MessageStatus.FAILED,
            },
            context
          );
        }
        // TODO: check when implementing i18n
        showAlert(
          'Ocurrió un error al enviar el mensaje. Por favor verifica tu conexión e intenta nuevamente.',
          'error'
        );
      },
      createWhatsAppMessageCacheUpdate
    );

  const {
    id: conversationId,
    integrationProviderId,
    mainParticipant,
  } = messagingConversation ?? {};
  const fromPhoneNumberId = mainParticipant?.providerId;

  const handleSendMessage = (content: string) => {
    console.log({
      conversationId,
      integrationProviderId,
      fromPhoneNumberId,
      toAddress,
    });
    if (
      !conversationId ||
      !integrationProviderId ||
      !fromPhoneNumberId ||
      !toAddress
    ) {
      return;
    }

    createWhatsAppMessage({
      conversationId,
      content,
      integrationProviderId,
      fromPhoneNumberId,
      toPhoneNumber: toAddress,
      type: WhatsAppMessageType.TEXT,
    });
  };

  const isSessionExpired = isExpired(channelSession?.endTime ?? undefined);

  // TODO: check when implementing i18n
  const getPlaceholder = () => {
    if (!channelSession) {
      return 'Inicia una conversación de WhatsApp para enviar mensajes';
    }

    return 'Escribe / para buscar plantillas ⚡, o escribe tu mensaje de WhatsApp...';
  };

  return (
    <div className="space-y-4">
      <WhatsAppExpirationAlert channelSession={channelSession} />

      <div className="flex items-end space-x-2">
        <MessageInput
          onSendMessage={handleSendMessage}
          isSendingMessage={isSendingMessage}
          placeholder={getPlaceholder()}
          disabled={!channelSession || isSessionExpired}
          renderSendButton={({ onClick, disabled }) => (
            <div className="flex flex-col gap-2">
              <Button
                onClick={onClick}
                // TODO: shouldn't be disabled if sending message
                disabled={isSendingMessage || disabled}
                loading={isSendingMessage}
                size="sm"
                className="w-full bg-whatsapp-500 hover:bg-whatsapp-600"
              >
                <PiPaperPlaneRightLight className="h-4 w-4" />
              </Button>

              <Button
                onClick={() => setIsTemplateModalOpen(true)}
                size="sm"
                appearance="outline"
                className="w-full gap-1 border border-whatsapp-500 hover:border-whatsapp-600"
              >
                <SiWhatsapp className="h-3.5 w-3.5 text-whatsapp-500" />
                <Typography className="text-xs font-medium text-whatsapp-500">
                  Plantilla
                </Typography>
              </Button>
            </div>
          )}
        />
      </div>

      <WhatsAppTemplateModal
        isOpen={isTemplateModalOpen}
        onClose={() => setIsTemplateModalOpen(false)}
        fromPhoneNumberId={fromPhoneNumberId}
        toNumber={toAddress}
      />
    </div>
  );
};
