import { ReactNode } from 'react';
import {
  BaseProps,
  ComponentSize,
  ComponentVariant,
} from 'src/modules/shared/types/component.type';

interface CircleDisplayProps extends BaseProps {
  text?: string;
  icon?: ReactNode;
}

export const CircleDisplay: React.FC<CircleDisplayProps> = ({
  text,
  icon,
  size = 'md',
  variant = 'primary',
  className = '',
  disabled = false,
}) => {
  const sizeClasses: Record<ComponentSize, string> = {
    '2xs': 'w-6 h-6',
    xs: 'w-7 h-7',
    sm: 'w-8 h-8',
    md: 'w-10 h-10',
    lg: 'w-12 h-12',
  };

  const textSizeClasses: Record<ComponentSize, string> = {
    '2xs': 'text-2xs',
    xs: 'text-xs',
    sm: 'text-xs',
    md: 'text-sm',
    lg: 'text-base',
  };

  const iconSizeClasses: Record<ComponentSize, string> = {
    '2xs': 'w-3 h-3',
    xs: 'w-3.5 h-3.5',
    sm: 'w-4 h-4',
    md: 'w-5 h-5',
    lg: 'w-6 h-6',
  };

  const variantClasses: Record<ComponentVariant, string> = {
    primary: 'bg-primary-100 text-primary-600',
    secondary: 'bg-secondary-100 text-secondary-600',
    danger: 'bg-danger-100 text-danger-600',
    warning: 'bg-warning-100 text-warning-600',
    neutral: 'bg-gray-100 text-gray-600',
    success: 'bg-success-100 text-success-600',
    info: 'bg-info-100 text-info-600',
  };

  return (
    <div
      className={`
        ${sizeClasses[size]} 
        rounded-full 
        ${variantClasses[variant]}
        flex 
        items-center 
        justify-center 
        font-medium
        transition-all
        duration-200
        ${disabled ? 'opacity-50 cursor-not-allowed' : ''}
        ${className}
      `}
    >
      {icon ? (
        <div
          className={`${iconSizeClasses[size]} flex items-center justify-center`}
        >
          {icon}
        </div>
      ) : (
        <span className={textSizeClasses[size]}>
          {text
            ?.split(' ')
            .map((word) => word[0])
            .join('')
            .toUpperCase()
            .slice(0, 2)}
        </span>
      )}
    </div>
  );
};
