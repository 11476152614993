import React, {
  useState,
  KeyboardEvent,
  useCallback,
  ComponentProps,
} from 'react';
import { PiXLight } from 'react-icons/pi';
import {
  BaseProps,
  ComponentSize,
  ComponentVariant,
} from 'src/modules/shared/types/component.type';
import { getDisabledClasses } from 'src/modules/shared/styles/component.styles';
import Tag from 'src/modules/shared/components/atoms/tag';
import {
  tagInputContainerClasses,
  tagInputClasses,
  tagInputVariantClasses,
  tagInputContainerSizeClasses,
  tagSizeMap,
} from './styles';

type BaseTagInputProps = Omit<
  ComponentProps<'input'>,
  'size' | 'value' | 'onChange'
>;

interface TagInputProps extends BaseProps<BaseTagInputProps> {
  value: string[];
  onChange: (value: string[]) => void;
  size?: ComponentSize;
  variant?: ComponentVariant;
  containerClassName?: string;
  validateValue?: (value: string) => boolean;
  formatValue?: (value: string) => string;
}

export const TagInput: React.FC<TagInputProps> = ({
  value = [],
  onChange,
  size = 'md',
  variant = 'primary',
  disabled = false,
  loading = false,
  className = '',
  containerClassName = '',
  // TODO: check when implementing i18n
  placeholder = 'Escribe y presiona Enter o Espacio',
  validateValue = () => true,
  formatValue = (v: string) => v,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState('');

  const containerClasses = [
    ...tagInputContainerClasses,
    tagInputVariantClasses[variant],
    tagInputContainerSizeClasses[size],
    getDisabledClasses(disabled || loading),
    containerClassName,
  ].join(' ');

  const inputClasses = [...tagInputClasses, className].join(' ');

  const addTag = useCallback(
    (tag: string) => {
      const trimmedTag = tag.trim();

      const isValidTag =
        trimmedTag && validateValue(trimmedTag) && !value.includes(trimmedTag);

      if (isValidTag) {
        const formattedValue = formatValue(trimmedTag);
        onChange([...value, formattedValue]);
      }
    },
    [value, onChange, validateValue, formatValue]
  );

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const isEmptyInput = !target.value;
    const hasTags = value.length > 0;

    switch (e.key) {
      case 'Enter':
      case ' ': {
        e.preventDefault();
        addTag(target.value);
        setInputValue('');
        break;
      }
      case 'Backspace': {
        if (isEmptyInput && hasTags) {
          onChange(value.slice(0, -1));
        }
        break;
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    const tags = pastedText.split(/[,\s]+/);

    tags.forEach((tag) => addTag(tag));
    setInputValue('');
  };

  const removeTag = (indexToRemove: number) => {
    onChange(value.filter((_, index) => index !== indexToRemove));
  };

  return (
    <div className={containerClasses}>
      <div className="flex flex-wrap gap-1.5 items-center">
        {value.map((tag, index) => (
          <Tag
            key={`${tag}-${index}`}
            content={
              <div className="flex items-center gap-1">
                {tag}
                <PiXLight className="w-3 h-3" />
              </div>
            }
            size={tagSizeMap[size]}
            variant={variant}
            appearance="soft"
            onClick={() => removeTag(index)}
          />
        ))}
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          className={inputClasses}
          disabled={disabled || loading}
          placeholder={value.length === 0 ? placeholder : ''}
          {...rest}
        />
      </div>
    </div>
  );
};
