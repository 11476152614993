/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CallStatus } from './CallStatus';
import type { Dictionary_unknown_ } from './Dictionary_unknown_';

/**
 * Interface representing a call document
 */
export type CallDocument = {
    analysisConfigId?: number;
    tenantId: string;
    createdAt: string;
    updatedAt: string;
    category?: string;
    customData: Dictionary_unknown_;
    providerId: string;
    providerName: string;
    from: string;
    to: string;
    redirectedTo?: string | null;
    duration: number;
    direction: string;
    status: CallStatus;
    startTime: string;
    metadata?: Dictionary_unknown_;
    recordingUrl?: string | null;
    id: string;
    modelType: CallDocument.modelType;
    archivedAt?: string | null;
    eventSequence?: number;
    eventTimestamp?: string;
    funnelId?: string;
    funnelConversationId?: string;
    contactId?: string;
    sessionId?: string;
    userId?: string | null;
    teamId?: string | null;
};

export namespace CallDocument {

    export enum modelType {
        CALL = 'Call',
    }


}

