import { type FC, type ReactNode } from 'react';
import {
  type ComponentSize,
  type ComponentVariant,
} from 'src/modules/shared/types/component.type';

interface StyledIconProps {
  icon: ReactNode;
  variant?: ComponentVariant;
  size?: ComponentSize;
  className?: string;
}

const variantClasses: Record<
  NonNullable<StyledIconProps['variant']>,
  string
> = {
  primary: 'text-primary-500',
  secondary: 'text-gray-500',
  danger: 'text-red-500',
  warning: 'text-warning-500',
  neutral: 'text-gray-300',
  success: 'text-success-500',
  info: 'text-info-500',
};

const sizeClasses: Record<NonNullable<StyledIconProps['size']>, string> = {
  '2xs': 'w-2 h-2',
  xs: 'w-3 h-3',
  sm: 'w-4 h-4',
  md: 'w-5 h-5',
  lg: 'w-6 h-6',
};

export const StyledIcon: FC<StyledIconProps> = ({
  icon,
  variant,
  size = 'md',
  className = '',
}) => {
  return (
    <div
      className={`
        inline-flex items-center justify-center
        ${variant ? variantClasses[variant] : ''}
        ${sizeClasses[size]}
        ${className}
      `}
    >
      {icon}
    </div>
  );
};
