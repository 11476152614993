const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const isValidEmail = (email: string): boolean => {
  return EMAIL_REGEX.test(email);
};

export const normalizeEmail = (email: string): string | undefined => {
  if (!email) {
    return undefined;
  }

  const trimmed = email.trim();
  if (!isValidEmail(trimmed)) {
    return undefined;
  }

  return trimmed.toLowerCase();
};
