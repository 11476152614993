import React from 'react';
import Modal from '../modals/modal';
import { Typography } from '@material-tailwind/react';
import Button from '../../atoms/buttons/button';
import { ComponentVariant } from 'src/modules/shared/types/component.type';

interface DialogProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  description: string;
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirm: () => void | Promise<void>;
  isLoading?: boolean;
  variant?: ComponentVariant;
}

export const Dialog: React.FC<DialogProps> = ({
  isOpen,
  onClose,
  title,
  description,
  // TODO: check when implementing i18n
  confirmLabel = 'Confirmar',
  cancelLabel = 'Cancelar',
  onConfirm,
  isLoading = false,
  variant = 'primary',
}) => {
  const handleConfirm = async () => {
    await onConfirm();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <Typography className="text-lg font-medium text-secondary-800">
          {title}
        </Typography>
      }
      body={
        <div className="w-full">
          <Typography className="text-secondary-600">{description}</Typography>
        </div>
      }
      footer={
        <div className="flex justify-end gap-3 px-4 py-3">
          <Button
            variant="neutral"
            appearance="ghost"
            onClick={onClose}
            disabled={isLoading}
          >
            {cancelLabel}
          </Button>
          <Button
            variant={variant}
            onClick={handleConfirm}
            loading={isLoading}
            disabled={isLoading}
          >
            {confirmLabel}
          </Button>
        </div>
      }
      dialogClassName="max-w-md"
    />
  );
};

export default Dialog;
