import {
  ComponentSize,
  ComponentVariant,
} from 'src/modules/shared/types/component.type';

export const cardVariantClasses: Record<
  ComponentVariant,
  {
    base: string;
    hover: string;
    active: string;
  }
> = {
  primary: {
    base: 'bg-primary-50 shadow-nm-flat-2xs',
    hover: 'hover:shadow-nm-floating-xs',
    active: 'active:shadow-nm-focused-sm',
  },
  secondary: {
    base: 'bg-white shadow-nm-flat-2xs',
    hover: 'hover:shadow-nm-floating-xs',
    active: 'active:shadow-nm-focused-sm',
  },
  danger: {
    base: 'bg-danger-50 shadow-nm-flat-2xs',
    hover: 'hover:shadow-nm-floating-xs',
    active: 'active:shadow-nm-focused-sm',
  },
  warning: {
    base: 'bg-warning-50 shadow-nm-flat-2xs',
    hover: 'hover:shadow-nm-floating-xs',
    active: 'active:shadow-nm-focused-sm',
  },
  neutral: {
    base: 'bg-neutral-50 shadow-nm-flat-2xs',
    hover: 'hover:shadow-nm-floating-xs',
    active: 'active:shadow-nm-focused-sm',
  },
  success: {
    base: 'bg-success-50 shadow-nm-flat-2xs',
    hover: 'hover:shadow-nm-floating-xs',
    active: 'active:shadow-nm-focused-sm',
  },
  info: {
    base: 'bg-info-50 shadow-nm-flat-2xs',
    hover: 'hover:shadow-nm-floating-xs',
    active: 'active:shadow-nm-focused-sm',
  },
};

export const cardSizeClasses: Record<ComponentSize, string> = {
  '2xs': 'p-2',
  xs: 'p-2',
  sm: 'p-3',
  md: 'p-4',
  lg: 'p-6',
};

export const cardBaseClasses = [
  'w-full',
  'rounded-lg',
  'select-none',
  'transition-all',
  'duration-200',
].join(' ');

export const cardHighlightedClass = 'bg-blue-50';
