import { FC } from 'react';
import { Typography } from '@material-tailwind/react';
import { TagDropdown } from 'src/modules/shared/components/molecules/dropdowns/tag-dropdown';
import { useUsers } from 'src/modules/shared/admin/hooks/api/users';
import { useUsersSelect } from 'src/modules/shared/hooks/users/select';
import { UNASSIGNED_USER_ID } from 'src/modules/communications/constants/filters.const';

interface BulkActionsProps {
  selectedCount: number;
  onUserAssign: (userId: number | null) => void;
  isLoading?: boolean;
}

export const BulkActions: FC<BulkActionsProps> = ({
  selectedCount,
  onUserAssign,
  isLoading = false,
}) => {
  const { users } = useUsers();
  const { userOptions } = useUsersSelect({
    users: users || [],
    options: {
      autoSelectCurrentUser: false,
      clearSelectionForAdmin: false,
    },
  });

  if (selectedCount === 0) return null;

  const dropdownOptions = [
    {
      id: UNASSIGNED_USER_ID,
      // TODO: check when implementing i18n
      content: 'Sin asignar',
    },
    ...userOptions.map((opt) => ({
      id: opt.value,
      content: opt.label,
    })),
  ];

  return (
    <div className="flex items-center gap-6 px-8 py-[5.5px] bg-white rounded-full bg-primary-100">
      <Typography className="font-normal text-sm text-secondary-600">
        {/* TODO: check when implementing i18n */}
        {selectedCount} {selectedCount === 1 ? 'seleccionado' : 'seleccionados'}
      </Typography>

      <TagDropdown
        // TODO: check when implementing i18n
        content="Asignar"
        variant="primary"
        appearance="outline"
        size="sm"
        options={dropdownOptions}
        onOptionSelect={(option) =>
          onUserAssign(
            option.id === UNASSIGNED_USER_ID ? null : Number(option.id)
          )
        }
        isLoading={isLoading}
      />
    </div>
  );
};
