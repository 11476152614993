import React from 'react';
import {
  WhatsAppTemplate,
  WhatsAppTemplateMessageComponent,
} from 'src/api/generated';
import { buildTemplateMessageFromComponents } from 'src/modules/messaging/whatsapp/utils/template.util';
import ComponentInputs from './component-inputs';
import { Typography } from '@material-tailwind/react';
import WhatsAppText from '../../messages/whatsapp-text';
import { VARIABLE_REPLACER } from 'src/modules/messaging/whatsapp/utils/messages/format.util';

interface MessagePreviewProps {
  template: WhatsAppTemplate;
  messageComponents: WhatsAppTemplateMessageComponent[];
  onComponentChange: (components: WhatsAppTemplateMessageComponent[]) => void;
}

const MessagePreview: React.FC<MessagePreviewProps> = ({
  template,
  messageComponents,
  onComponentChange,
}) => {
  const messageText = buildTemplateMessageFromComponents(
    template,
    messageComponents,
    VARIABLE_REPLACER
  );

  return (
    <div className="flex flex-col space-y-4 p-3">
      {/* TODO: check when implementing i18n */}
      <Typography className="text-md font-medium">
        Vista previa del mensaje
      </Typography>
      <ComponentInputs
        messageComponents={messageComponents}
        onComponentChange={onComponentChange}
      />
      <div className="py-6 px-8 bg-gray-100 rounded-lg shadow-sm">
        <WhatsAppText text={messageText} />
      </div>
    </div>
  );
};

export default MessagePreview;
