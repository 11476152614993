import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Contact,
  CrmContactsService,
  ContactDocument,
} from 'src/api/generated';
import {
  getModelFromCache,
  updateCache,
} from 'src/modules/shared/utils/cache/update.util';
import {
  createOptimisticContact,
  createOptimisticContactDocument,
  UpdateContactParams,
} from '../../utils/cache/contacts/optimistic.util';

interface UseUpdateContactMutationResponse {
  updateContact: (params: UpdateContactParams) => Promise<Contact>;
  loading: boolean;
  error: Error | null;
}

export const useUpdateContactMutation = (
  onSuccess: (updatedContact: Contact) => void = () => {},
  onError: (error: Error) => void = () => {}
): UseUpdateContactMutationResponse => {
  const queryClient = useQueryClient();

  const updateContact = async (
    params: UpdateContactParams
  ): Promise<Contact> => {
    return await CrmContactsService.updateContact(params.id, params.data);
  };

  const {
    mutateAsync,
    isPending: loading,
    error,
  } = useMutation({
    mutationFn: updateContact,
    onMutate: async (params) => {
      await queryClient.cancelQueries({
        queryKey: ['crm/documents/contacts'],
      });
      await queryClient.cancelQueries({
        queryKey: ['crm/contacts'],
      });

      const previousContactDocument = getModelFromCache<ContactDocument>(
        queryClient,
        ['crm/documents/contacts'],
        params.id
      );
      const previousContact = getModelFromCache<Contact>(
        queryClient,
        ['crm/contacts'],
        params.id
      );

      if (previousContactDocument) {
        const optimisticContactDocument = createOptimisticContactDocument(
          params,
          previousContactDocument
        );
        updateCache({
          queryClient,
          queryKey: ['crm/documents/contacts'],
          updatedData: optimisticContactDocument,
        });
      }
      if (previousContact) {
        const optimisticContact = createOptimisticContact(
          params,
          previousContact
        );
        updateCache({
          queryClient,
          queryKey: ['crm/contacts'],
          updatedData: optimisticContact,
        });
      }

      return {
        previousContactDocument,
        previousContact,
      };
    },
    onError: (error, _variables, context) => {
      if (context?.previousContactDocument) {
        updateCache({
          queryClient,
          queryKey: ['crm/documents/contacts'],
          updatedData: context.previousContactDocument,
        });
      }
      if (context?.previousContact) {
        updateCache({
          queryClient,
          queryKey: ['crm/contacts'],
          updatedData: context.previousContact,
        });
      }

      onError(error);
    },
    onSuccess: (data, _variables, context) => {
      if (context?.previousContactDocument) {
        const realContactDocument = createOptimisticContactDocument(
          {
            id: Number(context.previousContactDocument.id),
            data,
          },
          context.previousContactDocument
        );
        updateCache({
          queryClient,
          queryKey: ['crm/documents/contacts'],
          updatedData: realContactDocument,
        });
      }
      if (context?.previousContact) {
        const realContact = createOptimisticContact(
          {
            id: Number(context.previousContact.id),
            data,
          },
          context.previousContact
        );
        updateCache({
          queryClient,
          queryKey: ['crm/contacts'],
          updatedData: realContact,
        });
      }

      onSuccess(data);
    },
  });

  return {
    updateContact: mutateAsync,
    loading,
    error,
  };
};
