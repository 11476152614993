/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BulkConversationPassthroughCreateParams } from '../models/BulkConversationPassthroughCreateParams';
import type { BulkConversationPassthroughUpdateParams } from '../models/BulkConversationPassthroughUpdateParams';
import type { BulkOperationResponse } from '../models/BulkOperationResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class V1ConversationsService {

    /**
     * Create multiple conversations in a single request.
     *
     * This endpoint allows you to create multiple conversations at once. Each conversation in the batch
     * will be validated before processing. The response includes details about any validation issues
     * encountered during processing.
     *
     * If some conversations fail validation, the valid ones will still be processed.
     * @param requestBody
     * @returns BulkOperationResponse Ok
     * @throws ApiError
     */
    public static bulkCreate(
        requestBody: BulkConversationPassthroughCreateParams,
    ): CancelablePromise<BulkOperationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/conversations/bulk',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Error`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update multiple conversations in a single request.
     *
     * This endpoint allows you to update multiple existing conversations at once. Each conversation
     * update in the batch will be validated before processing. The response includes details about
     * any validation issues encountered during processing.
     *
     * You must provide the ID for each conversation you want to update. If some conversations fail
     * validation, the valid ones will still be processed.
     * @param requestBody
     * @returns BulkOperationResponse Ok
     * @throws ApiError
     */
    public static bulkUpdate(
        requestBody: BulkConversationPassthroughUpdateParams,
    ): CancelablePromise<BulkOperationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/conversations/bulk/update',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Validation Error`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }

}
