import { FC, useMemo } from 'react';
import { PiArrowsDownUpLight, PiPlusLight, PiXLight } from 'react-icons/pi';
import { FilterCondition } from './filter-condition';
import { FilterValueInput } from './filter-value-input';
import Button from '../../../atoms/buttons/button';
import {
  FilterFieldOptions,
  Filter,
  FilterValue,
  UserOption,
  ListOption,
  StatusOption,
  FilterOption,
} from 'src/modules/shared/types/filters.types';
import { getDefaultValueForFieldType } from 'src/modules/shared/utils/filters/values.util';
import { SearcherDocumentSearchFilterOperator } from 'src/api/generated';
import { Typography } from '@material-tailwind/react';
import { EmptyState } from '../../../atoms/states/empty-state';
import { useCustomFieldListOptions } from 'src/modules/communications/hooks/api/custom-fields/list-options';
import { createQuery } from 'src/modules/shared/utils/api/query-builder.util';
import { updateFilterValue } from 'src/modules/shared/utils/filters/updates.util';
import { updateFilterOperator } from 'src/modules/shared/utils/filters/updates.util';

export interface FilterBuilderProps {
  fieldsOptions: FilterFieldOptions[];
  filters: Filter[];
  onChange: (fields: Filter[]) => void;
  userOptions?: UserOption[];
  statusOptions?: StatusOption[];
}

export const FilterBuilder: FC<FilterBuilderProps> = ({
  fieldsOptions,
  filters,
  onChange,
  userOptions = [],
  statusOptions = [],
}) => {
  const listDefinitionIds = useMemo(() => {
    const listFields = fieldsOptions.filter((field) => field.type === 'list');
    const definitionIds = listFields.map((field) => field.definitionId);
    const validDefinitionIds = definitionIds.filter(
      (id): id is number => id !== undefined
    );

    return validDefinitionIds;
  }, [fieldsOptions]);

  const optionsQuery = useMemo(() => {
    if (listDefinitionIds.length === 0) return null;

    return createQuery()
      .equals('definitionId', listDefinitionIds)
      .take(100)
      .build();
  }, [listDefinitionIds]);

  const { options: listOptions } = useCustomFieldListOptions({
    ...(optionsQuery || {}),
    enabled: listDefinitionIds.length > 0,
  });

  const optionsMap = useMemo(() => {
    const map: Record<number, Array<ListOption>> = {};

    listOptions.forEach((option) => {
      if (!map[option.definitionId]) {
        map[option.definitionId] = [];
      }

      map[option.definitionId].push({
        definitionId: option.definitionId,
        label: option.label || option.value,
        value: option.value,
      });
    });

    return map;
  }, [listOptions]);

  const getOptionsForField = (
    fieldOption?: FilterFieldOptions
  ): FilterOption[] => {
    if (!fieldOption) return [];

    if (fieldOption.type === 'user') {
      return userOptions;
    }

    if (fieldOption.type === 'status') {
      return statusOptions.map((status) => ({
        id: status.id,
        label: status.name,
        value: String(status.id),
      }));
    }

    if (fieldOption.type === 'list' && fieldOption.definitionId) {
      return optionsMap[fieldOption.definitionId] || [];
    }

    return [];
  };

  const handleAddFilterField = () => {
    if (fieldsOptions.length === 0) return;

    const firstField = fieldsOptions[0];
    const defaultValue = getDefaultValueForFieldType(
      firstField.type,
      getOptionsForField(firstField)
    );

    const newFilter: Filter = {
      id: `filter-${Date.now()}-${firstField.id}`,
      fieldOptionId: firstField.id,
      operator: SearcherDocumentSearchFilterOperator.EQ,
      value: defaultValue,
    };

    onChange([...filters, newFilter]);
  };

  const handleRemoveFilterField = (filterId: string) => {
    const remainingFields = filters.filter((filter) => filter.id !== filterId);
    onChange(remainingFields);
  };

  const handleFieldChange = (filterId: string, fieldOptionId: string) => {
    const fieldOption = fieldsOptions.find(
      (option) => option.id === fieldOptionId
    );
    if (!fieldOption) return;

    const defaultValue = getDefaultValueForFieldType(
      fieldOption.type,
      getOptionsForField(fieldOption)
    );

    onChange(
      filters.map((filter) => {
        if (filter.id !== filterId) return filter;

        return {
          ...filter,
          fieldOptionId: fieldOptionId,
          operator: SearcherDocumentSearchFilterOperator.EQ,
          value: defaultValue,
        };
      })
    );
  };

  const handleOperatorChange = (
    filterId: string,
    operator: SearcherDocumentSearchFilterOperator
  ) => {
    onChange(
      filters.map((filter) => {
        if (filter.id !== filterId) return filter;

        const fieldOption = fieldsOptions.find(
          (option) => option.id === filter.fieldOptionId
        );

        return updateFilterOperator(
          filter,
          operator,
          getOptionsForField,
          fieldOption
        );
      })
    );
  };

  const handleValueChange = (filterId: string, value: FilterValue) => {
    onChange(
      filters
        .map((filter) => {
          if (filter.id !== filterId) return filter;
          return updateFilterValue(filter, value);
        })
        .filter((filter): filter is Filter => filter !== null)
    );
  };

  const handleToggleOr = (filterId: string) => {
    onChange(
      filters.map((filter) => {
        if (filter.id !== filterId) return filter;

        return { ...filter, isOr: !filter.isOr };
      })
    );
  };

  return (
    <div className="flex flex-col space-y-4">
      {filters.length === 0 ? (
        <EmptyState
          // TODO: check when implementing i18n
          description="Agrega un filtro para empezar a buscar"
          className="py-3"
        />
      ) : (
        <div className="space-y-2">
          {filters.map((filter, index) => {
            const fieldOption = fieldsOptions.find(
              (fieldOption) => fieldOption.id === filter.fieldOptionId
            );

            return (
              <div
                key={`${filter.id}-${filter.fieldOptionId}`}
                className="group flex items-center gap-2"
              >
                <div className="w-12 flex-shrink-0 text-right">
                  {index === 0 ? (
                    // TODO: check when implementing i18n
                    <Typography className="text-sm font-medium text-secondary-600">
                      Si
                    </Typography>
                  ) : (
                    <Button
                      variant={filter.isOr ? 'warning' : 'info'}
                      appearance="soft"
                      size="sm"
                      onClick={() => handleToggleOr(filter.id)}
                    >
                      {/* TODO: check when implementing i18n */}
                      <span className="flex items-center gap-1">
                        {/* TODO: check when implementing i18n */}
                        <Typography className="text-xs font-medium">
                          {filter.isOr ? 'O' : 'Y'}
                        </Typography>
                        <PiArrowsDownUpLight className="w-4 h-4" />
                      </span>
                    </Button>
                  )}
                </div>

                <div className="flex-1 flex items-center gap-2 p-2">
                  <FilterCondition
                    fieldsOptions={fieldsOptions}
                    selectedFieldId={filter.fieldOptionId}
                    selectedOperator={filter.operator}
                    onFieldChange={(fieldOptionId) =>
                      handleFieldChange(filter.id, fieldOptionId)
                    }
                    onOperatorChange={(operator) =>
                      handleOperatorChange(filter.id, operator)
                    }
                  />

                  <FilterValueInput
                    field={fieldOption}
                    operator={filter.operator}
                    value={filter.value}
                    onChange={(value) => handleValueChange(filter.id, value)}
                    options={getOptionsForField(fieldOption)}
                  />
                </div>

                <Button
                  variant="secondary"
                  appearance="ghost"
                  size="sm"
                  onClick={() => handleRemoveFilterField(filter.id)}
                  aria-label="Remove filter"
                >
                  <PiXLight className="w-4 h-4" />
                </Button>
              </div>
            );
          })}
        </div>
      )}

      <div className="flex justify-center">
        <Button appearance="ghost" size="sm" onClick={handleAddFilterField}>
          <PiPlusLight className="w-4 h-4" />
          {/* TODO: check when implementing i18n */}
          <Typography className="text-xs font-normal">
            Agregar filtro
          </Typography>
        </Button>
      </div>
    </div>
  );
};
