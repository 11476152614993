import React, { ReactNode } from 'react';

interface PaginationBarProps {
  start?: ReactNode;
  center?: ReactNode;
  end?: ReactNode;
  className?: string;
}

export const PaginationBar: React.FC<PaginationBarProps> = ({
  start,
  center,
  end,
  className = '',
}) => {
  const containerClasses =
    'flex items-center justify-between px-4 py-2 bg-white';

  return (
    <div className={`${containerClasses} ${className}`}>
      {start && <div className="flex items-center gap-4">{start}</div>}

      {center && (
        <div className="flex-1 flex items-center justify-center">{center}</div>
      )}

      {end && <div className="flex items-center gap-1">{end}</div>}
    </div>
  );
};
