import React from 'react';
import {
  type ComponentVariant,
  type ComponentSize,
} from 'src/modules/shared/types/component.type';
import { PiImage, PiVideo, PiFileAudio, PiFile } from 'react-icons/pi';

interface MediaSkeletonProps {
  variant?: ComponentVariant;
  className?: string;
  type?: 'image' | 'video' | 'audio' | 'file';
  size?: ComponentSize;
}

export const MediaSkeleton: React.FC<MediaSkeletonProps> = ({
  variant = 'secondary',
  className = '',
  type = 'file',
  size = 'md',
}) => {
  const variantClasses: Record<ComponentVariant, string> = {
    primary: 'bg-secondary-200',
    secondary: 'bg-secondary-200',
    danger: 'bg-danger-200',
    neutral: 'bg-neutral-200',
    warning: 'bg-warning-200',
    success: 'bg-success-200',
    info: 'bg-info-200',
  };

  const sizeClasses: Record<ComponentSize, Record<string, string>> = {
    '2xs': {
      image: 'min-h-[100px] min-w-[75px] aspect-[3/4]',
      video: 'min-h-[100px] min-w-[75px] aspect-[3/4]',
      audio: 'min-h-[48px] min-w-[200px] aspect-[5/1]',
      file: 'min-h-[48px] min-w-[200px] aspect-[5/1]',
    },
    xs: {
      image: 'min-h-[200px] min-w-[150px] aspect-[3/4]',
      video: 'min-h-[200px] min-w-[150px] aspect-[3/4]',
      audio: 'min-h-[48px] min-w-[200px] aspect-[5/1]',
      file: 'min-h-[48px] min-w-[200px] aspect-[5/1]',
    },
    sm: {
      image: 'min-h-[300px] min-w-[225px] aspect-[3/4]',
      video: 'min-h-[300px] min-w-[225px] aspect-[3/4]',
      audio: 'min-h-[48px] min-w-[200px] aspect-[5/1]',
      file: 'min-h-[48px] min-w-[200px] aspect-[5/1]',
    },
    md: {
      image: 'min-h-[400px] min-w-[300px] aspect-[3/4]',
      video: 'min-h-[400px] min-w-[300px] aspect-[3/4]',
      audio: 'min-h-[48px] min-w-[200px] aspect-[5/1]',
      file: 'min-h-[48px] min-w-[200px] aspect-[5/1]',
    },
    lg: {
      image: 'min-h-[500px] min-w-[375px] aspect-[3/4]',
      video: 'min-h-[500px] min-w-[375px] aspect-[3/4]',
      audio: 'min-h-[48px] min-w-[200px] aspect-[5/1]',
      file: 'min-h-[48px] min-w-[200px] aspect-[5/1]',
    },
  };

  const iconSizeClasses: Record<ComponentSize, Record<string, string>> = {
    '2xs': {
      image: 'w-6 h-6',
      video: 'w-6 h-6',
      audio: 'w-5 h-5',
      file: 'w-5 h-5',
    },
    xs: {
      image: 'w-8 h-8',
      video: 'w-8 h-8',
      audio: 'w-5 h-5',
      file: 'w-5 h-5',
    },
    sm: {
      image: 'w-10 h-10',
      video: 'w-10 h-10',
      audio: 'w-5 h-5',
      file: 'w-5 h-5',
    },
    md: {
      image: 'w-12 h-12',
      video: 'w-12 h-12',
      audio: 'w-5 h-5',
      file: 'w-5 h-5',
    },
    lg: {
      image: 'w-16 h-16',
      video: 'w-16 h-16',
      audio: 'w-5 h-5',
      file: 'w-5 h-5',
    },
  };

  const iconMap = {
    image: PiImage,
    video: PiVideo,
    audio: PiFileAudio,
    file: PiFile,
  };

  const Icon = iconMap[type];

  return (
    <div
      className={`
        animate-pulse rounded-lg w-full h-full relative
        ${variantClasses[variant]}
        ${sizeClasses[size][type]}
        ${className}
      `.trim()}
    >
      <div className="absolute inset-0 flex items-center justify-center translate-x-[2px] translate-y-[2px]">
        <Icon className={`${iconSizeClasses[size][type]} text-secondary-300`} />
      </div>
    </div>
  );
};
