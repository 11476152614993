import { ComponentSize, ComponentVariant } from '../types/component.type';

export const baseInputClasses = [
  'w-full',
  'rounded-md',
  'transition-all',
  'duration-200',
  'outline-none',
  'focus:outline-none',
  'focus:ring-2',
  'focus:ring-offset-2',
  'border',
];

export const variantClasses: Record<NonNullable<ComponentVariant>, string> = {
  primary:
    'bg-primary-600 text-white hover:bg-primary-700 active:bg-primary-800 focus:ring-primary-500',
  secondary:
    'bg-white text-secondary-500 border border-secondary-300 hover:bg-secondary-50',
  danger:
    'bg-danger-500 text-white hover:bg-danger-600 active:bg-danger-700 focus:ring-danger-500',
  warning:
    'bg-warning-500 text-white hover:bg-warning-600 active:bg-warning-700 focus:ring-warning-500',
  neutral:
    'bg-transparent text-neutral-600 hover:bg-neutral-100 active:bg-neutral-200',
  success:
    'bg-success-500 text-white hover:bg-success-600 active:bg-success-700 focus:ring-success-500',
  info: 'bg-blue-500 text-white hover:bg-blue-600 active:bg-blue-700 focus:ring-blue-500',
};

export const inputVariantClasses: Record<
  NonNullable<ComponentVariant>,
  string
> = {
  primary: 'border-neutral-300 focus:border-primary-500 focus:ring-primary-500',
  secondary:
    'border-neutral-300 focus:border-secondary-600 focus:ring-secondary-500',
  danger: 'border-danger-300 focus:border-danger-500 focus:ring-danger-500',
  warning: 'border-warning-300 focus:border-warning-500 focus:ring-warning-500',
  neutral:
    'border-transparent bg-neutral-100 focus:bg-white focus:border-neutral-300',
  success: 'border-success-300 focus:border-success-500 focus:ring-success-500',
  info: 'border-blue-300 focus:border-blue-500 focus:ring-blue-500',
};

export const inputSizeClasses: Record<NonNullable<ComponentSize>, string> = {
  '2xs': 'h-6 px-1.5 text-xs',
  xs: 'h-6 px-1.5 text-xs',
  sm: 'h-7 px-2 text-xs',
  md: 'h-8 px-2.5 text-sm',
  lg: 'h-10 px-3.5 text-sm',
};

export const labelSizeClasses: Record<NonNullable<ComponentSize>, string> = {
  '2xs': 'text-xs',
  xs: 'text-xs',
  sm: 'text-sm',
  md: 'text-sm',
  lg: 'text-base',
};

export function getDisabledClasses(isDisabled: boolean) {
  return isDisabled ? 'opacity-50 cursor-not-allowed' : '';
}

export function getErrorClasses(hasError: boolean) {
  return hasError
    ? 'border-danger-500 focus:border-danger-500 focus:ring-danger-500'
    : '';
}
