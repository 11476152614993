import { SearcherDocumentSearchFilter } from 'src/api/generated';
import { Filter } from '../../types/filters.types';
import { isFilterValue } from './values.util';

/**
 * Convert a FilterField to an API filter
 */
export const toApiFilter = (filter: Filter): SearcherDocumentSearchFilter => {
  return {
    field: filter.fieldOptionId,
    operator: filter.operator,
    or: filter.isOr,
    value: filter.value,
  };
};

/**
 * Convert an API filter to a FilterField
 */
export const fromApiFilter = (
  filter: SearcherDocumentSearchFilter,
  id: string
): Filter => {
  return {
    id,
    fieldOptionId: filter.field,
    operator: filter.operator,
    value: isFilterValue(filter.value) ? filter.value : undefined,
    isOr: filter.or,
  };
};
