/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ContactAddress } from './ContactAddress';
import type { Dictionary_unknown_ } from './Dictionary_unknown_';

/**
 * Interface representing a contact document
 */
export type ContactDocument = {
    tenantId: string;
    createdAt: string;
    updatedAt: string;
    providerId?: string | null;
    providerName?: string | null;
    metadata: any;
    customFields?: Dictionary_unknown_;
    /**
     * @deprecated
     */
    email?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    addresses?: Array<ContactAddress>;
    nationalId?: string | null;
    /**
     * @deprecated
     */
    phone?: string | null;
    companyPosition?: string | null;
    id: string;
    modelType: ContactDocument.modelType;
    archivedAt?: string | null;
    eventSequence?: number;
    eventTimestamp?: string;
    userId?: string | null;
    contactCompanyId?: string | null;
};

export namespace ContactDocument {

    export enum modelType {
        CONTACT = 'Contact',
    }


}

