import { CustomField } from '../../../types/custom-fields.type';
import {
  InlineTextInput,
  InlineNumberInput,
  InlineBooleanInput,
  InlineDateInput,
  InlineSelectInput,
} from 'src/modules/shared/components/molecules/inputs/inline';
import {
  formatCustomFieldValue,
  parseCustomFieldValue,
} from '../../../utils/custom-fields.util';
import { CustomFieldType } from 'src/api/generated';

interface CustomFieldEditorProps {
  field: CustomField;
  onSave: (value: unknown) => Promise<void>;
  disabled?: boolean;
  loading?: boolean;
  readOnly?: boolean;
}

export const CustomFieldEditor: React.FC<CustomFieldEditorProps> = ({
  field,
  onSave,
  disabled = false,
  readOnly = false,
  loading = false,
}) => {
  const handleSave = async (value: string) => {
    const parsedValue = parseCustomFieldValue(value, field.type);
    await onSave(parsedValue);
  };

  const stringValue = formatCustomFieldValue(field.value, field.type);
  const isDisabled = disabled || readOnly;

  const options =
    field.options?.map((option) => ({
      label: option.label,
      value: option.value,
    })) || [];

  switch (field.type) {
    case CustomFieldType.NUMBER:
      return (
        <InlineNumberInput
          label={field.name}
          value={stringValue}
          onSave={handleSave}
          disabled={isDisabled}
          loading={loading}
        />
      );
    case CustomFieldType.BOOLEAN:
      return (
        <InlineBooleanInput
          label={field.name}
          value={stringValue}
          onSave={handleSave}
          disabled={isDisabled}
          loading={loading}
        />
      );
    case CustomFieldType.DATE:
      return (
        <InlineDateInput
          label={field.name}
          value={stringValue}
          onSave={handleSave}
          disabled={isDisabled}
          loading={loading}
        />
      );
    case CustomFieldType.LIST:
      return (
        <InlineSelectInput
          label={field.name}
          value={stringValue}
          options={options}
          onSave={handleSave}
          disabled={isDisabled}
          loading={loading}
        />
      );
    case CustomFieldType.STRING:
    default:
      return (
        <InlineTextInput
          label={field.name}
          value={stringValue}
          onSave={handleSave}
          disabled={isDisabled}
          loading={loading}
        />
      );
  }
};
