import { useState } from 'react';

/**
 * Hook for copying text to clipboard with visual feedback
 * @param copyDuration Duration in ms to show "copied" feedback
 * @returns Object with copy function and copied state
 */
export function useCopyToClipboard(copyDuration = 2000) {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = async (text?: string) => {
    if (!text) return false;

    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), copyDuration);
      return true;
    } catch (error) {
      console.error('Error copying to clipboard:', error);
      return false;
    }
  };

  return { copyToClipboard, isCopied };
}
