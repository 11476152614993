import React from 'react';
import {
  BaseProps,
  ComponentVariant,
} from 'src/modules/shared/types/component.type';
import { labelSizeClasses } from 'src/modules/shared/styles/component.styles';
import { Typography } from '@material-tailwind/react';

interface LabelProps extends BaseProps {
  onClick?: () => void;
  title?: string;
}

const Label: React.FC<LabelProps> = ({
  children,
  className = '',
  variant = 'primary',
  size = 'md',
  onClick,
  title,
}) => {
  const baseClasses = ['transition-colors', 'duration-200', 'font-medium'];

  const variantTextColors: Record<ComponentVariant, string> = {
    primary: 'text-secondary-700',
    secondary: 'text-secondary-600',
    danger: 'text-danger-600',
    warning: 'text-warning-600',
    neutral: 'text-neutral-500',
    success: 'text-success-600',
    info: 'text-blue-600',
  };

  const classes = [
    ...baseClasses,
    variantTextColors[variant],
    labelSizeClasses[size],
    className,
  ].join(' ');

  return (
    <Typography as="label" className={classes} onClick={onClick} title={title}>
      {children}
    </Typography>
  );
};

export default Label;
