import { useNavigate, useSearch } from '@tanstack/react-router';
import ContactDetailPanel from '../organisms/contact-detail-panel';
import { useEffect } from 'react';
import {
  Route as CommunicationsRoute,
  CommunicationsSearch,
} from 'src/routes/communications';
import { useFunnels } from 'src/modules/communications/hooks/api';
import { ConversationDetailPanel } from '../organisms/conversation-detail-panel';
import ConversationPanel from '../organisms/conversation-panel/conversation-panel';
import Loading from 'src/modules/shared/components/atoms/loading';
import { Typography } from '@material-tailwind/react';
import { createQuery } from 'src/modules/shared/utils/api/query-builder.util';

interface CommunicationsPageProps {}

const CommunicationsPage: React.FC<CommunicationsPageProps> = () => {
  const navigate = useNavigate();
  const { funnelId, conversationId, contactId } = useSearch({
    from: CommunicationsRoute.id,
    select: (search: CommunicationsSearch) => ({
      view: search.view,
      funnelId: search.funnelId,
      conversationId: search.conversationId,
      contactId: search.contactId,
    }),
  });
  const funnelsQuery = createQuery().orderByDesc('createdAt').build();
  const { funnels, loading: loadingFunnels } = useFunnels(funnelsQuery);

  useEffect(() => {
    const omitFirstFunnelSelection =
      loadingFunnels || funnels.length === 0 || funnelId;
    if (omitFirstFunnelSelection) return;

    navigate({
      to: CommunicationsRoute.to,
      search: (prev) => ({
        ...(prev as CommunicationsSearch),
        view: (prev as CommunicationsSearch).view,
        funnelId: funnels[0].id,
      }),
    });
  }, [loadingFunnels, funnels, funnelId, navigate]);

  const handleFunnelSelect = (id: number) => {
    navigate({
      to: CommunicationsRoute.to,
      search: (prev) => {
        const {
          conversationId: _,
          contactId: __,
          ...rest
        } = prev as CommunicationsSearch;

        return {
          ...rest,
          funnelId: id,
        };
      },
    });
  };

  const handleConversationSelect = (id: number) => {
    navigate({
      to: CommunicationsRoute.to,
      search: (prev) => {
        const { contactId: _, ...rest } = prev as CommunicationsSearch;

        return {
          ...rest,
          conversationId: id,
        };
      },
    });
  };

  const handleConversationClose = () => {
    navigate({
      to: CommunicationsRoute.to,
      search: (prev) => {
        const {
          conversationId: _,
          contactId: __,
          ...rest
        } = prev as CommunicationsSearch;

        return rest;
      },
    });
  };

  const handleOpenContactProfile = (id: number) => {
    navigate({
      to: CommunicationsRoute.to,
      search: (prev) => ({
        ...(prev as CommunicationsSearch),
        contactId: id,
      }),
    });
  };

  const handleCloseContactProfile = () => {
    navigate({
      to: CommunicationsRoute.to,
      search: (prev) => {
        const { contactId: _, ...rest } = prev as CommunicationsSearch;

        return rest;
      },
    });
  };

  const getConversationListWidth = () => {
    if (!conversationId && !contactId) return 'w-full';
    if (conversationId && contactId) return 'w-5/12';
    if (conversationId) return 'w-7/12';
    return 'w-9/12';
  };

  const getConversationDetailWidth = () => {
    if (!conversationId) return 'w-0 hidden';
    if (!contactId) return 'w-5/12';
    return 'w-4/12';
  };

  const getContactProfileWidth = () => {
    if (!contactId) return 'w-0 hidden';
    return 'w-3/12';
  };

  if (loadingFunnels) {
    return (
      <div className="flex items-center justify-center h-full">
        <Loading />
      </div>
    );
  }

  if (funnels.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        {/* TODO: check when implementing i18n */}
        <Typography>No hay embudos disponibles</Typography>
        <Typography>Crea un embudo para comenzar</Typography>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full">
      {funnelId && (
        <ConversationPanel
          funnels={funnels}
          selectedFunnelId={funnelId}
          onFunnelSelect={handleFunnelSelect}
          onConversationSelect={handleConversationSelect}
          selectedConversationId={conversationId}
          layout={{
            conversationListWidth: getConversationListWidth(),
            conversationDetailWidth: getConversationDetailWidth(),
            contactProfileWidth: getContactProfileWidth(),
          }}
        >
          {conversationId && (
            <div
              className={`${getConversationDetailWidth()} transition-all duration-200`}
            >
              <ConversationDetailPanel
                conversationId={conversationId}
                onOpenProfile={handleOpenContactProfile}
                onClose={handleConversationClose}
              />
            </div>
          )}
          {contactId && (
            <div
              className={`${getContactProfileWidth()} transition-all duration-200`}
            >
              <ContactDetailPanel
                onClose={handleCloseContactProfile}
                contactId={contactId}
                conversationId={conversationId}
              />
            </div>
          )}
        </ConversationPanel>
      )}
    </div>
  );
};

export default CommunicationsPage;
