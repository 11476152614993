import React, { useState } from 'react';
import TextArea from 'src/modules/shared/components/atoms/inputs/text-area';
import { Template } from 'src/api/generated';
import { useThrottle } from '@uidotdev/usehooks';
import { useTemplateSuggestions } from '../../../hooks/templates/suggestions';
import TemplateSuggestions from '../templates/template-suggestions';
import TemplateModal from '../../organisms/templates/template-modal';

interface MessageInputProps {
  onSendMessage: (message: string) => void;
  isSendingMessage?: boolean;
  disabled?: boolean;
  placeholder?: string;
  renderSendButton?: (props: {
    onClick: () => void;
    disabled: boolean;
  }) => React.ReactNode;
}

const SEARCH_THROTTLE_MS = 1000;

export const MessageInput: React.FC<MessageInputProps> = ({
  onSendMessage,
  isSendingMessage = false,
  disabled = false,
  placeholder = 'Type / to search templates, or write your message...',
  renderSendButton,
}) => {
  const [message, setMessage] = useState('');
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
  const throttledMessage = useThrottle(message, SEARCH_THROTTLE_MS);

  const handleTemplateSelect = (template: Template) => {
    const words = message.split(' ');
    words.pop();
    const newMessage = [...words, template.content].join(' ');
    setMessage(newMessage);
  };

  const {
    isOpen: isSuggestionsOpen,
    suggestions,
    selectedIndex,
    loading: isLoadingSuggestions,
    hasMorePages,
    handleKeyDown,
    fetchMore,
    handleClose,
  } = useTemplateSuggestions({
    inputValue: throttledMessage,
    onSelect: handleTemplateSelect,
  });

  const handleSubmit = () => {
    if (!message.trim() || isSendingMessage || disabled) return;
    onSendMessage(message.trim());
    setMessage('');
  };

  return (
    <>
      <div className="flex-1 relative">
        <div className="flex gap-2">
          <TextArea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={(e) => {
              if (isSuggestionsOpen) {
                handleKeyDown(e);
              } else if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSubmit();
              }
            }}
            placeholder={placeholder}
            disabled={disabled}
            rows={3}
            className="flex-1"
          />
          {renderSendButton?.({
            onClick: handleSubmit,
            disabled: !message.trim() || isSendingMessage || disabled,
          })}
        </div>

        <TemplateSuggestions
          suggestions={suggestions}
          selectedIndex={selectedIndex}
          loading={isLoadingSuggestions}
          hasMorePages={hasMorePages}
          isOpen={isSuggestionsOpen}
          onClose={handleClose}
          onSelect={handleTemplateSelect}
          onLoadMore={fetchMore}
          onCreateTemplate={() => setIsTemplateModalOpen(true)}
        />
      </div>

      <TemplateModal
        isOpen={isTemplateModalOpen}
        onClose={() => setIsTemplateModalOpen(false)}
      />
    </>
  );
};
