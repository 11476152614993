import React from 'react';
import { Button } from '@material-tailwind/react';
import Loading from 'components/atoms/loading/loading';
import {
  Conversation,
  ConversationStatus,
  MessageDirection,
} from 'src/api/generated';
import { useMessagingConversations } from 'src/modules/shared/messaging/hooks/api/conversations';
import ConversationItem from '../../../molecules/conversations/conversation-item';

interface ConversationListProps {
  onSelectConversation: (conversation: Conversation) => void;
  selectedConversationId?: string;
  selectedStatus?: ConversationStatus;
  selectedDirection?: MessageDirection;
}

const ConversationList: React.FC<ConversationListProps> = ({
  onSelectConversation,
  selectedConversationId,
  selectedStatus,
  selectedDirection,
}) => {
  const {
    conversations: allConversations,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    loading,
  } = useMessagingConversations({
    status: selectedStatus,
  });

  const conversations = React.useMemo(() => {
    if (!selectedDirection) return allConversations;

    return allConversations.filter(
      (conversation) =>
        conversation.lastMessage?.direction === selectedDirection
    );
  }, [allConversations, selectedDirection]);

  if (loading) {
    return <Loading className="h-full items-center" />;
  }

  return (
    <>
      {conversations.map((conversation) => (
        <div
          key={conversation.id}
          onClick={() => onSelectConversation(conversation)}
        >
          <ConversationItem
            conversation={conversation}
            isSelected={conversation.id === selectedConversationId}
          />
        </div>
      ))}
      {hasNextPage && (
        <Button
          onClick={() => fetchNextPage()}
          disabled={isFetchingNextPage}
          className="w-full py-2 bg-blue-500 text-white hover:bg-blue-600"
        >
          {/* TODO: check when implementing i18n */}
          {isFetchingNextPage ? <Loading /> : 'Más conversaciones..'}
        </Button>
      )}
    </>
  );
};

export default ConversationList;
