import { useQuery } from '@tanstack/react-query';
import { Message, V1MessagingMessagesService } from 'src/api/generated';
import { ApiResponse } from 'src/hooks/api/api';
import { MINUTE_IN_MS } from 'src/utils/date';

interface UseMessageParams {
  id: string;
}

interface UseMessageResponse extends ApiResponse {
  message: Message | null;
}

// 75% of the 1hr expiration time for signed URLs
const REFRESH_INTERVAL_FOR_SIGNED_URLS = MINUTE_IN_MS * 45;

/**
 * Hook to fetch a message with recently signed attachments
 * @param params - The parameters for fetching the message
 * @param enabled - Whether the query should be enabled
 * @returns The message data, loading state, error state, and refetch function
 */
export const useMessage = (
  { id }: UseMessageParams,
  enabled: boolean = true
): UseMessageResponse => {
  const fetchMessage = async (): Promise<Message | null> => {
    return await V1MessagingMessagesService.getMessage(id);
  };

  const {
    data,
    error,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['messaging/messages', id],
    queryFn: fetchMessage,
    enabled,
    refetchInterval: enabled ? REFRESH_INTERVAL_FOR_SIGNED_URLS : false,
  });

  return {
    loading,
    error,
    message: data ?? null,
    refetch,
  };
};
