/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Represents the supported data types for custom fields.
 */
export enum CustomFieldType {
    STRING = 'STRING',
    NUMBER = 'NUMBER',
    DATE = 'DATE',
    BOOLEAN = 'BOOLEAN',
    LIST = 'LIST',
}
