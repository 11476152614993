import React, { useState } from 'react';
import {
  MessageType,
  MessageTypeSelector,
} from '../../../../../shared/messaging/components/molecules/message-type-selector';
import { Panel } from 'src/modules/shared/components/molecules/panel';
import { ChannelType, ContactAddress } from 'src/api/generated';
import { WhatsAppMessageBox } from './whatsapp';
import { createQuery } from 'src/modules/shared/utils/api/query-builder.util';
import { useFunnelChannelSessions } from 'src/modules/communications/hooks/api';

interface ConversationMessageBoxProps {
  conversationId: number;
  contactAddresses: ContactAddress[];
}

export const ConversationMessageBox: React.FC<ConversationMessageBoxProps> = ({
  conversationId,
  contactAddresses,
}) => {
  const [selectedMessageType, setSelectedMessageType] =
    useState<MessageType>('whatsapp');

  const funnelChannelSessionsQuery = createQuery()
    .equals('funnelConversationId', conversationId)
    .orderByDesc('createdAt')
    .build();
  const { channelSessions } = useFunnelChannelSessions(
    funnelChannelSessionsQuery
  );

  // TODO: consider the case where the contact has multiple addresses
  const whatsappContactAddress = contactAddresses.find(
    (address) => address.channelType === ChannelType.PHONE
  );
  const whatsappSession = channelSessions.find(
    (session) => session.channelType === ChannelType.WHATSAPP
  );

  return (
    <div className="border-t border-gray-200">
      <Panel neumorphic="flat" className="rounded-none border-x-0">
        <div className="flex flex-col p-4 gap-4">
          <MessageTypeSelector
            selectedType={selectedMessageType}
            onTypeChange={setSelectedMessageType}
            availableTypes={['whatsapp']}
          />

          {selectedMessageType === 'whatsapp' && (
            <WhatsAppMessageBox
              toAddress={whatsappContactAddress?.address}
              channelSession={whatsappSession}
            />
          )}

          {/* Future channel types will be added here */}
          {/* Example:
        {selectedMessageType === 'email' && emailAddress && (
          <EmailMessageBox
            fromAddress={...}
            toAddress={...}
            channelSession={emailSession}
          />
        )} 
        */}

          {/* <div className="flex items-end space-x-2">
          <MessageInput
            onSendMessage={handleSendMessage}
            isSendingMessage={false}
            disabled={disabled}
            placeholder={getPlaceholder()}
          />

          {selectedMessageType === 'whatsapp' && (
            <Button
              onClick={() => setIsWhatsAppTemplateModalOpen(true)}
              size="sm"
              variant="secondary"
              className="flex items-center gap-1 px-2 py-1"
            >
              <SiWhatsapp className="h-4 w-4" />
              Send template
            </Button>
          )}
        </div> */}
        </div>
      </Panel>
    </div>
  );
};
