import React, { useEffect } from 'react';
import LabelSelect from 'src/modules/shared/components/atoms/inputs/label-select';
import { Funnel } from 'src/api/generated';
import { ComponentSize } from 'src/modules/shared/types/component.type';

interface FunnelSelectorProps {
  funnels: Funnel[];
  selectedFunnelId?: number;
  onFunnelSelect: (id: number) => void;
  size?: ComponentSize;
}

const FunnelSelector: React.FC<FunnelSelectorProps> = ({
  funnels,
  selectedFunnelId,
  onFunnelSelect,
  size = 'md',
}) => {
  useEffect(() => {
    if (funnels.length > 0 && !selectedFunnelId) {
      onFunnelSelect(funnels[0].id);
    }
  }, [funnels, onFunnelSelect, selectedFunnelId]);

  return (
    <LabelSelect
      label="Embudo"
      labelPosition="top"
      size={size}
      options={funnels.map((funnel) => ({
        content: funnel.name,
        value: funnel.id.toString(),
      }))}
      selectProps={{
        value: selectedFunnelId?.toString(),
        onChange: (event: React.ChangeEvent<HTMLSelectElement>) => {
          onFunnelSelect(Number(event.target.value));
        },
      }}
    />
  );
};

export default FunnelSelector;
