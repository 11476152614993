import React from 'react';
import { Attachment } from 'src/api/generated';
import { AudioMedia } from './audio-media';
import { FileMedia } from './file-media';
import { ImageMedia } from './image-media';
import { VideoMedia } from './video-media';

interface AttachmentListProps {
  attachments: Attachment[];
  className?: string;
  isLoading?: boolean;
}

const getFileNameFromUrl = (url: string): string => {
  try {
    const urlPath = new URL(url).pathname;
    const fileName = urlPath.split('/').pop();
    return fileName || 'document';
  } catch {
    return 'document';
  }
};

export const AttachmentList: React.FC<AttachmentListProps> = ({
  attachments = [],
  className = '',
  isLoading = false,
}) => {
  if (attachments.length === 0) return null;

  const isSingleAttachment = attachments.length === 1;
  const gridClasses = `grid ${
    isSingleAttachment ? 'justify-center' : 'grid-cols-2 gap-2'
  } ${className}`;

  return (
    <div className={gridClasses}>
      {attachments.map((attachment) => {
        const { id, type, url } = attachment;

        switch (type) {
          case 'image':
            return <ImageMedia key={id} url={url} isLoading={isLoading} />;
          case 'video':
            return <VideoMedia key={id} url={url} isLoading={isLoading} />;
          case 'audio':
            return <AudioMedia key={id} url={url} isLoading={isLoading} />;
          case 'file':
            return (
              <FileMedia
                key={id}
                url={url}
                fileName={getFileNameFromUrl(url)}
                isLoading={isLoading}
              />
            );
          default:
            return null;
        }
      })}
    </div>
  );
};
