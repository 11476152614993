import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { type BaseProps } from 'src/modules/shared/types/component.type';
import { type BaseItemDragData } from 'src/modules/shared/types/dnd.types';

interface DraggableItemProps<T> extends BaseProps {
  id: string;
  data: T;
  disabled?: boolean;
}

export const DraggableItem = <T,>({
  children,
  id,
  data,
  disabled = false,
  className = '',
}: DraggableItemProps<T>) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id,
    disabled,
    data: {
      type: 'item',
      data,
    } satisfies BaseItemDragData<T>,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const itemClassName = `
        bg-white rounded-lg transition-all duration-200 shadow-nm-flat-xs hover:shadow-nm-flat-sm
        ${isDragging ? 'cursor-grabbing shadow-nm-flat-xl' : 'cursor-grab'}
        ${className}
      `;

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={itemClassName}
      {...attributes}
      {...listeners}
    >
      {children}
    </div>
  );
};
