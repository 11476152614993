import React from 'react';
import Button from '../../../atoms/buttons/button';
import { BaseComponentProps } from 'src/modules/shared/types/component.type';

export interface ButtonGroupOption<T = string> {
  value: T;
  label: string;
  icon?: React.ReactNode;
}

type ButtonGroupProps<T = string> = Omit<
  BaseComponentProps,
  'neumorphic' | 'state' | 'error' | 'position'
> & {
  options: ButtonGroupOption<T>[];
  value: T;
  onChange: (value: T) => void;
  showLabels?: boolean;
};

export function ButtonGroup<T = string>({
  options,
  value,
  onChange,
  size = 'md',
  variant = 'primary',
  showLabels = true,
  disabled = false,
  loading = false,
  className = '',
}: ButtonGroupProps<T>) {
  return (
    <div
      className={`inline-flex border border-gray-200 divide-x divide-gray-200 rounded-md ${className}`}
    >
      {options.map((option, index) => {
        const isFirst = index === 0;
        const isLast = index === options.length - 1;
        const isActive = value === option.value;

        return (
          <Button
            key={String(option.value)}
            variant={isActive ? variant : 'neutral'}
            appearance={isActive ? 'filled' : 'ghost'}
            size={size}
            onClick={() => onChange(option.value)}
            disabled={disabled}
            loading={loading}
            className={`
              ${!showLabels ? 'p-1.5' : ''}
              ${isFirst ? 'rounded-r-none' : ''}
              ${isLast ? 'rounded-l-none' : ''}
              ${!isFirst && !isLast ? 'rounded-none' : ''}
              border-0
              relative
              hover:z-10
            `}
          >
            {!showLabels && option.icon ? (
              option.icon
            ) : (
              <div className="flex items-center gap-2">
                {option.icon}
                <span>{option.label}</span>
              </div>
            )}
          </Button>
        );
      })}
    </div>
  );
}
