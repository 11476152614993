import React from 'react';
import {
  ComponentSize,
  ComponentVariant,
} from 'src/modules/shared/types/component.type';
import Loading from 'src/modules/shared/components/atoms/loading';
import { checkboxSizeClasses } from './styles';
import { checkboxColorClasses } from './styles';

export interface CheckboxProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  size?: ComponentSize;
  variant?: ComponentVariant;
  label?: string | React.ReactNode;
  labelPosition?: 'left' | 'right';
  className?: string;
  loading?: boolean;
  indeterminate?: boolean;
}

const loadingSizeClasses: Record<NonNullable<ComponentSize>, string> = {
  '2xs': 'w-1.5 h-1.5',
  xs: 'w-2.5 h-2.5',
  sm: 'w-3 h-3',
  md: 'w-3.5 h-3.5',
  lg: 'w-4 h-4',
};

const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  onChange,
  disabled = false,
  size = 'sm',
  variant = 'primary',
  label,
  labelPosition = 'right',
  className = '',
  loading = false,
  indeterminate = false,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };

  const sizeClasses = checkboxSizeClasses[size];
  const colorClasses = checkboxColorClasses[variant];
  const isDisabled = disabled || loading;

  return (
    <div className={`flex items-center ${className}`}>
      {label && labelPosition === 'left' && (
        <label
          className={`mr-2 text-sm text-secondary-700 ${isDisabled ? 'opacity-50' : ''}`}
        >
          {label}
        </label>
      )}
      <div className="flex items-center">
        {loading && (
          <div className="mr-2 flex items-center transition-opacity duration-300 ease-in-out">
            <Loading
              className={`w-fit ${loadingSizeClasses[size]}`}
              iconClassName={loadingSizeClasses[size]}
              variant={variant}
              appearance="filled"
            />
          </div>
        )}
        <div className={`relative inline-block ${sizeClasses.container}`}>
          <input
            type="checkbox"
            checked={checked}
            onChange={handleChange}
            disabled={isDisabled}
            ref={(input) => {
              if (input) {
                input.indeterminate = indeterminate;
              }
            }}
            className={`
              peer appearance-none rounded cursor-pointer transition-colors duration-300 ease-in-out
              ${sizeClasses.input}
              ${colorClasses.input}
              ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}
            `}
          />
          <span
            className={`
              absolute inset-0 flex items-center justify-center pointer-events-none
              transition-all duration-300 ease-in-out
              ${sizeClasses.icon}
              ${colorClasses.icon}
              ${isDisabled ? 'opacity-50' : ''}
            `}
          >
            {indeterminate && (
              <svg
                className="w-3/5 h-3/5"
                viewBox="0 0 16 16"
                fill="currentColor"
              >
                <path d="M3 8h10" strokeWidth="2" stroke="currentColor" />
              </svg>
            )}

            {!indeterminate && checked && (
              <svg
                className="w-3/5 h-3/5"
                viewBox="0 0 16 16"
                fill="currentColor"
              >
                <path d="M13.293 4.293a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414 0l-3-3a1 1 0 1 1 1.414-1.414L6 9.586l5.293-5.293a1 1 0 0 1 1.414 0z" />
              </svg>
            )}
          </span>
        </div>
      </div>
      {label && labelPosition === 'right' && (
        <label
          className={`ml-2 text-sm text-secondary-700 transition-opacity duration-200 ${isDisabled ? 'opacity-50' : ''}`}
        >
          {label}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
