export const ListItemSkeleton = () => {
  return (
    <div className="space-y-3 animate-pulse">
      {[...Array(3)].map((_, index) => (
        <div key={index} className="flex items-center gap-3 p-2">
          <div className="w-8 h-8 bg-gray-200 rounded-full" />
          <div className="flex-1 space-y-2">
            <div className="h-4 w-32 bg-gray-200 rounded" />
            <div className="h-3 w-24 bg-gray-200 rounded" />
          </div>
        </div>
      ))}
    </div>
  );
};
