import { Contact } from 'src/api/generated';
import { useAlert } from 'src/contexts/alert-context';
import { useUpdateContactMutation } from '../../../../hooks/api/contacts-mutations';
import { InlineTextInput } from 'src/modules/shared/components/molecules/inputs/inline/inline-text-input';
import { ContactCustomFieldsSection } from './contact-custom-fields-section';
import { FormSkeleton } from 'src/modules/shared/components/molecules/form-skeleton';
import { ExpandableSection } from 'src/modules/shared/components/molecules/expandable-section';
import { ErrorState } from 'src/modules/shared/components/atoms/states/error-state';

interface ContactFieldsSectionProps {
  contact: Contact | null;
  loading?: boolean;
  error?: Error | null;
}

export const ContactFieldsSection: React.FC<ContactFieldsSectionProps> = ({
  contact,
  loading = false,
  error = null,
}) => {
  const { showAlert } = useAlert();
  const { updateContact, loading: saving } = useUpdateContactMutation(
    () => {},
    () => {
      // TODO: check when implementing i18n
      showAlert(
        'No se pudo actualizar el campo. Por favor, inténtalo de nuevo.',
        'error'
      );
    }
  );

  const handleUpdateField = (field: keyof Contact) => async (value: string) => {
    if (!contact) return;

    await updateContact({
      id: contact.id,
      data: {
        [field]: value,
      },
    });
  };

  if (loading) {
    return (
      // TODO: check when implementing i18n
      <ExpandableSection title="Información">
        <FormSkeleton
          fieldCount={5}
          variant="primary"
          spacing="space-y-2"
          defaultInputClassName="w-5/6"
        />
      </ExpandableSection>
    );
  }

  if (!contact || error) {
    return (
      // TODO: check when implementing i18n
      <ExpandableSection title="Información">
        <ErrorState
          title="Error al cargar la información"
          description="No se pudo cargar la información del contacto. Por favor, inténtalo de nuevo."
        />
      </ExpandableSection>
    );
  }

  return (
    // TODO: check when implementing i18n
    <ExpandableSection title="Información">
      <div className="space-y-1">
        <InlineTextInput
          // TODO: check when implementing i18n
          label="Nombre"
          value={contact.firstName || ''}
          placeholder="Ingresa el nombre..."
          onSave={handleUpdateField('firstName')}
          loading={saving}
        />
        <InlineTextInput
          // TODO: check when implementing i18n
          label="Apellido"
          value={contact.lastName || ''}
          placeholder="Ingresa el apellido..."
          onSave={handleUpdateField('lastName')}
          loading={saving}
        />
        <InlineTextInput
          // TODO: check when implementing i18n
          label="CI"
          value={contact.nationalId || ''}
          placeholder="Ingresa el identificador nacional..."
          onSave={handleUpdateField('nationalId')}
          loading={saving}
        />
        <InlineTextInput
          // TODO: check when implementing i18n
          label="Cargo"
          value={contact.companyPosition || ''}
          placeholder="Ingresa el cargo..."
          onSave={handleUpdateField('companyPosition')}
          loading={saving}
        />

        <ContactCustomFieldsSection contact={contact} />
      </div>
    </ExpandableSection>
  );
};
