/**
 * Merges multiple React refs into a single ref callback function.
 * This is useful when you need to pass multiple refs to a single element.
 *
 * @template T - The type of the ref value
 * @param refs - Array of React refs to merge. Can include undefined refs which will be ignored.
 * @returns A ref callback function that updates all provided refs with the given value
 */
export const mergeRefs = <T>(
  ...refs: Array<React.Ref<T> | undefined>
): React.RefCallback<T> => {
  return (value: T) => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(value);
      } else {
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
};
