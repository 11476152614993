import { useState } from 'react';
import { Typography } from '@material-tailwind/react';
import { PiCaretDown, PiCaretRight } from 'react-icons/pi';
import Button from 'src/modules/shared/components/atoms/buttons/button';
import Loading from 'src/modules/shared/components/atoms/loading';
import { motion, AnimatePresence } from 'framer-motion';

export type ExpansionControlType =
  | 'none'
  | 'left-icon'
  | 'clickable-title'
  | 'bottom-text';

interface ExpandableSectionProps {
  title: string;
  loading?: boolean;
  children: React.ReactNode;
  isInitiallyExpanded?: boolean;
  actions?: React.ReactNode;
  expansionControl?: ExpansionControlType;
  onExpandChange?: (isExpanded: boolean) => void;
}

export const ExpandableSection: React.FC<ExpandableSectionProps> = ({
  title,
  loading = false,
  children,
  isInitiallyExpanded = true,
  actions,
  expansionControl = 'left-icon',
  onExpandChange,
}) => {
  const [isExpanded, setIsExpanded] = useState(isInitiallyExpanded);

  const toggleExpand = () => {
    const newExpandedState = !isExpanded;
    setIsExpanded(newExpandedState);
    onExpandChange?.(newExpandedState);
  };

  const renderExpansionControl = () => {
    if (expansionControl === 'none') return null;

    if (expansionControl === 'left-icon') {
      return (
        <div
          onClick={toggleExpand}
          className="flex items-center cursor-pointer group"
          role="button"
          aria-expanded={isExpanded}
          // TODO: check when implementing i18n
          title={isExpanded ? 'Ocultar' : 'Mostrar'}
        >
          <div className="mr-2 transition-colors group-hover:text-primary-600">
            {isExpanded ? (
              <PiCaretDown className="h-4 w-4" />
            ) : (
              <PiCaretRight className="h-4 w-4" />
            )}
          </div>
          <Typography className="text-md font-medium transition-colors group-hover:text-primary-600">
            {title}
          </Typography>
        </div>
      );
    }

    return null;
  };

  const getTitleContainerProps = () => {
    if (expansionControl === 'clickable-title') {
      return {
        onClick: toggleExpand,
        className:
          'font-medium cursor-pointer hover:text-primary-600 transition-colors',
        role: 'button',
        'aria-expanded': isExpanded,
      };
    }
    return { className: 'font-medium' };
  };

  return (
    <div className="space-y-3">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          {expansionControl === 'left-icon' ? (
            renderExpansionControl()
          ) : (
            <Typography {...getTitleContainerProps()}>{title}</Typography>
          )}
        </div>
        {actions && (
          <div className="flex items-center space-x-2">{actions}</div>
        )}
      </div>

      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="space-y-2 overflow-hidden p-1"
          >
            {loading ? (
              <div className="flex justify-center py-4">
                <Loading size="sm" />
              </div>
            ) : (
              <>
                {children}
                {expansionControl === 'bottom-text' && (
                  <div className="text-center pt-2">
                    <Button
                      variant="neutral"
                      appearance="ghost"
                      size="sm"
                      onClick={toggleExpand}
                      className="font-medium text-primary-600"
                    >
                      {/* TODO: check when implementing i18n */}
                      Ver menos
                    </Button>
                  </div>
                )}
              </>
            )}
          </motion.div>
        )}
      </AnimatePresence>

      {!isExpanded && expansionControl === 'bottom-text' && (
        <div className="text-center">
          <Button
            variant="neutral"
            appearance="ghost"
            size="sm"
            onClick={toggleExpand}
            className="font-medium text-primary-600"
          >
            {/* TODO: check when implementing i18n */}
            Ver más
          </Button>
        </div>
      )}
    </div>
  );
};
