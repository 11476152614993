import {
  type DragEndEvent,
  type DragOverEvent,
  type DragStartEvent,
} from '@dnd-kit/core';
import { type FunnelConversationDocument } from 'src/api/generated';
import { useUpdateFunnelConversation } from '../api/funnels/funnel-conversations-mutations';
import { useBoardDnd } from 'src/modules/shared/hooks/board/board-dnd';
import {
  type PipelineDragData,
  isStatusContainerDragData,
  isConversationDragData,
} from '../../types/pipeline/dnd.types';
import {
  extractStatusIdFromContainerId,
  extractPipelineItemId,
  createPipelineContainerId,
} from '../../utils/pipeline/dnd.utils';

interface UsePipelineDndParams {
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
}

interface UsePipelineDndReturn {
  activeConversation: FunnelConversationDocument | null;
  activeStatusId: string | null;
  shouldAnimate: boolean;
  isUpdating: boolean;
  sensors: ReturnType<typeof useBoardDnd>['sensors'];
  handleDragStart: (event: DragStartEvent) => void;
  handleDragOver: (event: DragOverEvent) => void;
  handleDragEnd: (event: DragEndEvent) => void;
}

export const usePipelineDnd = ({
  onSuccess,
  onError,
}: UsePipelineDndParams): UsePipelineDndReturn => {
  const { updateConversation, loading: isUpdating } =
    useUpdateFunnelConversation(
      onSuccess,
      onError ??
        ((error) => {
          console.error('Failed to update conversation status:', error);
        })
    );

  const {
    activeItem: activeConversation,
    activeContainerId: activeStatusId,
    shouldAnimate,
    sensors,
    handleDragStart,
    handleDragOver,
    handleDragEnd,
  } = useBoardDnd<FunnelConversationDocument>({
    onItemMove: async (itemId, targetContainerId) => {
      const statusId = extractStatusIdFromContainerId(targetContainerId);
      if (!statusId) {
        console.error('Invalid container ID:', targetContainerId);
        return;
      }

      const conversationId = extractPipelineItemId(itemId);
      if (!conversationId) {
        console.error('Invalid item ID:', itemId);
        return;
      }

      await updateConversation({
        id: parseInt(conversationId),
        data: { funnelStatusId: statusId },
      });
    },
    onError,
    getItemData: (event: DragStartEvent) => {
      const activeData = event.active.data.current as
        | PipelineDragData
        | undefined;
      const conversation = isConversationDragData(activeData)
        ? activeData.data.conversation
        : undefined;
      return conversation;
    },
    getContainerId: (event: DragOverEvent | DragEndEvent) => {
      if (!event.over) return undefined;

      const overData = event.over.data.current as PipelineDragData | undefined;

      // If hovering over a conversation item, get its container ID
      if (isConversationDragData(overData)) {
        return createPipelineContainerId(overData.data.status.id);
      }

      // If hovering over a container, validate it's a status container
      if (!isStatusContainerDragData(overData)) return undefined;

      return overData.containerId;
    },
    getSourceContainerId: (event: DragEndEvent) => {
      const activeData = event.active.data.current as
        | PipelineDragData
        | undefined;

      if (isConversationDragData(activeData)) {
        return createPipelineContainerId(activeData.data.status.id);
      }

      if (isStatusContainerDragData(activeData)) {
        return activeData.containerId;
      }

      return undefined;
    },
  });

  return {
    activeConversation,
    activeStatusId,
    shouldAnimate,
    isUpdating,
    sensors,
    handleDragStart,
    handleDragOver,
    handleDragEnd,
  };
};
