import { useMemo } from 'react';
import { CustomFieldType } from 'src/api/generated';
import { useCustomFieldDefinitions } from '../../api/custom-fields';
import { CustomFieldEntityType } from 'src/api/generated';
import { CUSTOM_FIELD_TYPE_PREFIX } from '../../../constants/custom-fields.const';
import {
  FilterFieldOptions,
  SortFieldOptions,
} from 'src/modules/shared/types/filters.types';

function mapCustomFieldTypeToFilterType(
  type: CustomFieldType
): FilterFieldOptions['type'] {
  switch (type) {
    case CustomFieldType.LIST:
      return 'list';
    case CustomFieldType.STRING:
      return 'string';
    case CustomFieldType.NUMBER:
      return 'number';
    case CustomFieldType.BOOLEAN:
      return 'boolean';
    case CustomFieldType.DATE:
      return 'date';
    default:
      return 'string';
  }
}

export interface UseConversationFieldsResult {
  filterFieldsOptions: FilterFieldOptions[];
  sortFieldsOptions: SortFieldOptions[];
}

export function useConversationFields(): UseConversationFieldsResult {
  const { definitions: conversationDefinitions } = useCustomFieldDefinitions({
    entityType: CustomFieldEntityType.FUNNEL_CONVERSATION,
  });

  const { definitions: contactDefinitions } = useCustomFieldDefinitions({
    entityType: CustomFieldEntityType.CONTACT,
  });

  const conversationCustomFieldsOptions = useMemo(() => {
    return conversationDefinitions.map((def) => ({
      id: `customFields.${CUSTOM_FIELD_TYPE_PREFIX[def.fieldType]}${def.fieldKey}`,
      label: `💬 ${def.fieldLabel || def.fieldKey}`,
      type: mapCustomFieldTypeToFilterType(def.fieldType),
      definitionId: def.id,
    }));
  }, [conversationDefinitions]);

  const contactCustomFieldsOptions = useMemo(() => {
    return contactDefinitions.map((def) => ({
      id: `contacts.customFields.${CUSTOM_FIELD_TYPE_PREFIX[def.fieldType]}${def.fieldKey}`,
      label: `👤 ${def.fieldLabel || def.fieldKey}`,
      type: mapCustomFieldTypeToFilterType(def.fieldType),
      definitionId: def.id,
    }));
  }, [contactDefinitions]);

  const filterFieldsOptions: FilterFieldOptions[] = useMemo(
    () => [
      {
        id: 'userId',
        label: 'Asignado',
        type: 'user',
      },
      {
        id: 'funnelStatusId',
        label: 'Estado',
        type: 'status',
      },
      {
        id: 'contacts.firstName',
        label: 'Nombre de contacto',
        type: 'string',
      },
      {
        id: 'contacts.lastName',
        label: 'Apellido de contacto',
        type: 'string',
      },
      {
        id: 'contacts.addresses.address',
        label: 'Dirección de contacto',
        type: 'string',
      },
      {
        id: 'lastInteractionAt',
        label: 'Última interacción',
        type: 'date',
      },
      {
        id: 'createdAt',
        label: 'Fecha de creación',
        type: 'date',
      },
      {
        id: 'updatedAt',
        label: 'Fecha de actualización',
        type: 'date',
      },
      ...conversationCustomFieldsOptions,
      ...contactCustomFieldsOptions,
    ],
    [conversationCustomFieldsOptions, contactCustomFieldsOptions]
  );

  const sortFieldsOptions: SortFieldOptions[] = useMemo(() => {
    return [
      ...filterFieldsOptions
        .filter(
          (field): field is SortFieldOptions =>
            field.type !== 'boolean' && field.type !== 'list'
        )
        .map((field) => ({
          ...field,
          type: field.type,
        })),
    ];
  }, [filterFieldsOptions]);

  return {
    filterFieldsOptions,
    sortFieldsOptions,
  };
}
