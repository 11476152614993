import { createRootRouteWithContext } from '@tanstack/react-router';
import React, { Suspense } from 'react';
import { App } from '../app';
import NotFoundView from 'components/views/not-found-view/not-found-view';
import { Auth0ContextInterface, User } from '@auth0/auth0-react';
import { globalSearchSchema } from '../modules/shared/schemas/search.schema';

const TanStackRouterDevtools = import.meta.env.PROD
  ? () => null
  : React.lazy(() =>
      import('@tanstack/router-devtools').then((res) => ({
        default: res.TanStackRouterDevtools,
        // For Embedded Mode
        // default: res.TanStackRouterDevtoolsPanel
      }))
    );

interface RouterContext {
  auth: Auth0ContextInterface<User>;
}

export const Route = createRootRouteWithContext<RouterContext>()({
  component: RootComponent,
  notFoundComponent: () => {
    return <NotFoundView />;
  },
  validateSearch: (search) => globalSearchSchema.parse(search),
});

function RootComponent() {
  return (
    <>
      <Suspense>
        <App />
      </Suspense>

      <Suspense>
        <TanStackRouterDevtools initialIsOpen={true} />
      </Suspense>
    </>
  );
}
