import React from 'react';
import { ConversationStatus } from 'src/api/generated';
import { conversationStatusTranslations } from 'src/modules/messaging/utils/conversations/status.util';
import Tag from 'src/modules/shared/components/atoms/tag';
import type { TagProps } from 'src/modules/shared/components/atoms/tag';

interface ConversationStatusDisplayProps {
  status: ConversationStatus | 'expired';
  size?: TagProps['size'];
  className?: TagProps['className'];
  textClassName?: TagProps['textClassName'];
}

const statusVariantMap: Record<
  ConversationStatus | 'expired',
  TagProps['variant']
> = {
  [ConversationStatus.OPEN]: 'success',
  [ConversationStatus.CLOSED]: 'danger',
  [ConversationStatus.ARCHIVED]: 'secondary',
  [ConversationStatus.PENDING]: 'info',
  expired: 'warning',
};

const ConversationStatusDisplay: React.FC<ConversationStatusDisplayProps> = ({
  status,
  size = 'md',
  className,
  textClassName,
}) => (
  <Tag
    content={conversationStatusTranslations[status]}
    variant={statusVariantMap[status]}
    size={size}
    className={className}
    textClassName={textClassName}
  />
);

export default ConversationStatusDisplay;
