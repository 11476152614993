import { motion } from 'framer-motion';
import Button from 'src/modules/shared/components/atoms/buttons/button';
import { Panel } from 'src/modules/shared/components/molecules/panel';
import { PanelHeader } from 'src/modules/shared/components/molecules/panel/panel-header';
import { useContact } from 'src/modules/communications/hooks/api';
import { createQuery } from 'src/modules/shared/utils/api/query-builder.util';
import { ContactFieldsSection } from '../../molecules/contacts/contact-detail-panel/contact-fields-section';
import { ContactAddressesSection } from '../../molecules/contacts/contact-detail-panel/contact-addresses-section';
import { CompanyInfoSection } from '../../molecules/contacts/contact-detail-panel/company-info-section';
import { ConversationFieldsSection } from '../../molecules/contacts/contact-detail-panel/conversation-fields-section';
import { Separator } from 'src/modules/shared/components/atoms/separator';
import { Typography } from '@material-tailwind/react';
import { ErrorState } from 'src/modules/shared/components/atoms/states/error-state';
import { PiXLight } from 'react-icons/pi';
import { useFunnelConversations } from '../../../hooks/api/funnels/funnel-conversations';

interface ContactDetailPanelProps {
  onClose?: () => void;
  contactId: number;
  conversationId?: number;
}

const ContactDetailPanel: React.FC<ContactDetailPanelProps> = ({
  onClose,
  contactId,
  conversationId,
}) => {
  const {
    contact,
    loading: loadingContact,
    error: contactError,
  } = useContact({ id: contactId }, !!contactId);

  const conversationsQuery = createQuery()
    .equals('contactId', contactId)
    .build();

  const {
    conversations,
    loading: loadingConversations,
    error: conversationsError,
  } = useFunnelConversations(conversationsQuery, !!contactId);

  const selectedConversation = conversationId
    ? conversations.find((c) => c.id === conversationId)
    : undefined;

  if (contactError) {
    return (
      <motion.div
        initial={{ x: 20, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 20, opacity: 0 }}
        className="h-full flex flex-col bg-white"
      >
        {/* TODO: check when implementing i18n */}
        <ErrorState
          title="Error cargando los datos"
          description="No se pudo cargar la información del contacto. Por favor, inténtalo de nuevo."
          onAction={onClose}
          actionText="Cerrar"
        />
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ x: 20, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: 20, opacity: 0 }}
      className="transition-all duration-200 h-full flex flex-col bg-white"
    >
      <PanelHeader
        fixed
        size="lg"
        actions={
          onClose && (
            <Button
              variant="neutral"
              appearance="ghost"
              size="md"
              onClick={onClose}
              isIconOnly
            >
              <PiXLight className="h-5 w-5" />
            </Button>
          )
        }
      >
        <div className="flex items-center min-w-0">
          <div className="flex flex-col gap-0.5 min-w-0">
            <Typography className="text-lg font-medium">
              {/* TODO: check when implemnting i18n */}
              Contacto
            </Typography>
          </div>
        </div>
      </PanelHeader>

      <Panel
        neumorphic="flat"
        className="flex-1 overflow-y-auto bg-white rounded-none"
      >
        <div className="p-4 space-y-6">
          {selectedConversation && (
            <>
              <ConversationFieldsSection
                conversation={selectedConversation}
                loading={loadingConversations}
                error={conversationsError}
              />
              <Separator />
            </>
          )}

          <ContactFieldsSection
            contact={contact}
            loading={loadingContact}
            error={contactError}
          />
          <Separator />

          <ContactAddressesSection
            addresses={contact?.addresses ?? []}
            loading={loadingContact}
            contactId={contactId}
            error={contactError}
          />
          <Separator />

          <CompanyInfoSection
            contact={contact}
            loading={loadingContact}
            error={contactError}
          />
        </div>
      </Panel>
    </motion.div>
  );
};

export default ContactDetailPanel;
