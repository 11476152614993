import { type FC } from 'react';
import {
  type FunnelConversationDocument,
  ChannelType,
} from 'src/api/generated';
import { formatFullName } from 'src/modules/shared/utils/formatting/name.util';
import { ChannelIcon } from 'src/modules/shared/components/atoms/icons/channel-icon';
import { Typography } from '@material-tailwind/react';

interface CardConversationHeaderProps {
  conversation: FunnelConversationDocument;
  className?: string;
}

export const ConversationCardHeader: FC<CardConversationHeaderProps> = ({
  conversation,
  className = '',
}) => {
  const contact = conversation.contacts?.[0];
  const address = contact?.addresses?.[0];
  const activeSessions = conversation.channelSessions || [];
  const uniqueChannelTypes = [
    ...new Set(activeSessions.map((session) => session.channelType)),
  ].sort((a, b) => {
    const order = [ChannelType.PHONE, ChannelType.WHATSAPP, ChannelType.EMAIL];
    return order.indexOf(a) - order.indexOf(b);
  });

  // TODO: check when implementing i18n
  const fallbackConversationHeader = address?.address || 'Sin dirección';

  return (
    <div className={`flex items-start justify-between ${className}`}>
      <div className="flex-1 min-w-0">
        <Typography className="text-sm font-semibold text-primary-800 truncate">
          {formatFullName(
            contact?.firstName,
            contact?.lastName,
            fallbackConversationHeader
          )}
        </Typography>
      </div>
      <div className="ml-2 flex gap-2">
        {uniqueChannelTypes.map((channelType) => (
          <div key={channelType} className="relative">
            <ChannelIcon
              channelType={channelType as ChannelType}
              className="text-secondary-400"
            />
          </div>
        ))}
      </div>
    </div>
  );
};
