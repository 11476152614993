import { useQuery, useInfiniteQuery } from '@tanstack/react-query';
import { ApiResponse, useMemoizedArrayData } from 'src/hooks/api/api';
import {
  Contact,
  ContactListResponse,
  CrmContactsService,
} from 'src/api/generated';
import { QueryParams } from 'src/modules/shared/types/api/query-builder.type';

interface UseContactParams {
  id: number;
}

interface UseContactResponse extends ApiResponse {
  contact: Contact | null;
}

export const useContact = (
  { id }: UseContactParams,
  enabled: boolean = true
): UseContactResponse => {
  const fetchContact = async (): Promise<Contact | null> => {
    return await CrmContactsService.getContact(id);
  };

  const {
    data,
    error,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['crm/contacts', id],
    queryFn: fetchContact,
    enabled,
  });

  return {
    loading,
    error,
    contact: data ?? null,
    refetch,
  };
};

interface UseContactsParams extends QueryParams {
  enabled?: boolean;
}

interface UseContactsResponse extends ApiResponse {
  contacts: Contact[];
  fetchNextPage: () => void;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
}

export const useContacts = ({
  enabled = true,
  pageSize = 10,
  ...params
}: UseContactsParams = {}): UseContactsResponse => {
  const fetchContacts = async ({
    pageParam,
  }: {
    pageParam: string | undefined;
  }) => {
    const { filterBy, searchBy, sortBy, page } = params;

    return await CrmContactsService.getContacts(
      filterBy,
      searchBy,
      sortBy,
      page,
      pageSize,
      pageParam
    );
  };

  const getNextPageParam = (lastPage: ContactListResponse) =>
    lastPage.nextPageToken;

  const {
    data,
    error,
    isLoading: loading,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['crm/contacts', { pageSize, ...params }],
    queryFn: fetchContacts,
    getNextPageParam,
    initialPageParam: undefined,
    enabled,
  });

  const contacts = useMemoizedArrayData<Contact>(
    data?.pages.flatMap((page) => page.items) || []
  );

  return {
    loading,
    error,
    contacts,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  };
};
