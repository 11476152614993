import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  FunnelConversation,
  CrmFunnelsConversationsService,
  FunnelConversationDocument,
} from 'src/api/generated';
import { getModelFromCache } from 'src/modules/shared/utils/cache/update.util';
import { updateFunnelConversationCache } from '../../../utils/cache/funnel-conversations/update.util';
import {
  createOptimisticConversation,
  UpdateFunnelConversationContext,
} from 'src/modules/communications/utils/cache/funnel-conversations/optimistic.util';
import { UpdateFunnelConversationParams } from 'src/modules/communications/utils/cache/funnel-conversations/optimistic.util';

interface UseUpdateFunnelConversationResponse {
  loading: boolean;
  error: Error | null;
  conversation?: FunnelConversation;
  updateConversation: (
    params: UpdateFunnelConversationParams
  ) => Promise<FunnelConversation>;
}

export const useUpdateFunnelConversation = (
  onSuccess: (
    conversation: FunnelConversation,
    context: UpdateFunnelConversationContext
  ) => void = () => {},
  onError: (
    error: Error,
    context: UpdateFunnelConversationContext
  ) => void = () => {},
  onOptimisticUpdate: (
    conversation: FunnelConversation,
    context: UpdateFunnelConversationContext
  ) => void = () => {}
): UseUpdateFunnelConversationResponse => {
  const queryClient = useQueryClient();

  const updateFunnelConversation = async ({
    id,
    data,
  }: UpdateFunnelConversationParams): Promise<FunnelConversation> => {
    return await CrmFunnelsConversationsService.updateFunnelConversation(
      id,
      data
    );
  };

  const mutation = useMutation({
    mutationFn: updateFunnelConversation,
    onMutate: async (params) => {
      await queryClient.cancelQueries({
        queryKey: ['crm/funnel-conversations'],
      });
      await queryClient.cancelQueries({
        queryKey: ['crm/documents/funnel-conversations'],
      });

      const previousConversationDocument =
        getModelFromCache<FunnelConversationDocument>(
          queryClient,
          ['crm/documents/funnel-conversations'],
          params.id
        );
      const previousConversation = getModelFromCache<FunnelConversation>(
        queryClient,
        ['crm/funnel-conversations'],
        params.id
      );

      const optimisticConversation = createOptimisticConversation(
        params,
        previousConversation
      );

      const context: UpdateFunnelConversationContext = {
        params,
        previousConversation,
        previousConversationDocument,
      };

      updateFunnelConversationCache(
        queryClient,
        optimisticConversation,
        context
      );
      onOptimisticUpdate?.(optimisticConversation, context);

      return context;
    },
    onError: (error, params, context) => {
      if (context?.previousConversation) {
        updateFunnelConversationCache(
          queryClient,
          context.previousConversation,
          context
        );
      }

      onError(error, context || { params });
    },
    onSuccess: (updatedConversation, _params, context) => {
      if (context) {
        updateFunnelConversationCache(
          queryClient,
          updatedConversation,
          context
        );
        onSuccess(updatedConversation, context);
      }
    },
  });

  return {
    loading: mutation.isPending,
    error: mutation.error,
    conversation: mutation.data,
    updateConversation: mutation.mutateAsync,
  };
};
