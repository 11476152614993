import {
  MessageDocument,
  MessageDirection,
  MessageStatus,
  Contact,
} from 'src/api/generated';
import { motion } from 'framer-motion';
import { format } from 'date-fns';
import { IoLogoWhatsapp } from 'react-icons/io';
import { CircleDisplay } from 'src/modules/shared/components/atoms/circle-display';
import { WhatsAppText } from 'src/modules/shared/components/molecules/messages/whatsapp-text';
import { MessageStatusIndicator } from 'src/modules/shared/components/atoms/indicators/message-status-indicator';
import { Typography } from '@material-tailwind/react';
import { formatFullName } from 'src/modules/shared/utils/formatting/name.util';
import { TextSkeleton } from 'src/modules/shared/components/atoms/text/text-skeleton';
import {
  useCurrentUser,
  useUsers,
} from 'src/modules/shared/admin/hooks/api/users';
import { AttachmentList } from '../media/attachment-list';
import { useMessage } from '../../../hooks/api/messages';
import { useMemo } from 'react';
import { generateMemoToken } from 'src/modules/shared/utils/memo.util';
import { isAnyAttachmentExpired } from 'src/modules/shared/utils/attachments/expiration.util';

interface TimelineWhatsAppItemProps {
  item: MessageDocument;
  groupedItems?: MessageDocument[];
  isNew?: boolean;
  contact?: Contact;
}

export const TimelineWhatsAppItem: React.FC<TimelineWhatsAppItemProps> = ({
  item,
  groupedItems,
  isNew = false,
  contact,
}) => {
  const { users, loading: usersLoading } = useUsers();
  const { user: currentUser, loading: currentUserLoading } = useCurrentUser();
  const isOutbound = item.direction === MessageDirection.OUTBOUND;

  const shouldFetchMessage =
    Boolean(item.attachments?.length) &&
    isAnyAttachmentExpired(item.attachments);
  const { message, loading } = useMessage({ id: item.id }, shouldFetchMessage);

  const getMessageSenderName = (): string => {
    if (!isOutbound) {
      return formatFullName(
        contact?.firstName,
        contact?.lastName,
        // TODO: check when implementing i18n
        item.sender.displayName || 'Contacto'
      );
    }

    const currentUserId = currentUser?.id ? currentUser.id : null;
    if (currentUserId && item.userId === String(currentUserId)) {
      // TODO: check when implementing i18n
      return 'Tú';
    }

    const messageUser = users?.find((user) => String(user.id) === item.userId);
    return messageUser
      ? formatFullName(messageUser.firstName, messageUser.lastName)
      : // TODO: check when implementing i18n
        'Usuario';
  };

  const combinedContent = groupedItems
    ? groupedItems.map((msg) => msg.content).join('\n\n')
    : item.content;

  const messageToken = generateMemoToken(message, ['id', 'attachments']);
  const itemToken = generateMemoToken(item, ['id', 'attachments']);

  const attachments = useMemo(
    () => message?.attachments || item.attachments || [],
    // Using pre-computed memo tokens instead of raw objects to optimize performance
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [messageToken, itemToken]
  );

  return (
    <motion.div
      initial={isNew ? { opacity: 0, y: 20 } : false}
      animate={{ opacity: 1, y: 0 }}
      className={`flex ${isOutbound ? 'justify-end' : 'justify-start'}`}
    >
      <div
        className={`w-fit min-w-[180px] max-w-[85%] overflow-hidden ${
          isOutbound ? 'bg-blue-50' : 'bg-white'
        } rounded-lg shadow-nm-flat-2xs`}
      >
        <div className="flex items-start gap-3 p-3">
          <CircleDisplay
            icon={<IoLogoWhatsapp />}
            variant="primary"
            size="sm"
            className={`flex-shrink-0 !text-whatsapp-500 ${
              isOutbound ? '!bg-white' : '!bg-whatsapp-50'
            }`}
          />

          <div className="flex-1 flex flex-col gap-1 min-w-0">
            <div className="flex flex-wrap items-center justify-between gap-4">
              <h4 className="text-sm font-semibold text-primary-800 break-all">
                {usersLoading || currentUserLoading ? (
                  <TextSkeleton className="w-36 h-5" />
                ) : (
                  getMessageSenderName()
                )}
              </h4>
              <Typography className="text-xs font-normal text-secondary-600">
                {format(new Date(item.createdAt), 'HH:mm')}
              </Typography>
            </div>

            <div className="p-2 flex flex-col gap-2">
              {attachments && attachments.length > 0 && (
                <div className="mt-2">
                  <AttachmentList
                    attachments={attachments}
                    isLoading={loading}
                  />
                </div>
              )}
              <div className="break-words whitespace-pre-wrap">
                <WhatsAppText text={combinedContent || ''} />
              </div>
            </div>

            {isOutbound && (
              <div className="flex flex-wrap items-center justify-end gap-4 gap-y-2">
                <div className="flex flex-wrap items-center gap-1 text-xs">
                  <MessageStatusIndicator
                    status={item.status as MessageStatus}
                    errorMessage={item.errors?.[0]?.message}
                    variant="whatsapp"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};
