import React from 'react';
import Modal from 'src/modules/shared/components/molecules/modals/modal';
import { Typography } from '@material-tailwind/react';
import {
  useCreateTemplate,
  useUpdateTemplate,
} from 'src/modules/shared/messaging/hooks/api/templates';
import { useAlert } from 'src/contexts/alert-context';
import { Template } from 'src/api/generated';
import TemplateForm, {
  TemplateFormData,
} from '../../../molecules/templates/template-form';

interface TemplateModalProps {
  isOpen: boolean;
  onClose: () => void;
  template?: Template;
}

const TemplateModal: React.FC<TemplateModalProps> = ({
  isOpen,
  onClose,
  template,
}) => {
  const { showAlert } = useAlert();

  const { createTemplate, loading: isCreating } = useCreateTemplate({
    // TODO: check when implementing i18n
    onSuccess: () => {
      showAlert('Plantilla creada correctamente', 'success');
      onClose();
    },
    onError: () => {
      showAlert('Error al crear plantilla', 'error');
    },
  });

  const { updateTemplate, loading: isUpdating } = useUpdateTemplate({
    // TODO: check when implementing i18n
    onSuccess: () => {
      showAlert('Plantilla actualizada correctamente', 'success');
      onClose();
    },
    onError: () => {
      showAlert('Error al actualizar plantilla', 'error');
    },
  });

  const handleSubmit = async (data: TemplateFormData) => {
    if (template) {
      updateTemplate({ id: template.id, ...data });
    } else {
      createTemplate(data);
    }
  };

  const isSubmitting = isCreating || isUpdating;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={
        <Typography variant="h5" color="blue-gray">
          {/* TODO: check when implementing i18n */}
          {template ? 'Editar plantilla' : 'Crear plantilla'}
        </Typography>
      }
      body={
        <TemplateForm
          template={template}
          onSubmit={handleSubmit}
          onCancel={onClose}
          isSubmitting={isSubmitting}
        />
      }
    />
  );
};

export default TemplateModal;
