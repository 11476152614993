import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import {
  BaseProps,
  ComponentSize,
  ComponentVariant,
  ComponentAppearance,
} from 'src/modules/shared/types/component.type';

interface LoadingProps extends BaseProps {
  className?: string;
  iconClassName?: string;
}

const sizeClasses: Record<NonNullable<ComponentSize>, string> = {
  '2xs': 'h-3 w-3',
  xs: 'h-4 w-4',
  sm: 'h-5 w-5',
  md: 'h-6 w-6',
  lg: 'h-8 w-8',
};

const variantClasses: Record<NonNullable<ComponentVariant>, string> = {
  primary: 'text-primary-600',
  secondary: 'text-secondary-500',
  danger: 'text-danger-500',
  warning: 'text-warning-500',
  neutral: 'text-neutral-600',
  success: 'text-success-500',
  info: 'text-blue-500',
};

const appearanceClasses: Record<NonNullable<ComponentAppearance>, string> = {
  filled: '',
  outline: 'text-white',
  soft: '',
  ghost: '',
};

const Loading: React.FC<LoadingProps> = ({
  className = '',
  iconClassName = '',
  variant = 'primary',
  appearance = 'filled',
  size = 'md',
}) => {
  const classes = [
    'flex w-full justify-center',
    sizeClasses[size],
    className,
  ].join(' ');

  const spinnerClasses = [
    'animate-spin',
    sizeClasses[size],
    variantClasses[variant],
    appearance && appearanceClasses[appearance],
    iconClassName,
  ].join(' ');

  return (
    <div className={classes}>
      <AiOutlineLoading3Quarters className={spinnerClasses} />
    </div>
  );
};

export default Loading;
