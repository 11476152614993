import { useState } from 'react';
import { PiUserLight, PiPhoneLight, PiXLight } from 'react-icons/pi';
import { ContactSelect } from 'src/modules/shared/components/molecules/contacts/contact-select';
import { formatPhoneNumberAsYouType } from 'src/modules/shared/utils/formatting/phone.util';
import { isValidPhoneNumber } from 'src/modules/shared/utils/validation/phone.util';
import Button from 'src/modules/shared/components/atoms/buttons/button';
import TextInput from 'src/modules/shared/components/atoms/inputs/text-input';
import { useNavigate } from '@tanstack/react-router';
import {
  Route as CommunicationsRoute,
  CommunicationsSearch,
} from 'src/routes/communications';
import { useGlobalCallProviderContext } from 'src/contexts/global-call-provider-context';

interface NewCallFormProps {
  onClose: () => void;
}

export const NewCallForm = ({ onClose }: NewCallFormProps) => {
  const navigate = useNavigate();
  const callProvider = useGlobalCallProviderContext();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedContactId, setSelectedContactId] = useState<string | null>(
    null
  );

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatted = formatPhoneNumberAsYouType(e.target.value);
    setPhoneNumber(formatted);
  };

  const handleContactSelect = (contactId: string, phoneNumber?: string) => {
    setSelectedContactId(contactId);
    if (phoneNumber) {
      const formatted = formatPhoneNumberAsYouType(phoneNumber);
      setPhoneNumber(formatted);
    }
  };

  const handleViewContact = () => {
    if (!selectedContactId) return;

    navigate({
      to: CommunicationsRoute.to,
      search: (prev) => ({
        ...(prev as CommunicationsSearch),
        contactId: parseInt(selectedContactId),
      }),
    });
    onClose();
  };

  const handleStartCall = () => {
    if (!phoneNumber) return;

    callProvider.callActions.startCall({ phoneNumber });
    onClose();
  };

  const isValidNumber = isValidPhoneNumber(phoneNumber);

  return (
    <div className="flex flex-col w-full space-y-6">
      <div className="flex items-center gap-3">
        <div className="flex-1 relative">
          <TextInput
            type="tel"
            placeholder="Ingresa un número de teléfono"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            size="lg"
            className={`text-md text-center ${
              !isValidNumber && phoneNumber ? 'border-danger-500' : ''
            }`}
          />
          {phoneNumber && (
            <Button
              variant="neutral"
              appearance="ghost"
              size="sm"
              isIconOnly
              className="absolute right-2 top-1/2 -translate-y-1/2"
              onClick={() => setPhoneNumber('')}
            >
              <PiXLight className="h-4 w-4" />
            </Button>
          )}
        </div>
        <Button
          variant="primary"
          size="lg"
          isIconOnly
          disabled={!isValidNumber}
          onClick={handleStartCall}
          className="rounded-full h-12 w-12 shadow-md flex-shrink-0"
        >
          <PiPhoneLight className="h-5 w-5" />
        </Button>
      </div>

      <div className="flex flex-col gap-2">
        <div className="flex items-center justify-end px-1">
          {/* <Typography className="text-md">Contactos</Typography> */}
          <Button
            variant="neutral"
            appearance="ghost"
            size="sm"
            onClick={handleViewContact}
            disabled={!selectedContactId}
            className="flex items-center gap-2"
          >
            <PiUserLight className="h-5 w-5" />
            Ver perfil
          </Button>
        </div>
        <ContactSelect
          onSelect={handleContactSelect}
          selectedContactId={selectedContactId}
        />
      </div>
    </div>
  );
};
