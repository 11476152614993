import React, { useState } from 'react';
import TextArea from 'src/modules/shared/components/atoms/inputs/text-area';
import Button from 'src/modules/shared/components/atoms/buttons/button';
import { PaperAirplaneIcon } from '@heroicons/react/24/solid';
import TemplateSuggestions from '../../../../../shared/messaging/components/molecules/templates/template-suggestions';
import { Template } from 'src/api/generated';
import { useThrottle } from 'src/modules/shared/hooks/throttle';
import { useTemplateSuggestions } from 'src/modules/shared/messaging/hooks/templates/suggestions';
import TemplateModal from 'src/modules/shared/messaging/components/organisms/templates/template-modal';

interface MessageInputProps {
  onSendMessage: (message: string) => void;
  isSendingMessage?: boolean;
  disabled?: boolean;
}

const SEARCH_THROTTLE_MS = 1000;

export const MessageInput: React.FC<MessageInputProps> = ({
  onSendMessage,
  isSendingMessage = false,
  disabled = false,
}) => {
  const [message, setMessage] = useState('');
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
  const throttledMessage = useThrottle(message, SEARCH_THROTTLE_MS);

  const handleTemplateSelect = (template: Template) => {
    // Replace the shortcut with the template content
    const words = message.split(' ');
    words.pop(); // Remove the shortcut
    const newMessage = [...words, template.content].join(' ');
    setMessage(newMessage);
  };

  const {
    isOpen: isSuggestionsOpen,
    suggestions,
    selectedIndex,
    loading: isLoadingSuggestions,
    hasMorePages,
    handleKeyDown,
    fetchMore,
    handleClose,
  } = useTemplateSuggestions({
    inputValue: throttledMessage,
    onSelect: handleTemplateSelect,
  });

  const handleSubmit = () => {
    if (!message.trim() || isSendingMessage || disabled) return;
    onSendMessage(message.trim());
    setMessage('');
  };

  return (
    <>
      <div className="flex-1 relative">
        <div className="flex gap-2">
          <TextArea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={(e) => {
              if (isSuggestionsOpen) {
                handleKeyDown(e);
              } else if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                handleSubmit();
              }
            }}
            // TODO: check when implementing i18n
            placeholder="Escribe / para buscar y usar una plantilla, o redacta tu mensaje..."
            disabled={disabled}
            rows={1}
          />
          <Button
            onClick={handleSubmit}
            disabled={!message.trim() || isSendingMessage || disabled}
            loading={isSendingMessage}
            size="sm"
            className="self-end"
          >
            <PaperAirplaneIcon className="h-4 w-4" />
          </Button>
        </div>

        <TemplateSuggestions
          suggestions={suggestions}
          selectedIndex={selectedIndex}
          loading={isLoadingSuggestions}
          hasMorePages={hasMorePages}
          isOpen={isSuggestionsOpen}
          onClose={handleClose}
          onSelect={handleTemplateSelect}
          onLoadMore={fetchMore}
          onCreateTemplate={() => setIsTemplateModalOpen(true)}
        />
      </div>

      <TemplateModal
        isOpen={isTemplateModalOpen}
        onClose={() => setIsTemplateModalOpen(false)}
      />
    </>
  );
};
