import { Typography } from '@material-tailwind/react';
import LoginButton from 'components/molecules/buttons/login-button';

function LandingView() {
  return (
    <div className="max-w-sm w-full mx-auto">
      <div className="bg-white/80 backdrop-blur-sm rounded-xl shadow-nm-floating-sm px-8 py-8">
        <div className="text-center mb-16">
          <img
            src="/favicon.svg"
            alt="LlamadaPro Logo"
            className="w-10 h-10 opacity-80 mx-auto mb-3"
          />
          <Typography variant="h3" className="text-secondary-800 font-medium">
            LlamadaPro
          </Typography>
        </div>
        <Typography
          variant="paragraph"
          className="text-secondary-600 text-center mb-8"
        >
          Tus comunicaciones en un sólo lugar
        </Typography>

        <LoginButton
          size="lg"
          className="w-full bg-primary-600 hover:bg-primary-700 shadow-nm-flat-xs"
        />
      </div>
    </div>
  );
}

export default LandingView;
