import { useState, useCallback } from 'react';
import {
  type DragEndEvent,
  type DragOverEvent,
  type DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

interface UseBoardDndParams<TItem> {
  onItemMove?: (
    itemId: string,
    targetContainerId: string,
    sourceContainerId: string
  ) => Promise<void>;
  onError?: (error: unknown) => void;
  getItemData?: (event: DragStartEvent) => TItem | undefined;
  getContainerId?: (event: DragOverEvent | DragEndEvent) => string | undefined;
  getSourceContainerId?: (event: DragEndEvent) => string | undefined;
}

interface UseBoardDndReturn<TItem> {
  activeItem: TItem | null;
  activeContainerId: string | null;
  shouldAnimate: boolean;
  isUpdating: boolean;
  sensors: ReturnType<typeof useSensors>;
  handleDragStart: (event: DragStartEvent) => void;
  handleDragOver: (event: DragOverEvent) => void;
  handleDragEnd: (event: DragEndEvent) => void;
}

export function useBoardDnd<TItem>({
  onItemMove,
  onError,
  getItemData,
  getContainerId,
  getSourceContainerId,
}: UseBoardDndParams<TItem>): UseBoardDndReturn<TItem> {
  const [activeItem, setActiveItem] = useState<TItem | null>(null);
  const [shouldAnimate, setShouldAnimate] = useState(true);
  const [activeContainerId, setActiveContainerId] = useState<string | null>(
    null
  );
  const [isUpdating, setIsUpdating] = useState(false);

  /**
   * You can adjust the distance and/or delay here.
   *
   * - distance: Lowering it (e.g., 5: must move pointer at least 5px) makes drag
   * start quickly with a small pointer movement.
   * - delay: A short delay (e.g., 150ms: must hold down for 150ms) can help avoid
   * accidental drags if the user is just tapping/clicking.
   */
  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  const sensors = useSensors(
    pointerSensor
    // , keyboardSensor
    // , touchSensor
  );

  function resetDragState(animate: boolean) {
    setShouldAnimate(animate);
    setActiveItem(null);
    setActiveContainerId(null);
  }

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      if (!getItemData) return;

      const item = getItemData(event);
      if (item) {
        resetDragState(true);
        setActiveItem(item);
      }
    },
    [getItemData]
  );

  const handleDragOver = useCallback(
    (event: DragOverEvent) => {
      if (!getContainerId) return;

      const containerId = getContainerId(event);
      setActiveContainerId(containerId ?? null);
    },
    [getContainerId]
  );

  const handleDragEnd = useCallback(
    async (event: DragEndEvent) => {
      if (
        !getContainerId ||
        !getItemData ||
        !onItemMove ||
        !getSourceContainerId
      ) {
        return;
      }

      const targetContainerId = getContainerId(event);
      if (!targetContainerId) {
        resetDragState(true);
        return;
      }

      const sourceContainerId = getSourceContainerId(event);
      if (!sourceContainerId) {
        console.error('No source container ID found');
        resetDragState(true);
        return;
      }

      if (targetContainerId === sourceContainerId) {
        resetDragState(true);
        return;
      }

      setShouldAnimate(false);
      setIsUpdating(true);

      try {
        await onItemMove(
          event.active.id.toString(),
          targetContainerId,
          sourceContainerId
        );
        setActiveItem(null);
        setActiveContainerId(null);
      } catch (error) {
        onError?.(error);
        resetDragState(true);
      } finally {
        setIsUpdating(false);
      }
    },
    [getContainerId, getItemData, onItemMove, onError, getSourceContainerId]
  );

  return {
    activeItem,
    activeContainerId,
    shouldAnimate,
    isUpdating,
    sensors,
    handleDragStart,
    handleDragOver,
    handleDragEnd,
  };
}
