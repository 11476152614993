import { type ComponentVariant } from 'src/modules/shared/types/component.type';

interface InputFieldSkeletonProps {
  variant?: ComponentVariant;
  labelClassName?: string;
  inputClassName?: string;
  className?: string;
  showLabel?: boolean;
}

export const InputFieldSkeleton: React.FC<InputFieldSkeletonProps> = ({
  variant = 'primary',
  labelClassName = 'w-1/4',
  inputClassName = 'w-2/3',
  className = '',
  showLabel = false,
}) => {
  const variantClasses: Record<ComponentVariant, string> = {
    primary: 'bg-secondary-200',
    secondary: 'bg-secondary-200',
    danger: 'bg-danger-200',
    neutral: 'bg-neutral-200',
    warning: 'bg-warning-200',
    success: 'bg-success-200',
    info: 'bg-info-200',
  };

  return (
    <div className={`space-y-1 ${className}`}>
      {showLabel && (
        <div
          className={`h-4 rounded ${variantClasses[variant]} mb-1 ${labelClassName}`}
        />
      )}
      <div
        className={`h-8 rounded ${variantClasses[variant]} ${inputClassName}`}
      />
    </div>
  );
};
