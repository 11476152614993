import { ComponentProps, forwardRef } from 'react';
import {
  BaseProps,
  ComponentSize,
} from 'src/modules/shared/types/component.type';
import {
  baseInputClasses,
  inputVariantClasses,
  getDisabledClasses,
  getErrorClasses,
} from 'src/modules/shared/styles/component.styles';
import InputError from '../input-error';

export const textAreaSizeClasses: Record<NonNullable<ComponentSize>, string> = {
  '2xs': 'py-1 px-1.5 text-xs',
  xs: 'py-1.5 px-2 text-xs',
  sm: 'py-2 px-2.5 text-sm',
  md: 'py-2 px-3.5 text-sm',
  lg: 'py-3 px-4.5 text-base',
};

interface TextAreaProps
  extends BaseProps<Omit<ComponentProps<'textarea'>, 'size' | 'variant'>> {}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      className = '',
      variant = 'primary',
      size = 'md',
      disabled = false,
      error,
      ...rest
    },
    ref
  ) => {
    const classes = [
      ...baseInputClasses,
      inputVariantClasses[variant],
      textAreaSizeClasses[size as ComponentSize],
      getDisabledClasses(disabled),
      getErrorClasses(!!error),
      'border',
      'resize-none',
      className,
    ].join(' ');

    return (
      <div className="flex flex-1 flex-col w-full">
        <textarea ref={ref} className={classes} disabled={disabled} {...rest} />
        <InputError message={error} />
      </div>
    );
  }
);

TextArea.displayName = 'TextArea';

export default TextArea;
