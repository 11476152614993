import { useQuery } from '@tanstack/react-query';
import {
  CrmCustomFieldsDefinitionsOptionsService,
  CrmCustomFieldsOptionsService,
  CustomFieldListOption,
  CustomFieldListOptionGetResponse,
  CustomFieldListOptionListQueryParams,
  CustomFieldListOptionListResponse,
} from 'src/api/generated';
import { ApiResponse, useMemoizedArrayData } from 'src/hooks/api/api';

interface UseCustomFieldListOptionsParams
  extends CustomFieldListOptionListQueryParams {
  enabled?: boolean;
}

interface UseCustomFieldListOptionsResponse extends ApiResponse {
  options: CustomFieldListOption[];
}

/**
 * Hook for fetching list options
 */
export const useCustomFieldListOptions = (
  params: UseCustomFieldListOptionsParams
): UseCustomFieldListOptionsResponse => {
  const { enabled = true, ...queryParams } = params;

  const fetchOptions = async (): Promise<CustomFieldListOptionListResponse> => {
    return await CrmCustomFieldsOptionsService.getCustomFieldListOptions(
      queryParams.filterBy,
      queryParams.searchBy,
      queryParams.sortBy,
      queryParams.page,
      queryParams.pageSize,
      queryParams.pageToken
    );
  };

  const {
    data,
    error,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: ['crm/custom-fields/list-options', queryParams],
    queryFn: fetchOptions,
    enabled: enabled,
  });

  const options = useMemoizedArrayData<CustomFieldListOption>(
    data?.items || []
  );

  return {
    loading,
    error,
    options,
    refetch,
  };
};

interface UseCustomFieldListOptionResponse extends ApiResponse {
  option: CustomFieldListOption | null;
}

/**
 * Hook for fetching a single list option by ID
 */
export const useCustomFieldListOption = (
  definitionId: number,
  optionId: number,
  enabled: boolean = true
): UseCustomFieldListOptionResponse => {
  const fetchOption = async (): Promise<CustomFieldListOptionGetResponse> => {
    return await CrmCustomFieldsDefinitionsOptionsService.getCustomFieldListOption(
      definitionId,
      optionId
    );
  };

  const {
    data,
    error,
    isLoading: loading,
    refetch,
  } = useQuery({
    queryKey: [
      'crm/custom-fields/definitions/list-options',
      definitionId,
      optionId,
    ],
    queryFn: fetchOption,
    enabled: enabled && !!definitionId && !!optionId,
  });

  return {
    loading,
    error,
    option: data || null,
    refetch,
  };
};
