import {
  ComponentSize,
  type ComponentVariant,
} from 'src/modules/shared/types/component.type';
import { TableCell, TableRow } from '@mui/material';
import { tableCellPadding } from './styles';

interface TableRowSkeletonProps {
  cellCount: number;
  variant?: ComponentVariant;
  size?: ComponentSize;
  showSelection?: boolean;
  className?: string;
}

export const TableRowSkeleton = ({
  cellCount,
  variant = 'primary',
  size = 'md',
  showSelection = false,
  className = '',
}: TableRowSkeletonProps) => {
  const variantClasses: Record<ComponentVariant, string> = {
    primary: 'bg-secondary-300',
    secondary: 'bg-secondary-300',
    danger: 'bg-danger-300',
    neutral: 'bg-neutral-300',
    warning: 'bg-warning-300',
    success: 'bg-success-300',
    info: 'bg-info-300',
  };

  return (
    <TableRow className={`${className} ${tableCellPadding[size]}`}>
      {showSelection && (
        <TableCell padding="checkbox">
          <div
            className={`h-4 w-4 rounded ${variantClasses[variant]} ml-2 animate-pulse`}
          />
        </TableCell>
      )}
      {Array.from({ length: cellCount }).map((_, index) => (
        <TableCell key={index}>
          <div
            className={`h-4 rounded ${variantClasses[variant]} ${tableCellPadding[size]} animate-pulse`}
            style={{
              width: `${Math.floor(Math.random() * 30 + 70)}%`,
            }}
          />
        </TableCell>
      ))}
    </TableRow>
  );
};
