import { useState, useMemo } from 'react';
import { Sort, SortFieldOptions } from '../../types/filters.types';
import { SearcherDocumentSearchSort } from 'src/api/generated';

export interface UseSortParams {
  fieldsOptions: SortFieldOptions[];
  initialSorts?: Sort[];
}

export interface UseSortResult {
  sorts: Sort[];
  apiSorts: SearcherDocumentSearchSort[];
  setSorts: (sorts: Sort[]) => void;
  fieldsOptions: SortFieldOptions[];
  activeSortCount: number;
  clearSorts: () => void;
}

export const useSorts = ({
  fieldsOptions,
  initialSorts = [],
}: UseSortParams): UseSortResult => {
  const [sorts, setSorts] = useState<Sort[]>(initialSorts);

  const apiSorts = useMemo(() => {
    return sorts.map((sort) => ({
      field: sort.fieldOptionId,
      direction: sort.direction,
    }));
  }, [sorts]);

  const activeSortCount = useMemo(() => {
    return sorts.length;
  }, [sorts]);

  const clearSorts = () => {
    setSorts([]);
  };

  return {
    sorts,
    apiSorts,
    setSorts,
    fieldsOptions,
    activeSortCount,
    clearSorts,
  };
};
