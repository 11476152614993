import { useNavigate, useSearch } from '@tanstack/react-router';
import { PiSquareSplitVertical } from 'react-icons/pi';
import { HiOutlineViewColumns, HiOutlineTableCells } from 'react-icons/hi2';
import {
  Route as CommunicationsRoute,
  communicationsSearchSchema,
} from 'src/routes/communications';
import {
  ButtonGroup,
  ButtonGroupOption,
} from 'src/modules/shared/components/molecules/buttons/button-group';

type ViewType = 'cards' | 'pipeline' | 'table';

interface ViewSwitcherProps {
  showLabels?: boolean;
}

const ViewSwitcher: React.FC<ViewSwitcherProps> = ({ showLabels = true }) => {
  const navigate = useNavigate();
  const { view } = useSearch({ from: CommunicationsRoute.id });

  const handleViewChange = (newView: ViewType) => {
    navigate({
      to: CommunicationsRoute.to,
      search: (prev) => {
        const parsedSearch = communicationsSearchSchema.parse(prev);
        return {
          ...parsedSearch,
          view: newView,
        };
      },
    });
  };

  const options: ButtonGroupOption<ViewType>[] = [
    {
      value: 'cards',
      label: 'Cards',
      icon: <PiSquareSplitVertical className="w-4 h-4" />,
    },
    {
      value: 'pipeline',
      label: 'Pipeline',
      icon: <HiOutlineViewColumns className="w-4 h-4" />,
    },
    {
      value: 'table',
      label: 'Table',
      icon: <HiOutlineTableCells className="w-4 h-4" />,
    },
  ];

  return (
    <ButtonGroup
      options={options}
      value={view}
      onChange={handleViewChange}
      size="sm"
      showLabels={showLabels}
    />
  );
};

export default ViewSwitcher;
