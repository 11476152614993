import React from 'react';
import ViewContainer from 'components/atoms/view-container';
import { Chat } from 'src/modules/messaging/components/organisms/conversations/chat';
import { BoltIcon } from '@heroicons/react/24/outline';
import { SiWhatsapp } from 'react-icons/si';
import MenuDropdown, {
  DropdownItem,
} from 'src/modules/shared/components/molecules/dropdowns/menu-dropdown';
import TemplateListModal from 'src/modules/shared/messaging/components/organisms/templates/template-list-modal';

const WHATSAPP_TEMPLATES_MANAGEMENT_URL =
  'https://business.facebook.com/latest/whatsapp_manager/message_templates';

const ConversationsView: React.FC = () => {
  const [isTemplateListModalOpen, setIsTemplateListModalOpen] =
    React.useState(false);

  const templateMenuItems: DropdownItem[] = [
    {
      icon: <BoltIcon className="h-4 w-4" />,
      // TODO: check when implementing i18n
      content: 'Plantillas rápidas',
      onClick: () => setIsTemplateListModalOpen(true),
    },
    {
      icon: <SiWhatsapp className="h-4 w-4" />,
      // TODO: check when implementing i18n
      content: 'Plantillas de WhatsApp',
      onClick: () => window.open(WHATSAPP_TEMPLATES_MANAGEMENT_URL, '_blank'),
    },
  ];

  return (
    <ViewContainer
      title="Conversaciones"
      headerElement={
        <>
          <MenuDropdown
            // TODO: check when implementing i18n
            label="Plantillas"
            items={templateMenuItems}
            size="md"
            variant="secondary"
            appearance="outline"
          />
          <TemplateListModal
            isOpen={isTemplateListModalOpen}
            onClose={() => setIsTemplateListModalOpen(false)}
          />
        </>
      }
    >
      <Chat />
    </ViewContainer>
  );
};

export default ConversationsView;
