/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { Attachment } from './Attachment';
import type { ConversationChannel } from './ConversationChannel';
import type { Dictionary_unknown_ } from './Dictionary_unknown_';
import type { MessageDirection } from './MessageDirection';
import type { MessageError } from './MessageError';
import type { MessageParticipantInfo } from './MessageParticipantInfo';
import type { MessageStatus } from './MessageStatus';

/**
 * Interface representing a message document
 */
export type MessageDocument = {
    id: string;
    tenantId: string;
    providerId: string;
    userId?: string;
    direction: MessageDirection;
    status: MessageStatus;
    metadata?: Record<string, any>;
    customFields?: Dictionary_unknown_;
    conversationId: string;
    content: string;
    channel: ConversationChannel;
    sender: MessageParticipantInfo;
    recipient?: MessageParticipantInfo;
    attachments?: Array<Attachment>;
    errors?: Array<MessageError>;
    modelType: MessageDocument.modelType;
    createdAt: string;
    updatedAt: string;
    archivedAt?: string | null;
    eventSequence?: number;
    eventTimestamp?: string;
    funnelId?: string;
    funnelConversationId?: string;
    contactId?: string;
    sessionId?: string;
};

export namespace MessageDocument {

    export enum modelType {
        MESSAGE = 'Message',
    }


}

