import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  ContactAddress,
  CrmContactsAddressesService,
  ContactAddressCreateBodyParams,
  ContactAddressUpdateBodyParams,
  ContactAddressDeleteResponse,
  ContactAddressUpdateResponse,
} from 'src/api/generated';
import {
  getQueriesSnapshots,
  SnapshotType,
} from 'src/modules/shared/utils/cache/update.util';
import { updateContactAddressCache } from '../../utils/cache/contact-addresses/update.util';

interface UseCreateContactAddressMutationResponse {
  createAddress: (
    data: ContactAddressCreateBodyParams
  ) => Promise<ContactAddress>;
  loading: boolean;
  error: Error | null;
}

export const useCreateContactAddressMutation = (
  onSuccess: (newAddress: ContactAddress) => void = () => {},
  onError: (error: Error) => void = () => {}
): UseCreateContactAddressMutationResponse => {
  const queryClient = useQueryClient();

  const createContactAddress = async (
    data: ContactAddressCreateBodyParams
  ): Promise<ContactAddress> => {
    return await CrmContactsAddressesService.createContactAddress(data);
  };

  const {
    mutateAsync,
    isPending: loading,
    error,
  } = useMutation({
    mutationFn: createContactAddress,
    onSuccess: async (newAddress) => {
      await updateContactAddressCache(queryClient, {
        id: newAddress.id,
        data: newAddress,
      });

      onSuccess(newAddress);
    },
    onError: (error: Error) => onError(error),
  });

  return {
    createAddress: mutateAsync,
    loading,
    error,
  };
};

interface UpdateContactAddressParams {
  id: number;
  data: ContactAddressUpdateBodyParams;
}

interface UseUpdateContactAddressMutationResponse {
  updateAddress: (
    params: UpdateContactAddressParams
  ) => Promise<ContactAddress>;
  loading: boolean;
  error: Error | null;
}

export const useUpdateContactAddressMutation = (
  onSuccess: (newAddress: ContactAddress) => void = () => {},
  onError: (error: Error) => void = () => {}
): UseUpdateContactAddressMutationResponse => {
  const queryClient = useQueryClient();

  const updateContactAddress = async (
    params: UpdateContactAddressParams
  ): Promise<ContactAddressUpdateResponse> => {
    return await CrmContactsAddressesService.updateContactAddress(
      params.id,
      params.data
    );
  };

  const mutation = useMutation({
    mutationFn: updateContactAddress,
    onMutate: async (params) => {
      const snapshotQueries: SnapshotType[] = await getQueriesSnapshots(
        queryClient,
        [
          ['crm/contacts/addresses'],
          ['crm/contacts'],
          ['crm/documents/contacts'],
          ['crm/funnel-conversations'],
          ['crm/documents/funnel-conversations'],
        ]
      );

      await updateContactAddressCache(queryClient, params);

      return {
        snapshotQueries,
      };
    },
    onError: (error, _variables, context) => {
      if (context?.snapshotQueries) {
        context.snapshotQueries.forEach(({ queries }) => {
          queries.forEach(([queryKey, queryData]) => {
            queryClient.setQueryData(queryKey, queryData);
          });
        });
      }

      onError(error);
    },
    onSuccess: async (updatedAddress, _variables, _context) => {
      await updateContactAddressCache(queryClient, {
        id: updatedAddress.id,
        data: updatedAddress,
      });

      onSuccess(updatedAddress);
    },
  });

  return {
    updateAddress: mutation.mutateAsync,
    loading: mutation.isPending,
    error: mutation.error,
  };
};

interface UseDeleteContactAddressMutationResponse {
  deleteAddress: (id: number) => Promise<ContactAddress>;
  loading: boolean;
  error: Error | null;
}

export const useDeleteContactAddressMutation = (
  onSuccess: () => void = () => {},
  onError: (error: Error) => void = () => {}
): UseDeleteContactAddressMutationResponse => {
  const queryClient = useQueryClient();

  const deleteContactAddress = async (
    id: number
  ): Promise<ContactAddressDeleteResponse> => {
    return await CrmContactsAddressesService.deleteContactAddress(id);
  };

  const {
    mutateAsync,
    isPending: loading,
    error,
  } = useMutation({
    mutationFn: deleteContactAddress,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['crm/contacts/addresses'],
      });
      onSuccess();
    },
    onError: (error: Error) => onError(error),
  });

  return {
    deleteAddress: mutateAsync,
    loading,
    error,
  };
};
