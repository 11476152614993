import React from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import {
  ComponentSize,
  ComponentVariant,
} from 'src/modules/shared/types/component.type';
import Button from '../../../atoms/buttons/button';
import Dropdown, { DropdownItem, DropdownPlacement } from '../dropdown';

export type { DropdownItem };

interface SplitButtonDropdownProps {
  label: string;
  onClick: () => void;
  items: DropdownItem[];
  className?: string;
  size?: ComponentSize;
  variant?: ComponentVariant;
  icon?: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  placement?: DropdownPlacement;
}

export const SplitButtonDropdown: React.FC<SplitButtonDropdownProps> = ({
  label,
  onClick,
  items,
  className = '',
  size = 'md',
  variant = 'primary',
  icon,
  disabled = false,
  loading = false,
  placement = 'bottom-end',
}) => {
  const buttonSizeToDropdownSize: Record<ComponentSize, string> = {
    lg: 'h-10',
    md: 'h-8',
    sm: 'h-7',
    xs: 'h-6',
    '2xs': 'h-4',
  };

  const handler = (
    <Button
      variant={variant}
      size={size}
      className={`${buttonSizeToDropdownSize[size]} px-2 border-l border-opacity-50 rounded-l-none ${className}`}
      disabled={disabled}
      loading={loading}
    >
      <ChevronDownIcon className="h-4 w-4" />
    </Button>
  );

  return (
    <div className="inline-flex">
      <Button
        variant={variant}
        size={size}
        onClick={onClick}
        className={`rounded-r-none border-r-0 ${className}`}
        disabled={disabled}
        loading={loading}
      >
        <div className="flex items-center justify-center">
          {icon}
          <span>{label}</span>
        </div>
      </Button>
      <Dropdown
        items={items}
        handler={handler}
        size={size}
        placement={placement}
      />
    </div>
  );
};
