import { Typography } from '@material-tailwind/react';
import { StyledIcon } from 'src/modules/shared/components/atoms/icons/styled-icon';
import { PiChatCircle, PiCaretRightBold } from 'react-icons/pi';
import {
  ChannelType,
  FunnelConversationDocument,
  FunnelInteractionDirection,
  FunnelInteractionType,
} from 'src/api/generated';
import { formatDate } from 'src/modules/shared/utils/formatting/date.util';
import { formatFullName } from 'src/modules/shared/utils/formatting/name.util';
import { TableColumnProps } from 'src/modules/shared/components/molecules/table/table';
import { TagDropdown } from 'src/modules/shared/components/molecules/dropdowns/tag-dropdown';
import Button from 'src/modules/shared/components/atoms/buttons/button';
import { DirectionIndicator } from 'src/modules/shared/components/atoms/indicators/direction-indicator';
import { CallStatusIndicator } from 'src/modules/shared/components/atoms/indicators/call-status-indicator';
import {
  tableCellText,
  tableVariantClasses,
} from 'src/modules/shared/components/molecules/table/styles';
import { isValidPhoneNumber } from 'src/utils/validation/phone';
import { EditableAddressField } from 'src/modules/communications/components/molecules/contacts/editable-address-field';
import {
  getActionTooltip,
  isActionDisabled,
} from 'src/modules/communications/utils/quick-phone.util';

interface PhoneState {
  isLoading: boolean;
  hasError: boolean;
  isReady: boolean;
  isDisabled: boolean;
}

interface BasicColumnsParams {
  users?: { id: number; firstName: string; lastName: string }[];
  statuses?: { id: number; name: string }[];
  selectedConversationId?: number;
  onConversationSelect?: (id: number) => void;
  handleStatusChange: (conversationId: number, statusId: number) => void;
  handleUserAssign: (conversationId: number, userId: number | null) => void;
  handleCall?: (phoneNumber: string) => void;
  phoneState?: PhoneState;
  updatingConversation: boolean;
  variant?: keyof typeof tableVariantClasses;
  handleUpdateAddress?: (
    addressId: number,
    data: { address: string }
  ) => Promise<void>;
  updatingAddressId?: number;
}

export const createBasicColumns = ({
  users = [],
  statuses = [],
  selectedConversationId,
  onConversationSelect,
  handleStatusChange,
  handleUserAssign,
  handleCall,
  phoneState = {
    isLoading: false,
    hasError: false,
    isReady: true,
    isDisabled: false,
  },
  updatingConversation,
  variant = 'primary',
  handleUpdateAddress,
  updatingAddressId,
}: BasicColumnsParams): TableColumnProps<FunnelConversationDocument>[] => {
  const { text, hoverText } = tableVariantClasses[variant];

  return [
    {
      // TODO: check when implementing i18n
      header: 'Contacto',
      accessor: 'id',
      render: (_value: unknown, row: FunnelConversationDocument) => {
        const contacts = row.contacts;
        if (!contacts || contacts.length === 0) return null;

        const contact = contacts[0];
        const isSelected = row.id === selectedConversationId?.toString();

        return (
          <Typography
            className={`font-semibold text-xs ${
              isSelected ? hoverText : text
            } cursor-pointer ${hoverText}`}
            onClick={() => onConversationSelect?.(Number(row.id))}
          >
            {formatFullName(contact.firstName, contact.lastName)}
          </Typography>
        );
      },
    },
    {
      // TODO: check when implementing i18n
      header: 'Teléfonos',
      accessor: 'phone_addresses',
      render: (_value: unknown, row: FunnelConversationDocument) => {
        const contacts = row.contacts;
        if (!contacts || contacts.length === 0 || !contacts[0].addresses) {
          return null;
        }

        const addresses = contacts[0].addresses;
        const phoneAddresses = addresses.filter(
          (a) =>
            a.channelType === ChannelType.PHONE ||
            a.channelType === ChannelType.WHATSAPP
        );

        if (phoneAddresses.length === 0) return null;

        return (
          <div className="flex flex-col gap-1">
            {phoneAddresses.map((address) => {
              const isValidPhone = isValidPhoneNumber(address.address);
              const isUpdating = updatingAddressId === address.id;

              return (
                <EditableAddressField
                  key={address.id}
                  address={address.address}
                  channelType={address.channelType}
                  onSave={async ({ address: newAddress }) => {
                    if (!handleUpdateAddress) return;

                    await handleUpdateAddress(address.id, {
                      address: newAddress,
                    });
                  }}
                  onAction={isValidPhone && handleCall ? handleCall : undefined}
                  loading={isUpdating}
                  // TODO: check when implementing i18n
                  placeholder="Ingrese teléfono..."
                  actionDisabled={isActionDisabled(
                    ChannelType.PHONE,
                    phoneState
                  )}
                  actionLoading={phoneState.isLoading}
                  actionTooltip={getActionTooltip(
                    ChannelType.PHONE,
                    phoneState,
                    isValidPhone
                  )}
                />
              );
            })}
          </div>
        );
      },
    },
    {
      // TODO: check when implementing i18n
      header: 'Correos',
      accessor: 'email_addresses',
      render: (_value: unknown, row: FunnelConversationDocument) => {
        const contacts = row.contacts;
        if (!contacts || contacts.length === 0 || !contacts[0].addresses) {
          return null;
        }

        const addresses = contacts[0].addresses;
        const emailAddresses = addresses.filter(
          (a) => a.channelType === ChannelType.EMAIL
        );

        if (emailAddresses.length === 0) return null;

        return (
          <div className="flex flex-col gap-1 w-full">
            {emailAddresses.map((address) => {
              const isUpdating = updatingAddressId === address.id;

              return (
                <div key={address.id} className="w-full">
                  <EditableAddressField
                    address={address.address}
                    channelType={address.channelType}
                    onSave={async ({ address: newAddress }) => {
                      if (!handleUpdateAddress) return;

                      await handleUpdateAddress(address.id, {
                        address: newAddress,
                      });
                    }}
                    loading={isUpdating}
                    onAction={() => {
                      // TODO: handle email action
                    }}
                    placeholder="Ingrese correo..."
                  />
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      // TODO: check when implementing i18n
      header: 'Estado',
      accessor: 'funnelStatusId',
      render: (value: unknown, row: FunnelConversationDocument) => {
        const statusId = value as string;
        const currentStatus = statuses?.find((s) => String(s.id) === statusId);

        const statusOptions =
          statuses?.map((status) => ({
            id: status.id,
            content: status.name,
            selected: String(status.id) === statusId,
          })) ?? [];

        return (
          <TagDropdown
            content={currentStatus?.name || 'Sin estado'}
            variant="primary"
            appearance="outline"
            size="sm"
            options={statusOptions}
            onOptionSelect={(option) =>
              handleStatusChange(Number(row.id), Number(option.id))
            }
            isLoading={updatingConversation}
          />
        );
      },
    },
    {
      // TODO: check when implementing i18n
      header: 'Asignado',
      accessor: 'userId',
      render: (value: unknown, row: FunnelConversationDocument) => {
        const userId = value as number;
        const currentUser = users?.find((u) => u.id === userId);

        const userOptions = [
          {
            id: -1,
            content: 'Sin asignar',
            selected: !userId,
          },
          ...(users?.map((user) => ({
            id: user.id,
            content: formatFullName(user.firstName, user.lastName),
            selected: user.id === userId,
          })) ?? []),
        ];

        return (
          <TagDropdown
            content={
              currentUser
                ? formatFullName(currentUser.firstName, currentUser.lastName)
                : 'Sin asignar'
            }
            variant="primary"
            appearance="outline"
            size="sm"
            options={userOptions}
            onOptionSelect={(option) =>
              handleUserAssign(
                Number(row.id),
                option.id === -1 ? null : Number(option.id)
              )
            }
            isLoading={updatingConversation}
          />
        );
      },
    },
    {
      // TODO: check when implementing i18n
      header: 'Última interacción',
      accessor: 'lastInteraction',
      render: (value: unknown) => {
        const lastInteraction =
          value as FunnelConversationDocument['lastInteraction'];
        if (!lastInteraction) {
          return null;
        }

        const { modelType, direction, status, snippet } = lastInteraction;

        return (
          <div className="flex items-start gap-2">
            <div className="flex items-center gap-1 mt-1">
              {direction && (
                <DirectionIndicator
                  isOutbound={direction === FunnelInteractionDirection.OUTGOING}
                  className={
                    modelType === FunnelInteractionType.CALL
                      ? '!text-call-500'
                      : '!text-whatsapp-500'
                  }
                />
              )}
              {modelType === FunnelInteractionType.CALL ? (
                <CallStatusIndicator status={status} size="sm" />
              ) : (
                <StyledIcon
                  icon={<PiChatCircle className="w-4 h-4" />}
                  variant="secondary"
                  size="sm"
                  className="!text-whatsapp-500"
                />
              )}
            </div>
            <Typography
              className={`${tableCellText} ${text} truncate max-w-[18rem] flex-1`}
            >
              {snippet}
            </Typography>
          </div>
        );
      },
    },
    {
      // TODO: check when implementing i18n
      header: 'Fecha de actualización',
      accessor: 'updatedAt',
      render: (value: unknown) => (
        <Typography className={`${tableCellText} ${text}`}>
          {formatDate(value as string)}
        </Typography>
      ),
    },
    {
      // TODO: check when implementing i18n
      header: 'Fecha de creación',
      accessor: 'createdAt',
      render: (value: unknown) => (
        <Typography className={`${tableCellText} ${text}`}>
          {formatDate(value as string)}
        </Typography>
      ),
    },
    {
      header: '',
      accessor: 'actions',
      className: '!w-16 !min-w-0',
      render: (_value: unknown, row: FunnelConversationDocument) => (
        <Button
          variant={
            row.id === selectedConversationId?.toString()
              ? 'primary'
              : 'secondary'
          }
          appearance="ghost"
          size="sm"
          onClick={() => {
            onConversationSelect?.(Number(row.id));
          }}
          isIconOnly
          className={
            row.id === selectedConversationId?.toString() ? '' : `${hoverText}`
          }
        >
          <PiCaretRightBold className="w-5 h-5" />
        </Button>
      ),
    },
  ];
};
