/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FunnelChannelSessionCreateBodyParams } from '../models/FunnelChannelSessionCreateBodyParams';
import type { FunnelChannelSessionCreateResponse } from '../models/FunnelChannelSessionCreateResponse';
import type { FunnelChannelSessionDeleteResponse } from '../models/FunnelChannelSessionDeleteResponse';
import type { FunnelChannelSessionGetResponse } from '../models/FunnelChannelSessionGetResponse';
import type { FunnelChannelSessionListResponse } from '../models/FunnelChannelSessionListResponse';
import type { FunnelChannelSessionUpdateBodyParams } from '../models/FunnelChannelSessionUpdateBodyParams';
import type { FunnelChannelSessionUpdateResponse } from '../models/FunnelChannelSessionUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CrmFunnelsChannelSessionsService {

    /**
     * @param filterBy
     * @param searchBy
     * @param sortBy
     * @param page
     * @param pageSize
     * @param pageToken
     * @returns FunnelChannelSessionListResponse Ok
     * @throws ApiError
     */
    public static getFunnelChannelSessions(
        filterBy?: string,
        searchBy?: string,
        sortBy?: string,
        page?: number,
        pageSize?: number,
        pageToken?: string,
    ): CancelablePromise<FunnelChannelSessionListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/crm/funnel-channel-sessions',
            query: {
                'filterBy': filterBy,
                'searchBy': searchBy,
                'sortBy': sortBy,
                'page': page,
                'pageSize': pageSize,
                'pageToken': pageToken,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns FunnelChannelSessionCreateResponse Ok
     * @throws ApiError
     */
    public static createFunnelChannelSession(
        requestBody: FunnelChannelSessionCreateBodyParams,
    ): CancelablePromise<FunnelChannelSessionCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/crm/funnel-channel-sessions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param id
     * @returns FunnelChannelSessionGetResponse Ok
     * @throws ApiError
     */
    public static getFunnelChannelSession(
        id: number,
    ): CancelablePromise<FunnelChannelSessionGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/crm/funnel-channel-sessions/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns FunnelChannelSessionUpdateResponse Ok
     * @throws ApiError
     */
    public static updateFunnelChannelSession(
        id: number,
        requestBody: FunnelChannelSessionUpdateBodyParams,
    ): CancelablePromise<FunnelChannelSessionUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/crm/funnel-channel-sessions/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
            },
        });
    }

    /**
     * @param id
     * @returns FunnelChannelSessionDeleteResponse Ok
     * @throws ApiError
     */
    public static deleteFunnelChannelSession(
        id: number,
    ): CancelablePromise<FunnelChannelSessionDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/crm/funnel-channel-sessions/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
            },
        });
    }

    /**
     * @param id
     * @returns FunnelChannelSessionUpdateResponse Ok
     * @throws ApiError
     */
    public static archiveFunnelChannelSession(
        id: number,
    ): CancelablePromise<FunnelChannelSessionUpdateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/crm/funnel-channel-sessions/{id}/archive',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
            },
        });
    }

}
