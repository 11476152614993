import React, { useRef, useState } from 'react';
import { useMessages } from 'src/modules/messaging/hooks/api/messages';
import { useCreateWhatsAppMessage } from 'src/modules/messaging/whatsapp/hooks/api/messages-mutations';
import { useAlert } from 'src/contexts/alert-context';
import InfoError from 'components/atoms/info-error';
import { WhatsAppTemplateModal } from '../../../../whatsapp/components/organisms/templates/template-modal';
import { getContactAddress } from 'src/modules/messaging/utils/participant.util';
import { MessageItem } from '../../../molecules/messages/message-item';
import { MessageInput } from '../../../molecules/messages/message-input';
import Button from 'src/modules/shared/components/atoms/buttons/button';
import {
  Conversation,
  Participant,
  WhatsAppMessageType,
} from 'src/api/generated';
import {
  useInfiniteScroll,
  useScrollPosition,
  useScrollToBottom,
} from 'src/modules/shared/hooks/scroll/movement';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { isConversationExpired } from 'src/modules/messaging/utils/conversations/conversation.util';
import Loading from 'components/atoms/loading/loading';
import { SiWhatsapp } from 'react-icons/si';

interface MessageListProps {
  conversation: Conversation;
  participants: Participant[];
}

export const MessageList: React.FC<MessageListProps> = ({
  conversation,
  participants,
}) => {
  const [isWhatsAppTemplateModalOpen, setIsWhatsAppTemplateModalOpen] =
    useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const bottomAnchorRef = useRef<HTMLDivElement>(null);

  const { showAlert } = useAlert();

  const {
    messages,
    loading,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useMessages({
    conversationId: conversation.id,
  });

  const { isAtBottom, handleScroll } = useScrollPosition(messages);
  const { scrollToBottom } = useScrollToBottom({
    bottomAnchorRef,
    isAtBottom,
    dependencies: messages,
  });
  useInfiniteScroll({
    containerRef: scrollContainerRef,
    isFetching: isFetchingNextPage,
    hasMore: hasNextPage,
    fetchMore: fetchNextPage,
  });

  const { createWhatsAppMessage, loading: isSendingMessage } =
    useCreateWhatsAppMessage(
      // TODO: check when implementing i18n
      () => {
        showAlert('Mensaje enviado correctamente.', 'success');
      },
      (error) => {
        console.error('Error sending message:', error);
        showAlert(
          'Ocurrió un error enviando el mensaje. Por favor revisa tu conexión e intenta nuevamente.',
          'error'
        );
      }
    );

  const { id: conversationId, integrationProviderId } = conversation;
  const fromPhoneNumberId = conversation.mainParticipant.providerId;
  const toNumber = getContactAddress(participants);

  const handleSendMessage = (message: string) => {
    if (!fromPhoneNumberId || !toNumber) return;

    createWhatsAppMessage({
      conversationId,
      content: message,
      integrationProviderId,
      fromPhoneNumberId: fromPhoneNumberId,
      toPhoneNumber: toNumber,
      type: WhatsAppMessageType.TEXT,
    });
  };

  const conversationExpired = isConversationExpired(conversation);

  if (loading) return <Loading className="h-full items-center" />;
  if (error) return <InfoError error={error} />;

  return (
    <>
      <div
        className="flex-1 overflow-y-auto space-y-4 p-4 bg-gray-100 shadow-inner relative"
        onScroll={handleScroll}
        ref={scrollContainerRef}
      >
        {isFetchingNextPage && (
          <div className="flex justify-center py-2">
            <Loading className="h-full items-center" />
          </div>
        )}

        {messages
          .slice()
          .reverse()
          .map((message) => (
            <MessageItem key={message.id} message={message} />
          ))}

        <div ref={bottomAnchorRef} />

        {!isAtBottom && (
          <div className="sticky bottom-4 flex justify-end">
            <Button
              onClick={scrollToBottom}
              size="sm"
              variant="secondary"
              appearance="outline"
              className="rounded-full"
            >
              <ChevronDownIcon className="w-5 h-5" />
            </Button>
          </div>
        )}
      </div>

      <div className="flex-none border-t border-gray-200 p-4 items-end space-x-2 bg-white flex">
        <MessageInput
          onSendMessage={handleSendMessage}
          isSendingMessage={isSendingMessage}
          disabled={conversationExpired}
        />
        <Button
          onClick={() => setIsWhatsAppTemplateModalOpen(true)}
          size="sm"
          variant="secondary"
          appearance="outline"
          className="flex items-center gap-1 px-2 py-1"
        >
          <SiWhatsapp className="h-4 w-4" />
          {/* TODO: check when implementing i18n */}
          Enviar plantilla
        </Button>
      </div>

      <WhatsAppTemplateModal
        isOpen={isWhatsAppTemplateModalOpen}
        onClose={() => setIsWhatsAppTemplateModalOpen(false)}
        fromPhoneNumberId={fromPhoneNumberId}
        toNumber={toNumber}
      />
    </>
  );
};
