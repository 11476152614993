import { MdSettings } from 'react-icons/md';
import { PhoneControlButtonProps } from '../types';
import Button from '../../../buttons/button';

export const SettingsButton: React.FC<PhoneControlButtonProps> = ({
  onClick,
  disabled = true,
  size = 'sm',
}) => {
  return (
    <Button
      variant="neutral"
      appearance="ghost"
      size={size}
      onClick={onClick}
      disabled={disabled}
      className="rounded-full w-8 h-8 p-0 flex items-center justify-center"
    >
      <MdSettings className="w-4 h-4" />
    </Button>
  );
};
