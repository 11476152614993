import React from 'react';
import Modal from 'src/modules/shared/components/molecules/modals/modal';
import { Typography } from '@material-tailwind/react';
import { Template } from 'src/api/generated';
import TemplateList from '../template-list';
import TemplateModal from '../template-modal';
import { useAlert } from 'src/contexts/alert-context';
import { useDeleteTemplate } from 'src/modules/shared/messaging/hooks/api/templates';

interface TemplateListModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const TemplateListModal: React.FC<TemplateListModalProps> = ({
  isOpen,
  onClose,
}) => {
  const [editingTemplate, setEditingTemplate] = React.useState<
    Template | undefined
  >();
  const [isCreating, setIsCreating] = React.useState(false);
  const { showAlert } = useAlert();

  // TODO: check when implementing i18n
  const { deleteTemplate } = useDeleteTemplate({
    onSuccess: () => {
      showAlert('Plantilla eliminada correctamente', 'success');
    },
    onError: () => {
      showAlert('Error al eliminar plantilla', 'error');
    },
  });

  const handleDeleteTemplate = async (template: Template) => {
    if (window.confirm('¿Estás seguro de eliminar esta plantilla?')) {
      deleteTemplate(template.id);
    }
  };

  const handleCreateTemplate = () => {
    setIsCreating(true);
  };

  const handleCloseTemplateModal = () => {
    setEditingTemplate(undefined);
    setIsCreating(false);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        header={
          <Typography variant="h5" color="blue-gray">
            {/* TODO: check when implementing i18n */}
            Plantillas rápidas
          </Typography>
        }
        body={
          <TemplateList
            onEditTemplate={setEditingTemplate}
            onDeleteTemplate={handleDeleteTemplate}
            onCreateTemplate={handleCreateTemplate}
          />
        }
      />

      <TemplateModal
        isOpen={!!editingTemplate || isCreating}
        onClose={handleCloseTemplateModal}
        template={editingTemplate}
      />
    </>
  );
};

export default TemplateListModal;
