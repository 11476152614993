import { Typography } from '@material-tailwind/react';
import React from 'react';
import { WhatsAppTemplate } from 'src/api/generated';
import { translateTemplateValues } from 'src/modules/messaging/whatsapp/utils/template.util';

interface TemplateInfoProps {
  template: WhatsAppTemplate;
}

const TemplateInfo: React.FC<TemplateInfoProps> = ({ template }) => {
  return (
    <div className="space-y-4">
      <div className="rounded-lg shadow-nm-flat-sm">
        <div className="flex items-center justify-between p-3">
          {/* TODO: check when implementing i18n */}
          <Typography className="text-md font-medium">
            Detalles de {translateTemplateValues('name', template.name)}
          </Typography>
        </div>
        <div className="py-2 px-8">
          <table className="min-w-full">
            <tbody className="divide-y divide-gray-200">
              <tr>
                {/* TODO: check when implementing i18n */}
                <td className="py-2">
                  <Typography className="text-sm font-medium text-gray-500">
                    Nombre
                  </Typography>
                </td>
                <td className="py-2 text-sm text-gray-600">
                  <Typography className="text-sm text-gray-600">
                    {translateTemplateValues('name', template.name)}
                  </Typography>
                </td>
              </tr>
              <tr>
                {/* TODO: check when implementing i18n */}
                <td className="py-2">
                  <Typography className="text-sm font-medium text-gray-500">
                    Idioma
                  </Typography>
                </td>
                <td className="py-2 text-sm text-gray-600">
                  <Typography className="text-sm text-gray-600">
                    {translateTemplateValues('language', template.language)}
                  </Typography>
                </td>
              </tr>
              <tr>
                {/* TODO: check when implementing i18n */}
                <td className="py-2">
                  <Typography className="text-sm font-medium text-gray-500">
                    Estado
                  </Typography>
                </td>
                <td className="py-2 text-sm text-gray-600">
                  <Typography className="text-sm text-gray-600">
                    {translateTemplateValues('status', template.status)}
                  </Typography>
                </td>
              </tr>
              <tr>
                {/* TODO: check when implementing i18n */}
                <td className="py-2">
                  <Typography className="text-sm font-medium text-gray-500">
                    Categoría
                  </Typography>
                </td>
                <td className="py-2 text-sm text-gray-600">
                  <Typography className="text-sm text-gray-600">
                    {translateTemplateValues('category', template.category)}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TemplateInfo;
