import { ComponentProps, forwardRef } from 'react';
import {
  BaseProps,
  ComponentSize,
  NeumorphicStyle,
} from 'src/modules/shared/types/component.type';
import {
  baseInputClasses,
  inputVariantClasses,
  inputSizeClasses,
  getDisabledClasses,
  getErrorClasses,
} from 'src/modules/shared/styles/component.styles';
import InputError from '../input-error';

interface TextInputProps
  extends BaseProps<Omit<ComponentProps<'input'>, 'size' | 'variant'>> {}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      className = '',
      variant = 'primary',
      size = 'md',
      disabled = false,
      error,
      neumorphic,
      ...rest
    },
    ref
  ) => {
    const neumorphicMap: Record<NeumorphicStyle, string> = {
      'inset-sm': 'shadow-nm-inset-2xs',
      inset: 'shadow-nm-inset-sm',
      flat: 'shadow-nm-flat',
      none: '',
    };
    const neumorphicClasses = neumorphic ? `${neumorphicMap[neumorphic]}` : '';

    const classes = [
      ...baseInputClasses,
      inputVariantClasses[variant],
      inputSizeClasses[size as ComponentSize],
      getDisabledClasses(disabled),
      getErrorClasses(!!error),
      'border',
      neumorphicClasses,
      className,
    ].join(' ');

    return (
      <div className="flex flex-1 flex-col w-full">
        <input
          ref={ref}
          type="text"
          className={classes}
          disabled={disabled}
          {...rest}
        />
        <InputError message={error} />
      </div>
    );
  }
);

TextInput.displayName = 'TextInput';

export default TextInput;
