import React, { useState } from 'react';
import { Template } from 'src/api/generated';
import { useTemplates } from 'src/modules/shared/messaging/hooks/api/templates';
import Loading from 'components/atoms/loading/loading';
import InfoError from 'components/atoms/info-error';
import Button from 'src/modules/shared/components/atoms/buttons/button';
import { PlusIcon } from '@heroicons/react/24/outline';
import SearchInput from 'src/modules/shared/components/molecules/inputs/search-input';
import Pagination from 'src/modules/shared/components/molecules/paginations/pagination';
import TemplateListItem from '../../../molecules/templates/template-list-item/template-list-item';

interface TemplateListProps {
  onEditTemplate: (template: Template) => void;
  onDeleteTemplate: (template: Template) => void;
  onCreateTemplate: () => void;
}

const TemplateList: React.FC<TemplateListProps> = ({
  onEditTemplate,
  onDeleteTemplate,
  onCreateTemplate,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const { templates, loading, error, totalPages } = useTemplates({
    searchBy: searchTerm,
    page: currentPage,
    pageSize,
  });

  if (loading) return <Loading />;
  if (error) return <InfoError error={error} />;

  const handleSearch = () => {
    setSearchTerm(searchQuery);
    setCurrentPage(1);
  };

  return (
    <div className="flex flex-col gap-6 w-full">
      <div className="flex justify-between items-center">
        <div className="flex-1 flex gap-2">
          <div className="flex-1">
            <SearchInput
              value={searchQuery}
              onChange={setSearchQuery}
              onSearch={handleSearch}
              placeholder="Buscar plantillas..."
              size="xs"
              variant="secondary"
            />
          </div>
        </div>
        <div className="flex-1 flex justify-end">
          <Button onClick={onCreateTemplate} size="xs">
            <PlusIcon className="h-4 w-4" />
            {/* TODO: check when implementing i18n */}
            Nueva plantilla
          </Button>
        </div>
      </div>

      <div className="divide-y divide-gray-100 border border-gray-200 rounded-lg bg-white h-80 overflow-y-auto">
        {templates.map((template) => (
          <TemplateListItem
            key={template.id}
            template={template}
            onEdit={onEditTemplate}
            onDelete={onDeleteTemplate}
          />
        ))}
      </div>

      {templates.length > 0 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
          size="xs"
          variant="neutral"
          appearance="ghost"
        />
      )}
    </div>
  );
};

export default TemplateList;
