import { QueryClient } from '@tanstack/react-query';
import {
  Contact,
  ContactDocument,
  FunnelConversation,
  FunnelConversationDocument,
} from 'src/api/generated';
import {
  getModelFromCache,
  updateCache,
  InfiniteQueryData,
  RegularQueryData,
  isInfiniteQueryData,
  isRegularQueryData,
} from 'src/modules/shared/utils/cache/update.util';
import {
  UpdateContactParams,
  createOptimisticContact,
  createOptimisticContactDocument,
  mergeContact,
} from './optimistic.util';

/**
 * Updates the contact in all relevant caches (contacts, documents, and conversations)
 */
export const updateContactCache = async (
  queryClient: QueryClient,
  params: UpdateContactParams
) => {
  await queryClient.cancelQueries({ queryKey: ['crm/contacts'] });
  const previousContact = getModelFromCache<Contact>(
    queryClient,
    ['crm/contacts'],
    params.id
  );

  const optimisticContact = createOptimisticContact(params, previousContact);

  if (previousContact) {
    updateCache({
      queryClient,
      queryKey: ['crm/contacts'],
      updatedData: optimisticContact,
    });
  }

  await queryClient.cancelQueries({ queryKey: ['crm/documents/contacts'] });
  const previousContactDocument = getModelFromCache<ContactDocument>(
    queryClient,
    ['crm/documents/contacts'],
    params.id
  );

  const optimisticContactDocument = createOptimisticContactDocument(
    { id: params.id, data: params.data },
    previousContactDocument
  );

  if (previousContactDocument) {
    updateCache({
      queryClient,
      queryKey: ['crm/documents/contacts'],
      updatedData: optimisticContactDocument,
    });
  }

  await updateFunnelConversationsWithContact(queryClient, optimisticContact);
};

/**
 * Finds all funnel conversations in the cache that include the updated contact
 * and updates the contact information within them
 */
const updateFunnelConversationsWithContact = async (
  queryClient: QueryClient,
  updatedContact: Contact
) => {
  await queryClient.cancelQueries({
    queryKey: ['crm/funnel-conversations'],
  });

  const conversationQueries = queryClient.getQueriesData<
    | InfiniteQueryData<FunnelConversation>
    | RegularQueryData<FunnelConversation>
    | FunnelConversation
  >({
    queryKey: ['crm/funnel-conversations'],
    exact: false,
  });
  conversationQueries.forEach(([queryKey, data]) => {
    if (!data) return;

    if (isInfiniteQueryData<FunnelConversation>(data)) {
      const clonedData = JSON.parse(
        JSON.stringify(data)
      ) as InfiniteQueryData<FunnelConversation>;

      let updated = false;
      clonedData.pages = clonedData.pages.map((page) => ({
        ...page,
        items: page.items.map((conversation) => {
          const updatedConversation = updateConversationContacts(
            conversation,
            updatedContact
          );
          if (updatedConversation) {
            updated = true;
            return updatedConversation;
          }

          return conversation;
        }),
      }));

      if (updated) {
        queryClient.setQueryData(queryKey, clonedData);
      }
    } else if (isRegularQueryData<FunnelConversation>(data)) {
      const clonedData = JSON.parse(
        JSON.stringify(data)
      ) as RegularQueryData<FunnelConversation>;

      let updated = false;
      clonedData.data = clonedData.data.map((conversation) => {
        const updatedConversation = updateConversationContacts(
          conversation,
          updatedContact
        );
        if (updatedConversation) {
          updated = true;
          return updatedConversation;
        }

        return conversation;
      });

      if (updated) {
        queryClient.setQueryData(queryKey, clonedData);
      }
    }
  });

  await queryClient.cancelQueries({
    queryKey: ['crm/documents/funnel-conversations'],
  });

  const documentQueries = queryClient.getQueriesData<
    | InfiniteQueryData<FunnelConversationDocument>
    | RegularQueryData<FunnelConversationDocument>
    | FunnelConversationDocument
  >({
    queryKey: ['crm/documents/funnel-conversations'],
    exact: false,
  });

  documentQueries.forEach(([queryKey, data]) => {
    if (!data) return;

    if (isInfiniteQueryData<FunnelConversationDocument>(data)) {
      let updated = false;
      const clonedData = JSON.parse(
        JSON.stringify(data)
      ) as InfiniteQueryData<FunnelConversationDocument>;

      clonedData.pages = clonedData.pages.map((page) => ({
        ...page,
        items: page.items.map((document) => {
          const updatedDocument = updateConversationContacts(
            document,
            updatedContact
          );
          if (updatedDocument) {
            updated = true;
            return updatedDocument;
          }

          return document;
        }),
      }));

      if (updated) {
        queryClient.setQueryData(queryKey, clonedData);
      }
    } else if (isRegularQueryData<FunnelConversationDocument>(data)) {
      let updated = false;
      const clonedData = JSON.parse(
        JSON.stringify(data)
      ) as RegularQueryData<FunnelConversationDocument>;

      clonedData.data = clonedData.data.map((document) => {
        const updatedDocument = updateConversationContacts(
          document,
          updatedContact
        );
        if (updatedDocument) {
          updated = true;
          return updatedDocument;
        }

        return document;
      });

      if (updated) {
        queryClient.setQueryData(queryKey, clonedData);
      }
    }
  });
};

const updateConversationContacts = <
  T extends FunnelConversation | FunnelConversationDocument,
>(
  conversation: T,
  updatedContact: Contact
): T | undefined => {
  const hasMatchingContact = conversation.contacts?.some(
    (contact: Contact) => String(contact.id) === String(updatedContact.id)
  );
  if (!hasMatchingContact) return undefined;

  const contacts = [...(conversation.contacts ?? [])];
  const contactIndex = contacts.findIndex(
    (c: Contact) => String(c.id) === String(updatedContact.id)
  );

  if (contactIndex !== -1) {
    const mergedContact = mergeContact(contacts[contactIndex], updatedContact);
    const updatedContacts = [
      ...contacts.slice(0, contactIndex),
      mergedContact,
      ...contacts.slice(contactIndex + 1),
    ];

    const updatedConversation: T = {
      ...conversation,
      contacts: updatedContacts,
    };

    return updatedConversation;
  }

  return undefined;
};
