import { ComponentVariant } from 'src/modules/shared/types/component.type';

export const baseButtonClasses: string[] = [
  'inline-flex',
  'items-center',
  'justify-center',
  'gap-2',
  'font-medium',
  'rounded-md',
  'transition-all',
  'duration-200',
  'focus:outline-none',
  'focus:ring-2',
  'focus:ring-offset-2',
];

export const buttonFilledClasses: Record<
  NonNullable<ComponentVariant>,
  string
> = {
  primary:
    'bg-primary-600 text-white hover:bg-primary-700 active:bg-primary-800 focus:ring-primary-500',
  secondary:
    'bg-secondary-500 text-white hover:bg-secondary-600 active:bg-secondary-700 focus:ring-secondary-500',
  danger:
    'bg-danger-500 text-white hover:bg-danger-600 active:bg-danger-700 focus:ring-danger-500',
  warning:
    'bg-warning-500 text-white hover:bg-warning-600 active:bg-warning-700 focus:ring-warning-500',
  neutral:
    'bg-neutral-500 text-white hover:bg-neutral-600 active:bg-neutral-700 focus:ring-neutral-500',
  success:
    'bg-success-500 text-white hover:bg-success-600 active:bg-success-700 focus:ring-success-500',
  info: 'bg-blue-500 text-white hover:bg-blue-600 active:bg-blue-700 focus:ring-blue-500',
};

export const buttonOutlineClasses: Record<
  NonNullable<ComponentVariant>,
  string
> = {
  primary:
    'bg-white text-primary-600 border border-primary-500 hover:bg-primary-50 active:bg-primary-100 focus:ring-primary-500',
  secondary:
    'bg-white text-secondary-800 border border-secondary-600 hover:bg-secondary-50 active:bg-secondary-100 focus:ring-secondary-500',
  danger:
    'bg-white text-danger-500 border border-danger-500 hover:bg-danger-50 active:bg-danger-100 focus:ring-danger-500',
  warning:
    'bg-white text-warning-500 border border-warning-500 hover:bg-warning-50 active:bg-warning-100 focus:ring-warning-500',
  neutral:
    'bg-white text-neutral-600 border border-neutral-300 hover:bg-neutral-50 active:bg-neutral-100 focus:ring-neutral-500',
  success:
    'bg-white text-success-500 border border-success-500 hover:bg-success-50 active:bg-success-100 focus:ring-success-500',
  info: 'bg-white text-blue-500 border border-blue-500 hover:bg-blue-50 active:bg-blue-100 focus:ring-blue-500',
};

export const buttonSoftClasses: Record<
  NonNullable<ComponentVariant>,
  string
> = {
  primary:
    'bg-primary-50 text-primary-600 hover:bg-primary-100 active:bg-primary-200 focus:ring-primary-500',
  secondary:
    'bg-secondary-50 text-secondary-600 hover:bg-secondary-100 active:bg-secondary-200 focus:ring-secondary-500',
  danger:
    'bg-danger-50 text-danger-600 hover:bg-danger-100 active:bg-danger-200 focus:ring-danger-500',
  warning:
    'bg-warning-50 text-warning-600 hover:bg-warning-100 active:bg-warning-200 focus:ring-warning-500',
  neutral:
    'bg-neutral-50 text-neutral-600 hover:bg-neutral-100 active:bg-neutral-200 focus:ring-neutral-500',
  success:
    'bg-success-50 text-success-600 hover:bg-success-100 active:bg-success-200 focus:ring-success-500',
  info: 'bg-blue-50 text-blue-600 hover:bg-blue-100 active:bg-blue-200 focus:ring-blue-500',
};
export const buttonGhostClasses: Record<
  NonNullable<ComponentVariant>,
  string
> = {
  primary:
    'bg-transparent text-primary-600 hover:bg-primary-50 active:bg-primary-100 focus:ring-primary-500',
  secondary:
    'bg-transparent text-secondary-600 hover:bg-secondary-50 active:bg-secondary-100 focus:ring-secondary-500',
  danger:
    'bg-transparent text-danger-600 hover:bg-danger-50 active:bg-danger-100 focus:ring-danger-500',
  warning:
    'bg-transparent text-warning-600 hover:bg-warning-50 active:bg-warning-100 focus:ring-warning-500',
  neutral:
    'bg-transparent text-neutral-600 hover:bg-neutral-50 active:bg-neutral-100 focus:ring-neutral-500',
  success:
    'bg-transparent text-success-600 hover:bg-success-50 active:bg-success-100 focus:ring-success-500',
  info: 'bg-transparent text-blue-600 hover:bg-blue-50 active:bg-blue-100 focus:ring-blue-500',
};
