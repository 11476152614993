import React, { ComponentProps } from 'react';
import { BaseProps } from 'src/modules/shared/types/component.type';
import { getDisabledClasses } from 'src/modules/shared/styles/component.styles';
import {
  dateInputBaseClasses,
  dateInputContainerClasses,
  dateInputSizeClasses,
  dateInputVariantClasses,
} from './styles';

interface DateInputProps
  extends BaseProps<Omit<ComponentProps<'input'>, 'size' | 'variant'>> {
  containerClassName?: string;
  type?: 'date' | 'datetime-local';
}

export const DateInput: React.FC<DateInputProps> = ({
  variant = 'primary',
  size = 'md',
  loading = false,
  disabled = false,
  className = '',
  containerClassName = '',
  type = 'date',
  ...rest
}) => {
  const containerClasses = [
    ...dateInputContainerClasses,
    containerClassName,
  ].join(' ');

  const inputClasses = [
    ...dateInputBaseClasses,
    dateInputVariantClasses[variant],
    dateInputSizeClasses[size],
    getDisabledClasses(disabled || loading),
    className,
  ].join(' ');

  return (
    <div className={containerClasses}>
      <input
        type={type}
        className={inputClasses}
        disabled={disabled || loading}
        {...rest}
      />
    </div>
  );
};
