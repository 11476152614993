import React, { useState, useEffect } from 'react';
import { MediaModal } from '../../atoms/media/media-modal';
import { MediaSkeleton } from '../../atoms/media/media-skeleton';
import { MediaErrorSkeleton } from '../../atoms/media/media-error-skeleton';

interface ImageMediaProps {
  url: string;
  isLoading?: boolean;
  alt?: string;
  className?: string;
}

export const ImageMedia: React.FC<ImageMediaProps> = ({
  url,
  isLoading = false,
  alt = 'image',
  className = '',
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoaded(false);
    setHasError(false);

    if (isLoading) return;

    const img = new Image();
    img.src = url;
    img.onload = () => {
      setIsLoaded(true);
      setHasError(false);
    };
    img.onerror = () => {
      setIsLoaded(false);
      setHasError(true);
    };
  }, [url, isLoading]);

  const containerClasses = `relative w-full h-auto max-w-[27rem] max-h-[27rem] rounded-lg ${className}`;
  const imageLoading = !isLoaded || isLoading;
  const imgUrl = imageLoading ? undefined : url;

  return (
    <>
      <div className={containerClasses}>
        {imageLoading && <MediaSkeleton type="image" size="sm" />}
        {hasError && <MediaErrorSkeleton />}
        <img
          src={imgUrl}
          alt={alt}
          onClick={() => !imageLoading && setIsModalOpen(true)}
          className={`w-full h-full rounded-lg cursor-pointer object-cover ${
            !imageLoading ? 'block' : 'hidden'
          }`}
        />
      </div>
      <MediaModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <img
          src={imgUrl}
          alt={alt}
          onClick={() => setIsModalOpen(false)}
          className={`max-h-[85vh] max-w-[85vw] w-auto h-auto rounded-lg cursor-pointer object-contain ${
            !imageLoading ? 'block' : 'hidden'
          }`}
        />
      </MediaModal>
    </>
  );
};
