import {
  ComponentSize,
  ComponentVariant,
} from 'src/modules/shared/types/component.type';

interface SwitchSizeClasses {
  container: string;
  input: string;
  handle: string;
}

interface SwitchColorClasses {
  input: string;
  handle: string;
}

export const switchSizeClasses: Record<ComponentSize, SwitchSizeClasses> = {
  '2xs': {
    container: 'w-8 h-4',
    input: 'w-8 h-4',
    handle: 'top-0 left-0 w-4 h-4 peer-checked:translate-x-4',
  },
  xs: {
    container: 'w-9 h-4',
    input: 'w-9 h-4',
    handle: 'top-0 left-0 w-4 h-4 peer-checked:translate-x-5',
  },
  sm: {
    container: 'w-11 h-5',
    input: 'w-11 h-5',
    handle: 'top-0 left-0 w-5 h-5 peer-checked:translate-x-6',
  },
  md: {
    container: 'w-12 h-6',
    input: 'w-12 h-6',
    handle: 'top-0 left-0 w-6 h-6 peer-checked:translate-x-6',
  },
  lg: {
    container: 'w-14 h-7',
    input: 'w-14 h-7',
    handle: 'top-0 left-0 w-7 h-7 peer-checked:translate-x-7',
  },
};

export const switchColorClasses: Record<ComponentVariant, SwitchColorClasses> =
  {
    primary: {
      input: 'bg-gray-200 checked:bg-primary-600',
      handle: 'border-gray-300 peer-checked:border-primary-600',
    },
    secondary: {
      input: 'bg-gray-200 checked:bg-secondary-600',
      handle: 'border-gray-300 peer-checked:border-secondary-600',
    },
    success: {
      input: 'bg-gray-200 checked:bg-success-500',
      handle: 'border-gray-300 peer-checked:border-success-500',
    },
    danger: {
      input: 'bg-gray-200 checked:bg-danger-500',
      handle: 'border-gray-300 peer-checked:border-danger-500',
    },
    warning: {
      input: 'bg-gray-200 checked:bg-warning-500',
      handle: 'border-gray-300 peer-checked:border-warning-500',
    },
    info: {
      input: 'bg-gray-200 checked:bg-cyan-500',
      handle: 'border-gray-300 peer-checked:border-cyan-500',
    },
    neutral: {
      input: 'bg-gray-200 checked:bg-neutral-500',
      handle: 'border-gray-300 peer-checked:border-neutral-500',
    },
  };
