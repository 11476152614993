import React from 'react';
import { PiDownloadSimple } from 'react-icons/pi';
import { MediaSkeleton } from '../../atoms/media/media-skeleton';
import Button from 'src/modules/shared/components/atoms/buttons/button';
import { Typography } from '@material-tailwind/react';

interface FileMediaProps {
  url: string;
  isLoading?: boolean;
  fileName?: string;
  className?: string;
}

export const FileMedia: React.FC<FileMediaProps> = ({
  url,
  isLoading = false,
  fileName = 'Download File',
  className = '',
}) => {
  const containerClasses = `relative ${className}`;
  const fileUrl = isLoading ? undefined : url;

  const handleDownload = () => {
    if (!fileUrl) return;

    window.open(fileUrl, '_blank');
  };

  const buttonClasses = `!h-12 space-x-3 ${!isLoading ? 'block' : 'hidden'}`;

  return (
    <div className={containerClasses}>
      {isLoading && <MediaSkeleton type="file" />}
      <Button
        variant="secondary"
        appearance="soft"
        size="lg"
        className={buttonClasses}
        onClick={handleDownload}
      >
        <Typography className="text-sm font-normal text-primary-600 truncate max-w-[200px]">
          {fileName}
        </Typography>
        <PiDownloadSimple className="w-5 h-5 text-primary-600" />
      </Button>
    </div>
  );
};
