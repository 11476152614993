/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomFieldDefinitionCreateBodyParams } from '../models/CustomFieldDefinitionCreateBodyParams';
import type { CustomFieldDefinitionCreateResponse } from '../models/CustomFieldDefinitionCreateResponse';
import type { CustomFieldDefinitionDeleteResponse } from '../models/CustomFieldDefinitionDeleteResponse';
import type { CustomFieldDefinitionGetResponse } from '../models/CustomFieldDefinitionGetResponse';
import type { CustomFieldDefinitionListResponse } from '../models/CustomFieldDefinitionListResponse';
import type { CustomFieldDefinitionUpdateBodyParams } from '../models/CustomFieldDefinitionUpdateBodyParams';
import type { CustomFieldDefinitionUpdateResponse } from '../models/CustomFieldDefinitionUpdateResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CrmCustomFieldsDefinitionsService {

    /**
     * @param filterBy
     * @param searchBy
     * @param sortBy
     * @param page
     * @param pageSize
     * @param pageToken
     * @returns CustomFieldDefinitionListResponse Ok
     * @throws ApiError
     */
    public static getCustomFieldDefinitions(
        filterBy?: string,
        searchBy?: string,
        sortBy?: string,
        page?: number,
        pageSize?: number,
        pageToken?: string,
    ): CancelablePromise<CustomFieldDefinitionListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/crm/custom-fields/definitions',
            query: {
                'filterBy': filterBy,
                'searchBy': searchBy,
                'sortBy': sortBy,
                'page': page,
                'pageSize': pageSize,
                'pageToken': pageToken,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns CustomFieldDefinitionCreateResponse Ok
     * @throws ApiError
     */
    public static createCustomFieldDefinition(
        requestBody: CustomFieldDefinitionCreateBodyParams,
    ): CancelablePromise<CustomFieldDefinitionCreateResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/crm/custom-fields/definitions',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param id
     * @returns CustomFieldDefinitionGetResponse Ok
     * @throws ApiError
     */
    public static getCustomFieldDefinition(
        id: number,
    ): CancelablePromise<CustomFieldDefinitionGetResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/crm/custom-fields/definitions/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
            },
        });
    }

    /**
     * @param id
     * @param requestBody
     * @returns CustomFieldDefinitionUpdateResponse Ok
     * @throws ApiError
     */
    public static updateCustomFieldDefinition(
        id: number,
        requestBody: CustomFieldDefinitionUpdateBodyParams,
    ): CancelablePromise<CustomFieldDefinitionUpdateResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/crm/custom-fields/definitions/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
            },
        });
    }

    /**
     * @param id
     * @returns CustomFieldDefinitionDeleteResponse Ok
     * @throws ApiError
     */
    public static deleteCustomFieldDefinition(
        id: number,
    ): CancelablePromise<CustomFieldDefinitionDeleteResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/crm/custom-fields/definitions/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
            },
        });
    }

}
