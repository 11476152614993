import { type ComponentVariant } from 'src/modules/shared/types/component.type';
import { InputFieldSkeleton } from 'src/modules/shared/components/atoms/inputs/input-field-skeleton';

interface FieldConfig {
  labelClassName?: string;
  inputClassName?: string;
  showLabel?: boolean;
}

interface FormSkeletonProps {
  variant?: ComponentVariant;
  fieldCount?: number;
  spacing?: string;
  className?: string;
  fieldsConfig?: FieldConfig[];
  defaultLabelClassName?: string;
  defaultInputClassName?: string;
  showLabels?: boolean;
}

export const FormSkeleton: React.FC<FormSkeletonProps> = ({
  variant = 'primary',
  fieldCount = 3,
  spacing = 'space-y-4',
  className = '',
  fieldsConfig = [],
  defaultLabelClassName = 'w-1/4',
  defaultInputClassName = 'w-2/3',
  showLabels = false,
}) => {
  const fields = Array.from({ length: fieldCount }, (_, index) => ({
    id: index,
    config: fieldsConfig[index] || {},
  }));

  return (
    <div className={`animate-pulse ${spacing} ${className}`}>
      {fields.map((field) => (
        <InputFieldSkeleton
          key={field.id}
          variant={variant}
          labelClassName={field.config.labelClassName || defaultLabelClassName}
          inputClassName={field.config.inputClassName || defaultInputClassName}
          showLabel={
            field.config.showLabel !== undefined
              ? field.config.showLabel
              : showLabels
          }
        />
      ))}
    </div>
  );
};
