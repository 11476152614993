import { QueryClient } from '@tanstack/react-query';
import { ContactAddress } from 'src/api/generated';
import {
  getModelFromCache,
  updateCache,
} from 'src/modules/shared/utils/cache/update.util';
import {
  createOptimisticContactAddress,
  UpdateContactAddressParams,
} from './optimistic.util';
import { updateContactCache } from '../contacts/update.util';

/**
 * Updates the contact address in the cache and triggers contact cache updates
 */
export const updateContactAddressCache = async (
  queryClient: QueryClient,
  params: UpdateContactAddressParams
) => {
  await queryClient.cancelQueries({ queryKey: ['crm/contacts/addresses'] });

  const previousAddress = getModelFromCache<ContactAddress>(
    queryClient,
    ['crm/contacts/addresses'],
    params.id
  );

  const optimisticAddress = createOptimisticContactAddress(
    params,
    previousAddress
  );

  if (previousAddress) {
    updateCache({
      queryClient,
      queryKey: ['crm/contacts/addresses'],
      updatedData: optimisticAddress,
    });
  }

  const contactId = optimisticAddress.contactId ?? previousAddress?.contactId;
  if (!contactId) return;

  await updateContactCache(queryClient, {
    id: contactId,
    data: {
      addresses: [optimisticAddress],
    },
  });
};
