import { useState } from 'react';
import MenuDropdown from 'src/modules/shared/components/molecules/dropdowns/menu-dropdown';
import { SiWhatsapp } from 'react-icons/si';
import Modal from 'src/modules/shared/components/molecules/modals/modal';
import { NewCallForm } from '../calls/new-call-form';
import { useGlobalPhoneContext } from 'src/contexts/global-phone-context';
import { PiPhoneLight, PiPlusLight } from 'react-icons/pi';
import { WhatsAppTemplateModal } from 'src/modules/messaging/whatsapp/components/organisms/templates/template-modal';
import { Typography } from '@material-tailwind/react';

export const InteractionsActionsDropdown = () => {
  const [isNewCallOpen, setIsNewCallOpen] = useState(false);
  const [isWhatsAppTemplateOpen, setIsWhatsAppTemplateOpen] = useState(false);
  const { setGlobalPhoneVisible } = useGlobalPhoneContext();

  const actionItems = [
    {
      icon: <PiPhoneLight className="h-4 w-4" />,
      content: 'Nueva llamada',
      onClick: () => {
        setGlobalPhoneVisible(true);
        setIsNewCallOpen(true);
      },
    },
    {
      icon: <SiWhatsapp className="h-4 w-4" />,
      content: 'Nuevo mensaje',
      onClick: () => {
        setIsWhatsAppTemplateOpen(true);
      },
    },
    // {
    //   icon: <PiUserPlusLight className="h-4 w-4" />,
    //   content: 'Nuevo contacto',
    //   onClick: () => {
    //     /* TODO: Implement new contact */
    //   },
    // },
  ];

  return (
    <>
      <MenuDropdown
        label=""
        items={actionItems}
        icon={<PiPlusLight className="h-5 w-5" />}
        size="sm"
        variant="primary"
        placement="bottom-start"
        className="rounded-lg"
      />

      <Modal
        isOpen={isNewCallOpen}
        onClose={() => setIsNewCallOpen(false)}
        // TODO: check when implementing i18n
        header={<Typography className="font-medium">Nueva llamada</Typography>}
        body={<NewCallForm onClose={() => setIsNewCallOpen(false)} />}
        dialogClassName="sm:max-w-md"
      />

      <WhatsAppTemplateModal
        isOpen={isWhatsAppTemplateOpen}
        onClose={() => setIsWhatsAppTemplateOpen(false)}
      />
    </>
  );
};
