import React from 'react';
import {
  Popover as MaterialPopover,
  PopoverHandler,
  PopoverContent,
  PopoverProps as MaterialPopoverProps,
} from '@material-tailwind/react';

export type ContainerPopoverPlacement =
  | 'top-start'
  | 'top'
  | 'top-end'
  | 'right-start'
  | 'right'
  | 'right-end'
  | 'bottom-start'
  | 'bottom'
  | 'bottom-end'
  | 'left-start'
  | 'left'
  | 'left-end';

export interface PopoverProps {
  handler: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  contentClassName?: string;
  placement?: ContainerPopoverPlacement;
  isLoading?: boolean;
  disabled?: boolean;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

const dismissConfig: MaterialPopoverProps['dismiss'] = {
  enabled: true,
  escapeKey: true,
  referencePress: false,
  outsidePress: true,
  ancestorScroll: false,
};

export const Popover: React.FC<PopoverProps> = ({
  handler,
  children,
  className = '',
  contentClassName = '',
  placement = 'bottom-end',
  isLoading = false,
  disabled = false,
  open,
  onOpenChange,
}) => {
  const handlerClasses = `inline-block ${disabled ? 'opacity-50 cursor-not-allowed' : ''} ${className}`;
  const contentClasses = `p-0 shadow-xl z-[99] ${contentClassName}`;

  const handleOpen = (open: boolean) => {
    if (isLoading || disabled) return;

    onOpenChange(open);
  };

  return (
    <MaterialPopover
      open={open}
      handler={handleOpen}
      placement={placement}
      dismiss={dismissConfig}
    >
      <PopoverHandler>
        <div className={handlerClasses}>{handler}</div>
      </PopoverHandler>
      <PopoverContent className={contentClasses}>{children}</PopoverContent>
    </MaterialPopover>
  );
};
