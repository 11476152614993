import { type ComponentVariant } from 'src/modules/shared/types/component.type';
import { tagSizeClasses } from './tag.styles';

interface TagSkeletonProps {
  size?: keyof typeof tagSizeClasses;
  variant?: ComponentVariant;
  className?: string;
  withIcon?: boolean;
  iconPosition?: 'left' | 'right';
}

export const TagSkeleton: React.FC<TagSkeletonProps> = ({
  size = 'md',
  variant = 'neutral',
  className = '',
  withIcon = false,
  iconPosition = 'left',
}) => {
  const variantClasses: Record<ComponentVariant, string> = {
    primary: 'bg-gray-200',
    secondary: 'bg-secondary-200',
    danger: 'bg-danger-200',
    neutral: 'bg-neutral-200',
    warning: 'bg-warning-200',
    success: 'bg-success-200',
    info: 'bg-info-200',
  };

  const bgClass = variantClasses[variant];

  return (
    <div
      className={`
        flex w-fit items-center justify-center gap-1 rounded-full animate-pulse
        ${tagSizeClasses[size]}
        ${className}
      `.trim()}
    >
      {withIcon && iconPosition === 'left' && (
        <div className={`h-4 w-4 ${bgClass} rounded-full`} />
      )}
      <div className={`h-4 w-16 ${bgClass} rounded-full`} />
      {withIcon && iconPosition === 'right' && (
        <div className={`h-4 w-4 ${bgClass} rounded-full`} />
      )}
    </div>
  );
};
