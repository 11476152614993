import React from 'react';
import { type ComponentSize } from 'src/modules/shared/types/component.type';
import { PiWarning } from 'react-icons/pi';

interface MediaErrorSkeletonProps {
  className?: string;
  size?: ComponentSize;
}

export const MediaErrorSkeleton: React.FC<MediaErrorSkeletonProps> = ({
  className = '',
  size = 'md',
}) => {
  const sizeClasses: Record<ComponentSize, string> = {
    '2xs': 'min-h-[100px]',
    xs: 'min-h-[150px]',
    sm: 'min-h-[175px]',
    md: 'min-h-[200px]',
    lg: 'min-h-[250px]',
  };

  const iconSizeClasses: Record<ComponentSize, string> = {
    '2xs': 'w-6 h-6',
    xs: 'w-8 h-8',
    sm: 'w-10 h-10',
    md: 'w-12 h-12',
    lg: 'w-16 h-16',
  };

  return (
    <div
      className={`
        rounded-lg w-full h-full relative
        bg-secondary-200
        ${sizeClasses[size]}
        ${className}
      `.trim()}
    >
      <div className="absolute inset-0 flex items-center justify-center translate-x-[2px] translate-y-[2px]">
        <PiWarning className={`${iconSizeClasses[size]} text-danger-400`} />
      </div>
    </div>
  );
};
