import { ComponentSize, ComponentVariant } from '../../../types/component.type';

export const baseAlertClasses = [
  'flex',
  'items-center',
  'gap-2',
  'rounded-lg',
  'transition-all',
  'duration-200',
];

export const alertSizeClasses: Record<NonNullable<ComponentSize>, string> = {
  '2xs': 'px-2 py-1',
  xs: 'px-2 py-1.5',
  sm: 'px-3 py-2',
  md: 'px-4 py-3',
  lg: 'px-6 py-4',
};

export const alertTextSizeClasses: Record<
  NonNullable<ComponentSize>,
  string
> = {
  '2xs': 'text-xs',
  xs: 'text-xs',
  sm: 'text-sm',
  md: 'text-base',
  lg: 'text-lg',
};

export const alertFilledClasses: Record<
  NonNullable<ComponentVariant>,
  string
> = {
  primary: 'bg-primary-500 text-white',
  secondary: 'bg-secondary-500 text-white',
  danger: 'bg-danger-500 text-white',
  warning: 'bg-warning-500 text-white',
  neutral: 'bg-neutral-500 text-white',
  success: 'bg-success-500 text-white',
  info: 'bg-blue-500 text-white',
};

export const alertSoftClasses: Record<NonNullable<ComponentVariant>, string> = {
  primary: 'bg-primary-50 text-primary-600',
  secondary: 'bg-secondary-50 text-secondary-600',
  danger: 'bg-danger-50 text-danger-600',
  warning: 'bg-warning-50 text-warning-600',
  neutral: 'bg-neutral-50 text-neutral-600',
  success: 'bg-success-50 text-success-600',
  info: 'bg-blue-50 text-blue-600',
};

export const alertOutlineClasses: Record<
  NonNullable<ComponentVariant>,
  string
> = {
  primary: 'bg-white text-primary-600 border border-primary-500',
  secondary: 'bg-white text-secondary-600 border border-secondary-500',
  danger: 'bg-white text-danger-600 border border-danger-500',
  warning: 'bg-white text-warning-600 border border-warning-500',
  neutral: 'bg-white text-neutral-600 border border-neutral-500',
  success: 'bg-white text-success-600 border border-success-500',
  info: 'bg-white text-blue-600 border border-blue-500',
};
