import { Typography } from '@material-tailwind/react';
import { PiPhoneXLight, PiPhoneLight, PiTimerLight } from 'react-icons/pi';
import { callStatusMap } from 'src/modules/shared/utils/call/formatting.util';
import { ComponentSize } from 'src/modules/shared/types/component.type';

const indicatorSizeClasses: Record<
  NonNullable<ComponentSize>,
  { text: string; icon: string }
> = {
  '2xs': { text: 'text-xs', icon: 'w-3 h-3' },
  xs: { text: 'text-xs', icon: 'w-3.5 h-3.5' },
  sm: { text: 'text-xs', icon: 'w-4 h-4' },
  md: { text: 'text-sm', icon: 'w-4.5 h-4.5' },
  lg: { text: 'text-base', icon: 'w-5 h-5' },
};

interface CallStatusIndicatorProps {
  status?: string;
  duration?: number;
  className?: string;
  size?: ComponentSize;
}

export const CallStatusIndicator: React.FC<CallStatusIndicatorProps> = ({
  status,
  duration,
  className = '',
  size = 'sm',
}) => {
  const isMissed = status === 'no-answer';
  const isBusy = status === 'busy';
  const isActive = status === 'in-progress';

  const { text: textSize, icon: iconSize } = indicatorSizeClasses[size];

  return (
    <div className={`flex items-center gap-2 ${textSize} ${className}`}>
      {duration && duration > 0 && (
        <div className="flex items-center gap-1">
          <PiTimerLight className={`${iconSize} text-call-500`} />
          <Typography className={`${textSize} font-normal text-secondary-600`}>
            {duration}s
          </Typography>
        </div>
      )}
      <div className="flex items-center gap-1">
        {isMissed || isBusy ? (
          <PiPhoneXLight className={`${iconSize} text-rose-500`} />
        ) : (
          <PiPhoneLight
            className={`${iconSize} ${isActive ? 'animate-pulse' : ''} text-call-500`}
          />
        )}
        <Typography className={`${textSize} font-normal text-secondary-600`}>
          {callStatusMap(status || '')}
        </Typography>
      </div>
    </div>
  );
};
