import { type FC } from 'react';
import {
  ComponentVariant,
  ComponentAppearance,
  type BaseProps,
} from 'src/modules/shared/types/component.type';
import { getDisabledClasses } from 'src/modules/shared/styles/component.styles';
import { Typography } from '@material-tailwind/react';
import {
  baseAlertClasses,
  alertSizeClasses,
  alertTextSizeClasses,
  alertFilledClasses,
  alertSoftClasses,
  alertOutlineClasses,
} from './alert.styles';

export type AlertProps = BaseProps & {
  icon?: React.ReactNode;
  message: string | React.ReactNode;
  appearance?: ComponentAppearance;
};

const appearanceClasses: Record<
  ComponentAppearance,
  Record<NonNullable<ComponentVariant>, string>
> = {
  filled: alertFilledClasses,
  outline: alertOutlineClasses,
  soft: alertSoftClasses,
  ghost: alertOutlineClasses,
};

export const Alert: FC<AlertProps> = ({
  icon,
  message,
  className = '',
  variant = 'primary',
  appearance = 'soft',
  size = 'sm',
  disabled = false,
}) => {
  const classes = [
    ...baseAlertClasses,
    appearanceClasses[appearance][variant],
    alertSizeClasses[size],
    getDisabledClasses(disabled),
    className,
  ].join(' ');

  return (
    <div className={classes}>
      {icon && <div className="flex-shrink-0">{icon}</div>}
      {typeof message === 'string' ? (
        <Typography className={alertTextSizeClasses[size]}>
          {message}
        </Typography>
      ) : (
        message
      )}
    </div>
  );
};
