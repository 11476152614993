// src/components/views/reports-view/index.tsx

import { useEffect, useState } from 'react';
import ViewContainer from 'components/atoms/view-container';
import AgentSelect from 'components/molecules/charts/agent-select';
import { useCallSummaries } from 'hooks/api/call-summaries';
import { getDateRange } from 'src/utils/date';
import CallTypeSelect from 'components/molecules/charts/call-type-select';
import MessageLoading from 'components/atoms/loading/message-loading';
import InfoError from 'components/atoms/info-error';
import {
  useAgentFilteredCalls,
  useAgentSorting,
  useCallTypeFilteredCalls,
} from 'hooks/charts/filters';
import { periodLengths } from 'src/utils/charts';
import ViewFilter from 'components/atoms/view-filter';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useRetoolReportsEmbed } from 'hooks/api/reports';
import LoadedTabs from '../../molecules/tabs/loaded-tabs';
import { useBooleanSetting } from 'hooks/settings/settings';
import { SettingName } from 'src/api/generated';
import BasicReports from './basic-reports';
import AdvancedReports from './advanced-reports';
import { useHasPermissions } from 'src/modules/shared/hooks/auth';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  ChartDataLabels
);

// this range should include all others, and then daily and
// weekly will filter out their data from here
const initialDateRange = getDateRange('monthly', periodLengths.monthly);

const ReportsView: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<string>('basic');

  // TODO: implement date range picker
  const [dateRange] = useState<Array<Date>>([
    initialDateRange.startDate,
    initialDateRange.endDate,
  ]);
  const {
    summaries,
    loading: summariesLoading,
    error,
  } = useCallSummaries(dateRange[0].toISOString(), dateRange[1].toISOString());

  const { selectedAgents, setSelectedAgents, filteredSummariesByAgent } =
    useAgentFilteredCalls(summaries);
  const { selectedCallType, setSelectedCallType, filteredSummariesByCallType } =
    useCallTypeFilteredCalls(filteredSummariesByAgent);

  const sortedAgents = useAgentSorting(summaries);
  useEffect(() => {
    if (sortedAgents.length === 0) return;

    const allAgentIds = sortedAgents.map((agent) => agent.id);
    setSelectedAgents(allAgentIds);
  }, [setSelectedAgents, sortedAgents]);

  const { setting: advancedReportsEnabled, loading: settingLoading } =
    useBooleanSetting(SettingName.ADVANCED_REPORTS);
  const { hasPermission: canReadReport, loading: permissionLoading } =
    useHasPermissions('read:report');
  const showAdvancedReports = canReadReport && advancedReportsEnabled;

  const { embedUrl, loading: embedLoading } =
    useRetoolReportsEmbed(showAdvancedReports);

  const headerElement = (
    <ViewFilter>
      <CallTypeSelect
        selectedCallType={selectedCallType}
        onCallTypeChange={setSelectedCallType}
      />
      <AgentSelect
        agents={sortedAgents}
        selectedAgents={selectedAgents}
        onAgentSelectionChange={setSelectedAgents}
      />
    </ViewFilter>
  );

  const reportTabs = [
    {
      key: 'basic',
      label: 'Básicos',
      content: (
        <>
          {headerElement}
          <BasicReports summaries={filteredSummariesByCallType} />
        </>
      ),
      loading: summariesLoading,
    },
    {
      key: 'advanced',
      label: 'Avanzados',
      content: <AdvancedReports embedUrl={embedUrl} />,
      loading: embedLoading,
    },
  ];

  if (permissionLoading || settingLoading) {
    return <MessageLoading />;
  }
  if (error) {
    return <InfoError error={error} />;
  }
  return (
    <ViewContainer
      title="Reportes"
      {...(!showAdvancedReports && { headerElement })}
    >
      {showAdvancedReports && (
        <LoadedTabs
          tabs={reportTabs}
          selectedTab={selectedTab}
          onSelectTab={setSelectedTab}
        />
      )}
      {!showAdvancedReports && summariesLoading && <MessageLoading />}
      {!showAdvancedReports && !summariesLoading && (
        <BasicReports summaries={filteredSummariesByCallType} />
      )}
    </ViewContainer>
  );
};

export default ReportsView;
