import React, { useRef } from 'react';
import { Typography } from '@material-tailwind/react';
import Loading from '../../../atoms/loading';
import { useInfiniteScroll } from 'src/modules/shared/hooks/scroll/movement';

interface SuggestionsPanelProps<T> {
  items: T[];
  selectedIndex: number;
  loading: boolean;
  hasMore: boolean;
  isOpen: boolean;
  renderItem: (item: T, isSelected: boolean) => React.ReactNode;
  renderEmpty?: () => React.ReactNode;
  renderFooter?: () => React.ReactNode;
  onLoadMore: () => void;
  className?: string;
  position?: 'top' | 'bottom';
}

export const SuggestionsPanel = <T,>({
  items,
  selectedIndex,
  loading,
  hasMore,
  isOpen,
  renderItem,
  renderEmpty,
  renderFooter,
  onLoadMore,
  className = '',
  position = 'bottom',
}: SuggestionsPanelProps<T>) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useInfiniteScroll({
    containerRef,
    isFetching: loading,
    hasMore,
    fetchMore: onLoadMore,
    direction: 'down',
    threshold: 0.2,
  });

  if (!isOpen) return null;

  const renderContent = () => {
    if (items.length === 0 && !loading) {
      return (
        renderEmpty?.() || (
          <div className="p-4">
            <Typography variant="small" color="gray" className="text-center">
              {/* TODO: check when implementing i18n */}
              No se encontraron resultados
            </Typography>
          </div>
        )
      );
    }

    return (
      <div className="flex flex-col">
        {items.map((item, index) => (
          <div
            key={index}
            className={`scroll-mt-2 scroll-mb-2 ${index === selectedIndex ? 'scroll-my-2' : ''}`}
            id={index === selectedIndex ? 'selected-item' : undefined}
          >
            {renderItem(item, index === selectedIndex)}
          </div>
        ))}
        {loading && <Loading className="p-4" />}
      </div>
    );
  };

  const positionClasses: Record<
    NonNullable<SuggestionsPanelProps<T>['position']>,
    string
  > = {
    top: 'bottom-full mb-2',
    bottom: 'top-full mt-2',
  };

  return (
    <div
      className={`absolute left-0 right-0 z-[20] bg-white rounded-lg shadow-lg border border-gray-200 max-w-2xl ${
        positionClasses[position]
      } ${className}`}
    >
      <div className="max-h-[210px] flex flex-col">
        <div
          ref={containerRef}
          className="min-h-0 flex-1 overflow-y-auto overflow-x-hidden"
        >
          <div className="p-1">{renderContent()}</div>
        </div>
        {items.length > 0 && renderFooter && (
          <div className="flex-shrink-0 border-t border-gray-100 bg-gray-50">
            {renderFooter()}
          </div>
        )}
      </div>
    </div>
  );
};
