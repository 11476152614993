// src/modules/shared/components/molecules/modals/button-modal/index.tsx

import React, { ComponentProps, useState } from 'react';
import Button from 'src/modules/shared/components/atoms/buttons/button';
import CopyModal from 'src/modules/shared/components/molecules/modals/copy-modal/index';

interface ButtonModalProps
  extends Omit<ComponentProps<typeof Button>, 'children'> {
  children: (enabled: boolean) => JSX.Element;
  copyableContent?: string;
  buttonContent: React.ReactNode;
  title?: string;
  visible?: boolean;
  bodyClassName?: string;
}

const ButtonModal = React.forwardRef<HTMLButtonElement, ButtonModalProps>(
  (
    {
      children,
      copyableContent,
      buttonContent,
      title = '',
      visible = true,
      className = '',
      bodyClassName = '',
      variant = 'neutral',
      appearance = 'ghost',
      size = 'sm',
      ...buttonProps
    },
    ref
  ) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
      <>
        <Button
          ref={ref}
          onClick={() => setIsModalOpen(true)}
          variant={variant}
          appearance={appearance}
          size={size}
          className={`${className} ${!visible ? 'invisible' : ''}`}
          disabled={!visible}
          {...buttonProps}
        >
          {buttonContent}
        </Button>
        <CopyModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={title}
          copyableContent={copyableContent}
          bodyClassName={bodyClassName}
        >
          {children(isModalOpen)}
        </CopyModal>
      </>
    );
  }
);

ButtonModal.displayName = 'ButtonModal';

export default ButtonModal;
