import {
  SearcherDocumentSearchFilter,
  SearcherDocumentSearchFilterOperator,
  SearcherDocumentSearchSort,
} from 'src/api/generated';
import { useFunnelConversationDocuments } from '../../../../hooks/api';
import ConversationList from '../../../molecules/lists/conversation-list';
import { useMemo } from 'react';

interface ConversationListViewProps {
  selectedFunnelId?: number;
  searchTerm?: string;
  onConversationSelect?: (id: number) => void;
  selectedConversationId?: number;
  filters: SearcherDocumentSearchFilter[];
  sorts?: SearcherDocumentSearchSort[];
}

export default function ConversationListView({
  selectedFunnelId,
  searchTerm,
  onConversationSelect,
  selectedConversationId,
  filters,
  sorts = [],
}: ConversationListViewProps) {
  const searchParams = useMemo(() => {
    if (!selectedFunnelId) return {};

    const baseFilters: SearcherDocumentSearchFilter[] = [
      {
        field: 'funnelId',
        operator: SearcherDocumentSearchFilterOperator.EQ,
        value: selectedFunnelId.toString(),
      },
    ];

    return {
      search: searchTerm,
      filters: [...baseFilters, ...filters],
      ...(sorts.length > 0 && { sort: sorts }),
      size: 20,
    };
  }, [selectedFunnelId, searchTerm, filters, sorts]);

  const {
    conversations,
    loading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useFunnelConversationDocuments(searchParams, !!selectedFunnelId);

  return (
    <ConversationList
      isLoading={loading}
      error={error}
      conversations={conversations}
      onLoadMore={() => fetchNextPage()}
      hasNextPage={hasNextPage && !isFetchingNextPage}
      onConversationSelect={onConversationSelect}
      selectedConversationId={selectedConversationId?.toString()}
      onRetry={refetch}
    />
  );
}
