import { Typography } from '@material-tailwind/react';
import { type FC } from 'react';
import { type FunnelStatus } from 'src/api/generated';

interface StatusHeaderProps {
  status: FunnelStatus;
  count: number;
  className?: string;
}

export const PipelineStatusColumnHeader: FC<StatusHeaderProps> = ({
  status,
  count,
  className = '',
}) => {
  return (
    <div className={`flex items-center justify-between ${className}`}>
      <Typography className="text-sm font-medium text-secondary-800">
        {status.name}
      </Typography>
      <Typography className="text-xs font-medium text-secondary-800">
        {count}
      </Typography>
    </div>
  );
};
