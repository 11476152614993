import { SearcherDocumentSearchFilterOperator } from 'src/api/generated';
import {
  FilterFieldOptions,
  FilterFieldType,
  FilterValue,
  ListOption,
  UserOption,
  FilterOption,
} from '../../types/filters.types';
import { isArrayOperator } from './operators.util';
import { isNoValueOperator } from './operators.util';

/**
 * Formats a filter value for display
 * @param value The filter value
 * @param type The type of the field
 * @param userOptions Available user options for user type fields
 * @returns The formatted value
 */
export const formatFilterValue = (
  value: FilterValue,
  type: FilterFieldType,
  userOptions: UserOption[] = []
): string => {
  if (value === null || value === undefined) return '';

  switch (type) {
    case 'boolean': {
      return String(value);
    }
    case 'date': {
      return value instanceof Date
        ? value.toISOString().split('T')[0]
        : String(value);
    }
    case 'user': {
      const userId = String(value);
      return userOptions.find((user) => String(user.id) === userId)?.name || '';
    }
    default: {
      return String(value);
    }
  }
};

/**
 * Gets the default value for a given field type
 * @param type The type of the field
 * @param options Available options for user and list type fields
 * @returns The default value for the field type
 */
export const getDefaultValueForFieldType = (
  type: FilterFieldType,
  options: FilterOption[] = []
): FilterValue => {
  switch (type) {
    case 'boolean':
      return true;
    case 'number':
      return 0;
    case 'date':
      return new Date().toISOString().split('T')[0];
    case 'user':
    case 'list':
    case 'status': {
      const firstOption = options[0];
      if (!firstOption) return '';

      return 'value' in firstOption ? firstOption.value : firstOption.id;
    }
    default:
      return '';
  }
};

/**
 * Type guard to check if a value is a valid FilterValue
 */
export function isFilterValue(value: unknown): value is FilterValue {
  if (value === null) return false;
  if (value === undefined) return true;

  if (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean' ||
    value instanceof Date
  ) {
    return true;
  }

  if (Array.isArray(value)) {
    return value.every(
      (item) =>
        typeof item === 'string' ||
        typeof item === 'number' ||
        typeof item === 'boolean'
    );
  }

  return false;
}

/**
 * Validates and normalizes a filter value based on the operator type
 * @returns The normalized value if valid, null if invalid
 */
export const validateAndNormalizeValue = (
  value: FilterValue,
  operator: SearcherDocumentSearchFilterOperator,
  fieldOption?: FilterFieldOptions,
  getOptionsForField?: (
    fieldOption?: FilterFieldOptions
  ) => Array<UserOption | ListOption>
): FilterValue | null => {
  if (isNoValueOperator(operator)) {
    return undefined;
  }

  const isEmptyValue =
    value === undefined ||
    value === '' ||
    (Array.isArray(value) && value.length === 0);
  if (isEmptyValue) {
    if (!fieldOption || !getOptionsForField) {
      return null;
    }

    return getDefaultValueForFieldType(
      fieldOption.type,
      getOptionsForField(fieldOption)
    );
  }

  const isArrayOp = isArrayOperator(operator);
  const isValueArray = Array.isArray(value);

  if (isArrayOp && !isValueArray) {
    return [String(value)];
  }
  if (!isArrayOp && isValueArray) {
    return value.length > 0 ? value[0] : null;
  }

  return value;
};
