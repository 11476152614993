/**
 * Generates a deterministic string representation of an object's selected fields.
 * Useful for React's useMemo dependency arrays when memoizing based on specific object properties.
 *
 * @template T - Type of the object, must be a record with string keys and unknown values
 * @param obj - The object to generate a token from. Can be null or undefined.
 * @param fields - Array of field names from the object to include in the token
 * @returns A deterministic string representation of the selected fields
 *
 * @example
 * ```ts
 * // Use as dependency in useMemo
 * const memoizedValue = useMemo(() => {
 *   // expensive computation using user.id and user.name
 * }, [generateMemoToken(user, ['id', 'name'])]);
 * ```
 */
export const generateMemoToken = <T extends Record<string, unknown>>(
  obj: T | null | undefined,
  fields: (keyof T)[] | keyof T
): string => {
  const fieldArray = Array.isArray(fields) ? fields : [fields];

  const selectedFields = fieldArray.reduce(
    (acc, field) => ({
      ...acc,
      [field]: obj?.[field],
    }),
    {}
  );

  const sortedKeys = Object.keys(selectedFields).sort();

  return JSON.stringify(selectedFields, sortedKeys);
};
